import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
@Component({
  selector: 'app-list-customer',
  templateUrl: './list-customer.component.html',
  styleUrls: ['./list-customer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListCustomerComponent implements OnInit {
  public customers = [
    {
      image: 'assets/images/landing-page/LogoRecruitery1.png',
      title: 'Recruitery',
    },
    {
      image: 'assets/images/landing-page/logo_CMC_TSSG.png',
      title: 'CMC TSSG',
    },
    {
      image: 'assets/images/landing-page/logoTH2.png',
      title: 'TH',
    },
    {
      image: 'assets/images/landing-page/cmc-telecom.png',
      title: 'TH',
    },
    {
      image: 'assets/images/landing-page/LogoRecruitery1.png',
      title: 'Recruitery',
    },
    {
      image: 'assets/images/landing-page/logo_CMC_TSSG.png',
      title: 'CMC TSSG',
    },
    {
      image: 'assets/images/landing-page/logoTH2.png',
      title: 'TH',
    },
    {
      image: 'assets/images/landing-page/cmc-telecom.png',
      title: 'TH',
    },
    
  ];
  public slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    dots: false,
    arrows: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  constructor(
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
   }

  ngOnInit(): void {
  }

}
