<app-header></app-header> 

<div class="container-fluid block2">
    <div class="container" id="ngx-datatable-row-details">
      
      <div>
        
  
          <div class="mt-4 mt-lg-5">
            <div class="col-xxl-12">
              <div class="mb-3 col-xxl-12">
                <!-- <label for="filePdf" class="form-label" [translate]="'RESUME_PARSER.PARSE_RESUME.UPLOAD_UP_TO'"></label>
                <input class="form-control" #InputVar (change)="onFileSelect($event)" type="file" name="filePdf"
                  accept=".pdf, .doc, .docx, .png, .jpg, .jpeg, .xlsx" multiple required /> -->
                
                  <label ng2FileDrop [ngClass]="{ 'file-drop-over': hasBaseDropZoneOver }" (onFileDrop)="onFileDrop($event)"
                  [uploader]="uploader" class="card input-file d-flex justify-content-center align-items-center"
                  for="file-upload-single">
                  <div class="card-body child-input-file">
                    <div class="row">
                      <div class="col-md-12 col-lg-12">
                        <div class="py-5 text-center font-medium-5 file-drop-zone">
                          <input type="file" [uploader]="uploader" id="file-upload-single" ng2FileSelect (change)="handleFile($event)" accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                            style="display: none" />
                          <div class="upload-option">
                            <span style="color: #00a5f4" translate="RESUME_PARSER.PARSE_RESUME.LABEL_UPLOAD"></span>
                            <div class="contain-input d-flex justify-content-center">
                              <div class="mx-1 mt-1">
                                <div class="upload-button d-flex">
                                  <img src="assets/images/backgrounds/icon-floder.svg" width="25px" height="25px" />
                                  <span translate="RESUME_PARSER.PARSE_RESUME.FROM_DEVICE"></span>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
                
              </div>
            </div>
          </div>
          <div class="row" [hidden]="!displayTable">
            <div class="col-md-3 col-12">
              <div class="d-flex justify-content-between align-items-center mb-1">
                <div class="btn-group">
                    <div ngbDropdown>
                      <button
                        ngbDropdownToggle
                        class="btn btn-success"
                        type="button"
                        id="dropdownMenuButton"
                        rippleEffect
                        [translate]="'RESUME_PARSER.MANAGE_RESUME.DOWNLOAD_TYPE'"
                        >
                        
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                        <a ngbDropdownItem (click)="export_csv()">Csv</a>
                        <a ngbDropdownItem (click)="export_excel()">Excel</a>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <ngx-datatable [hidden]="!displayTable" id="table-user" #tableRowDetails class="bootstrap core-bootstrap"
            [rows]="resume_parser" [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber"
            [limit]="page.size" (page)="setPage($event)" [rowHeight]="50" class="bootstrap core-bootstrap"
            [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="false"
            [limit]="basicSelectedOption">
            <!-- <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
          [resizeable]="false">
          <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
            let-selectFn="selectFn">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                (change)="selectFn(!allRowsSelected)" id="headerFolderChkbxRef" />
              <label class="custom-control-label" for="headerFolderChkbxRef"></label>
            </div>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-isSelected="isSelected"
            let-onCheckboxChangeFn="onCheckboxChangeFn">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                (change)="onCheckboxChangeFn($event)" id="rowFolderChkbxRef{{ rowIndex }}" />
              <label class="custom-control-label" for="rowFolderChkbxRef{{ rowIndex }}"></label>
            </div>
          </ng-template>
        </ngx-datatable-column> -->
            <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate" [width]="2*widthColumn">
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100">
                  <a routerLink="/resume/{{row.id}}"
                    class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.file_name}}">{{ row.file_name }}</a>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.STATUS'|translate" prop="status" [width]="widthColumn">
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100">
                  <span class="badge bg-{{status_color[row.status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom"
                    title="{{row.message}}">{{row.status}}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CONF_SCORE'|translate" [width]="widthColumn">
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100">
                  <span class="badge bg-success"
                    *ngIf="row.conf_score>range_conf.success">{{row.conf_score*100|number:'1.2-2'}}%</span>
                  <span class="badge bg-warning"
                    *ngIf="row.conf_score>range_conf.warning && row.conf_score < range_conf.success">{{row.conf_score*100|number:'1.2-2'}}%</span>
                  <span class="badge bg-danger"
                    *ngIf="row.conf_score<range_conf.warning">{{row.conf_score*100|number:'1.2-2'}}%</span>
                </div>
              </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CREATE_AT'|translate" [width]="widthColumn">
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100">
                  <span>{{ row.modified_at|date:'short' }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <!-- ACTIONSSSSSSSSSSSSSSSSS -->
            <!-- <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.FIRST_NAME'|translate" prop="first_name" width="140">
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.LAST_NAME'|translate" prop="last_name" width="140">
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.EMAIL'|translate" prop="email" width="200">
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'|translate" prop="phone_number" width="140">
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.GENDER'|translate" prop="gender" width="100">
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.DATE_OF_BIRTH'|translate" width="200">
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100">
                  <span>{{ row.date_of_birth|date:'longDate' }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column> -->
            <!-- <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'|translate" [width]="1.5*widthColumn">
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100">
                  <span class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.current_title}}">{{ row.current_title }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column> -->
            <!-- <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_COMPANY'|translate" [width]="widthColumn">
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100">
                  <span class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.current_company}}">{{ row.current_company }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-column
                      [name]="'RESUME_PARSER.MANAGE_RESUME.ACTION' | translate"
                      [sortable]="false"
                      [sortable]="false"
                      [width]="widthColumn"
                      [draggable]="false"
                    >
                      <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="d-flex align-items-center">
                          
                          <a type="button" href="javascript:void(0)" (click)="deleteResume(row.id)" title="Delete" class="btn btn-icon btn-flat-danger rounded-circle" rippleEffect>
                            <span
                                  class="d-flex align-items-center justify-content-center"
                                  ><i class="fa fa-trash-o"></i>
                                </span>
                              </a>
                          
                        </div>
                        
                      </ng-template>
                    </ngx-datatable-column>
          </ngx-datatable>
        
      </div>
    </div>
  </div>
  
  <app-list-customer></app-list-customer>
  <app-solution></app-solution>
  <app-chose></app-chose>
  <app-pricing></app-pricing>
  <app-footer-landing></app-footer-landing>