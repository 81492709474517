import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SearchResume } from '../../models/resume.model';
import { Router } from '@angular/router';
import { TalentPoolService } from '../../services/talent-pool.service';
import { ResumeParserService } from 'app/main/resume-parser/services/resume-parser.service';
import { locale as enLocale } from "../../i18n/en";
import { locale as vnLocal } from "../../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
@Component({
  selector: 'app-save-resume-jd',
  templateUrl: './save-resume-jd.component.html',
  styleUrls: ['./save-resume-jd.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SaveResumeJdComponent implements OnInit {
  public saved_cvs: SearchResume[] = []
  public jd_id!: any

  public errorMessagesListSavedResumeByJd: any
  public page = 1
  public pageSize = 8
  public num_page = 0
  public searchFullname: string = '';
  public searchAddress: string = '';
  public searchCurrentTitle: string = '';
  public searchCurrentCompany: string = '';
  public list_form_table = false
  public href: string=""
  public status_color: any = {
    "Approved": 'success',
    "SF4C_background_failed": 'warning',
    "Done": "primary",
    "Failure": "danger",
    "Processing": "info",
    "Wait_to_process":"dark",
    "Suspended":"muted",
    "Modified":"warning",
    "SF4C_wait_to_upload":"dark",
    "SF4C_failed":"secondary",
    "SF4C_uploading":"info",
    "SF4C_uploaded":"success",
    "Base_upload_failed":"secondary",
    "Base_uploading":"info",
    "Base_upload_success":"success"
  }
  public have_hcm_system_id = false
  constructor(
    private _router: Router,
    private talentPoolService: TalentPoolService,
    private resumeParserService: ResumeParserService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
   }

  upload_sf4c(){
    let id_approved: number[] = []
    for (let i=0;i<this.saved_cvs.length;i++){
      if(this.saved_cvs[i].status=="Approved"){
        id_approved.push(this.saved_cvs[i].id)
      }
    }
    this.resumeParserService.sf4c_upload(id_approved, this.jd_id.toString()).subscribe(
      (res)=>{
        if(res.status){
          this._router.navigateByUrl("/manage-resume");
        }
      },
      (err)=>{
        console.error("Error: "+err)
      }
    )
  }
  SearchFullname(){
    // alert(this.searchText)
    if(this.searchFullname!== ""){
      let searchValue = this.searchFullname.toLocaleLowerCase();

      this.saved_cvs = this.saved_cvs.filter((saved_cvs_:any) =>{
        return saved_cvs_.full_name.toLocaleLowerCase().match(searchValue )
        ;
      // you can keep on adding object properties here

            });

          }
          else{
            this.GetSavedCvs()
          }
        }
        SearchAddress(){
          // alert(this.searchText)
          if(this.searchAddress!== ""){
            let searchValue = this.searchAddress.toLocaleLowerCase();
      
            this.saved_cvs = this.saved_cvs.filter((saved_cvs_:any) =>{
              return saved_cvs_.address.toLocaleLowerCase().match(searchValue )
              ;
            // you can keep on adding object properties here
      
                  });
      
                }
                else{
                  this.GetSavedCvs()
                }
              }
              SearchCurrentTitle(){
                // alert(this.searchText)
                if(this.searchCurrentTitle!== ""){
                  let searchValue = this.searchCurrentTitle.toLocaleLowerCase();
            
                  this.saved_cvs = this.saved_cvs.filter((saved_cvs_:any) =>{
                    return saved_cvs_.current_title.toLocaleLowerCase().match(searchValue )
                    ;
                  // you can keep on adding object properties here
            
                        });
            
                      }
                      else{
                        this.GetSavedCvs()
                      }
                    }
                    SearchCurrentCompany(){
                      // alert(this.searchText)
                      if(this.searchCurrentCompany!== ""){
                        let searchValue = this.searchCurrentCompany.toLocaleLowerCase();
                  
                        this.saved_cvs = this.saved_cvs.filter((saved_cvs_:any) =>{
                          return saved_cvs_.current_company.toLocaleLowerCase().match(searchValue )
                          ;
                        // you can keep on adding object properties here
                  
                              });
                  
                            }
                            else{
                              this.GetSavedCvs()
                            }
                          }
  GetSavedCvs(){
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
      });
    let href = window.location.href
    this.jd_id = href.split("?hcm_system_id")[0].split("saved-cvs/")[1]
    if(href.split("?hcm_system_id=")[1]!=''){
      this.have_hcm_system_id = true
    }
    this.talentPoolService.list_saved_resume_by_jd(Number(this.jd_id)).subscribe(
      (res)=>{
        this.saved_cvs = res
      },
      (err)=>{
        this.errorMessagesListSavedResumeByJd = err
      }
    )
  }
  toggle_list_form_table(){
    this.list_form_table = !this.list_form_table
  }
  ngOnInit(): void {
    this.GetSavedCvs()
  }

}
