import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SearchResume } from '../models/resume.model';
import { CV_task } from '../models/job.model';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TalentPoolService } from '../services/talent-pool.service';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
@Component({
  selector: 'app-search-resume',
  templateUrl: './search-resume.component.html',
  styleUrls: ['./search-resume.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchResumeComponent implements OnInit {
  public resultSearchCv: SearchResume[] = []

  public selectedCity: string[] = []
  public job_title: string = ''
  public c = ['Lào Cai',
    'Lai Châu',
    'Phú Thọ',
    'Quảng Nam',
    'Thái Nguyên',
    'Hải Dương',
    'Bạc Liêu',
    'Quảng Ninh',
    'Cần Thơ',
    'Bến Tre',
    'Bình Dương',
    'Tiền Giang',
    'Lâm Đồng',
    'Sóc Trăng',
    'Phú Yên',
    'Quảng Bình',
    'Hải Phòng',
    'Hưng Yên',
    'Hoà Bình',
    'Hậu Giang',
    'Tây Ninh',
    'Hà Nội',
    'Bình Định',
    'Lạng Sơn',
    'Nam Định',
    'Bắc Ninh',
    'Kiên Giang',
    'Vĩnh Long',
    'Long An',
    'Nghệ An',
    'Đắk Lắk',
    'An Giang',
    'Hà Giang',
    'Bắc Giang',
    'Kon Tum',
    'Cà Mau',
    'Quảng Trị',
    'Yên Bái',
    'Cao Bằng',
    'Sơn La',
    'Gia Lai',
    'Điện Biên',
    'Bà Rịa - Vũng Tàu',
    'Thừa Thiên Huế',
    'Bình Phước',
    'Ninh Thuận',
    'Khánh Hòa',
    'Đắk Nông',
    'Quảng Ngãi',
    'Đồng Nai',
    'Tuyên Quang',
    'Bắc Kạn',
    'Vĩnh Phúc',
    'Đà Nẵng',
    'Thanh Hóa',
    'Trà Vinh',
    'Hồ Chí Minh',
    'Thái Bình',
    'Bình Thuận',
    'Hà Nam',
    'Ninh Bình',
    'Đồng Tháp',
    'Hà Tĩnh']
  public city = [{}];

  public fulltext_bool_expression: string = ''
  public experience_bool_expression: string = ''
  public education_bool_expression: string = ''
  public current_title_bool_expression: string = ''
  public current_company_bool_expression: string = ''

  public page_num = 0
  public num_result = 0
  public num_page = 0
  public pageSizeNum=12

  public errorMessagesSearchResume!: any
  public next_page: boolean = false;
  public page = 1
  public pageSize=8
  public list_task: CV_task[] =[]
  public save_cv: string = ''
  public loading = false
  public positive = false
  public negative = false
  public errorMessagesListTasksSave: any
  public errorMessagesSaveCv: any
  public arr_checkboxListTasks:string[]=[]
  public hidden_menu = false
  
  formCheckBoxListTasks = this.fb.group({
    checkResumeArrayListTasks: this.fb.array([],Validators.required)
  })
  public arr_checkboxSaveCv:string[]=[]
  
  formCheckBoxSaveCv = this.fb.group({
    checkResumeArraySaveCv: this.fb.array([],Validators.required)
  })
  constructor(
    private _router: Router,
    private fb: FormBuilder,
    private talentPoolService: TalentPoolService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,

  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
   }
  onSubmit(){
    this.SearchCv()
  }
  SearchCv(){
    this.loading = true
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
      });
    this.page_num=0;
    this.num_result=0;
    this.resultSearchCv=[]
    let page_num= this.page_num;
    let job_title= this.job_title;
    let city= this.selectedCity;
    let fulltext_bool_expression= this.fulltext_bool_expression;
    let education_bool_expression= this.education_bool_expression;
    let experience_bool_expression= this.experience_bool_expression;
    let current_title_bool_expression= this.current_title_bool_expression;
    let current_company_bool_expression= this.current_company_bool_expression;
    this.talentPoolService.search_resume(page_num, job_title, city,fulltext_bool_expression,education_bool_expression,
      experience_bool_expression,current_title_bool_expression,current_company_bool_expression).subscribe(
        (res)=>{
          this.resultSearchCv = res.result;
          this.next_page = res.next_page;
          this.num_result = res.num_result;
          this.num_page = Math.min(res.num_result, 1200);
          this.loading = false
        },
        (err)=>{
          this.errorMessagesSearchResume = err
        }
      )
  }
  pageChangeEventGetCv(event: number){
    this.page_num = event;
    this.SearchCv();
  }
  ngOnInit(): void {
    this.SearchCv()
    this.c.sort((a, b) => a.localeCompare(b, "fr", { ignorePunctuation: true }));
    for(let i=0;i<this.c.length;i++){
      this.city.push({"name":this.c[i],"value":this.c[i]})
    }
  }

}
