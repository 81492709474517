import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Jr, ResumeParser } from '../models/resume.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, concat, interval } from 'rxjs';
import { ResumeParserService } from '../services/resume-parser.service';
import saveAs from 'file-saver';
import { Page } from '../models/paging.model';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { switchMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-parse-resume',
  templateUrl: './parse-resume.component.html',
  styleUrls: ['./parse-resume.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ParseResumeComponent implements OnInit {
  public isLoading = false
  public isAuthenticated=false
  public resume_parser: ResumeParser[]=[]
  public id_resume_upload!: number
  public tag_status_done = true
  public tag_stat = false
  public selectedPdf: string=""
  public myFiles:File[]=[];
  basicSelectedOption = 5
  public page = new Page({});
  ColumnMode = ColumnMode;
  widthColumn: number = 0;
  @ViewChild(DatatableComponent) tableRowDetails: DatatableComponent;
  INTERVAL_TIME = 3000;
  intervalSubscription: Subscription;


  // page = 1;
  // pageSize = 5;
  // pageSizes = [5, 10, 15];
  // totalItems: number = 0

  public status_color: any = {
    "Approved": 'success',
    "SF4C_background_failed": 'warning',
    "Done": "primary",
    "Failure": "danger",
    "Processing": "info",
    "Wait_to_process":"dark",
    "Suspended":"secondary",
    "Modified":"warning",
    "SF4C_wait_to_upload":"dark",
    "SF4C_failed":"secondary",
    "SF4C_uploading":"info",
    "SF4C_uploaded":"success"

  }
  public status: string = ''
  form!: FormGroup
  public success=false
  public src_images: any;
  statusApproveList!: string
  tag_statusApproveList = false
  //sf4c auto upload
  public status_sf4c_auto_upload:string=''
  public tag_resultConnectSf4c_exit = false
  public arr_checkboxJr:string[]=[]
  public arr_checkbox_current_title:string[]=[]
  public resultJr: Jr[]=[]
  totalItemsJr: number = 0
  public searchText: string = '';
  public pageJr=1
  public isLoadingJr = false
  formCheckBoxJr = this.fb.group({
    checkResumeArrayJr: this.fb.array([],Validators.required)
  })
  public range_conf: any = {
    'success': 0.6,
    'warning': 0.4,
  }
  public tag_export = false
  public limitedError!: string
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  @ViewChild('InputVar')
  myInputVariable!: ElementRef;

  public hasBaseDropZoneOver: boolean = false;
  public uploader: FileUploader = new FileUploader({
    url: environment.apiUrl,
    isHTML5: true,
  });

  constructor(
    private fb: FormBuilder,
    private resumesService: ResumeParserService,
    private elementRef: ElementRef,
    private modalService: NgbModal,
    private toastr: ToastrService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,

  ){
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this.form = new FormGroup({
      filePdf: new FormControl(null, Validators.required),
    });

  }
  selectedFiles?: FileList;

    onFileDrop(fileList: File[]) {
      this.myFiles = fileList
      this.form.patchValue({ filePdf: fileList });
      this.ParseListResume()
      
    }
  
    async handleFile(e: any) {
      this.myFiles = []

      this.selectedFiles = e.target.files;
      if (e.target.files.length > 50){
        alert('To many files')
        this.myFiles = []
      }else{
        for (var i =0; i< e.target.files.length;i++){
          
          if(e.target.files[i].size<10000000){
            this.myFiles.push(e.target.files[i])
            
          }
          
        }
        if (this.selectedFiles) {
          
          this.form.patchValue({ filePdf: this.selectedFiles });
    
          
        }
        this.ParseListResume()
      }
    }
    ParseListResume(){
      this.resume_parser = []
      this.tag_statusApproveList = false;
      const resumeSubscr = this.resumesService.uploadResume(this.myFiles)
      .subscribe(
        (res)=>{
          this.id_resume_upload = res.id;
          const resizeTableObserver = new ResizeObserver((entries) => {
            this.widthColumn = entries[0].contentRect.width / 7;
          });
          resizeTableObserver.observe(
            this.elementRef.nativeElement.querySelector(
              "#ngx-datatable-row-details"
            )
          );
          this.getListResume();
          // if(this.resumesService.statusValue != "Done"){
          //   setTimeout(() => {this.getTaskStatus();
          //     this.getFillListResume();
          //       },1000 );
          // }
          this.form.reset();

        },
        (err)=>console.error("err "+err)
      )
      this.unsubscribe.push(resumeSubscr);
    }
    getListResume(){
      if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
      const api$ = this.resumesService.getFillListResume(this.id_resume_upload, this.page.pageNumber, this.page.size)
      const intervalAfterCallApi = interval(this.INTERVAL_TIME).pipe(
        switchMap(() =>
          this.resumesService.getFillListResume(this.id_resume_upload, this.page.pageNumber, this.page.size)
        )
      );
      this.intervalSubscription = concat(api$, intervalAfterCallApi).subscribe(
        (res)=>{
          this.resume_parser = res.results;
          this.page.totalElements = res.count
          this.isLoading = false
        }
      )
    }
    getFillListResume(){
      this.isLoading = true
      const getFillListResumeSubscr = this.resumesService.getFillListResume(this.id_resume_upload, this.page.pageNumber, this.page.size)
      .subscribe(
        (res)=>{
          this.resume_parser = res.results;
          this.page.totalElements = res.count;
          this.isLoading = false
        },
        (err)=>console.error("err "+err)
      )
      this.unsubscribe.push(getFillListResumeSubscr);
    }
    pageChangeEvent(event: number){
      this.page.pageNumber = event;
      this.getFillListResume();
  
    }
    handlePageSizeChange(event: any): void {
      this.page.size = event.target.value;
      this.page.pageNumber = 1;
      this.getFillListResume();
  
    }
    setPage(pageInfo) {
      this.page.pageNumber = pageInfo.offset;
      this.getFillListResume();

    }
    getTaskStatus(){
      const getTaskStatusSubscr = this.resumesService.getTaskStatus(this.id_resume_upload)
      .subscribe(
        (res)=>{
          (res.status=="Done"||res.status=="Failure"||res.status=="Suspended")?this.tag_status_done==true:this.tag_status_done==false
        },
        (err)=>console.error("err "+err)
      )
      this.unsubscribe.push(getTaskStatusSubscr);

    }
    approveListResume(){
      const approveListResumeSubscr = this.resumesService.approveListResume(this.id_resume_upload)
      .subscribe(
        (res)=>{
          this.statusApproveList = res.status;
          if(res.status=='Approve task done'){
            this.tag_statusApproveList = true;
          }
        },
        (err)=>console.error("err "+err)
      )
      this.unsubscribe.push(approveListResumeSubscr);
    }
    //sf4c upload
    modalOpen(modalBasic:any){
    
      this.modalService.open(modalBasic, {
        windowClass: "modal",
        size: 'lg',
      });
    }
  modalOpenUploadSf4c(modalBasic:any) {
    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
    this.getJr();
    }
    
    getJr(){
      this.isLoadingJr=true
      let url_dynamic = ""
      for (let i=0;i<this.arr_checkbox_current_title.length;i++){
        let url_current = "job_title="+this.arr_checkbox_current_title[0]
        url_dynamic = url_dynamic ? url_dynamic + "&" + url_current : url_current
      }
     const jrSubscr = this.resumesService.getJrWithQuery(url_dynamic).subscribe(
      (res)=>{   
        this.resultJr = res;
        this.totalItemsJr = res.length
        this.isLoadingJr = false
      },
      (err)=>{
        console.error("err: "+err)
        this.isLoadingJr = false;
      }
     )
     this.unsubscribe.push(jrSubscr);

    }
  
  onCheckboxChangeJr(e:any) {
    const checkResumeArrayJr: FormArray = this.formCheckBoxJr.get('checkResumeArrayJr') as FormArray;
    if (e.target.checked) {
      checkResumeArrayJr.push(new FormControl(e.target.value)); 
      this.arr_checkboxJr.push(e.target.value)
    
    } else {
      let i: number = 0;
      checkResumeArrayJr.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArrayJr.removeAt(i);
          this.arr_checkboxJr=this.arr_checkboxJr.filter(item=>item!==e.target.value)
          return;
        }
        i++;
      });
    }
  }
  FormCheckBoxJr(){
    // this.loading_=true
    const formJrSubscr = this.resumesService.sf4c_auto_upload(this.id_resume_upload, this.arr_checkboxJr).subscribe(
      (res)=>{
        if (this.resume_parser.length>0 && this.resume_parser[this.resume_parser.length-1].status!='SF4C_uploaded'&&this.resume_parser[this.resume_parser.length-1].status!='SF4C_failed'){
          this.toastr.success('Upload SuccessFactors Success');
          

        }
      },
      (err)=>{
        console.error("err: "+err)
      }   
    )
    this.unsubscribe.push(formJrSubscr);

  }
  Search(){
    // alert(this.searchText)
    if(this.searchText!== ""){
      let searchValue = this.searchText.toLocaleLowerCase();
      

      this.resultJr = this.resultJr.filter((resultJr_:any) =>{
        return resultJr_.jobTitle.toLocaleLowerCase().match(searchValue )
        ;
      // you can keep on adding object properties here

            });
            
          }
          else{
            this.getJr();
          }
        }

  getListCredential(){
    const credentialSubscr = this.resumesService.getListCredential().subscribe(
      (res)=>{
        this.tag_resultConnectSf4c_exit = true
      },
      (err)=>{
        console.error("err: "+err)
      }   
    )
    this.unsubscribe.push(credentialSubscr);
    }
    
    export(){
      this.tag_export = true;
      const type_file = "excel";
      this.resumesService.downloadResume(type_file).subscribe(
        (res)=>{
          const filename = `Result parse resumes.xlsx`;
          let blob = new Blob([res],{type: 'application/vnd.ms-excel;charset=utf-8'});
          this.tag_export = false;
          saveAs(blob, filename);
        }
      )
    }
    ngOnDestroy() {
      this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
    ngOnInit(): void {
      this.getListCredential()
    }

}
