import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, interval, throwError } from 'rxjs';
import { CHR_Picklist, Jr, ListResumeParser, Mapping_Picklist, Matching_JR, ResultConnectSf4c, ResultResume, ResultResumeSettings, ResultSourceMapping, ResultUpload, ResumeParser, Sf4cCandidate, Sf4cUpload, Stat, StatResumeMatching, Status, Successfactor_update_status } from '../models/resume.model';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResumeParserService {
  public status: Observable<string>;
  private statusSubject: BehaviorSubject<string>;
  private BASE_URL = `${environment.apiUrl}`;
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService
  ) {
    this.statusSubject = new BehaviorSubject<string>(localStorage.getItem("status"));
    this.status = this.statusSubject.asObservable();
   }
   get statusValue(): string{
    return this.statusSubject.value;
  }
  public setStatusValue(value: string){
    this.statusSubject.next(value)
  }
  parseResume(file: File): Observable<ResultResume>{
    const profileData = new FormData();
    profileData.append("file", file);
    const url = `${this.BASE_URL}/parse-resume/`;
    let headers = new HttpHeaders();
    // headers = headers.append('content-type', 'multipart/form-data');
    headers = headers.append('enctype', 'multipart/form-data');
    const httpOptions = {
        headers: headers
    };
    return this._http.post<ResultResume>(url, profileData, httpOptions)
  }
  //get resume parser
getListResume(page: number, page_size: number): Observable<ListResumeParser>{
  const url = `${this.BASE_URL}/resume/?page=${page+1}&page_size=${page_size}`;
  return this._http.get<ListResumeParser>(url);
}
//get all resume
getAllResume(url_dynamic: string):Observable<ResumeParser[]>{
  const url = `${this.BASE_URL}/resume/select_all?${url_dynamic}`;
  return this._http.get<ResumeParser[]>(url);
}
//get resume parser
getFillListResume(id: number, page: number, page_size: number): Observable<ListResumeParser>{
  const url = `${this.BASE_URL}/resume/?task=${id}&page=${page+1}&page_size=${page_size}`;
  return this._http.get<ListResumeParser>(url);
}
//get resume parser
getFilterListResume(url_dynamic: string, page: number, page_size: number): Observable<ListResumeParser>{
  const url = `${this.BASE_URL}/resume/?page=${page+1}&page_size=${page_size}`+`${url_dynamic}`;
  return this._http.get<ListResumeParser>(url);
}
//get task status
getTaskStatus(id: number): Observable<ResultUpload>{
  const url = `${this.BASE_URL}/resume_parser/task/${id}`;
  return this._http.get<ResultUpload>(url).pipe(
    map((res)=>{
      
      if(res.status=="Done"){
        this.statusSubject.next(res.status)
      }
      return res;
    })
    
  );
}

//Approve resume
approveResume(id: number): Observable<Status>{
  const url = `${this.BASE_URL}/resume/${id}/approve`;
  return this._http.put<Status>(url,{})
}
//Approve resume list
approveListResume(task_id: number): Observable<Status>{
  const url = `${this.BASE_URL}/resume/approve`;
  return this._http.post<Status>(url,{task_id})
}
//update resume
updateResume(id: number, first_name: string, last_name: string, email: string, phone_number: string, gender: string, date_of_birth: any, address: string, category: string, year_exp: string, experience: any[], education: any[]): Observable<ResumeParser>{
  const url = `${this.BASE_URL}/resume/${id}`;
  return this._http.put<ResumeParser>(url, {first_name, last_name, email, phone_number, gender, date_of_birth, address, category, year_exp, experience, education})
}
//upload resume
uploadResume(files: File[]): Observable<ResultUpload>{
  const uploadResumeData = new FormData();
  for (var i=0; i< files.length; i++){
    uploadResumeData.append("file", files[i]);
  }
  console.log("uploadResumeData",uploadResumeData)
  const url = `${this.BASE_URL}/resume_parser/upload`;
  let headers = new HttpHeaders();
  headers = headers.append('enctype', 'multipart/form-data');
  const httpOptions = {
    headers: headers
  }
  return this._http.post<ResultUpload>(url, uploadResumeData, httpOptions)
}
//get detail resume
getDetailResume(id: number): Observable<ResumeParser>{
  const url = `${this.BASE_URL}/resume/${id}`;

  return this._http.get<ResumeParser>(url);
}
//Get Censor resume
getCensorResume(id:number): Observable<ResumeParser>{
  const url = `${this.BASE_URL}/resume/${id}/censor`;
  return this._http.get<ResumeParser>(url)
}
//Get DeCensor resume
getDeCensorResume(id: number): Observable<ResumeParser>{
  const url = `${this.BASE_URL}/resume/${id}/decensor`;
  return this._http.get<ResumeParser>(url)
}
//download resume
downloadResume(type_file: string): Observable<Blob>{
  const url = `${this.BASE_URL}/resume/export/?type=${type_file}`;
  return this._http.get(url, {responseType: 'blob'});
}

//delete resume
deleteResume(id: number): Observable<any>{
  const url = `${this.BASE_URL}/resume/${id}`;
  return this._http.delete<any>(url)
}
//delete education
deleteEducation(id: number): Observable<any>{
  const url = `${this.BASE_URL}/background_edu/${id}`;
  return this._http.delete<any>(url)
}
//delete experience
deleteExperience(id:number):Observable<any>{
  const url = `${this.BASE_URL}/background_exp/${id}`;
  return this._http.delete<any>(url)
}
//upload sf4c
uploadSf4c(): Observable<Status>{
  const url = `${this.BASE_URL}/sourcing/sf4c_upload`;
  return this._http.post<Status>(url, {}).pipe(
    map((respData)=>{
      setTimeout(() => {
        this._toastrService.success(
            "`   `,",
            respData['message'],
            {
            toastClass: "toast ngx-toastr",
            closeButton: true,
            }
        );
        }, 300);
        return respData;
    }),
    catchError((error) => {
      setTimeout(() => {
        console.log("ALO");
        this._toastrService.success(
            "Upload Failed",
            error.error['message'],
            {
            toastClass: "toast ngx-toastr",
            closeButton: true,
            }
        );
        }, 300);
        return throwError(error);

    })
    );

}

//get credential sf4c connector
getCredential(file: File, api_key: string, company_id: string, username: string, token_url: string): Observable<ResultConnectSf4c>{
  const url = `${this.BASE_URL}/sourcing/credentials`;
  const sourceData = new FormData();
  sourceData.append("file", file);
  sourceData.append("api_key", api_key);
  sourceData.append("company_id", company_id);
  sourceData.append("username",username);
  sourceData.append("token_url",token_url);
  let headers = new HttpHeaders();
  headers = headers.append('enctype', 'multipart/form-data');
  const httpOptions = {
    headers: headers
  };
  return this._http.post<ResultConnectSf4c>(url, sourceData, httpOptions)
  }
//get list credential
 getListCredential():Observable<ResultConnectSf4c[]>{
  const url = `${this.BASE_URL}/sourcing/credentials`
  return this._http.get<ResultConnectSf4c[]>(url)
 }

//Get Resume parser settings
resumeSettings():Observable<ResultResumeSettings>{
  const url = `${this.BASE_URL}/configure/setting`;
  return this._http.get<ResultResumeSettings>(url)
}
//Put Resume parser settings
putResumeSettings(formData: any):Observable<ResultResumeSettings>{
  const url = `${this.BASE_URL}/configure/setting`;
  return this._http.put<ResultResumeSettings>(url, formData)
}
//sync email
syncEmail():Observable<Status>{
  const url = `${this.BASE_URL}/resume_parser/sync-email`;
  return this._http.get<Status>(url)
}
//get jr
getJr():Observable<Jr[]>{
  const url = `${this.BASE_URL}/sourcing/jobrequisition`;
  return this._http.get<Jr[]>(url)
}
//get jr with query
getJrWithQuery(url_dynamic: string):Observable<Jr[]>{
  const url = `${this.BASE_URL}/sourcing/jobrequisition?${url_dynamic}`;
  return this._http.get<Jr[]>(url)
}
//get jr detail
getDetailJr(id: any): Observable<Jr>{
  const url = `${this.BASE_URL}/sourcing/jobrequisition/${id}`;
  return this._http.get<Jr>(url)
}

//getSf4cCandidate
getSf4cCandidate(email: string): Observable<Sf4cCandidate>{
  const url = `${this.BASE_URL}/sourcing/candidate?email=${email}`;
  return this._http.get<Sf4cCandidate>(url)
}
//sf4c upload
sf4c_upload(id:any[], jobReqId: any[]):Observable<Sf4cUpload>{
  const url = `${this.BASE_URL}/sourcing/sf4c_upload`;
  return this._http.post<Sf4cUpload>(url,{id, jobReqId})
}
//sf4c upload auto
sf4c_auto_upload(task_id: number, jobReqId: any[]): Observable<Sf4cUpload>{
  const url = `${this.BASE_URL}/sourcing/sf4c_auto_upload`;
  return this._http.post<Sf4cUpload>(url, {task_id,jobReqId})
}
//update jr
updateJA(id:any[], jobReqId: any[]):Observable<Sf4cUpload>{
  const url = `${this.BASE_URL}/sourcing/jobrequisition/insert`;
  return this._http.post<Sf4cUpload>(url, {id,jobReqId})
}
//update sf4c resume
sf4c_sync(id: number, resume: any, sf4c_resume: any,jobReqId: number[]): Observable<Successfactor_update_status>{
  const url = `${this.BASE_URL}/sourcing/candidate/${id}/sf4c_sync`;
  return this._http.put<Successfactor_update_status>(url, {resume, sf4c_resume, jobReqId})
}
//Matching jr
matching_jr(id: number, url_dynamic: string): Observable<Matching_JR>{
  const url = `${this.BASE_URL}/resume/${id}/jr_matching`+`${url_dynamic}`;
  return this._http.get<Matching_JR>(url)
}
//Mapping picklist
get_CHR_picklist(url_dynamic: string): Observable<CHR_Picklist[]>{
  const url = `${this.BASE_URL}/configure/picklist?type=`+`${url_dynamic}`;
  return this._http.get<CHR_Picklist[]>(url)
}
get_mapping_picklist(): Observable<Mapping_Picklist[]>{
  const url = `${this.BASE_URL}/configure/picklist/mapping`;
  return this._http.get<Mapping_Picklist[]>(url)
}
post_mapping_picklist(options_mapping_picklist:any[]): Observable<Mapping_Picklist>{
  const url = `${this.BASE_URL}/configure/picklist/mapping`;
  return this._http.post<Mapping_Picklist>(url, {options_mapping_picklist})
}
//Source mapping
//Source mapping
get_source_mapping(): Observable<ResultSourceMapping>{
  const url = `${this.BASE_URL}/configure/setting/mapping`;
  return this._http.get<ResultSourceMapping>(url)
}
//Update source mapping
edit_source_mapping(id: number, config: any, jobapplication_config: any, company: number,autoupload_score:any): Observable<ResultSourceMapping>{
  const url = `${this.BASE_URL}/configure/setting/mapping`;
  return this._http.put<ResultSourceMapping>(url, {id, config, jobapplication_config, company,autoupload_score})
}
}
