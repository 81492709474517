<div class="content-wrapper container-xxxl p-0 card">
    <div class="content-body card-body">
        <div *ngIf="errorMessagesListUser; else Users">
            <p>Errors: <b style="color: red;">{{errorMessagesListUser}}</b></p>
          </div>
            <ng-template #Users>
            <div class="mt-2">
                <div class="row justify-content-end align-items-center mb-1">
                    <div class="mr-1">{{users.length}} users</div>
                            <div class="mr-1" *ngIf="currentUser.is_superuser"><button class="btn btn-outline-primary" (click)="modalOpenCreateUser(modalBasic);">Create user</button></div>
                            <ng-template #modalBasic let-modal>
                                <form [formGroup]="createUserForm" (ngSubmit)="CreateUser()">
                                    <div class="modal-header">
                                        <h4 class="modal-title"> Create user</h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                    <div class="modal-body" ngbAutofocus>
                                            <div class="form-group">
                                                <label for="first-name">First name</label>
                                                <input
                                                type="text"
                                                id="first-name"
                                                class="form-control"
                                                placeholder="First name"
                                                name="first-name"
                                                formControlName="first_name"
                                                required
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label for="last-name">Last name</label>
                                                <input
                                                type="text"
                                                id="last-name"
                                                class="form-control"
                                                placeholder="Last name"
                                                name="last-name"
                                                formControlName="last_name"
                                                required
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label for="first-name">Email</label>
                                                <input
                                                type="text"
                                                id="email"
                                                class="form-control"
                                                placeholder="Email"
                                                name="email"
                                                formControlName="email"
                                                required
                                                />
                                            </div>
                                            <div class="form-group" *ngIf="!editBoolean">
                                                <div class="d-flex justify-content-between">
                                                  <label for="login-password">Password</label
                                                  >
                                                </div>
                                                <div class="input-group input-group-merge form-password-toggle">
                                                  <input
                                                    [type]="passwordTextType ? 'text' : 'password'"
                                                    formControlName="password"
                                                    class="form-control form-control-merge"
                                                    [ngClass]="{
                                                      'is-invalid error': submitted && f.password.errors
                                                    }"
                                                    placeholder="············"
                                                    aria-describedby="login-password"
                                                    tabindex="2"
                                                  />
                                                  <div class="input-group-append">
                                                    <span class="input-group-text cursor-pointer"
                                                      ><i
                                                        class="feather font-small-4"
                                                        [ngClass]="{
                                                          'icon-eye-off': passwordTextType,
                                                          'icon-eye': !passwordTextType
                                                        }"
                                                        (click)="togglePasswordTextType()"
                                                      ></i
                                                    ></span>
                                                  </div>
                                                </div>
                                                <div
                                                  *ngIf="submitted && f.password.errors"
                                                  class="invalid-feedback"
                                                  [ngClass]="{ 'd-block': submitted && f.password.errors }"
                                                >
                                                  <div *ngIf="f.password.errors.required">Password is required</div>
                                                </div>
                                              </div>
                                              <div class="custom-control custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  class="custom-control-input"
                                                  id="checkSearchEngine"
                                                  formControlName="checkSearchEngine"
                                                />
                                                <label
                                                  class="custom-control-label"
                                                  for="checkSearchEngine"
                                                  
                                                >Permission search engine</label>
                                              </div>
                                              <div class="custom-control custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  class="custom-control-input"
                                                  id="checkParseResume"
                                                  formControlName="checkParseResume"
                                                />
                                                <label
                                                  class="custom-control-label"
                                                  for="checkParseResume"
                                                  
                                                >Permission parse resume</label>
                                              </div>
                                              <div class="custom-control custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  class="custom-control-input"
                                                  id="is_site_admin"
                                                  formControlName="is_site_admin"
                                                />
                                                <label
                                                  class="custom-control-label"
                                                  for="is_site_admin"
                                                  
                                                >Is site admin</label>
                                              </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button [disabled]="!createUserForm.valid" type="submit" (click)="modal.close('Accept click')" class="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </ng-template>
                            <div class="">
                                <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                                <input
                                    type="text"
                                    class="form-control"
                                    id="job-search"
                                    placeholder="Search email"
                                    
                                    [(ngModel)]="searchText"
                                    (input)="Search()"
                                />
                            </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover align-middle">
                        <thead>
                            <tr>
                                
                                <th >Name</th>
                                <th >Email</th>
                                <th >Permission parse resume</th>
                                
                                <th >Permission search engine</th>
                                
                                <th >Permissions talent pool</th>
                                <th >Is site admin</th>
                                <th >Last login</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of users
                            | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
                                
                                <td>
                                    
                                    <div class="">{{user.last_name}} {{user.first_name}}</div>
                                       
                                </td>
                                <td>
                                    <div class="">{{user.email}}</div>
                                </td>
                                <td *ngIf="user.permissions.includes('parse_cv');else noParse"><div class=""><i class="fa fa-check" aria-hidden="true"></i>
                                </div></td>
                                <ng-template #noParse>
                                    <td><div class=""><i class="fa fa-times" aria-hidden="true"></i>
                                    </div></td>
                                </ng-template>
                                
                                
                                <td *ngIf="user.permissions.includes('search_engine');else noSearch"><div class=""><i class="fa fa-check" aria-hidden="true"></i>
                                </div></td>
                                <ng-template #noSearch>
                                    <td><div class=""><i class="fa fa-times" aria-hidden="true"></i>
                                    </div></td>
                                </ng-template>
                                <td *ngIf="user.permissions.includes('parse_cv');else noTalentPool"><div class=""><i class="fa fa-check" aria-hidden="true"></i>
                                    <!-- <a title="Update permission talent pool" (click)="modalOpenUpdatePermissionTalentPool(modalBasicUpdate)"><span style="margin-top: -12px;color: #ffc107;" class="btn fa fa-pencil-square-o"></span></a> -->
                                </div></td>
                                <ng-template #noTalentPool>
                                    <td><div class=""><i class="fa fa-times" aria-hidden="true"></i>
                                        <!-- <a title="Update permission talent pool" (click)="modalOpenUpdatePermissionTalentPool(modalBasicUpdate)"><span style="margin-top: -12px;color: #ffc107;" class="btn fa fa-pencil-square-o"></span></a> -->
                                    </div></td>
                                </ng-template>
                                <!-- <ng-template #modalBasicUpdate let-modal>
                                    <form [formGroup]="UpdateUserTalentPoolForm" (ngSubmit)="UpdateUserPermissionTalentPool(user.id)">
                                        
                                        <div class="modal-header">
                                            <h4 class="modal-title">Permission talent pool</h4>
                                            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                              <span aria-hidden="true">&times;</span>
                                            </button>
                                          </div>
                                        <div class="modal-body" ngbAutofocus>
                                            <div class="custom-control custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  class="custom-control-input"
                                                  id="is_accessible"
                                                  formControlName="is_accessible"
                                                />
                                                <label
                                                  class="custom-control-label"
                                                  for="is_accessible"
                                                  
                                                >Is accessible</label>
                                              </div>
                                            
                                        </div>
                                        
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')" rippleEffect>
                                            Cancle
                                        </button>
                                        <button type="submit" class="btn btn-primary" (click)="modal.dismiss('Cross click')" rippleEffect>
                                            Accept
                                        </button>
                                        </div>
                                    </form>
                                </ng-template> -->
                                <td *ngIf="user.is_site_admin;else noSiteAdmin"><div class=""><i class="fa fa-check" aria-hidden="true"></i>
                                </div></td>
                                <ng-template #noSiteAdmin>
                                    <td><div class=""><i class="fa fa-times" aria-hidden="true"></i>
                                    </div></td>
                                </ng-template>
                                <td>
                                    <div class="">{{user.last_login|date:'medium'}}</div>
                                </td>
                                <td class="d-flex">
                                    <span *ngIf="currentUser.is_superuser"><a (click)="modalOpenCreateUser(modalBasic, {
                                        first_name: user.first_name,
                                        last_name: user.last_name,
                                        email: user.email,
                                        is_site_admin: user.is_site_admin,
                                        checkSearchEngine: user.permissions.includes('search_engine'),
                                        checkParseResume: user.permissions.includes('parse_cv'),
                                        id: user.id
                                    })"><span class="btn fa fa-pencil"></span></a></span>
                                    <span><a title="Delete user" (click)="modalOpenDeleteUser(modalBasicDeleteUser)"><span class="btn fa fa-trash-o"></span></a></span>
                                </td>

                                <ng-template #modalBasicDeleteUser let-modal>
                                    <form [formGroup]="deleteUserForm" (ngSubmit)="DeleteUser(user.id)">
                                        
                                        <div class="modal-header">
                                            <h4 class="modal-title">Select assign user</h4>
                                            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                              <span aria-hidden="true">&times;</span>
                                            </button>
                                          </div>
                                        <div class="modal-body" ngbAutofocus *ngFor="let user_ of users| slice: (pageAssignUser - 1) * basicSelectedOptionAssignUser:(pageAssignUser - 1) * basicSelectedOptionAssignUser + basicSelectedOptionAssignUser">
                                            <div class="custom-control custom-checkbox" *ngIf="_checkbox==user_.id.toString();else noChecked">
                                                <input
                                                  type="checkbox"
                                                  class="custom-control-input"
                                                  [value]="user_.id" (change)="onCheckboxChange($event)" checked
                                                />
                                                <label
                                                  class="custom-control-label"
                                                  for="checkbox_email"
                                                  
                                                >{{user_.email}}</label>
                                              </div>
                                              <ng-template #noChecked>
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                      type="checkbox"
                                                      class="custom-control-input"
                                                      [value]="user_.id" (change)="onCheckboxChange($event)"
                                                    />
                                                    <label
                                                      class="custom-control-label"
                                                      for="checkbox_email"
                                                      
                                                    >{{user_.email}}</label>
                                                  </div>
                                            </ng-template>
                                        </div>
                                        <div class="row mt-4 mt-lg-5 justify-content-between align-items-center" *ngIf="users.length>0">
                                            <div class="col-auto">
                                    
                                                <nav class="mt-3 mt-sm-0" aria-label="Users list pagination">
                                    
                                                <ngb-pagination [collectionSize]="users.length" [(page)]="pageAssignUser" [pageSize]="basicSelectedOptionAssignUser"
                                                [maxSize]="10" [rotate]="true" [boundaryLinks]="true">
                                    
                                    
                                                </ngb-pagination>
                                                </nav>
                                            </div>
    
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" style="background-color: gray;" (click)="modal.dismiss('Cross click')" rippleEffect>
                                            Cancle
                                        </button>
                                        <button type="submit" class="btn btn-primary" (click)="modal.dismiss('Cross click')" rippleEffect>
                                            Accept
                                        </button>
                                        </div>
                                    </form>
                                </ng-template>
                            </tr>

                        </tbody>
                    </table>

                   
                </div> 
                <div class="row mt-4 mt-lg-5 justify-content-between">
                    <div class="col-auto">

                        <nav class="mt-sm-0" aria-label="Jobs list pagination">

                            <ngb-pagination [collectionSize]="users.length" [(page)]="page" [pageSize]="pageSize"
                            [maxSize]="10" [rotate]="true" [boundaryLinks]="true">


                            </ngb-pagination>
                        </nav>
                    </div>
                    <div class="col-auto">
                        <select class="form-control" style="width: auto" [(ngModel)]="pageSize">
                            <option [ngValue]="5">5 ItemsPerPage</option>
                            <option [ngValue]="10">10 ItemsPerPage</option>
                            <option [ngValue]="15">15 ItemsPerPage</option>
                        </select>


                    </div>
                </div>   
            </div>
            </ng-template>
    </div>
</div>