import { Component, OnInit } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;
  language: string;
  constructor(
    private _coreConfigService: CoreConfigService,
    private _translateService: TranslateService

  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
   }

  ngOnInit(): void {
    // config layout and language
    this._coreConfigService.getConfig().subscribe((res) => {
      this.language = res.app.appLanguage;
    });

    this._translateService.use(this.language);
  }

}
