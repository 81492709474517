<div class="content-wrapper container-xxxl p-0 card">
    <div class="content-body card-body">
        <div *ngIf="errorMessagesListMatchResume; else ListMatchResume">
            <p>Errors: <b style="color: red;">{{errorMessagesListMatchResume}}</b></p>
          </div>
            <ng-template #ListMatchResume>
            <div class="mt-4 mt-lg-5">
                <ng-container>
                    <form (ngSubmit)="onSubmit()" [formGroup]="FilterMonitorUploadForm">
      
                      <div class="d-flex justify-content-start align-items-center mb-2">
                                  <div class="col-md-4 me-2">
                                    <select
                                        id="status"
                                        class="form-control"
                                        (change)="changeStatus($event)"
                                        formControlName="status"
                                        
                                      >
                                        <option value="" [translate]="'TALENT_POOL.RESUME_MATCHING.CHOOSE_STATUS'"></option>
                                        <option *ngFor="let status_ of StatusItems" [value]="status_.value">
                                          {{ status_.name }}
                                        </option>
                                      </select>
                                  </div>
                                  
                                  <div class="col-md-4 me-2">
                                    <select
                                        id="created_by__email"
                                        class="form-control"
                                        (change)="changeCreateByEmail($event)"
                                        formControlName="created_by__email"
                                        
                                      >
                                        <option value="" [translate]="'TALENT_POOL.RESUME_MATCHING.CHOOSE_BY_EMAIL'"></option>
                                        <option *ngFor="let createdby__email_items_ of createdby__email_items" [value]="createdby__email_items_.value">
                                          {{ createdby__email_items_.name }}
                                        </option>
                                      </select>
                                      
                                  </div>
                                <div class="col-md-2 me-2">
                                  <button type="submit" class="btn btn-primary" rippleEffect [translate]="'TALENT_POOL.RESUME_MATCHING.FILTER'"></button>
                              </div>
                      </div>
                      
                  </form>
                  </ng-container>
                  <div
                        *ngIf="loading;else noLoading"
                        id="loading"
                        style="margin-top: 50px"
                    >
                        <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                <ng-template #noLoading>
                    <div class="row justify-content-end align-items-center">
                        <!-- <div class="col-auto order-1 order-sm-2">
                            <div class="pxp-company-dashboard-jobs-search mb-3">
                                <div class="pxp-company-dashboard-jobs-search-results me-3"><button class="btn rounded-pill pxp-section-cta" style="background-color: green;" rippleEffect (click)="refresh()"><i class="fa fa-refresh" aria-hidden="true"></i> Refresh</button></div>
                                
                            </div>
                        </div> -->
                        <div class="col-auto order-1 order-sm-2">
                            <div class=" mb-3">
                                <div class=" me-3">{{list_resume_match.count}} <span [translate]="'TALENT_POOL.RESUME_MATCHING.RESUME_MATCH'"></span></div>
                                
                            </div>
                        </div>
                        
                    </div>
                    <!-- <div class="pxp-company-dashboard-jobs-search-results mb-3" *ngIf="loading">Đang tìm kiếm</div> -->
                    
                    <div class="table-responsive">
                        <table class="table table-hover align-middle">
                            <thead>
                                <tr>
                                    
                                    <th [translate]="'TALENT_POOL.RESUME_MATCHING.JOB_ID'"></th>
                                    <th [translate]="'TALENT_POOL.RESUME_MATCHING.CREATE_AT'"></th>
                                    <th [translate]="'TALENT_POOL.RESUME_MATCHING.OVERALL_SCORE'"></th>
                                    <th [translate]="'TALENT_POOL.RESUME_MATCHING.STATUS'"></th>         
                                    <th [translate]="'TALENT_POOL.RESUME_MATCHING.RESUME_ID'"></th>
                                    <th [translate]="'TALENT_POOL.RESUME_MATCHING.CREATED_BY'"></th>
                                    <th [translate]="'TALENT_POOL.RESUME_MATCHING.RESUME_STATUS'"></th>
                                    <th [translate]="'TALENT_POOL.RESUME_MATCHING.JOB_APP_STATUS'"></th>
                                    <th style="width: 5px;"></th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let _list_resume_match of list_resume_match.results
                            | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: totalItems }; let i=index">
                                <tr (click)="view_detail_resume(i)">
                                        
                                        <td>
                                            <a *ngIf="_list_resume_match.jobReqId_detail!=null;else NoJobReqIdDetail" routerLink="/employer/talent-pool/detail-jd/{{_list_resume_match.jobReqId_detail}}">
                                                <div class="">{{_list_resume_match.jobReqId}}</div>
                                            </a>
                                            <ng-template #NoJobReqIdDetail>
                                                <div class="">{{_list_resume_match.jobReqId}}</div>
                                            </ng-template>
                                        </td>
                                        <td>
                                            <div class="">{{_list_resume_match.created_at|date:'medium'}}</div>
                                        </td>
                                        <td>
                                            <div class="" *ngIf="_list_resume_match.result.overall_score!=undefined && _list_resume_match.result.overall_score!=null">{{_list_resume_match.result.overall_score*100|number:'1.2-2'}}%</div>
                                        </td>
                                        <td>
                                            <div class="">
                                                <span class="badge bg-{{status_color[_list_resume_match.status]}}">{{_list_resume_match.status}}</span>
                                                
                                            </div>
                                        </td>
                                        <td>
                                            <a routerLink="/manage-resume/detail-resume/{{_list_resume_match.resume}}">
                                                <div class="">{{_list_resume_match.resume}}</div>
                                            </a>
                                        </td>
                                        <td>
                                            <div class="">{{_list_resume_match.created_by}}</div>
                                        </td>
                                        <td>
                                            <div class="">
                                                <span class="badge bg-{{status_color[_list_resume_match.resume_status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{_list_resume_match.resume_detail_status}}" >{{_list_resume_match.resume_status}}</span>
                                                
                                            </div>
                                        </td>
                                        <td>
                                            <div class="">
                                                <span class="badge bg-{{job_status_color[_list_resume_match.job_application_status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{_list_resume_match.job_application_detail_status}}" >{{_list_resume_match.job_application_status}}</span>
        
                                                
                                            </div>
                                        </td>
                                        <td>
                                            <i
                                            id="Pw"
                                            class="fa"
                                            [ngClass]="{
                                                'fa-chevron-circle-up': !view_resume || view_resume_check!=i,
                                                'fa-chevron-circle-down': view_resume && view_resume_check==i
                                            }"
                                            ></i>
    
                                        </td>
                                    
                                </tr>
                                <tr *ngIf="view_resume_check==i && view_resume">
                                    <td colspan="12" class="hiddenRow">
                                        <div class="d-flex">
                                            <div class="col-md-5 row align-items-start">
                                                <div class="col-md-5">
                                                    <span style="font-weight: bold;" [translate]="'TALENT_POOL.RESUME_MATCHING.FULL_NAME'"></span>
                                                </div>
                                                <div class="col-md-7">
                                                    <p >{{_list_resume_match.resume_detail.full_name}}</p>
                                                    
                            
                                                </div>
                                            </div>
                                            <div class="col-md-5 row align-items-start">
                                                <div class="col-md-4">
                                                    <span style="font-weight: bold;" [translate]="'TALENT_POOL.SEARCH_RESUME.ADDRESS'"></span>
                                                </div>
                                                <div class="col-md-8">
                                                    <p >{{_list_resume_match.resume_detail.address}}</p>
                                                    
                            
                                                </div>
                                            </div>
                                            <div class="col-md-2 row align-items-start">
                                                <div class="col-md-4">
                                                    <span style="font-weight: bold;" [translate]="'TALENT_POOL.RESUME_MATCHING.STATUS'"></span>
                                                </div>
                                                <div class="col-md-8">
                                                    <p >{{_list_resume_match.resume_detail.status}}</p>
                                                    
                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="col-md-5 row align-items-start">
                                                <div class="col-md-5">
                                                    <span style="font-weight: bold;" [translate]="'TALENT_POOL.RESUME_MATCHING.CURRENT_TITLE'"></span>
                                                </div>
                                                <div class="col-md-7">
                                                    <p >{{_list_resume_match.resume_detail.current_title}}</p>
                                                    
                            
                                                </div>
                                            </div>
                                            <div class="col-md-7 row align-items-start">
                                                <div class="col-md-4">
                                                    <span style="font-weight: bold;" [translate]="'TALENT_POOL.RESUME_MATCHING.CURRENT_COMPANY'"></span>
                                                </div>
                                                <div class="col-md-8">
                                                    <p >{{_list_resume_match.resume_detail.current_company}}</p>
                                                    
                            
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                                        
                    </div>
                    <div class="d-flex mt-4 mt-lg-5 justify-content-between align-items-center">
                        <div>
    
                            <pagination-controls (pageChange)="pageChangeEvent($event, url_dynamic)"></pagination-controls>
                        </div>
                        <div class="d-flex">
                            <div><p style="padding-top: 6px;" [translate]="'TALENT_POOL.MANAGE_JOB.ITEMS_PER_PAGE'"><span>:</span></p></div>
                            <div>
                                <select (change)="handlePageSizeChange($event, url_dynamic)" class="form-control" style="width: auto">
                                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                                        {{ size }}
                                    </option>
                                </select>
                            </div>
                          </div>
                    </div>
                </ng-template>   
                
                
            </div>
            </ng-template>
    </div>
</div>