import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Jr } from 'app/main/resume-parser/models/resume.model';
import { DetailResume } from '../../models/resume.model';
import { CV_task } from '../../models/job.model';
import { User } from 'app/auth/models';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TalentPoolService } from '../../services/talent-pool.service';
import { AuthenticationService } from 'app/auth/service';
import { locale as enLocale } from "../../i18n/en";
import { locale as vnLocal } from "../../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
@Component({
  selector: 'app-detail-resume',
  templateUrl: './detail-resume.component.html',
  styleUrls: ['./detail-resume.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailResumeComponent implements OnInit {
  public jrDetail!: Jr
  public src_link: any;
  public detail_resume: DetailResume = new DetailResume()
  public errorMessagesDetailResume!: any
  public errorMessagesListAllJd!: any
  public errorMessagesSaveResumeByJd!: any
  public list_all_jd:CV_task[]=[]

  // public candidate_detail: Cv = new Cv();
  public page = 1;
  public basicSelectedOption: number = 1;
  public pageBasicText = 1;
  public errorMessagesGetCv!:any
  public cv_html_src: string=""
  public saved_cv = false
  public job_description!:any
  public user: User = new User()
  @ViewChild('CV') CV!: ElementRef;
 
  public arr_checkboxSaveCv:string[]=[]
  public arr_checkboxSaveCvJr:string[]=[]
  
  formCheckBoxSaveCv = this.fb.group({
    checkResumeArraySaveCv: this.fb.array([],Validators.required),
    checkResumeArraySaveCvJr: this.fb.array([],Validators.required)

  })
  public save_cv: string = ''
  public pageSize=8
  public page_sf4c=1
  public pageSize_sf4c=8
  public loading = false
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private talentPoolService: TalentPoolService,
    private _authenticationService: AuthenticationService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,

  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
   }

  DetailUser(){
    this._authenticationService.detailUser(this.detail_resume.owner).subscribe(
      (res)=>{
        this.user = res
      },
      (err)=>{
        console.error("Error: ",+err)
      }
    )
  }
  DetailResumeSearch(){
    this.loading = true
    let resume_id= this.route.snapshot.params["id"];
    this.talentPoolService.detail_resume(resume_id).subscribe(
      (res)=>{
        this.detail_resume = res;
        this.DetailUser();
        const blob = this.base64ToBlob( this.detail_resume.pdf_content, 'application/pdf' );
        this.src_link = URL.createObjectURL( blob );
        this.loading = false
      }
    )
  }
  base64ToBlob( base64: any, type = "application/octet-stream" ) {
    const binStr = atob( base64 );
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[ i ] = binStr.charCodeAt( i );
    }
    return new Blob( [ arr ], { type: type } );
  }
  ngOnInit(): void {
    this.DetailResumeSearch()
    let href = window.location.href
    this.job_description = href.split("jd_id=")[1]
  }
  //save cv
  modalOpenSaveCv(modalBasicSaveCv:any) {
    this.ListAllJd()
    this.modalService.open(modalBasicSaveCv, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
    }
    ListAllJd(){
      this.talentPoolService.list_all_jd().subscribe(
        (res)=>{
          this.list_all_jd = res;
        },
        (err)=>{
          this.errorMessagesListAllJd = err;
        }
      )
    }
    
  onCheckboxChangeSaveCv(e:any) {
    const checkResumeArraySaveCv: FormArray = this.formCheckBoxSaveCv.get('checkResumeArraySaveCv') as FormArray;
    if (e.target.checked) {
      checkResumeArraySaveCv.push(new FormControl(e.target.value));
      this.arr_checkboxSaveCv.push(e.target.value)
    } else {
      let i: number = 0;
      checkResumeArraySaveCv.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArraySaveCv.removeAt(i);
          this.arr_checkboxSaveCv=this.arr_checkboxSaveCv.filter(item=>item!==e.target.value)
          return;
        }
        i++;
      });
    }
  }
  onCheckboxChangeSaveCvJr(e:any) {
    const checkResumeArraySaveCvJr: FormArray = this.formCheckBoxSaveCv.get('checkResumeArraySaveCvJr') as FormArray;
    if (e.target.checked) {
      checkResumeArraySaveCvJr.push(new FormControl(e.target.value));
      this.arr_checkboxSaveCvJr.push(e.target.value)
    } else {
      let i: number = 0;
      checkResumeArraySaveCvJr.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArraySaveCvJr.removeAt(i);
          this.arr_checkboxSaveCvJr=this.arr_checkboxSaveCvJr.filter(item=>item!==e.target.value)
          return;
        }
        i++;
      });
    }
  }
  FormCheckBoxSaveCv(resume: number){
    
    let arr_checkbox_SaveCv=[]
    for (let i=0;i<this.arr_checkboxSaveCv.length;i++){
      arr_checkbox_SaveCv.push(parseInt(this.arr_checkboxSaveCv[i]))
    }
    let job_description= arr_checkbox_SaveCv[0];
    this.talentPoolService.save_resume_by_jd(job_description,resume).subscribe(
      (res)=>{
        this.save_cv = res.message;
        this.toastr.success('Save Success')
      },
      (err)=>{
        this.errorMessagesSaveResumeByJd = err
      }
    )
  }
  //Save recommend detail cv
  SaveResumeByJd(){
    let job_description= Number(this.job_description)
    let resume= this.detail_resume.id
    this.talentPoolService.save_resume_by_jd(job_description,resume).subscribe(
      (res)=>{
        this.save_cv = res.message;
        this.toastr.success('Save Success');
        this.saved_cv = true;
      },
      (err)=>{
        this.errorMessagesSaveResumeByJd = err
      }
    )
  }

}
