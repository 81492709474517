

<div class="content-wrapper container-xxxl p-0 card">
    <div class="content-body card-body">
      <div>
        <ul ngbNav #nav="ngbNav" class="nav nav-pills">
        
          <li ngbNavItem>
            <a ngbNavLink class="d-flex align-items-center">
              <i data-feather="upload" class="mr-sm-50"></i><span class="d-none d-sm-block" [translate]="'RESUME_PARSER.INTEGRATION.INTEGRATION'"></span></a
            >
            <ng-template ngbNavContent>
              <!-- Information Tab starts -->
              <div class="tab-pane" id="information" aria-labelledby="information-tab" role="tabpanel">
                <!-- users edit Info form start  -->
                <form (ngSubmit)="(SearchResume.form.valid)" #SearchResume="ngForm">

                  <div class="row mb-2">
                              <!-- <div class="col-md-2 me-2">
                                  <ng-select [items]="status"
                                  bindLabel="name"
                                  bindValue="value"
                                  [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_STATUS'|translate"
                                  name="selectedStatus"
                                  [(ngModel)]="selectedStatus"
                                  id="selectedStatus"
                                  [multiple]="true"
                                  
                                  
                                  >
        
                              </ng-select>
        
                              </div> -->
                              
                              <div class="col-md-2 me-2">
        
                                  <div class="input-group">
                                      
                                      <input
                                          type="text"
                                          class="form-control"
                                          id="email"
                                          [placeholder]="'RESUME_PARSER.PARSE_RESUME.EMAIL'|translate"
                                          [(ngModel)]="email"
                                          name="email"
                                          
                                      />
                                  </div>
        
                              </div>
                              <div class=" col-md-2 me-2">
        
                                  <div class="input-group">
                                      
                                      <input
                                          type="text"
                                          class="form-control"
                                          id="phone_number"
                                          [placeholder]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'|translate"
                                          [(ngModel)]="phone_number"
                                          name="phone_number"
                                          
                                      />
                                  </div>
        
                              </div>
                              <div class="col-md-4 me-2" *ngIf="user.role=='Site Admin' || user.role=='Admin'">
        
                                  <ng-select [items]="owner__email_items"
                                  bindLabel="name"
                                  bindValue="value"
                                  [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_OWNER'|translate"
                                  name="owner__email"
                                  [(ngModel)]="owner__email"
                                  id="owner__email"
                                  
                                  >
        
                              </ng-select>
        
                              </div>
                              <div class="col-md-2 me-2">
        
                                <div class="input-group">
                                    
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="job-search"
                                        [placeholder]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate"
                                        name="file_name"
                                        [(ngModel)]="file_name"
                                    />
                                </div>
              
                            </div>
                            <div class="col-md-2 d-flex align-items-center">
                              <button style="height: 38px;padding:6px" type="submit" (click)="onSubmit()" class="btn fw-bold btn-primary" rippleEffect><i class="fa fa-search mr-1" aria-hidden="true"></i> <span [translate]="'RESUME_PARSER.MANAGE_RESUME.SEARCH'"></span>  </button>
            
                          </div>
                      
                  </div>
                  
                  
              </form>
                <!-- users edit Info form ends -->
                <div class="content-body" id="ngx-datatable-row-details">
                  <div class="d-flex justify-content-center"
                  *ngIf="isLoading; else no_loading"
                  id="loading"
                  style="margin: 50px auto"
                >
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <ng-template #no_loading>
                  <div class="row" *ngIf="resume_parser.length>0">
                      <div class="col-md-3 col-12">
                        <div class="d-flex justify-content-between align-items-center m-1">
                          <label
                            class="d-flex align-items-center"
                            style="max-width: 100%; flex-wrap: nowrap"
                            ><span [translate]="'RESUME_PARSER.MANAGE_RESUME.SHOW'"></span>
                            <select
                              class="form-control mx-25 pr-3 show-doc"
                              [(ngModel)]="page.size"
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="40">40</option>
                            </select>
                            <span [translate]="'RESUME_PARSER.PARSE_RESUME.RESUMES'"></span>
                          </label>
                        </div>
                      </div>
                      <div *ngIf="selectedRows.length>0" class="col-md-9 col-12 d-flex justify-content-md-end align-items-center">
                        
                        <button class="btn btn-primary" (click)="modalOpenUploadSf4c(modalBasic)" [translate]="'RESUME_PARSER.INTEGRATION.UPLOAD'">
                            
                        </button>
                          <ng-template #modalBasic let-modal>
          
                            <div class="modal-header">
                              <h4 class="modal-title" [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'"></h4>
                              <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="me-4 mt-3">
                              <div class="d-flex justify-content-end">
                
                                <div class="input-group col-md-6">
                                  <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                                  <input type="text" class="form-control" id="job-search" placeholder="Search file name"
                                    aria-label="Search..." aria-describedby="job-search" [(ngModel)]="searchText" (input)="Search()" />
                                </div>
                
                              </div>
                            </div>
                            <div class="d-flex justify-content-center">
                              <div *ngIf="isLoadingJr" id="loading" style="margin: 50px auto">
                                <div class="spinner-border text-primary" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                
                            </div>
                
                            <form (ngSubmit)="FormCheckBoxJr()" [formGroup]="formCheckBoxJr">
                              <div class="modal-body" ngbAutofocus>
                
                
                                <table class="table table-hover align-middle">
                                  <thead>
                                    <tr>
                                      <th style="width: 1%;"></th>
                                      <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_REQ_ID'"></th>
                                      <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_TITLE'"></th>
                
                                      <!-- <th>Score</th> -->
                
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      *ngFor="let resultJr_ of resultJr
                                                      | slice: (pageJr - 1) * basicSelectedOption:(pageJr - 1) * basicSelectedOption + basicSelectedOption">
                                      <td *ngIf="arr_checkboxJr.includes(resultJr_.jobReqId);else noChecked">
                                        <input [value]="resultJr_.jobReqId" (change)="onCheckboxChangeJr($event)" type="checkbox"
                                          class="form-check" checked>
                
                                      </td>
                                      <ng-template #noChecked>
                                        <td>
                                          <input [value]="resultJr_.jobReqId" (change)="onCheckboxChangeJr($event)" type="checkbox"
                                            class="form-check">
                
                                        </td>
                                      </ng-template>
                
                                      <td>
                                        <div class="">{{resultJr_.jobReqId}}</div>
                                      </td>
                                      <td>
                                        <div class="">{{resultJr_.jobTitle}}</div>
                                      </td>
                                      <!-- <td>
                                                              <div class="" >{{resultJr_.score*100|number:'1.2-2'}}%</div>
                                                          </td> -->
                
                                    </tr>
                
                                  </tbody>
                                </table>
                                <div class="row mt-4 mt-lg-5 justify-content-between align-items-center" *ngIf="resultJr.length>0">
                                  <div class="col-auto">
                
                                    <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">
                
                                      <ngb-pagination [collectionSize]="resultJr.length" [(page)]="pageJr"
                                        [pageSize]="basicSelectedOption" [maxSize]="10" [rotate]="true" [boundaryLinks]="true">
                
                
                                      </ngb-pagination>
                                    </nav>
                                  </div>
                                  <!-- <div class="col-auto">
                                                  <select class="form-select" style="width: auto" [(ngModel)]="basicSelectedOption">
                                                      <option [ngValue]="5">5 items per page</option>
                                                      <option [ngValue]="10">10 items per page</option>
                                                      <option [ngValue]="15">15 items per page</option>
                                                  </select>
                                                  </div> -->
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button type="submit" class="btn btn-primary" rippleEffect  [translate]="'RESUME_PARSER.PARSE_RESUME.SUBMIT'">
                            
                                </button>
                              </div>
                            </form>
                          </ng-template>
                          <ng-container *ngIf="errorMessagesSf4cUpload">
                            <div class="alert alert-danger mt-2" role="alert">
                              {{errorMessagesSf4cUpload}}
                            </div>
                          </ng-container>
                            
                      
                      </div>
                  </div>
                  <ngx-datatable
                    *ngIf="resume_parser.length>0"
                    id="table-user"
                    #tableRowDetails
                    class="bootstrap core-bootstrap"
                    [rows]="resume_parser"
                    [externalPaging]="true"
                      [count]="page.totalElements"
                      [offset]="page.pageNumber"
                      [limit]="page.size"
                      (page)="setPage($event)"
                    [rowHeight]="50"
                    class="bootstrap core-bootstrap"
                    [columnMode]="ColumnMode.force"
                    [headerHeight]="50"
                    [footerHeight]="50"
                    [scrollbarH]="true"
                    [selectionType]="SelectionType.checkbox"
                      (activate)="onActivate($event)"
                      (select)="onSelect($event)"
                      [selected]="selectedRows"
                  >
                  <ngx-datatable-column
                  [width]="40"
                  [sortable]="false"
                  [canAutoResize]="false"
                  [draggable]="false"
                  [resizeable]="false"
                >
                  <ng-template
                    ngx-datatable-header-template
                    let-value="value"
                    let-selectFn="selectFn"
                    let-allRowsSelected="allRowsSelected"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [checked]="allRowsSelected"
                        (change)="selectFn(!allRowsSelected)"
                        id="headerChkbxRef"
                      />
                      <label
                        class="custom-control-label"
                        for="headerChkbxRef"
                      ></label>
                    </div>
                  </ng-template>
                  <ng-template
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row"
                    let-isSelected="isSelected"
                    let-onCheckboxChangeFn="onCheckboxChangeFn"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [checked]="setIsSelectedRows(row)"
                        (change)="onCheckboxChangeFn($event)"
                        id="rowChkbxRef{{ rowIndex }}"
                      />
                      <label
                        class="custom-control-label"
                        for="rowChkbxRef{{ rowIndex }}"
                      ></label>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                [name]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate"
                [width]="1.5*widthColumn"
              >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100">
                    <a routerLink="/manage-resume/detail-resume/{{row.id}}" class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.file_name}}">{{ row.file_name }}</a>
                </div>
              </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [name]="'RESUME_PARSER.PARSE_RESUME.STATUS'|translate"
                prop="status"
                width="120"
              >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100" >
                  <span class="badge bg-{{status_color[row.status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.message}}" >{{row.status}}</span>
                </div>
              </ng-template>
              </ngx-datatable-column>
              
              <ngx-datatable-column
                [name]="'RESUME_PARSER.PARSE_RESUME.CONF_SCORE'|translate"
                width="180"
              >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100">
                    <span *ngIf="row.conf_score>range_conf.success" class="badge bg-success">{{row.conf_score*100|number:'1.2-2'}}%</span>
                    <span *ngIf="row.conf_score>range_conf.warning && row.conf_score < range_conf.success" class="badge bg-warning">{{row.conf_score*100|number:'1.2-2'}}%</span>
                    <span *ngIf="row.conf_score<range_conf.warning" class="badge bg-danger">{{row.conf_score*100|number:'1.2-2'}}%</span>
                </div>
              </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [name]="'RESUME_PARSER.MANAGE_RESUME.OWNER'|translate"
                width="200"
                *ngIf="user.role=='Site Admin' || user.role=='Admin'"
              >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100" >
                    <span>{{ row.owner }}</span>
                </div>
              </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CREATE_AT'|translate" [width]="widthColumn">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div class="w-100">
                    <span>{{ row.created_at|date:'medium' }}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <!-- ACTIONSSSSSSSSSSSSSSSSS -->
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.FIRST_NAME'|translate" prop="first_name" width="140">
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.LAST_NAME'|translate" prop="last_name" width="140">
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.EMAIL'|translate" prop="email" width="200">
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'|translate" prop="phone_number" width="140">
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.GENDER'|translate" prop="gender" width="100">
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.DATE_OF_BIRTH'|translate" width="200">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div class="w-100">
                    <span>{{ row.date_of_birth|date:'longDate' }}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'|translate" [width]="widthColumn">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div class="w-100">
                    <span class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.current_title}}">{{ row.current_title }}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_COMPANY'|translate" [width]="widthColumn">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div class="w-100">
                    <span class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.current_company}}">{{ row.current_company }}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column>
                  </ngx-datatable>
                </ng-template>
              </div>
              </div>
              <!-- Information Tab ends -->
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="d-flex align-items-center"
              ><i data-feather="refresh-cw" class="mr-sm-50"></i><span class="d-none d-sm-block" [translate]="'RESUME_PARSER.INTEGRATION.UPDATE_JA_SUCCESSFACTORS'"></span></a
            >
            <ng-template ngbNavContent>
              <!-- Social Tab starts -->
              <div class="tab-pane" id="social" aria-labelledby="social-tab" role="tabpanel">
                <!-- users edit Info form start  -->
                <form (ngSubmit)="(SearchResumeJA.form.valid)" #SearchResumeJA="ngForm">

                  <div class="row mb-2">
                              <!-- <div class="col-md-2 me-2">
                                  <ng-select [items]="status"
                                  bindLabel="name"
                                  bindValue="value"
                                  [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_STATUS'|translate"
                                  name="selectedStatus"
                                  [(ngModel)]="selectedStatus"
                                  id="selectedStatus"
                                  [multiple]="true"
                                  
                                  
                                  >
        
                              </ng-select>
        
                              </div> -->
                              
                              <div class="col-md-2 me-2">
        
                                  <div class="input-group">
                                      
                                      <input
                                          type="text"
                                          class="form-control"
                                          id="email"
                                          [placeholder]="'RESUME_PARSER.PARSE_RESUME.EMAIL'|translate"
                                          [(ngModel)]="email"
                                          name="email"
                                          
                                      />
                                  </div>
        
                              </div>
                              <div class=" col-md-2 me-2">
        
                                  <div class="input-group">
                                      
                                      <input
                                          type="text"
                                          class="form-control"
                                          id="phone_number"
                                          [placeholder]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'|translate"
                                          [(ngModel)]="phone_number"
                                          name="phone_number"
                                          
                                      />
                                  </div>
        
                              </div>
                              <div class="col-md-4 me-2" *ngIf="user.role=='Site Admin' || user.role=='Admin'">
        
                                  <ng-select [items]="owner__email_items"
                                  bindLabel="name"
                                  bindValue="value"
                                  [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_OWNER'|translate"
                                  name="owner__email"
                                  [(ngModel)]="owner__email"
                                  id="owner__email"
                                  
                                  >
        
                              </ng-select>
        
                              </div>
                              <div class="col-md-2 me-2">
        
                                <div class="input-group">
                                    
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="job-search"
                                        [placeholder]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate"
                                        name="file_name"
                                        [(ngModel)]="file_name"
                                    />
                                </div>
              
                            </div>
                            
                            <div class="col-md-2 d-flex align-items-center">
                                <button style="height: 38px;padding:6px" type="submit" (click)="onSubmitJA()" class="btn fw-bold btn-primary" rippleEffect><i class="fa fa-search mr-1" aria-hidden="true"></i> <span [translate]="'RESUME_PARSER.MANAGE_RESUME.SEARCH'"></span>  </button>
              
                            </div>
                      
                  </div>
                 
              </form>
                <!-- users edit Info form ends -->
                <div class="content-body" id="ngx-datatable-row-details">
                  <div
                  class="d-flex justify-content-center"
                  *ngIf="isLoadingJA; else no_loadingJA"
                  id="loadingJA"
                  style="margin: 50px auto"
                >
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <ng-template #no_loadingJA>
                  <div class="row" *ngIf="resume_parserJA.length>0">
                      <div class="col-md-3 col-12">
                        <div class="d-flex justify-content-between align-items-center m-1">
                          <label
                            class="d-flex align-items-center"
                            style="max-width: 100%; flex-wrap: nowrap"
                            ><span [translate]="'RESUME_PARSER.MANAGE_RESUME.SHOW'"></span>
                            <select
                              class="form-control mx-25 pr-3 show-doc"
                              [(ngModel)]="pageJA.size"
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="40">40</option>
                            </select>
                            <span [translate]="'RESUME_PARSER.PARSE_RESUME.RESUMES'"></span>
                          </label>
                        </div>
                      </div>
                      <div *ngIf="selectedRowsJA.length>0" class="col-md-9 col-12 d-flex justify-content-md-end align-items-center">
                        
                        <button class="btn btn-primary" (click)="modalOpenUploadSf4c(modalBasic)" [translate]="'RESUME_PARSER.INTEGRATION.UPLOAD'">
                            
                        </button>
                          <ng-template #modalBasic let-modal>
          
                            <div class="modal-header">
                              <h4 class="modal-title" [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'"></h4>
                              <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="me-4 mt-3">
                              <div class="d-flex justify-content-end">
                
                                <div class="input-group col-md-6">
                                  <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                                  <input type="text" class="form-control" id="job-search" placeholder="Search file name"
                                    aria-label="Search..." aria-describedby="job-search" [(ngModel)]="searchText" (input)="Search()" />
                                </div>
                
                              </div>
                            </div>
                            <div class="d-flex justify-content-center">
                              <div *ngIf="isLoadingJr" id="loading" style="margin: 50px auto">
                                <div class="spinner-border text-primary" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                
                            </div>
                
                            <form (ngSubmit)="FormCheckBoxJA()" [formGroup]="formCheckBoxJr">
                              <div class="modal-body" ngbAutofocus>
                
                
                                <table class="table table-hover align-middle">
                                  <thead>
                                    <tr>
                                      <th style="width: 1%;"></th>
                                      <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_REQ_ID'"></th>
                                      <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_TITLE'"></th>
                
                                      <!-- <th>Score</th> -->
                
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      *ngFor="let resultJr_ of resultJr
                                                      | slice: (pageJr - 1) * basicSelectedOption:(pageJr - 1) * basicSelectedOption + basicSelectedOption">
                                      <td *ngIf="arr_checkboxJr.includes(resultJr_.jobReqId);else noChecked">
                                        <input [value]="resultJr_.jobReqId" (change)="onCheckboxChangeJr($event)" type="checkbox"
                                          class="form-check" checked>
                
                                      </td>
                                      <ng-template #noChecked>
                                        <td>
                                          <input [value]="resultJr_.jobReqId" (change)="onCheckboxChangeJr($event)" type="checkbox"
                                            class="form-check">
                
                                        </td>
                                      </ng-template>
                
                                      <td>
                                        <div class="">{{resultJr_.jobReqId}}</div>
                                      </td>
                                      <td>
                                        <div class="">{{resultJr_.jobTitle}}</div>
                                      </td>
                                      <!-- <td>
                                                              <div class="" >{{resultJr_.score*100|number:'1.2-2'}}%</div>
                                                          </td> -->
                
                                    </tr>
                
                                  </tbody>
                                </table>
                                <div class="row mt-4 mt-lg-5 justify-content-between align-items-center" *ngIf="resultJr.length>0">
                                  <div class="col-auto">
                
                                    <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">
                
                                      <ngb-pagination [collectionSize]="resultJr.length" [(page)]="pageJr"
                                        [pageSize]="basicSelectedOption" [maxSize]="10" [rotate]="true" [boundaryLinks]="true">
                
                
                                      </ngb-pagination>
                                    </nav>
                                  </div>
                                  <!-- <div class="col-auto">
                                                  <select class="form-select" style="width: auto" [(ngModel)]="basicSelectedOption">
                                                      <option [ngValue]="5">5 items per page</option>
                                                      <option [ngValue]="10">10 items per page</option>
                                                      <option [ngValue]="15">15 items per page</option>
                                                  </select>
                                                  </div> -->
                                </div>
                              </div>
                              <div class="modal-footer">
                                
                                <button *ngIf="loadingFormJA;else noLoadingFormJA" class="btn btn-outline-primary" type="button" disabled rippleEffect>
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  <span class="sr-only">Loading...</span>
                                </button>
                                <ng-temlate #noLoadingFormJA>
                                  <button type="submit" class="btn btn-primary" rippleEffect  [translate]="'RESUME_PARSER.PARSE_RESUME.SUBMIT'">
                            
                                  </button>
                                </ng-temlate>
                              </div>
                              
                                <div
                                *ngIf="loading_"
                                id="loadingJA"
                                style="margin-top: 10px"
                              >
                                <div class="spinner-border text-primary" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                            </form>
                          </ng-template>
                          <ng-container *ngIf="errorMessagesSf4cUpload">
                            <div class="alert alert-danger mt-2" role="alert">
                              {{errorMessagesSf4cUpload}}
                            </div>
                          </ng-container>
                            
                      
                      </div>
                  </div>
                  <ngx-datatable
                    *ngIf="resume_parserJA.length>0"
                    id="table-user"
                    #tableRowDetails
                    class="bootstrap core-bootstrap"
                    [rows]="resume_parserJA"
                    [externalPaging]="true"
                      [count]="pageJA.totalElements"
                      [offset]="pageJA.pageNumber"
                      [limit]="pageJA.size"
                      (page)="setPageJA($event)"
                    [rowHeight]="50"
                    class="bootstrap core-bootstrap"
                    [columnMode]="ColumnMode.force"
                    [headerHeight]="50"
                    [footerHeight]="50"
                    [scrollbarH]="true"
                    [selectionType]="SelectionType.checkbox"
                      (activate)="onActivate($event)"
                      (select)="onSelectJA($event)"
                      [selected]="selectedRowsJA"
                  >
                  <ngx-datatable-column
                  [width]="40"
                  [sortable]="false"
                  [canAutoResize]="false"
                  [draggable]="false"
                  [resizeable]="false"
                >
                  <ng-template
                    ngx-datatable-header-template
                    let-value="value"
                    let-selectFn="selectFn"
                    let-allRowsSelected="allRowsSelected"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [checked]="allRowsSelected"
                        (change)="selectFn(!allRowsSelected)"
                        id="headerChkbxRef"
                      />
                      <label
                        class="custom-control-label"
                        for="headerChkbxRef"
                      ></label>
                    </div>
                  </ng-template>
                  <ng-template
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row"
                    let-isSelected="isSelected"
                    let-onCheckboxChangeFn="onCheckboxChangeFn"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [checked]="setIsSelectedRows(row)"
                        (change)="onCheckboxChangeFn($event)"
                        id="rowChkbxRef{{ rowIndex }}"
                      />
                      <label
                        class="custom-control-label"
                        for="rowChkbxRef{{ rowIndex }}"
                      ></label>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                [name]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate"
                [width]="1.5*widthColumn"
              >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100">
                    <a routerLink="/manage-resume/detail-resume/{{row.id}}" class="d-inline-block text-truncate max-width-custom w-100">{{ row.file_name }}</a>
                </div>
              </ng-template>
              </ngx-datatable-column>
                  <ngx-datatable-column
                [name]="'RESUME_PARSER.PARSE_RESUME.SF4C_STATUS'|translate"
                prop="sf4c_status"
                width="150"
              >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100" >
                  <span class="badge bg-{{status_color[row.sf4c_status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.sf4c_message}}" >{{row.sf4c_status}}</span>
                </div>
              </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [name]="'RESUME_PARSER.MANAGE_RESUME.JOB_APPLICATION_STATUS'|translate"
                prop="status"
                width="200"
              >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100" >
                    <span *ngIf="row.status=='SF4C_uploaded';else noStatus" class="badge bg-{{job_status_color[row.job_application_status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.job_application_detail_status}}" >{{row.job_application_status}}</span>
                    <ng-template #noStatus></ng-template>
                </div>
              </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [name]="'RESUME_PARSER.PARSE_RESUME.CONF_SCORE'|translate"
                width="180"
              >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100">
                    <span *ngIf="row.conf_score>range_conf.success" class="badge bg-success">{{row.conf_score*100|number:'1.2-2'}}%</span>
                    <span *ngIf="row.conf_score>range_conf.warning && row.conf_score < range_conf.success" class="badge bg-warning">{{row.conf_score*100|number:'1.2-2'}}%</span>
                    <span *ngIf="row.conf_score<range_conf.warning" class="badge bg-danger">{{row.conf_score*100|number:'1.2-2'}}%</span>
                </div>
              </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [name]="'RESUME_PARSER.MANAGE_RESUME.OWNER'|translate"
                [width]="widthColumn"
                *ngIf="user.role=='Site Admin' || user.role=='Admin'"
              >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100" >
                    <span>{{ row.owner }}</span>
                </div>
              </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CREATE_AT'|translate" [width]="widthColumn">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div class="w-100">
                    <span>{{ row.created_at|date:'medium' }}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <!-- ACTIONSSSSSSSSSSSSSSSSS -->
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.FIRST_NAME'|translate" prop="first_name" [width]="widthColumn">
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.LAST_NAME'|translate" prop="last_name" [width]="widthColumn">
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.EMAIL'|translate" prop="email" [width]="widthColumn">
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'|translate" prop="phone_number" [width]="widthColumn">
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.GENDER'|translate" prop="gender" [width]="widthColumn">
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.DATE_OF_BIRTH'|translate" [width]="widthColumn">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div class="w-100">
                    <span>{{ row.date_of_birth|date:'longDate' }}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'|translate" [width]="widthColumn">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div class="w-100">
                    <span class="d-inline-block text-truncate max-width-custom w-100">{{ row.current_title }}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_COMPANY'|translate" [width]="widthColumn">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div class="w-100">
                    <span class="d-inline-block text-truncate max-width-custom w-100">{{ row.current_company }}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column>
                  </ngx-datatable>
                </ng-template>
              </div>
              </div>
              <!-- Social Tab ends -->
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>