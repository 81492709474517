import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PaymentInfo } from '../models/payment.model';
import { CoreTranslationService } from '@core/services/translation.service';
import { PaymentService } from '../services/payment.service';
import { locale as localeEn } from "../i18n/en";
import { locale as localeVn } from "../i18n/vn";
@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})
export class PaymentDetailComponent implements OnInit {

  public paymentInfo: PaymentInfo;
  public userInfo: any = new Object();
  public displayAmount: any = '';
  public paymentQuota: any;

  public paymentMethod: string='vnpay';
  public isLoading = false

  /**
   *  Constructor
   *
   * @param {EcommerceService} _ecommerceService
   */
  constructor(
    private _coreTranslateService: CoreTranslationService,
    private _paymentService: PaymentService,
  ) {
    this._coreTranslateService.translate(localeEn);
    this._coreTranslateService.translate(localeVn);
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.paymentInfo = this._paymentService.paymentInfoValue;
    this.paymentMethod = this._paymentService.paymentOptionValue
    if(!this.paymentMethod){
      this.paymentMethod = localStorage.getItem("paymentMethod")
    }

    if (!this.paymentInfo){
      this.paymentInfo = new PaymentInfo('500K_1000TAILIEU');
    }
    if (this.paymentInfo.packageName=='500K_1000TAILIEU'){
      this.paymentQuota = 500000;
    }
    else if(this.paymentInfo.packageName=='120K_200TAILIEU'){
      this.paymentQuota = 120000;

    }

    this.displayAmount = new Intl.NumberFormat(
        'vi-IN', 
        { style: "currency", currency: "VND" }
    ).format(this.paymentQuota);

    this._paymentService.getUserInfo().subscribe(
      (response) => {
        this.userInfo = response;
      }
    );
  }

  changePaymentMethod(value){
    this.paymentMethod = value;
    localStorage.setItem("paymentMethod", this.paymentMethod);
    this._paymentService.setPaymentOptionValue(value);
  }

  submitPaymentForm(){
    this.isLoading = true
    this._paymentService
      .buyQuota(this.paymentInfo.packageName, this.paymentMethod)
      .subscribe((res) => {
        if(res.payUrl){
          this.isLoading = false
        }
        window.location.href = res.payUrl||res.paypal_payment_url;
      });    
  }

}
