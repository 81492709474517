<div class="content-wrapper container-xxxl p-0 card">
  <div class="content-body card-body" id="ngx-datatable-row-details">
    
    <div>
      

        <div class="mt-4 mt-lg-5">
          <div class="col-xxl-12">
            <div class="mb-3 col-xxl-12">
              <!-- <label for="filePdf" class="form-label" [translate]="'RESUME_PARSER.PARSE_RESUME.UPLOAD_UP_TO'"></label>
              <input class="form-control" #InputVar (change)="onFileSelect($event)" type="file" name="filePdf"
                accept=".pdf, .doc, .docx, .png, .jpg, .jpeg, .xlsx" multiple required /> -->
              
                <label ng2FileDrop [ngClass]="{ 'file-drop-over': hasBaseDropZoneOver }" (onFileDrop)="onFileDrop($event)"
                [uploader]="uploader" class="card input-file d-flex justify-content-center align-items-center"
                for="file-upload-multiple">
                <div class="card-body child-input-file">
                  <div class="row">
                    <div class="col-md-12 col-lg-12">
                      <div class="py-5 text-center font-medium-5 file-drop-zone" (click)="fileInput.click()">
                        <input multiple type="file" id="file-upload-multiple" #fileInput (change)="handleFile($event)" accept=".pdf, .doc, .docx, .png, .jpg, .jpeg, .xlsx"
                          style="display: none" />
                        <div class="upload-option">
                          <span style="color: #00a5f4" translate="RESUME_PARSER.PARSE_RESUME.LABEL_UPLOAD"></span>
                          <div class="contain-input d-flex justify-content-center">
                            <div class="mx-1 mt-1">
                              <button class="upload-button d-flex">
                                <img src="assets/images/backgrounds/icon-floder.svg" width="25px" height="25px" />
                                <span translate="RESUME_PARSER.PARSE_RESUME.FROM_DEVICE"></span>
                              </button>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
              <!-- <div class="mt-4 mt-lg-5">
               

                <button type="submit" [disabled]="form.invalid"
                  class="btn fw-bold btn-primary" [translate]="'RESUME_PARSER.PARSE_RESUME.UPLOAD'"></button>


                
              </div> -->

            </div>
          </div>
        </div>
      
      
    </div>
  </div>
</div>
<div *ngIf="isLoading; else no_loading" id="loading" style="margin: 50px auto">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<ng-template #no_loading>
  <div class="content-wrapper container-xxxl p-0 card" *ngIf="resume_parser.length>0">
    <div class="content-body card-body">
      <div class="row">
        <div class="col-md-3 col-12">
          <div class="d-flex justify-content-between align-items-center m-1">
            <label class="d-flex align-items-center" style="max-width: 100%; flex-wrap: nowrap"><span>Show</span>
              <select class="form-control mx-25 pr-3 show-doc" [(ngModel)]="page.size">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="40">40</option>
              </select>
              <span [translate]="'RESUME_PARSER.PARSE_RESUME.RESUMES'"></span>
            </label>
          </div>
        </div>
        <div class="col-md-9 col-12 d-flex align-items-center justify-content-md-end">
          <ng-container *ngIf="resume_parser.length>0">
            <button (click)="approveListResume()" class="btn btn-success" rippleEffect><span class="fa fa-check"></span>
              <span [translate]="'RESUME_PARSER.PARSE_RESUME.APPROVE_RESUMES'"></span></button>

          </ng-container>

          <button *ngIf="tag_statusApproveList" (click)="modalOpenUploadSf4c(modalBasic)" class="btn btn-info ml-1"
            rippleEffect><span class="fa fa-upload"></span> <span [translate]="'RESUME_PARSER.PARSE_RESUME.UPLOAD_SF4C'"></span></button>
          <a *ngIf="tag_statusApproveList && !tag_resultConnectSf4c_exit" routerLink="/employer/get-credential"
            class="btn btn-info ml-1" rippleEffect><span class="fa fa-upload"></span> <span [translate]="'RESUME_PARSER.PARSE_RESUME.UPLOAD_SF4C'"></span></a>
          <ng-template #modalBasic let-modal>

            <div class="modal-header">
              <h4 class="modal-title" [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'"></h4>
              <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="me-4 mt-3">
              <div class="d-flex justify-content-end">

                <div class="input-group col-md-6">
                  <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                  <input type="text" class="form-control" id="job-search" placeholder="Search file name"
                    aria-label="Search..." aria-describedby="job-search" [(ngModel)]="searchText" (input)="Search()" />
                </div>

              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div *ngIf="isLoadingJr" id="loading" style="margin: 50px auto">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>

            </div>

            <form (ngSubmit)="FormCheckBoxJr()" [formGroup]="formCheckBoxJr">
              <div class="modal-body" ngbAutofocus>


                <table class="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th style="width: 1%;"></th>
                      <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_REQ_ID'"></th>
                      <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_TITLE'"></th>

                      <!-- <th>Score</th> -->

                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let resultJr_ of resultJr
                                      | slice: (pageJr - 1) * basicSelectedOption:(pageJr - 1) * basicSelectedOption + basicSelectedOption">
                      <td *ngIf="arr_checkboxJr.includes(resultJr_.jobReqId);else noChecked">
                        <input [value]="resultJr_.jobReqId" (change)="onCheckboxChangeJr($event)" type="checkbox"
                          class="form-check" checked>

                      </td>
                      <ng-template #noChecked>
                        <td>
                          <input [value]="resultJr_.jobReqId" (change)="onCheckboxChangeJr($event)" type="checkbox"
                            class="form-check">

                        </td>
                      </ng-template>

                      <td>
                        <div class="">{{resultJr_.jobReqId}}</div>
                      </td>
                      <td>
                        <div class="">{{resultJr_.jobTitle}}</div>
                      </td>
                      <!-- <td>
                                              <div class="" >{{resultJr_.score*100|number:'1.2-2'}}%</div>
                                          </td> -->

                    </tr>

                  </tbody>
                </table>
                <div class="row mt-4 mt-lg-5 justify-content-between align-items-center" *ngIf="resultJr.length>0">
                  <div class="col-auto">

                    <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">

                      <ngb-pagination [collectionSize]="resultJr.length" [(page)]="pageJr"
                        [pageSize]="basicSelectedOption" [maxSize]="10" [rotate]="true" [boundaryLinks]="true">


                      </ngb-pagination>
                    </nav>
                  </div>
                  <!-- <div class="col-auto">
                                  <select class="form-select" style="width: auto" [(ngModel)]="basicSelectedOption">
                                      <option [ngValue]="5">5 items per page</option>
                                      <option [ngValue]="10">10 items per page</option>
                                      <option [ngValue]="15">15 items per page</option>
                                  </select>
                                  </div> -->
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary" (click)="modal.dismiss('Cross click')" rippleEffect  [translate]="'RESUME_PARSER.PARSE_RESUME.SUBMIT'">
                  
                </button>
              </div>
            </form>
          </ng-template>
          <ng-container *ngIf="errorMessagesSf4cAutoUpload">
            <div class="alert alert-danger mt-2" role="alert">
              {{errorMessagesSf4cAutoUpload}}
            </div>
          </ng-container>
        </div>
      </div>
      <ngx-datatable *ngIf="resume_parser.length>0" id="table-user" #tableRowDetails class="bootstrap core-bootstrap"
        [rows]="resume_parser" [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber"
        [limit]="page.size" (page)="setPage($event)" [rowHeight]="50" class="bootstrap core-bootstrap"
        [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true"
        [limit]="basicSelectedOption">
        <!-- <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false">
      <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
            (change)="selectFn(!allRowsSelected)" id="headerFolderChkbxRef" />
          <label class="custom-control-label" for="headerFolderChkbxRef"></label>
        </div>
      </ng-template>
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" [checked]="isSelected"
            (change)="onCheckboxChangeFn($event)" id="rowFolderChkbxRef{{ rowIndex }}" />
          <label class="custom-control-label" for="rowFolderChkbxRef{{ rowIndex }}"></label>
        </div>
      </ng-template>
    </ngx-datatable-column> -->
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate" [width]="1.5*widthColumn">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
              <a routerLink="/manage-resume/detail-resume/{{row.id}}"
                class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.file_name}}">{{ row.file_name }}</a>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.STATUS'|translate" prop="status" width="140">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
              <span class="badge bg-{{status_color[row.status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom"
                title="{{row.message}}">{{row.status}}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CONF_SCORE'|translate" width="140">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
              <span class="badge bg-success"
                *ngIf="row.conf_score>range_conf.success">{{row.conf_score*100|number:'1.2-2'}}%</span>
              <span class="badge bg-warning"
                *ngIf="row.conf_score>range_conf.warning && row.conf_score < range_conf.success">{{row.conf_score*100|number:'1.2-2'}}%</span>
              <span class="badge bg-danger"
                *ngIf="row.conf_score<range_conf.warning">{{row.conf_score*100|number:'1.2-2'}}%</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CREATE_AT'|translate" width="200">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
              <span>{{ row.created_at|date:'medium' }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <!-- ACTIONSSSSSSSSSSSSSSSSS -->
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.FIRST_NAME'|translate" prop="first_name" width="140">
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.LAST_NAME'|translate" prop="last_name" width="140">
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.EMAIL'|translate" prop="email" width="200">
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'|translate" prop="phone_number" width="140">
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.GENDER'|translate" prop="gender" width="100">
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.DATE_OF_BIRTH'|translate" width="200">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
              <span>{{ row.date_of_birth|date:'longDate' }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'|translate" [width]="widthColumn">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
              <span class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.current_title}}">{{ row.current_title }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_COMPANY'|translate" [width]="widthColumn">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
              <span class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.current_company}}">{{ row.current_company }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</ng-template>