import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Jr } from 'app/main/resume-parser/models/resume.model';
import { ResumeParserService } from 'app/main/resume-parser/services/resume-parser.service';
import { CV_task } from 'app/main/talent-pool/models/job.model';
import { SearchResume } from 'app/main/talent-pool/models/resume.model';
import { TalentPoolService } from 'app/main/talent-pool/services/talent-pool.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resume-talent-pool',
  templateUrl: './resume-talent-pool.component.html',
  styleUrls: ['./resume-talent-pool.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResumeTalentPoolComponent implements OnInit {
  @Input() ResultSearchCv: SearchResume = new SearchResume()
  @Output() newItemEvent = new EventEmitter<any>();
  @Input() jd_id!: number
  @Input() saved!: boolean
  public overall_score!: number
  public detail_jd: CV_task=new CV_task()


  public arr_checkboxSaveCv:string[]=[]
  public arr_checkboxSaveCvJr:string[]=[]

  
  formCheckBoxSaveCv = this.fb.group({
    checkResumeArraySaveCv: this.fb.array([],Validators.required),
    checkResumeArraySaveCvJr: this.fb.array([],Validators.required)

  })
  public list_task: CV_task[] =[]
  public list_jr: Jr[] = []
  public save_cv: string = ''
  public saved_cv = false;
  public positive = false
  public negative = false
  public page=1
  public pageSize=8
  public page_sf4c=1
  public pageSize_sf4c=8
  public currentTitles: string[] = []
  public currentCompanies: string[] = []

  public errorMessagesSaveResumeByJd: any
  public errorMessagesListAllJd!: any
  public list_all_jd:CV_task[]=[]
  public status_color: any = {
    "Approved": 'success',
    "SF4C_background_failed": 'warning',
    "Done": "primary",
    "Failure": "danger",
    "Processing": "info",
    "Wait_to_process":"dark",
    "Suspended":"muted",
    "Modified":"warning",
    "SF4C_wait_to_upload":"dark",
    "SF4C_failed":"secondary",
    "SF4C_uploading":"info",
    "SF4C_uploaded":"success",
    "Base_upload_failed":"secondary",
    "Base_uploading":"info",
    "Base_upload_success":"success"
  }
  public progressbarHeight = '.857rem';

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private talentPoolService: TalentPoolService,
    private resumesService: ResumeParserService,

  ) { }
  modalOpenSaveCv(modalBasicSaveCv:any) {
    this.ListAllJd()
    this.modalService.open(modalBasicSaveCv, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
    }
  ListAllJd(){
    this.talentPoolService.list_all_jd().subscribe(
      (res)=>{
        this.list_all_jd = res;
      },
      (err)=>{
        this.errorMessagesListAllJd = err
      }
    )
  }
  onCheckboxChangeSaveCv(e:any) {
    const checkResumeArraySaveCv: FormArray = this.formCheckBoxSaveCv.get('checkResumeArraySaveCv') as FormArray;
    if (e.target.checked) {
  
      checkResumeArraySaveCv.push(new FormControl(e.target.value));
      this.arr_checkboxSaveCv.push(e.target.value)
      
  
    } else {
      let i: number = 0;
      checkResumeArraySaveCv.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArraySaveCv.removeAt(i);
          this.arr_checkboxSaveCv=this.arr_checkboxSaveCv.filter(item=>item!==e.target.value)
          return;
        }
        i++;
      });
    }
  }
  onCheckboxChangeSaveCvJr(e:any) {
    const checkResumeArraySaveCvJr: FormArray = this.formCheckBoxSaveCv.get('checkResumeArraySaveCvJr') as FormArray;
    if (e.target.checked) {
  
      checkResumeArraySaveCvJr.push(new FormControl(e.target.value));
      this.arr_checkboxSaveCvJr.push(e.target.value)
      
  
    } else {
      let i: number = 0;
      checkResumeArraySaveCvJr.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArraySaveCvJr.removeAt(i);
          this.arr_checkboxSaveCvJr=this.arr_checkboxSaveCvJr.filter(item=>item!==e.target.value)
          return;
        }
        i++;
      });
    }
  }
  FormCheckBoxSaveCv(resume: number){
    
    let arr_checkbox_SaveCv=[]
    for (let i=0;i<this.arr_checkboxSaveCv.length;i++){
      arr_checkbox_SaveCv.push(parseInt(this.arr_checkboxSaveCv[i]))
    }
    let job_description= arr_checkbox_SaveCv[0]
    this.talentPoolService.save_resume_by_jd(job_description, resume).subscribe(
      (res)=>{
        this.save_cv = res.message;
        this.toastr.success('Save Success')
      },
      (err)=>{
        this.errorMessagesSaveResumeByJd = err
      }
    )
  }
  SaveResumeByJd(resume: number){
    this.talentPoolService.save_resume_by_jd(this.jd_id, resume).subscribe(
      (res)=>{
        this.save_cv = res.message;
        this.toastr.success('Save Success');
        this.saved_cv = true
        this.newItemEvent.emit(resume);
      },
      (err)=>{
        this.errorMessagesSaveResumeByJd = err;
      }
    )
  }
  GetDetailJd(){
    const jd_id= Number(this.route.snapshot.paramMap.get('id'))
    this.talentPoolService.detail_jd(jd_id).subscribe(
      (res)=>{
        this.detail_jd = res;
        this.GetOverScore()
      },
      (err)=>{
        console.error("Error: "+ err)
      }
    )
    
  }
  GetOverScore(){
    if(this.detail_jd.hcm_system_id){
      let id = this.ResultSearchCv.resume_id
      let url_dynamic = "?jobReqId="+ this.detail_jd.hcm_system_id
      this.resumesService.matching_jr(id,url_dynamic).subscribe(
        (res)=>{
          if(res.match_result.overall_score>0){
            this.overall_score = res.match_result.overall_score    
          }
      },
      (err)=>{
        console.error("Error: "+err)
      }
      )
    }
  }
  ngOnInit(): void {
    this.currentTitles = this.ResultSearchCv.current_title.split('; ')
    this.currentCompanies = this.ResultSearchCv.current_company.split('; ')
    if(this.jd_id!=null && this.jd_id!=undefined){
      this.GetDetailJd()
    }
  }

}
