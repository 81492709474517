import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParseResumeComponent } from './parse-resume/parse-resume.component';
import { ManageResumeComponent } from './manage-resume/manage-resume.component';
import { IntegrationComponent } from './integration/integration.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from 'app/auth/helpers/jwt.interceptor';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { DetailResumeComponent } from './manage-resume/detail-resume/detail-resume.component';
import { ProgressBarAngularModule } from 'progress-bar-angular';
import { CachedSrcDirective } from './manage-resume/detail-resume/iframe.component';
import { GaugeModule } from 'angular-gauge';
import { EditResumeComponent } from './manage-resume/edit-resume/edit-resume.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FileUploadModule } from 'ng2-file-upload';

const routes: Routes = [
  {
    path: "parse-resume",
    component: ParseResumeComponent,
    canActivate: [AuthGuard],
    data: { animation: "parse-resume" },
  },
  {
    path: "manage-resume",
    component: ManageResumeComponent,
    canActivate: [AuthGuard],
    data: { animation: "manage-resume" },
  },
  {
    path: "manage-resume/detail-resume/:id",
    component: DetailResumeComponent,
    canActivate: [AuthGuard],
    data: { animation: "manage-resume" },

  },
  {
    path: "manage-resume/edit-resume/:id",
    component: EditResumeComponent,
    canActivate: [AuthGuard],
    data: { animation: "manage-resume" },

  },
  {
    path: "integration",
    component: IntegrationComponent,
    canActivate: [AuthGuard],
    data: { animation: "integration" },
  },
];

@NgModule({
  declarations: [
    ParseResumeComponent,
    ManageResumeComponent,
    IntegrationComponent,
    DetailResumeComponent,
    CachedSrcDirective,
    EditResumeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    NgxDatatableModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule,
    NgbNavModule,
    HttpClientModule,
    CoreCommonModule,
    NgbModule,
    ProgressBarAngularModule,
    GaugeModule.forRoot(),
    BsDatepickerModule,
    FileUploadModule
  ],
  exports: [CachedSrcDirective],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    AuthGuard,
  ],
})
export class ResumeParserModule { }
