<div class="content-wrapper p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- account setting page -->
    <section id="page-account-settings">
      <div class="row nav-vertical d-flex">
        <div class="col-md-3 mb-2 mb-md-0">
          <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left">
            <li class="nav-item" ngbNavItem>
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-pill-general"
                data-toggle="pill"
                href="#account-vertical-general"
                aria-expanded="true"
              >
                <i data-feather="user" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold" [translate]="'ACCOUNT_SETTING.GENERAL.TITLE'"></span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <!-- form -->
                    <form (ngSubmit)="saveProfile()" [formGroup]="EditProfileForm" class="validate-form mt-2">
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="first_name" [translate]="'ACCOUNT_SETTING.GENERAL.FIRST_NAME'"></label>
                            <input
                              type="text"
                              formControlName="first_name"
                              
                              class="form-control"
                              id="first_name"
                              name="first_name"                            
                              [placeholder]="'ACCOUNT_SETTING.GENERAL.FIRST_NAME'|translate"
                            />
                            <span *ngIf="EditProfileForm.hasError('required', 'first_name') && EditProfileForm.touched">
                              <small class="form-text text-danger"
                              [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"></small
                              >
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="last_name" [translate]="'ACCOUNT_SETTING.GENERAL.LAST_NAME'"></label>
                            <input
                              type="text"
                              formControlName="last_name"
                              class="form-control"
                              id="last_name"
                              name="last_name"                        
                              [placeholder]="'ACCOUNT_SETTING.GENERAL.LAST_NAME'|translate"
                            />
                            <span *ngIf="EditProfileForm.hasError('required', 'last_name') && EditProfileForm.touched">
                              <small class="form-text text-danger"
                              [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"></small
                              >
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="email" [translate]="'ACCOUNT_SETTING.GENERAL.EMAIL'"></label>
                            <input
                              type="email"
                              formControlName="email"
                              class="form-control"
                              id="email"
                              name="email"
                              [placeholder]="'ACCOUNT_SETTING.GENERAL.EMAIL'|translate"
                            />
                            <span *ngIf="EditProfileForm.hasError('required', 'email') && EditProfileForm.touched">
                              <small class="form-text text-danger"
                              [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"></small
                              >
                            </span>
                          </div>
                        </div>
                        <!-- <div class="col-12 mt-75">
                          <div class="alert alert-warning mb-50" role="alert">
                            <h4 class="alert-heading">Your email is not confirmed. Please check your inbox.</h4>
                            <div class="alert-body">
                              <a href="javascript: void(0);" class="alert-link">Resend confirmation</a>
                            </div>
                          </div>
                        </div> -->
                        <div class="col-12">
                          <button type="submit" class="btn btn-primary mt-2 mr-1" rippleEffect [translate]="'ACCOUNT_SETTING.GENERAL.SAVE'"></button>
                          <button type="reset" class="btn btn-outline-secondary mt-2" rippleEffect [translate]="'ACCOUNT_SETTING.GENERAL.CANCEL'"></button>
                        </div>
                        <ng-container *ngIf="errorMessagesUpdateUser">
                          <div class="alert alert-danger" role="alert">
                            {{errorMessagesUpdateUser}}
                          </div>
                      </ng-container>
                      </div>
                    </form>
                    <!--/ form -->
                  </div>
                </div>
              </ng-template>
            </li>
            <li class="nav-item" ngbNavItem>
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-pill-password"
                data-toggle="pill"
                href="#account-vertical-password"
                aria-expanded="false"
              >
                <i data-feather="lock" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold" [translate]="'ACCOUNT_SETTING.PASSWORD.TITLE'"></span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <!-- form -->
                    <form [formGroup]="ChangeProfileForm" (ngSubmit)="changePassword()" class="validate-form">
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-old-password" [translate]="'ACCOUNT_SETTING.PASSWORD.OLD_PASS'"></label>
                            <div class="input-group form-password-toggle input-group-merge">
                              <input
                                [type]="passwordTextTypeOld ? 'text' : 'password'"
                                class="form-control"
                                id="account-old-password"
                                name="old_password"
                                [placeholder]="'ACCOUNT_SETTING.PASSWORD.OLD_PASS'|translate"
                                formControlName="old_password"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"
                                  ><i
                                    class="feather font-small-4"
                                    [ngClass]="{
                                      'icon-eye-off': passwordTextTypeOld,
                                      'icon-eye': !passwordTextTypeOld
                                    }"
                                    (click)="togglePasswordTextTypeOld()"
                                  ></i
                                ></span>
                              </div>
                              <span *ngIf="ChangeProfileForm.hasError('required', 'old_password') && ChangeProfileForm.touched">
                                <small class="form-text text-danger"
                                [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"></small
                                >
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-new-password" [translate]="'ACCOUNT_SETTING.PASSWORD.NEW_PASS'"></label>
                            <div class="input-group form-password-toggle input-group-merge">
                              <input
                                [type]="passwordTextTypeNew ? 'text' : 'password'"
                                id="account-new-password"
                                name="new_password"
                                class="form-control"
                                formControlName="new_password"
                                [placeholder]="'ACCOUNT_SETTING.PASSWORD.NEW_PASS'|translate"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"
                                  ><i
                                    class="feather font-small-4"
                                    [ngClass]="{
                                      'icon-eye-off': passwordTextTypeNew,
                                      'icon-eye': !passwordTextTypeNew
                                    }"
                                    (click)="togglePasswordTextTypeNew()"
                                  ></i
                                ></span>
                              </div>
                              <span *ngIf="ChangeProfileForm.hasError('required', 'new_password') && ChangeProfileForm.touched">
                                <small class="form-text text-danger"
                                [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"></small
                                >
                              </span>
                              <span *ngIf="ChangeProfileForm.hasError('notMatch') && ChangeProfileForm.touched">
                                <small class="form-text text-danger"
                                [translate]="'ACCOUNT_SETTING.PASSWORD.NOT_MATCH'"></small
                                >
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-retype-new-password" [translate]="'ACCOUNT_SETTING.PASSWORD.RETYPE_PASS'"></label>
                            <div class="input-group form-password-toggle input-group-merge">
                              <input
                                [type]="passwordTextTypeRetype ? 'text' : 'password'"
                                class="form-control"
                                id="account-retype-new-password"
                                name="confirm-new-password"
                                formControlName="confirmPassword"
                                [placeholder]="'ACCOUNT_SETTING.PASSWORD.NEW_PASS'|translate"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"
                                  ><i
                                    class="feather font-small-4"
                                    [ngClass]="{
                                      'icon-eye-off': passwordTextTypeRetype,
                                      'icon-eye': !passwordTextTypeRetype
                                    }"
                                    (click)="togglePasswordTextTypeRetype()"
                                  ></i
                                ></span>
                              </div>
                              <span *ngIf="ChangeProfileForm.hasError('notSame') && ChangeProfileForm.touched">
                                <small class="form-text text-danger"
                                [translate]="'ACCOUNT_SETTING.PASSWORD.NOT_SAME'"></small
                                >
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <button type="submit" class="btn btn-primary mr-1 mt-1" rippleEffect [translate]="'ACCOUNT_SETTING.GENERAL.SAVE'">Save changes</button>
                          <button type="reset" class="btn btn-outline-secondary mt-1" rippleEffect [translate]="'ACCOUNT_SETTING.GENERAL.CANCEL'">Cancel</button>
                        </div>
                      </div>
                    </form>
                    <!--/ form -->
                  </div>
                </div>
              </ng-template>
            </li>
            <li class="nav-item" ngbNavItem>
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-pill-activity"
                data-toggle="pill"
                href="#account-vertical-activity"
                aria-expanded="false"
              >
                <i data-feather="users" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold" [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.TITLE'"></span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <div class="mt-2">
                      <div class="row justify-content-end align-items-center mb-1">
                          <div class="mr-1">{{users.length}} <span [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.USERS'"></span> </div>
                                  <div class="mr-1" *ngIf="currentUser.is_superuser||currentUser.is_site_admin"><button class="btn btn-outline-primary" (click)="modalOpenCreateUser(modalBasic);" [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.CREATE_USER'"></button></div>
                                  <ng-template #modalBasic let-modal>
                                      <form [formGroup]="createUserForm" (ngSubmit)="CreateUser()">
                                          <div class="modal-header">
                                              <h4 class="modal-title" [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.CREATE_USER'"> </h4>
                                              <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                              </button>
                                            </div>
                                          <div class="modal-body" ngbAutofocus>
                                                  <div class="form-group">
                                                      <label for="first-name" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.FIRST_NAME'"></label>
                                                      <input
                                                      type="text"
                                                      id="first-name"
                                                      class="form-control"
                                                      placeholder="First name"
                                                      name="first-name"
                                                      formControlName="first_name"
                                                      required
                                                      />
                                                  </div>
                                                  <div class="form-group">
                                                      <label for="last-name" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.LAST_NAME'"></label>
                                                      <input
                                                      type="text"
                                                      id="last-name"
                                                      class="form-control"
                                                      placeholder="Last name"
                                                      name="last-name"
                                                      formControlName="last_name"
                                                      required
                                                      />
                                                  </div>
                                                  <div class="form-group">
                                                      <label for="first-name" [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.EMAIL'"></label>
                                                      <input
                                                      type="text"
                                                      id="email"
                                                      class="form-control"
                                                      placeholder="Email"
                                                      name="email"
                                                      formControlName="email"
                                                      required
                                                      />
                                                  </div>
                                                  <div class="form-group" *ngIf="!editBoolean">
                                                      <div class="d-flex justify-content-between">
                                                        <label for="login-password" [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.PASSWORD'"></label
                                                        >
                                                      </div>
                                                      <div class="input-group input-group-merge form-password-toggle">
                                                        <input
                                                          [type]="passwordTextType ? 'text' : 'password'"
                                                          formControlName="password"
                                                          class="form-control form-control-merge"
                                                          [ngClass]="{
                                                            'is-invalid error': submitted && f_user.password.errors
                                                          }"
                                                          placeholder="············"
                                                          aria-describedby="login-password"
                                                          tabindex="2"
                                                        />
                                                        <div class="input-group-append">
                                                          <span class="input-group-text cursor-pointer"
                                                            ><i
                                                              class="feather font-small-4"
                                                              [ngClass]="{
                                                                'icon-eye-off': passwordTextType,
                                                                'icon-eye': !passwordTextType
                                                              }"
                                                              (click)="togglePasswordTextType()"
                                                            ></i
                                                          ></span>
                                                        </div>
                                                      </div>
                                                      <div
                                                        *ngIf="submitted && f.password.errors"
                                                        class="invalid-feedback"
                                                        [ngClass]="{ 'd-block': submitted && f_user.password.errors }"
                                                      >
                                                        <div *ngIf="f_user.password.errors.required" [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.PASSWORD_REQUIRED'"></div>
                                                      </div>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        id="checkSearchEngine"
                                                        formControlName="checkSearchEngine"
                                                      />
                                                      <label
                                                        class="custom-control-label"
                                                        for="checkSearchEngine"
                                                        [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.PERMISSION_SEARCH'"
                                                      ></label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        id="checkParseResume"
                                                        formControlName="checkParseResume"
                                                      />
                                                      <label
                                                        class="custom-control-label"
                                                        for="checkParseResume"
                                                        [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.PERMISSION_PARSE'"
                                                      ></label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        id="is_site_admin"
                                                        formControlName="is_site_admin"
                                                      />
                                                      <label
                                                        class="custom-control-label"
                                                        for="is_site_admin"
                                                        [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.IS_SITE_ADMIN'"
                                                      ></label>
                                                    </div>
                                          </div>
                                          <div class="modal-footer">
                                              <button [disabled]="!createUserForm.valid" type="submit" (click)="modal.close('Accept click')" class="btn btn-primary" [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.SUBMIT'"></button>
                                          </div>
                                      </form>
                                  </ng-template>
                                  <div class="">
                                      <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                                      <input
                                          type="text"
                                          class="form-control"
                                          id="job-search"
                                          placeholder="Search email"
                                          
                                          [(ngModel)]="searchText"
                                          (input)="Search()"
                                      />
                                  </div>
                      </div>
                      <div class="table-responsive">
                          <table class="table table-hover align-middle">
                              <thead>
                                  <tr>
                                      
                                      <th [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.NAME'"></th>
                                      <th [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.EMAIL'"></th>
                                      <th [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.PERMISSION_PARSE'"></th>
                                      
                                      <th [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.PERMISSION_SEARCH'"></th>
                                      
                                      <th [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.PERMISSION_TALENT'"></th>
                                      <th [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.IS_SITE_ADMIN'"></th>
                                      <th [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.LAST_LOGIN'"></th>
                                      <th [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.ACTION'"></th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let user of users
                                  | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
                                      
                                      <td>
                                          
                                          <div class="">{{user.last_name}} {{user.first_name}}</div>
                                             
                                      </td>
                                      <td>
                                          <div class="">{{user.email}}</div>
                                      </td>
                                      <td *ngIf="user.permissions.includes('parse_cv');else noParse"><div class=""><i class="fa fa-check" aria-hidden="true"></i>
                                      </div></td>
                                      <ng-template #noParse>
                                          <td><div class=""><i class="fa fa-times" aria-hidden="true"></i>
                                          </div></td>
                                      </ng-template>
                                      
                                      
                                      <td *ngIf="user.permissions.includes('search_engine');else noSearch"><div class=""><i class="fa fa-check" aria-hidden="true"></i>
                                      </div></td>
                                      <ng-template #noSearch>
                                          <td><div class=""><i class="fa fa-times" aria-hidden="true"></i>
                                          </div></td>
                                      </ng-template>
                                      <td *ngIf="user.permissions.includes('parse_cv');else noTalentPool"><div class=""><i class="fa fa-check" aria-hidden="true"></i>
                                          <!-- <a title="Update permission talent pool" (click)="modalOpenUpdatePermissionTalentPool(modalBasicUpdate)"><span style="margin-top: -12px;color: #ffc107;" class="btn fa fa-pencil-square-o"></span></a> -->
                                      </div></td>
                                      <ng-template #noTalentPool>
                                          <td><div class=""><i class="fa fa-times" aria-hidden="true"></i>
                                              <!-- <a title="Update permission talent pool" (click)="modalOpenUpdatePermissionTalentPool(modalBasicUpdate)"><span style="margin-top: -12px;color: #ffc107;" class="btn fa fa-pencil-square-o"></span></a> -->
                                          </div></td>
                                      </ng-template>
                                      <!-- <ng-template #modalBasicUpdate let-modal>
                                          <form [formGroup]="UpdateUserTalentPoolForm" (ngSubmit)="UpdateUserPermissionTalentPool(user.id)">
                                              
                                              <div class="modal-header">
                                                  <h4 class="modal-title">Permission talent pool</h4>
                                                  <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                  </button>
                                                </div>
                                              <div class="modal-body" ngbAutofocus>
                                                  <div class="custom-control custom-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        id="is_accessible"
                                                        formControlName="is_accessible"
                                                      />
                                                      <label
                                                        class="custom-control-label"
                                                        for="is_accessible"
                                                        
                                                      >Is accessible</label>
                                                    </div>
                                                  
                                              </div>
                                              
                                              <div class="modal-footer">
                                                  <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')" rippleEffect>
                                                  Cancle
                                              </button>
                                              <button type="submit" class="btn btn-primary" (click)="modal.dismiss('Cross click')" rippleEffect>
                                                  Accept
                                              </button>
                                              </div>
                                          </form>
                                      </ng-template> -->
                                      <td *ngIf="user.is_site_admin;else noSiteAdmin"><div class=""><i class="fa fa-check" aria-hidden="true"></i>
                                      </div></td>
                                      <ng-template #noSiteAdmin>
                                          <td><div class=""><i class="fa fa-times" aria-hidden="true"></i>
                                          </div></td>
                                      </ng-template>
                                      <td>
                                          <div class="">{{user.last_login|date:'medium'}}</div>
                                      </td>
                                      <td class="">
                                        

                                          <span *ngIf="currentUser.is_superuser||currentUser.is_site_admin"><a (click)="modalOpenCreateUser(modalBasic, {
                                              first_name: user.first_name,
                                              last_name: user.last_name,
                                              email: user.email,
                                              is_site_admin: user.is_site_admin,
                                              checkSearchEngine: user.permissions.includes('search_engine'),
                                              checkParseResume: user.permissions.includes('parse_cv'),
                                              id: user.id
                                          })"><span class="btn fa fa-pencil mr-1"></span></a></span>
                                          <!-- <span><a title="Delete user" (click)="modalOpenDeleteUser(modalBasicDeleteUser)"><span class="btn fa fa-trash-o"></span></a></span> -->
                                      </td>
      
                                      
                                  </tr>
      
                              </tbody>
                          </table>
                          
                         
                      </div>
                      <!-- <ng-template #modalBasicDeleteUser let-modal>
                        
                            
                            <div class="modal-header">
                                <h4 class="modal-title" [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.ASSIGN_USER'"></h4>
                                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="m-1 d-flex justify-content-end">
                                <div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="job-search"
                                    placeholder="Search email"
                                    
                                    [(ngModel)]="searchText"
                                    (input)="Search()"
                                />
                                </div>
                                
                            </div>
                            <form [formGroup]="deleteUserForm" (ngSubmit)="DeleteUser(user.id)">
                            <div class="modal-body" ngbAutofocus *ngFor="let user_ of users| slice: (pageAssignUser - 1) * basicSelectedOptionAssignUser:(pageAssignUser - 1) * basicSelectedOptionAssignUser + basicSelectedOptionAssignUser">
                                <div class="form-check form-check-inline">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      (change)="onCheckboxChange(user_.id.toString(),$event.target.checked)"
                                      formControlName="checkUser"
                                    />
                                    <label
                                      class="form-check-label"
                                      for="checkbox_email"
                                      
                                    >{{user_.email}}</label>
                                  </div>
                                  
                            </div>
                            <div class="row mt-4 mt-lg-5 justify-content-between align-items-center" *ngIf="users.length>0">
                                <div class="col-auto">
                        
                                    <nav class="mt-3 mt-sm-0" aria-label="Users list pagination">
                        
                                    <ngb-pagination [collectionSize]="users.length" [(page)]="pageAssignUser" [pageSize]="basicSelectedOptionAssignUser"
                                    [maxSize]="10" [rotate]="true" [boundaryLinks]="true">
                        
                        
                                    </ngb-pagination>
                                    </nav>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" style="background-color: gray;" (click)="modal.dismiss('Cross click')" rippleEffect [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.CANCLE'">
                                
                            </button>
                            <button type="submit" class="btn btn-primary" (click)="modal.dismiss('Cross click')" rippleEffect [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.ACCEPT'">
                                
                            </button>
                            </div>
                        </form>
                    </ng-template>  -->
                      <div class="row mt-4 mt-lg-5 justify-content-between">
                          <div class="col-auto">
      
                              <nav class="mt-sm-0" aria-label="Jobs list pagination">
      
                                  <ngb-pagination [collectionSize]="users.length" [(page)]="page" [pageSize]="pageSize"
                                  [maxSize]="10" [rotate]="true" [boundaryLinks]="true">
      
      
                                  </ngb-pagination>
                              </nav>
                          </div>
                          <div class="col-auto">
                              <select class="form-control" style="width: auto" [(ngModel)]="pageSize">
                                  <option [ngValue]="5">5 <span [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.ITEMS_PER_PAGE'"></span></option>
                                  <option [ngValue]="10">10 <span [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.ITEMS_PER_PAGE'"></span></option>
                                  <option [ngValue]="15">15 <span [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.ITEMS_PER_PAGE'"></span></option>
                              </select>
      
      
                          </div>
                      </div>   
                  </div>
                    
                  </div>
                </div>
              </ng-template>
            </li>
            <li class="nav-item" ngbNavItem>
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-pill-notifications"
                data-toggle="pill"
                href="#account-vertical-notifications"
                aria-expanded="false"
              >
                <i data-feather="settings" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold" [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.TITLE'"></span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <form (ngSubmit)="putResumeSettings()" class="validate-form">
                      <div class="row">
                        <!-- <h6 class="section-label mx-1 mb-2">Resume settings</h6> -->
                        <div class="col-12 mb-2">
                          <div class="custom-control custom-switch">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [(ngModel)]="auto_approve_done_resume"
                              id="auto_approve_done_resume"
                              name="auto_approve_done_resume"
                            />
                            <label class="custom-control-label" for="auto_approve_done_resume" [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.AUTO_APPROVE'">
                              
                            </label>
                          </div>
                        </div>
                        <div class="col-12 mb-2">
                          <div class="custom-control custom-switch">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [(ngModel)]="auto_sync_email"
                              id="auto_sync_email"
                              name="auto_sync_email"
                            />
                            <label class="custom-control-label" for="auto_sync_email" [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.AUTO_SYNC'">
                              
                            </label>
                          </div>
                        </div>   
                        <div class="col-12 mb-2">
                          <div class="custom-control custom-switch">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [(ngModel)]="censor_contact"
                              id="censor_contact"
                              name="censor_contact"
                              
                            />
                            <label class="custom-control-label" for="censor_contact" [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.CENSOR_CONTACT'">
                              
                            </label>
                          </div>
                        </div>   
                        <div class="col-6 mb-2">
                          <div class="form-group">
                            <label for="approve_score" [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.APPROVE_SCORE'">
                              
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              [(ngModel)]="approve_score"
                              id="approve_score"
                              name="approve_score"
                              placeholder="0.1->1.0"
                            />
                            
                          </div>
                        </div>   
                        <div class="col-6 mb-2">
                          <div class="form-group">
                            <label for="webhook_parsing_api" [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.WEBHOOK_PARSING'">
                              
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              [(ngModel)]="webhook_parsing_api"
                              id="webhook_parsing_api"
                              name="webhook_parsing_api"
                              placeholder="link url webhook"
                            />
                            
                          </div>
                        </div>   
                        <div class="col-6 mb-2">
                          <div class="form-group">
                            <label for="webhook_dev_parsing_api" [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.WEBHOOK_DEV_PARSING'">
                              
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              [(ngModel)]="webhook_dev_parsing_api"
                              id="webhook_dev_parsing_api"
                              name="webhook_dev_parsing_api"
                              placeholder="link url webhook"
                            />
                            
                          </div>
                        </div>
                        <div class="col-6 mb-2">
                          <div class="form-group">
                            <label for="webhook_matching_api" [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.WEBHOOK_MATCHING'">
                              
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              [(ngModel)]="webhook_matching_api"
                              id="webhook_matching_api"
                              name="webhook_matching_api"
                              placeholder="link url webhook"
                            />
                            
                          </div>
                        </div>
                        <div class="col-6 mb-2">
                          <div class="form-group">
                            <label for="webhook_dev_matching_api" [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.WEBHOOK_DEV_MATCHING'">
                              
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              [(ngModel)]="webhook_dev_matching_api"
                              id="webhook_dev_matching_api"
                              name="webhook_dev_matching_api"
                              placeholder="link url webhook"
                            />
                            
                          </div>
                        </div>
                        <div class="col-6 mb-2">
                          <div class="form-group">
                            <label for="webhook_recommend_api" [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.WEBHOOK_RECOMMEND'">
                              
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              [(ngModel)]="webhook_recommend_api"
                              id="webhook_recommend_api"
                              name="webhook_recommend_api"
                              placeholder="link url webhook"
                            />
                            
                          </div>
                        </div>
                        <div class="col-6 mb-2">
                          <div class="form-group">
                            <label for="webhook_recommend_dev_api" [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.WEBHOOK_RECOMMEND_DEV_API'">
                              
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              [(ngModel)]="webhook_recommend_dev_api"
                              id="webhook_recommend_dev_api"
                              name="webhook_recommend_dev_api"
                              placeholder="link url webhook"
                            />
                            
                          </div>
                        </div>                   
                        
                        <div class="col-12">
                          <button type="submit" class="btn btn-primary mt-2 mr-1" rippleEffect [translate]="'ACCOUNT_SETTING.GENERAL.SAVE'"></button>
                          <button type="reset" class="btn btn-outline-secondary mt-2" rippleEffect [translate]="'ACCOUNT_SETTING.GENERAL.CANCEL'"></button>
                        </div>
                      </div>
                    </form>
                    
                  </div>
                </div>
              </ng-template>
            </li>
            <li class="nav-item" ngbNavItem>
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-pill-notifications"
                data-toggle="pill"
                href="#account-vertical-connectSf4c"
                aria-expanded="false"
              >
                <i data-feather="upload" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold" [translate]="'ACCOUNT_SETTING.CONNECT_SF4C.TITLE'"></span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <form [formGroup]="ConnectSf4cForm" (ngSubmit)="GetCredential()" class="validate-form mt-2">
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="file" [translate]="'ACCOUNT_SETTING.CONNECT_SF4C.UPLOAD_FILE'"></label>
                            <input class="form-control" #InputVar (change)="onFileSelect($event)" type="file" name="file" accept=".pem" required/>
                            <span *ngIf="ConnectSf4cForm.hasError('required', 'file') && ConnectSf4cForm.touched">
                              <small class="form-text text-danger"
                              [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"></small
                              >
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="api_key" [translate]="'ACCOUNT_SETTING.CONNECT_SF4C.API_KEY'"></label>
                            <input
                              type="text"
                              formControlName="api_key"
                              class="form-control"
                              id="api_key"
                              name="api_key"
                              [placeholder]="'ACCOUNT_SETTING.CONNECT_SF4C.API_KEY'|translate"
                              required
                            />
                            <span *ngIf="ConnectSf4cForm.hasError('required', 'api_key') && ConnectSf4cForm.touched">
                              <small class="form-text text-danger"
                              [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"></small
                              >
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="company_id" [translate]="'ACCOUNT_SETTING.CONNECT_SF4C.COMPANY_ID'"></label>
                            <input
                              type="company_id"
                              formControlName="company_id"
                              class="form-control"
                              id="company_id"
                              name="company_id"
                              [placeholder]="'ACCOUNT_SETTING.CONNECT_SF4C.COMPANY_ID'|translate"
                              required
                            />
                            <span *ngIf="ConnectSf4cForm.hasError('required', 'company_id') && ConnectSf4cForm.touched">
                              <small class="form-text text-danger"
                              [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"></small
                              >
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="username" [translate]="'ACCOUNT_SETTING.CONNECT_SF4C.USER_NAME'"></label>
                            <input
                              type="username"
                              formControlName="username"
                              class="form-control"
                              id="username"
                              name="username"
                              [placeholder]="'ACCOUNT_SETTING.CONNECT_SF4C.USER_NAME'|translate"
                              required
                            />
                            <span *ngIf="ConnectSf4cForm.hasError('required', 'username') && ConnectSf4cForm.touched">
                              <small class="form-text text-danger"
                              [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"></small
                              >
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="token_url" [translate]="'ACCOUNT_SETTING.CONNECT_SF4C.TOKEN_URL'"></label>
                            <input
                              type="token_url"
                              formControlName="token_url"
                              class="form-control"
                              id="token_url"
                              name="token_url"
                              [placeholder]="'ACCOUNT_SETTING.CONNECT_SF4C.TOKEN_URL'|translate"
                              required
                            />
                            <span *ngIf="ConnectSf4cForm.hasError('required', 'token_url') && ConnectSf4cForm.touched">
                              <small class="form-text text-danger"
                              [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"></small
                              >
                            </span>
                          </div>
                        </div>
                        <!-- <div class="col-12 mt-75">
                          <div class="alert alert-warning mb-50" role="alert">
                            <h4 class="alert-heading">Your company_id is not confirmed. Please check your inbox.</h4>
                            <div class="alert-body">
                              <a href="javascript: void(0);" class="alert-link">Resend confirmation</a>
                            </div>
                          </div>
                        </div> -->
                        <div class="col-12">
                          <button type="submit" [disabled]="ConnectSf4cForm.invalid" class="btn btn-primary mt-2 mr-1" rippleEffect [translate]="'ACCOUNT_SETTING.GENERAL.SAVE'">Save changes</button>
                          <button type="reset" class="btn btn-outline-secondary mt-2" rippleEffect [translate]="'ACCOUNT_SETTING.GENERAL.CANCEL'">Cancel</button>
                        </div>
                        <ng-container *ngIf="errorMessagesResultConnectSf4c">
                          <div class="alert alert-danger" role="alert">
                            {{errorMessagesResultConnectSf4c}}
                          </div>
                      </ng-container>
                      </div>
                    </form>
                    
                  </div>
                </div>
              </ng-template>
            </li>
            <li class="nav-item" ngbNavItem *ngIf="currentUser.is_superuser||currentUser.is_site_admin">
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-pill-notifications"
                data-toggle="pill"
                href="#account-vertical-sourceMapping"
                aria-expanded="false"
              >
                <i data-feather="copy" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.TITLE'">
                </span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <form (ngSubmit)="SourceMapping()" class="validate-form mt-2">
                      <div class="row">
                        <h4 class="col-12" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.RESUME_CONFIG'"></h4>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="gender" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.GENDER'"></label>
                            <input
                              type="text"
                              [(ngModel)]="gender"
                              class="form-control"
                              id="gender"
                              name="gender"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.GENDER'|translate"
                              
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="address" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.ADDRESS'"></label>
                            <input
                              type="text"
                              [(ngModel)]="address"
                              class="form-control"
                              id="address"
                              name="address"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.ADDRESS'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="phone_number" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.PHONE_NUMBER'"></label>
                            <input
                              type="text"
                              [(ngModel)]="phone_number"
                              class="form-control"
                              id="phone_number"
                              name="phone_number"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.PHONE_NUMBER'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="firstNameMapping" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.FIRST_NAME'"></label>
                            <input
                              type="text"
                              [(ngModel)]="firstNameMapping"
                              class="form-control"
                              id="firstNameMapping"
                              name="firstNameMapping"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.FIRST_NAME'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="lastNameMapping" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.LAST_NAME'"></label>
                            <input
                              type="text"
                              [(ngModel)]="lastNameMapping"
                              class="form-control"
                              id="lastNameMapping"
                              name="lastNameMapping"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.LAST_NAME'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="date_of_birth" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.DATE_OF_BIRTH'"></label>
                            <input
                              type="text"
                              [(ngModel)]="date_of_birth"
                              class="form-control"
                              id="date_of_birth"
                              name="date_of_birth"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.DATE_OF_BIRTH'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="emailMapping" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.EMAIL'"></label>
                            <input
                              type="text"
                              [(ngModel)]="emailMapping"
                              class="form-control"
                              id="emailMapping"
                              name="emailMapping"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.EMAIL'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="current_title" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.CURRENT_TITLE'"></label>
                            <input
                              type="text"
                              [(ngModel)]="current_title"
                              class="form-control"
                              id="current_title"
                              name="current_title"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.CURRENT_TITLE'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="current_company" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.CURRENT_COMPANY'"></label>
                            <input
                              type="text"
                              [(ngModel)]="current_company"
                              class="form-control"
                              id="current_company"
                              name="current_company"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.CURRENT_COMPANY'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <h4 class="col-12" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.EDUCATION'"></h4>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="id_education">Id</label>
                            <input
                              type="text"
                              [(ngModel)]="id_education"
                              class="form-control"
                              id="id_education"
                              name="id_education"
                              placeholder="Id"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="start_time_education" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.START_TIME'"></label>
                            <input
                              type="text"
                              [(ngModel)]="start_time_education"
                              class="form-control"
                              id="start_time_education"
                              name="start_time_education"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.START_TIME'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="end_time_education" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.END_TIME'"></label>
                            <input
                              type="text"
                              [(ngModel)]="end_time_education"
                              class="form-control"
                              id="end_time_education"
                              name="end_time_education"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.END_TIME'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="major" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.MAJOR'"></label>
                            <input
                              type="text"
                              [(ngModel)]="major"
                              class="form-control"
                              id="major"
                              name="major"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.MAJOR'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="school" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.SCHOOL'"></label>
                            <input
                              type="text"
                              [(ngModel)]="school"
                              class="form-control"
                              id="school"
                              name="school"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.SCHOOL'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="diploma" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.DIPLOMA'"></label>
                            <input
                              type="text"
                              [(ngModel)]="diploma"
                              class="form-control"
                              id="diploma"
                              name="diploma"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.DIPLOMA'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="gpa" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.GPA'"></label>
                            <input
                              type="text"
                              [(ngModel)]="gpa"
                              class="form-control"
                              id="gpa"
                              name="gpa"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.GPA'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <h4 class="col-12" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.EXPERIENCE'"></h4>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="id_experience">Id</label>
                            <input
                              type="text"
                              [(ngModel)]="id_experience"
                              class="form-control"
                              id="id_experience"
                              name="id_experience"
                              placeholder="Id"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="start_time_experience" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.START_TIME'"></label>
                            <input
                              type="text"
                              [(ngModel)]="start_time_experience"
                              class="form-control"
                              id="start_time_experience"
                              name="start_time_experience"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.START_TIME'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="end_time_experience" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.END_TIME'"></label>
                            <input
                              type="text"
                              [(ngModel)]="end_time_experience"
                              class="form-control"
                              id="end_time_experience"
                              name="end_time_experience"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.END_TIME'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="position" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.POSITION'"></label>
                            <input
                              type="text"
                              [(ngModel)]="position"
                              class="form-control"
                              id="position"
                              name="position"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.POSITION'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="company" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.COMPANY'"></label>
                            <input
                              type="text"
                              [(ngModel)]="company"
                              class="form-control"
                              id="company"
                              name="company"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.COMPANY'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="detail" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.DETAIL'"></label>
                            <input
                              type="text"
                              [(ngModel)]="detail"
                              class="form-control"
                              id="detail"
                              name="detail"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.DETAIL'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <h4 class="col-12" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.JOB_APP_CONFIG'"></h4>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="matching_score" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.MATCHING_SCORE'"></label>
                            <input
                              type="text"
                              [(ngModel)]="matching_score"
                              class="form-control"
                              id="matching_score"
                              name="matching_score"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.MATCHING_SCORE'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <h4 class="col-12" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.AUTO_UPLOAD_SCORE'"></h4>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="autoupload_score" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.AUTO_UPLOAD_SCORE'"></label>
                            <input
                              type="text"
                              [(ngModel)]="autoupload_score"
                              class="form-control"
                              id="autoupload_score"
                              name="autoupload_score"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.AUTO_UPLOAD_SCORE'|translate"
                              
                            />
                            
                          </div>
                        </div>
                        <div class="col-12">
                          <button type="submit" class="btn btn-primary mt-2 mr-1" rippleEffect [translate]="'ACCOUNT_SETTING.GENERAL.SAVE'">Save changes</button>
                          <button type="reset" class="btn btn-outline-secondary mt-2" rippleEffect [translate]="'ACCOUNT_SETTING.GENERAL.CANCEL'">Cancel</button>
                        </div>
                        <ng-container *ngIf="errorMessagesResultConnectSf4c">
                          <div class="alert alert-danger" role="alert">
                            {{errorMessagesResultConnectSf4c}}
                          </div>
                      </ng-container>
                      </div>
                    </form>
                    
                  </div>
                </div>
              </ng-template>
            </li>
            <!-- <li class="nav-item" ngbNavItem>
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-pill-notifications"
                data-toggle="pill"
                href="#account-vertical-sourceMapping_"
                aria-expanded="false"
              >
                <i data-feather="copy" class="font-medium-3 mr-1"></i>
                <span class="font-weight-bold" [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.TITLE'">
                </span>
              </a>
              <ng-template ngbNavContent>
                <section class="horizontal-wizard">
                  <div id="stepper1" class="bs-stepper horizontal-wizard-example">
                    <div class="bs-stepper-header">
                      <div class="step" data-target="#account-details">
                        <button class="step-trigger">
                          <span class="bs-stepper-box">1</span>
                          <span class="bs-stepper-label">
                            <span class="bs-stepper-title">Account Details</span>
                            <span class="bs-stepper-subtitle">Setup Account Details</span>
                          </span>
                        </button>
                      </div>
                      <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                      <div class="step" data-target="#personal-info">
                        <button class="step-trigger">
                          <span class="bs-stepper-box">2</span>
                          <span class="bs-stepper-label">
                            <span class="bs-stepper-title">Personal Info</span>
                            <span class="bs-stepper-subtitle">Add Personal Info</span>
                          </span>
                        </button>
                      </div>
                      <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                      <div class="step" data-target="#address">
                        <button class="step-trigger">
                          <span class="bs-stepper-box">3</span>
                          <span class="bs-stepper-label">
                            <span class="bs-stepper-title">Address</span>
                            <span class="bs-stepper-subtitle">Add Address</span>
                          </span>
                        </button>
                      </div>
                      <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                      <div class="step" data-target="#social-links">
                        <button class="step-trigger">
                          <span class="bs-stepper-box">3</span>
                          <span class="bs-stepper-label">
                            <span class="bs-stepper-title">Social Links</span>
                            <span class="bs-stepper-subtitle">Add Social Links</span>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div class="bs-stepper-content">
                      <form (ngSubmit)="(HWForm.form.valid)" #HWForm="ngForm">
                        <div id="account-details" class="content">
                          <form #accountDetailsForm="ngForm">
                            <div class="content-header">
                              <h5 class="mb-0">Account Details</h5>
                              <small class="text-muted">Enter Your Account Details.</small>
                            </div>
            
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="form-label" for="username">Username</label>
                                <input
                                  [(ngModel)]="TDNameVar"
                                  #TDNameRef="ngModel"
                                  required
                                  type="text"
                                  name="username"
                                  id="username"
                                  class="form-control"
                                  [class.error]="!TDNameRef.valid && accountDetailsForm.submitted"
                                  placeholder="johndoe"
                                />
                                <span *ngIf="accountDetailsForm.submitted && TDNameRef.invalid" class="invalid-form">
                                  <small class="form-text text-danger" *ngIf="TDNameRef.errors.required"
                                    >This field is required!</small
                                  >
                                </span>
                              </div>
                              <div class="form-group col-md-6">
                                <label class="form-label" for="email">Email</label>
                                <input
                                  [(ngModel)]="TDEmailVar"
                                  #TDEmailRef="ngModel"
                                  required
                                  email
                                  type="email"
                                  name="email"
                                  id="email"
                                  class="form-control"
                                  [class.error]="!TDEmailRef.valid && accountDetailsForm.submitted"
                                  placeholder="john.doe@email.com"
                                  aria-label="john.doe"
                                />
                                <span *ngIf="accountDetailsForm.submitted" class="invalid-form">
                                  <small class="form-text text-danger" *ngIf="!TDEmailRef.valid">Email must be valid!</small>
                                </span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group form-password-toggle col-md-6">
                                <label class="form-label" for="password">Password</label>
                                <input
                                  type="password"
                                  name="password"
                                  id="password"
                                  class="form-control"
                                  [class.error]="!TDPasswordOnlyRef.valid && accountDetailsForm.submitted"
                                  placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                  ngModel
                                  #TDPasswordOnlyRef="ngModel"
                                  required
                                  minlength="6"
                                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                />
                                <div
                                  *ngIf="TDPasswordOnlyRef.invalid && accountDetailsForm.submitted"
                                  class="form-text text-danger"
                                >
                                  <small *ngIf="TDPasswordOnlyRef.errors.required"> Password is required. </small>
                                  <small *ngIf="TDPasswordOnlyRef.errors.pattern">
                                    Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more
                                    characters.</small
                                  >
                                </div>
                              </div>
                              <div class="form-group form-password-toggle col-md-6">
                                <label class="form-label" for="confirm-password">Confirm Password</label>
                                <input
                                  type="password"
                                  name="confirm-password"
                                  id="confirm-password"
                                  class="form-control"
                                  [class.error]="!TDRepeatPasswordRef.valid && accountDetailsForm.submitted"
                                  placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                  required
                                  ngModel
                                  #TDRepeatPasswordRef="ngModel"
                                  pattern="{{ TDPasswordOnlyRef.value }}"
                                  minlength="6"
                                />
                                <div
                                  *ngIf="TDRepeatPasswordRef.invalid && accountDetailsForm.submitted"
                                  class="form-text text-danger"
                                >
                                  <small *ngIf="TDRepeatPasswordRef.errors.required"> Confirm password is required. </small>
                                  <small *ngIf="TDRepeatPasswordRef.errors.pattern">
                                    Password & Confirm Password does not match.</small
                                  >
                                </div>
                              </div>
                            </div>
            
                            <div class="d-flex justify-content-between">
                              <button
                                (click)="horizontalWizardStepperPrevious()"
                                class="btn btn-outline-secondary btn-prev"
                                disabled
                                rippleEffect
                              >
                                <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                              </button>
                              <button
                                (click)="horizontalWizardStepperNext(accountDetailsForm)"
                                type="submit"
                                class="btn btn-primary btn-next"
                                rippleEffect
                              >
                                <span class="align-middle d-sm-inline-block d-none">Next</span>
                                <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                              </button>
                            </div>
                          </form>
                        </div>
                        <div id="personal-info" class="content">
                          <form #personalInfoForm="ngForm">
                            <div class="content-header">
                              <h5 class="mb-0">Personal Info</h5>
                              <small>Enter Your Personal Info.</small>
                            </div>
            
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="form-label" for="first-name">First Name</label>
                                <input
                                  [(ngModel)]="TDFirstNameVar"
                                  #TDFirstNameRef="ngModel"
                                  required
                                  type="text"
                                  name="first-name"
                                  id="first-name"
                                  class="form-control"
                                  [class.error]="personalInfoForm.submitted && TDFirstNameRef.invalid"
                                  placeholder="John"
                                />
                                <span *ngIf="personalInfoForm.submitted && TDFirstNameRef.invalid" class="invalid-form">
                                  <small class="form-text text-danger" *ngIf="TDFirstNameRef.errors.required"
                                    >This field is required!</small
                                  >
                                </span>
                              </div>
                              <div class="form-group col-md-6">
                                <label class="form-label" for="last-name">Last Name</label>
                                <input
                                  [(ngModel)]="TDLastNameVar"
                                  #TDLastNameRef="ngModel"
                                  required
                                  type="text"
                                  name="last-name"
                                  id="last-name"
                                  class="form-control"
                                  [class.error]="personalInfoForm.submitted && TDLastNameRef.invalid"
                                  placeholder="Doe"
                                />
                                <span *ngIf="personalInfoForm.submitted && TDLastNameRef.invalid" class="invalid-form">
                                  <small class="form-text text-danger" *ngIf="TDLastNameRef.errors.required"
                                    >This field is required!</small
                                  >
                                </span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="form-label" for="country">Country</label>
                                <ng-select [items]="selectBasic" bindLabel="name"> </ng-select>
                              </div>
                              <div class="form-group col-md-6">
                                <label class="form-label" for="language">Language</label>
                                <ng-select
                                  [items]="selectMulti"
                                  [multiple]="true"
                                  [closeOnSelect]="false"
                                  [searchable]="false"
                                  bindLabel="name"
                                  name="selectLanguage"
                                  placeholder="Select people"
                                  [(ngModel)]="selectMultiSelected"
                                >
                                </ng-select>
                              </div>
                            </div>
            
                            <div class="d-flex justify-content-between">
                              <button
                                type="button"
                                (click)="horizontalWizardStepperPrevious()"
                                class="btn btn-primary btn-prev"
                                rippleEffect
                              >
                                <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                              </button>
                              <button
                                (click)="horizontalWizardStepperNext(personalInfoForm)"
                                class="btn btn-primary btn-next"
                                rippleEffect
                              >
                                <span class="align-middle d-sm-inline-block d-none">Next</span>
                                <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                              </button>
                            </div>
                          </form>
                        </div>
                        <div id="address" class="content">
                          <form #addressForm="ngForm">
                            <div class="content-header">
                              <h5 class="mb-0">Address</h5>
                              <small>Enter Your Address.</small>
                            </div>
            
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="form-label" for="address">Address</label>
                                <input
                                  [(ngModel)]="addressVar"
                                  #addressRef="ngModel"
                                  required
                                  type="text"
                                  id="address"
                                  name="address"
                                  class="form-control"
                                  [class.error]="addressForm.submitted && addressRef.invalid"
                                  placeholder="98  Borough bridge Road, Birmingham"
                                />
                                <span *ngIf="addressForm.submitted && addressRef.invalid" class="invalid-form">
                                  <small class="form-text text-danger" *ngIf="addressRef.errors.required"
                                    >This field is required!</small
                                  >
                                </span>
                              </div>
                              <div class="form-group col-md-6">
                                <label class="form-label" for="landmark">Landmark</label>
                                <input
                                  [(ngModel)]="landmarkVar"
                                  #landmarkRef="ngModel"
                                  required
                                  type="text"
                                  name="landmark"
                                  id="landmark"
                                  class="form-control"
                                  [class.error]="addressForm.submitted && landmarkRef.invalid"
                                  placeholder="Borough bridge"
                                />
                                <span *ngIf="addressForm.submitted && landmarkRef.invalid" class="invalid-form">
                                  <small class="form-text text-danger" *ngIf="landmarkRef.errors.required"
                                    >This field is required!</small
                                  >
                                </span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="form-label" for="pincode1">Pincode</label>
                                <input type="text" id="pincode1" class="form-control" placeholder="658921" />
                              </div>
                              <div class="form-group col-md-6">
                                <label class="form-label" for="city1">City</label>
                                <input type="text" id="city1" class="form-control" placeholder="Birmingham" />
                              </div>
                            </div>
            
                            <div class="d-flex justify-content-between">
                              <button
                                type="button"
                                (click)="horizontalWizardStepperPrevious()"
                                class="btn btn-primary btn-prev"
                                rippleEffect
                              >
                                <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                              </button>
                              <button
                                (click)="horizontalWizardStepperNext(addressForm)"
                                class="btn btn-primary btn-next"
                                rippleEffect
                              >
                                <span class="align-middle d-sm-inline-block d-none">Next</span>
                                <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                              </button>
                            </div>
                          </form>
                        </div>
                        <div id="social-links" class="content">
                          <form #socialLinkForm="ngForm">
                            <div class="content-header">
                              <h5 class="mb-0">Social Links</h5>
                              <small>Enter Your Social Links.</small>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="form-label" for="twitter">Twitter</label>
                                <input
                                  [(ngModel)]="twitterVar"
                                  #twitterkRef="ngModel"
                                  required
                                  type="text"
                                  id="twitter"
                                  name="twitter"
                                  class="form-control"
                                  [class.error]="socialLinkForm.submitted && twitterkRef.invalid"
                                  placeholder="https://twitter.com/abc"
                                />
                                <span *ngIf="socialLinkForm.submitted && twitterkRef.invalid" class="invalid-form">
                                  <small class="form-text text-danger" *ngIf="twitterkRef.errors.required"
                                    >This field is required!</small
                                  >
                                </span>
                              </div>
                              <div class="form-group col-md-6">
                                <label class="form-label" for="facebook">Facebook</label>
                                <input
                                  [(ngModel)]="facebookVar"
                                  #facebookRef="ngModel"
                                  required
                                  type="text"
                                  id="facebook"
                                  name="facebook"
                                  class="form-control"
                                  [class.error]="socialLinkForm.submitted && facebookRef.invalid"
                                  placeholder="https://facebook.com/abc"
                                />
                                <span *ngIf="socialLinkForm.submitted && facebookRef.invalid" class="invalid-form">
                                  <small class="form-text text-danger" *ngIf="facebookRef.errors.required"
                                    >This field is required!</small
                                  >
                                </span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label class="form-label" for="google">Google+</label>
                                <input
                                  [(ngModel)]="googleVar"
                                  #googleRef="ngModel"
                                  required
                                  type="text"
                                  id="google"
                                  name="google"
                                  class="form-control"
                                  [class.error]="socialLinkForm.submitted && googleRef.invalid"
                                  placeholder="https://plus.google.com/abc"
                                />
                                <span *ngIf="socialLinkForm.submitted && googleRef.invalid" class="invalid-form">
                                  <small class="form-text text-danger" *ngIf="googleRef.errors.required"
                                    >This field is required!</small
                                  >
                                </span>
                              </div>
                              <div class="form-group col-md-6">
                                <label class="form-label" for="linkedin">Linkedin</label>
                                <input
                                  [(ngModel)]="linkedinVar"
                                  #linkedinRef="ngModel"
                                  required
                                  type="text"
                                  id="linkedin"
                                  name="linkedin"
                                  class="form-control"
                                  [class.error]="socialLinkForm.submitted && linkedinRef.invalid"
                                  placeholder="https://linkedin.com/abc"
                                />
                                <span *ngIf="socialLinkForm.submitted && linkedinRef.invalid" class="invalid-form">
                                  <small class="form-text text-danger" *ngIf="linkedinRef.errors.required"
                                    >This field is required!</small
                                  >
                                </span>
                              </div>
                            </div>
                            <div class="d-flex justify-content-between">
                              <button
                                type="button"
                                (click)="horizontalWizardStepperPrevious()"
                                class="btn btn-primary btn-prev"
                                rippleEffect
                              >
                                <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                              </button>
                              <button class="btn btn-success btn-submit" rippleEffect (click)="onSubmit()">Submit</button>
                            </div>
                          </form>
                        </div>
                      </form>
                    </div>
                  </div>
                </section>
              </ng-template>
            </li> -->
          </ul>
        </div>
        <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9"></div>
      </div>
    </section>
    <!--/ account setting page -->
  </div>
</div>
