export class CV_task{
    index?:number
    id!: number
    job_title: string = ''
    location: string = ''
    job_level: string = ''
    industry: any[] =[]
    saved_cvs: any[] = []
    job_type: string = ''
    job_description: string = ''
    requirements: string = ''
    years_of_experience_from: number = 0
    years_of_experience_to: number = 0
    yob_from: number = 0
    yob_to: number = 0
    gender: string = ''
    keyword_expression:string = ''
    status: string = ''
    viewed_cvs:Array<string> = []
    owner_email!: string
    num_labeled_cv!: number
    city: string[]=[]
    fulltext_bool_expression!: string
    experience_bool_expression!: string
    education_bool_expression!: string
    current_title_bool_expression!: string
    current_company_bool_expression!: string
    loaded: boolean = false;
    num_talent_pool_saved_cvs!: number
    hcm_system_id!: string
    created_at: string;
    owner: string;
}
