import { CoreMenu } from '@core/types';
import { Permissions, Role } from '../auth/models/role'
//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  // Dashboard
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'collapsible',
    // role: ['Admin'], //? To hide collapsible based on user role
    icon: 'home',
    badge: {
      title: '2',
      translate: 'MENU.DASHBOARD.BADGE',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'analytics',
        title: 'Analytics',
        translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        // role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'circle',
        url: 'dashboard/analytic',
        
      },
      {
        // If role is not assigned will be display to all
        id: 'history',
        title: 'Activity history',
        translate: 'MENU.DASHBOARD.ACTIVITY_HISTORY',
        type: 'item',
        icon: 'circle',
        url: 'dashboard/parse-resume'
      }
    ]
  },
  // Resume parser tools
  {
    id: 'apps',
    type: 'section',
    title: 'Resume parser tools',
    translate: 'MENU.RESUME_PARSER.SECTION',
    icon: 'package',
    children: [
      {
        id: 'parse-resume',
        title: 'Parse Resume',
        translate: 'MENU.RESUME_PARSER.PARSE_RESUME',
        type: 'item',
        icon: 'file',
        url: '/parse-resume',
        permissions: Permissions.PARSE_CV
      },
      {
        id: 'manage-resume',
        title: 'Manage resume',
        translate: 'MENU.RESUME_PARSER.MANAGE_RESUME',
        type: 'item',
        icon: 'folder',
        url: '/manage-resume',
        permissions: Permissions.PARSE_CV
      },
      {
        id: 'integration',
        title: 'Integration SAP SF',
        translate: 'MENU.RESUME_PARSER.INTEGRATION',
        type: 'item',
        icon: 'upload',
        url: '/integration',
        permissions: Permissions.PARSE_CV
      },
    ]
  },
  // Talent pool tools
  {
    id: 'talent-pool',
    type: 'section',
    title: 'Talent pool tools',
    translate: 'MENU.TALENT_POOL.SECTION',
    icon: 'package',
    children: [
      {
        id: 'manage-job',
        title: 'Manage job',
        translate: 'MENU.TALENT_POOL.MANAGE_JOB',
        type: 'item',
        icon: 'folder',
        url: '/manage-job',
        permissions: Permissions.SEARCH_ENGINE
      },
      {
        id: 'search-cv',
        title: 'Search CV',
        translate: 'MENU.TALENT_POOL.SEARCH_CV',
        type: 'item',
        icon: 'search',
        url: '/search-resume',
        permissions: Permissions.SEARCH_ENGINE
      },
      {
        id: 'monitor-upload',
        title: 'Monitor upload',
        translate: 'MENU.TALENT_POOL.MONITOR_UPLOAD',
        type: 'item',
        icon: 'refresh-ccw',
        url: '/monitor-upload'
      },
    ]
  },
  
];
