import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ListCompany, ResultChangePassword, User } from 'app/auth/models';
import { environment } from 'environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AccountSettingsService{
  rows: any;
  onSettingsChanged: BehaviorSubject<any>;
  private BASE_URL = `${environment.apiUrl}/users`;


  /**
   * Constructor
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {
    // Set the defaults
    this.onSettingsChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */

  /**
   * Get rows
   */
  changePassword(id: number, old_password: string, new_password: string):Observable<ResultChangePassword>{
    const url = `${this.BASE_URL}/${id}/change-password`;
    return this._http.put<ResultChangePassword>(url, {old_password, new_password})
  }
  updateUser(id: any, formData: any): Observable<any>{
    const url = `${this.BASE_URL}/${id}`;
    return this._http.put<any>(url, formData)
  }
  detailUser(id: number): Observable<User>{
    const url = `${this.BASE_URL}/${id}`;
    return this._http.get<User>(url) 
  }
  resetPassword(id: number): Observable<ResultChangePassword>{
    const url = `${this.BASE_URL}/${id}/reset-password`;
    return this._http.put<ResultChangePassword>(url,{})
  }
  createUser(formData: any): Observable<any>{
    const url = `${this.BASE_URL}/register/`;
    return this._http.post<any>(url, formData)
  }
  adminUpdateUser(id: number, first_name: string, last_name: string, email: string, permissions: string[],is_site_admin: boolean): Observable<User>{
    const url = `${this.BASE_URL}/${id}`;
    return this._http.put<User>(url, {first_name, last_name, email,permissions,is_site_admin})
  }
  deleteUser(id: any, assign_to: any): Observable<any>{
    const url = `${this.BASE_URL}/${id}/delete?assign_to=${assign_to}`;
    return this._http.delete<any>(url)
  }
  //List company
  list_company(): Observable<ListCompany>{
    const url = `${environment.apiUrl}/users/company`;
    return this._http.get<ListCompany>(url)
  }
}
