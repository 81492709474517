import { Locale } from '@core/services/translation.service';

export const locale: Locale = {
  lang: 'vn',
  data: {
    HEADER: {
      DASHBOARD: {
        COLLAPSIBLE: 'TỔNG QUAN',
        BADGE: '2',
        ANALYTICS: 'Phân tích',
        ACTIVITY_HISTORY: 'Lịch sử hoạt động'
      },
      RESUME_PARSER: {
        SECTION: 'HỒ SƠ',
        PARSE_RESUME: 'Thêm hồ sơ',
        MANAGE_RESUME: 'Quản lý hồ sơ',
        INTEGRATION: 'Tích hợp SAP SF',
        UPDATE_JA: 'Cập nhật công việc',

      },
      TALENT_POOL: {
        SECTION: 'CÔNG VIỆC & ỨNG VIÊN',
        CREATE_JOB: 'Tạo tin tuyển dụng',
        MANAGE_JOB: 'Quản lý đăng tuyển',
        SEARCH_CV: 'Tìm kiếm ứng viên',
        MONITOR_UPLOAD: 'Chấm điểm ứng viên'
      },
      PRODUCT: 'Sản phẩm',
      SOLUTION: 'Tính năng',
      PRICE: 'Bảng giá',
      ABOUT_US: "Về chúng tôi",
      SIGNIN: 'Đăng nhập',
      TRY: 'Trải nghiệm',
      SIGNUP: 'Đăng ký',
      PAGE_REST: "trang còn lại",
      LOG_OUT: "Đăng xuất"
    },
    FOOTER: {
      CALL_US: "Liên hệ",
      ADDRESS: "Địa chỉ: Tầng 4, tòa nhà CMC, số 11 Duy Tân, Dịch Vọng Hậu, Cầu Giấy, Hà Nội",
      ABOUT: "Về CHR",
      INTRO: "Trang chủ",
      CONTACT: "Liên lạc",
      PRICING: "Giá",
      PHONE: "Số điện thoại",
      TERMS: "Điều khoản dịch vụ",
      EXTRACT: "Trích xuất hồ sơ",
      SEARCH: "Tìm kiếm hồ sơ",
      PARTNER: "Đối tác",
      LET_TAKL: "Tăng hiệu năng quản lý hồ sơ tuyển dụng ngay hôm nay!",
      NAME: "Tên",
      EMAIL: "E-mail *",
      PHONE_NUMBER: "Số điện thoại *",
      POSITION: "Vị trí công việc",
      COMPANY: "Tên công ty",
      REGISTER: "Liên hệ",
      TOASTR: {
        CREATE_CUSTOMER: "Tạo thông tin khách hàng",
        SUCCESS: "Thành công!"
      },
      REGISTER_SUC: "Đăng ký thành công!",
      REGISTER_FAIL: "Đăng ký thất bại!"
    },
    ABOUT_US: {
      TITLE: "Chúng tôi giúp các công ty và ứng viên tìm được người phù hợp",
      ABOUT: "Về chúng tôi",
      
      C_HR: `C-HR là sản phẩm của Viện nghiên cứu ứng dụng công nghệ CMC ATI 
      Được thành lập từ năm 2014, CMC ATI là nơi nghiên cứu và tạo ra những 
      giải pháp công nghệ đột phá theo xu thế phát triển toàn cầu của Tập đoàn CMC.`,
      CMC_ATI: `CMC ATI là một trong những đơn vị tiên phong nghiên cứu và ứng dụng 
      các giải pháp công nghệ giúp giải quyết các bài toán chuyển đổi số, tạo ra 
      các sản phẩm, dịch vụ phục vụ phát triển kinh tế số, xã hội số, công dân số.
      CMC ATI nghiên cứu ứng dụng những công nghệ mới nhất trong các lĩnh 
      vực BigData, AI, Blockchain, IoT, Security… Tạo ra các sản phẩm hỗ trợ cho
      khách hàng là doanh nghiệp Việt Nam nâng cao năng lực cạnh tranh trong
      thời kỳ 4.0 hiện nay.`,
      CORE_VALUE: `Mọi hành động của người CMC đều xuất phát từ trái tim hướng tới khách hàng.
      Bằng khát khao và đam mê sáng tạo không ngừng  nghỉ, người CMC quyết 
      tâm “nhanh nhất có thể, nhanh như ánh sáng” trong cả tư duy và hành động
      để truyền cảm hứng và thực hiện tốt những cam kết của mình
      - Sáng tạo 
      - Tốc độ
      - Cam kết
      - Hướng tới khách hàng`,
      VALUE: "Những con số ấn tượng",
      DES_VALUE: "Kiến tạo sản phẩm công nghệ Việt, nhằm giải quyết bài toán của người Việt",
      COMMUNITY: "Community",
      DES_COMMUNITY: "We realize that no great work is done alone. Every one of us has a unique value and, when put in the right position, will maximize our potential. We believe that is the foundation to achieve greatness.",
      UNDERSTANDING: "Understanding",
      DES_UNDERSTANDING:"No connection is at its fullest without understanding. We put no one as we strive to listen and deeply understand each of our connection to build a strong bond in the community of C-HR.",
      GROWTH: "Growth",
      DES_GROWTH: "Our company can only grow when each of its connections grows. Therefore, we deeply understand each of our connection to give the best possible platform for growth, subsequently, to achieve greatness as a whole.",
      FAQ: {
        TITLE: "Các câu hỏi thường gặp",
        QUES1: "Làm thế nào phiên bản dùng thử miễn phí hoạt động",
        ANS1: "Chọn một gói và nhận một phiên dùng thử miễn phí trong vòng 4 ngày với bất kỳ gói đăng ký nào. Đăng việc miễn phí trong thời gian dùng thử của bạn. Khi kết thúc thời gian dùng thử miễn phí, chúng tôi sẽ tính phí từ phương thức thanh toán bạn đã cung cấp khi đăng ký. Bạn có thể tạm dừng, hủy bỏ hoặc thay đổi gói đăng ký của mình bất kỳ lúc nào bằng cách đăng nhập vào tài khoản của bạn.",
        QUES2: "Độ chính xác của trích xuất là bao nhiêu?",
        ANS2: "Họ và tên: 97.22%, Địa chỉ: 91.27%, Ngày sinh: 99.21%, Số điện thoại: 99.21%, Chức danh hiện tại: 78.97%, Kinh nghiệm: 73.31%, Học vấn: 77.35%",
        QUES3: "Bạn có thể tải lên bao nhiêu CV?",
        ANS3: "Có thể trích xuất đồng thời 50 CV",
        QUES4: "Tốc độ trích xuất thông tin từ CV là bao nhiêu?",
        ANS4: "Trung bình 2s trên 1 CV và 0.8s trên 1 trang",
        QUES5: "Tạo một đăng tuyển mất bao lâu?",
        ANS5: "Biểu mẫu đăng việc của chúng tôi chỉ mất khoảng 1 phút để hoàn thành. Bạn cần bao gồm chức danh công việc, mô tả công việc và yêu cầu công việc."
      }
    },
    INTRODUCE: {
      TITLE: 'Giải pháp nhân sự thông minh C-HR',
      SUB_TITLE: `Nền tảng ứng dụng công nghệ AI trong việc số hoá tự động dữ liệu CV, tìm kiếm 
      nâng cao và gợi ý thông minh ứng viên phù hợp của tập đoàn CMC giúp việc tối ưu 
      quản lý hồ sơ tuyển dụng `,
    },
    SOLUTION: {
      TITLE: 'Tính năng ưu việt',
      AVG_0: {
        TITLE: 'Công nghệ AI tân tiến',
        SUB_TITLE:
          'Sản phẩm C-HR vận dụng công nghệ AI được phát triển tập đoàn CMC như NLP, Computer Vision, Machine Learning vào việc hỗ trợ quản lý hồ sơ tuyển dụng',
      },
      AVG_1: {
        TITLE: 'Đảm bảo nhanh, chính xác',
        SUB_TITLE:
          'Sản phẩm C-HR tự hào với sự chính xác và nhanh chóng sánh ngang với đối thủ trong nước và quốc tế',
      },
      AVG_2: {
        TITLE: 'Tính linh hoạt cao',
        SUB_TITLE: 'Sản phẩm cho phép tính năng tải lên hồ sơ ứng viên đồng thời và cho phép tích hợp đa dang cơ sở dữ liệu của khách hàng như SAP SuccessFactor, Basevn,...',
      },
      AVG_3: {
        TITLE: 'Giao diện dễ sử dụng',
        SUB_TITLE:
          'Giao diện trực quan, thân thiện giúp người dùng có một trải nghiệm mượt mà, xuyên suốt trong quá trình sử dụng sản phẩm',
      },
    },
    CHOSE: {
      TITLE: 'Tại sao lại chọn',
      REASON_1: {
        TITLE: 'Tối ưu việc số hoá việc lưu trữ hồ sơ ứng viên ',
        SUB_TITLE:
          `Thay vì phải phân loại thông tin ứng viên một cách thủ công, C-HR sẽ tự động hoá việc
          bóc tách thông tin ứng viên ra hơn 10 trường thông tin hữu ích
          C-HR cũng hỗ trợ đa dạng định dạng file cũng như việc tải lên nhiều file đồng thời 
          cùng lúc giúp cho việc số hoá hồ sơ ứng viên trở nên nhanh chóng và chính xác hơn bao giờ hết `,
      },
      REASON_2: {
        TITLE: 'Tối ưu việc tìm kiếm ứng viên phù hợp nhất cho công việc',
        SUB_TITLE:
          `Điểm phù hợp được tính theo công nghệ AI của CMC
          Các kĩ năng ứng viên phù hợp sẽ được làm nổi bật và lưu lại trong cơ sở 
          dữ liệu C-HR`
      },
      REASON_3: {
        TITLE: 'Tối ưu việc hỗ trợ tuyển dụng cho đa dạng loại hình doanh nghiệp',
        SUB_TITLE:
          `Không còn lo lắng về tính linh hoạt của sản phẩm, các tính năng của C-HR 
          có thể được thiết kế và tinh chỉnh linh hoạt theo đa dạng mô hình doanh nghiệp. 
          Sản phẩm C-HR hiện tại đang phục vụ cho KH doanh nghiệp thường và KH tư vấn tuyển dụng`,
      },
    },
    PRODUCT: {
      TITLE: 'Trải nghiệm sản phẩm ngay',
      PROCCESSED: ' tài liệu đã xử lý',
    },
    CUSTOMER: {
      TITLE: 'Khách hàng của chúng tôi'
    },
    PAYMENT: {
      TITLE: "Chọn gói phù hợp với bạn nhất",
      PARSE: "Trích xuất",
      FULL_SERVICE: "Tất cả dịch vụ",
      DESCRIPTION:
        "",
        PLAN_1: {
          BASIC: "Cơ bản",
          SUB_BASIC: "Khách hàng doanh nghiệp thường",
          COUNT_BASIC: "tài liệu",
          PRICING_BASIC_PARSING: "9.999.000đ/tháng",
          PRICING_BASIC: "15.999.000đ/tháng",
          FEATURE_BASIC1: "Bóc tách 14 trường thông tin CV ",
          FEATURE_BASIC2: "Hỗ trợ định dạng: PDF, Doc, Docx, png, jpeg",
          FEATURE_BASIC3: "3 người dùng",
          FEATURE_BASIC4: "20 CV được trích xuất đồng thời ",
          FEATURE_BASIC5: "Cấu hình callback khi trích xuất xong CV",
          FEATURE_BASIC6: "Quản lý mô tả công việc",
          FEATURE_BASIC7: "Gợi ý ứng viên phù hợp với yêu cầu tuyển dụng",
          FEATURE_BASIC8: "Chấm điểm CV theo các tiểu chí: học vấn, kỹ năng ,kinh nghiệm làm việc",
        },
        PLAN_2: {
          STANDARD: "Nâng cao ",
          SUB_STANDARD: "Khách hàng doanh nghiệp tư vấn tuyển dụng",
          COUNT_STANDARD: "tài liệu",
          PRICING_STANDARD_PARSING: "19.999.000đ/tháng",
          PRICING_STANDARD: "24.999.000đ/tháng",
          FEATURE_STANDARD1: "Bóc tách 14 trường thông tin CV ",
          FEATURE_STANDARD2: "Hỗ trợ định dạng: PDF, Doc, Docx, png, jpeg",
          FEATURE_STANDARD3: "5 người dùng",
          FEATURE_STANDARD4: "50 CV được trích xuất đồng thời ",
          FEATURE_STANDARD5: "Che thông tin liên hệ",
          FEATURE_STANDARD6: "Cấu hình callback khi trích xuất xong CV",
          FEATURE_STANDARD7: "Hỗ trợ kĩ thuật",
          FEATURE_STANDARD8: "Quản lý mô tả công việc",
          FEATURE_STANDARD9: "Gợi ý ứng viên phù hợp với yêu cầu tuyển dụng",
          FEATURE_STANDARD10: `Chấm điểm CV theo các tiểu chí: học vấn, kỹ năng ,kinh nghiệm làm việc,
                                matching nâng cao`,
          MOST_POPULAR: "Thông dụng nhất",
          },
        PLAN_3: {
          ENTERPRISE: "Cao cấp",
          SUB_ENTERPRISE: "Lý tưởng cho các doanh nghiệp cần xử lý nhiều loại tài liệu khác nhau, huấn luyện dữ liệu của họ và triển khai quy trình làm việc tùy chỉnh.",
          COUNT_ENTERPRISE: "Liên hệ",
          PRICING_ENTERPRISE: "Giá Theo Yêu Cầu",
          FEATURE_ENTERPRISE1: "Bóc tách 14 trường thông tin CV ",
          FEATURE_ENTERPRISE2: "Hỗ trợ định dạng: PDF, Doc, Docx, png, jpeg",
          FEATURE_ENTERPRISE3: "Người dùng không giới hạn",
          FEATURE_ENTERPRISE4: "50 CV Parse đồng thời",
          FEATURE_ENTERPRISE5: "Che thông tin liên hệ",
          FEATURE_ENTERPRISE6: "Hỗ trợ kĩ thuật",
          FEATURE_ENTERPRISE7: "Hỗ trợ Tích hợp SAP, CRM ",
          FEATURE_ENTERPRISE8: "Hỗ trợ đồng bộ CSDL khách hàng",
  
        },
      PRICING: "Giá Theo Yêu Cầu",
      BUY_NOW: "Mua ngay",
    },
    UPLOAD: {
      TITLE: 'Trích xuất hoặc Số hóa',
      FEATURE_TYPE: {
        EXTRACT: "Trích xuất thông tin",
        CONVERT: "Số hóa tài liệu"
      },
      DOCUMENT_TYPE:{
        BANG_TOT_NGHIEP: "Bằng tốt nghiệp",
        CMND_4_LOAI: "CCCD/CMND 4 loại",
        CMND_4_LOAI_MAT_SAU: "CCCD/CMND 4 loại mặt sau",
        DANG_KIEM: "Đăng kiểm",
        DANG_KY_KET_HON: "Đăng ký kết hôn",
        GCNDN: "Giấy chứng nhận doanh nghiệp",
        GPLX: "Giấy phép lái xe",
        VCB: "Giấy mở tài khoản VCB",
        UY_NHIEM_CHI: "Ủy nhiệm chi",
        CONSENT_LETTER: "Giấy cam kết đồng ý cung cấp thông tin",
        HOA_DON_BAN: "Hóa đơn bán",
        HOA_DON_VIEN_PHI: "Hóa đơn viện phí",
        HO_CHIEU: "Hộ chiếu",
        GIAY_KHAI_SINH: "Giấy khai sinh",
        DANG_KY_XE: "Đăng ký xe",
        DON_THUOC: "Đơn thuốc",
      },
      LABEL_UPLOAD: 'Kéo thả tài liệu vào đây hoặc tải lên từ:',
      FROM_DEVICE: 'Từ thiết bị',
      TABLE: {
        NAME: 'Tên file',
        TIME: 'Thời gian tải lên',
        STATUS: {
          TITLE: 'Trạng thái',
          PROCESS: 'Đã thực thi',
          ERROR: 'Lỗi',
          CONFIRMED: 'Đã xác nhận',
          DOWNLOADED: 'Đã tải xuống',
          QUEUEING: "Trong hàng đợi",
          BEING_PROCESSED: "Đang được xử lý"
        },
        ACTIONS: 'Hành động',
        TOOLTIPS: {
          EXCEL: 'Tải xuống Excel',
          WORD: 'Tải xuống Word',
          TEXT: 'Tải xuống file text',
        },
        BUTTON: {
          CANCEL: 'Hủy',
          DOWNLOAD: 'Tải dữ liệu',
        },
        MODAL: {
          TITLE: 'Chọn định dạng',
          TAB: {
            BASIC: 'Định dạng cơ bản',
            ACCOUNTING: 'Phần mềm kế toán',
          },
          MISA: 'Phần mềm kế toán MISA',
          FAST: 'Phần mềm kế toán FAST',
        },
      },
      TOASTR:{
        UPLOAD_SUC: 'Tải tài liệu',
        SUCCESS: 'Thành công!',
        DOWNLOAD: 'Tải xuống',
        ERROR: "Lỗi",
        ERROR_FILE_DROP: 'Chỉ thả 1 tài liệu cho hệ thống!',
        DELETE: "Xóa"
      },
      EXPERIENCE_ALL_FEATURE: "Trải nghiệm tất cả tính năng"
    },
    DOCUMENT: {
      TITLE: "THÔNG TIN CƠ BẢN",
      TABLE: "Bảng",
      SIGNATURE: "Chữ ký",
      MATCHED: "So khớp",
      EXPORT: "Xuất thông tin"
    },
    DESCRIPTION: {
      DES_1: {
        TITLE: 'Hỗ trợ nhiều loại input',
        SUB_TITLE:
          'Hỗ trợ nhiều định dạng đầu vào khác nhau, bạn có thể tối ưu hóa quy trình xử lý tài liệu của mình và tiết kiệm thời gian cũng như tài nguyên.',
      },
      DES_2: {
        TITLE: 'Xử lý file từ email',
        SUB_TITLE:
          'Cho phép tích hợp với email và hỗ trợ nhập tệp trực tiếp, cho phép bạn nhanh chóng và dễ dàng xử lý tài liệu từ nhiều nguồn khác nhau.',
      },
      DES_3: {
        TITLE: 'Khả năng tự cải thiện',
        SUB_TITLE:
          'Bằng cách sử dụng học máy và các thuật toán phân tích dữ liệu tiên tiến, AI của chúng tôi có thể tiếp tục học tập và cải thiện kết quả theo thời gian',
      },
      DES_4: {
        TITLE: 'Điện toán đám mây',
        SUB_TITLE:
          'Mọi tài liệu sẽ được xử lý và lưu trữ trên đám mây, giúp bạn tiết kiệm chi phí cho việc lưu trữ dữ liệu và truy cập tài liệu mọi lúc mọi nơi một cách thuận tiện. bạn sẽ không cần phải cài đặt bất kỳ phần mềm nào',
      },
      DES_5: {
        TITLE: 'Tăng cường độ chính xác',
        SUB_TITLE:
          'Tăng cường độ chính xác và giảm thiểu sự cố và sai sót liên quan đến việc nhập liệu thủ công, tiết kiệm thời gian và tăng hiệu suất công việc của mình nhờ khả năng nhận dạng chữ viết tay và chữ in được tối ưu hóa',
      },
      DES_6: {
        TITLE: 'Dễ dàng tích hợp',
        SUB_TITLE:
          'Với tính năng xử lý hàng loạt, cài đặt tùy chỉnh và tích hợp với phần mềm khác, giải pháp OCR của CMC là một giải pháp quản lý tài liệu toàn diện, thân thiện với người dùng và trực quan',
      },
    },
    AUTH: {
      AUTH_LOGIN:{
        TITLE: "Chào mừng bạn đến với SmartDoc! 👋",
        SUB_TITLE: "Vui lòng đăng nhập vào tài khoản của bạn và bắt đầu trải nghiệm.",
        EMAIL: "E-mail hoặc tên người dùng",
        REQUIRED: "Trường này là bắt buộc.",
        EMAIL_REQUIRED: "Email phải là địa chỉ email hợp lệ",
        PHONE_REQUIRED: "Số điện thoại phải có 10 chữ số",
        PASSWORD: "Mật khẩu",
        SIGNIN: "Đăng nhập",
        NEW_PLATFORM: "Mới tham gia vào nền tảng của chúng tôi?",
        CREATE: "Tạo một tài khoản",
        OR: "hoặc"
      },
      AUTH_REG: {
        TITLE: "Đăng ký tài khoản bắt đầu từ đây 🚀",
        SUB_TITLE: "Làm cho việc quản lý ứng dụng của bạn trở nên dễ dàng và thú vị!",
        USER_NAME: "Tên người dùng",
        SIGNUP: "Đăng ký",
        HAVE_ACCOUNT: "Đã có tài khoản rồi à?",
        SIGNIN: "Đăng nhập ngay",
        
      },
      AUTH_NOTI: {
        CHECK_EMAIL: "Kiểm tra email của bạn để kích hoạt tài khoản!",
        BACK_TO: "Quay lại đăng nhập"
      }
    }
  },
};
