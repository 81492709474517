import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { User } from 'app/auth/models';
import { ResultDetailResumeMatching, Stat, StatResumeMatching } from 'app/main/resume-parser/models/resume.model';
import { DashboardService } from '../services/dashboard.service';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnalyticsComponent implements OnInit {
  public stat: Stat = new Stat()
   //Period
   public companys = [{id:19, name:""}]
   FilterStatForm = this.fb.group({
     company: new FormControl(''),
     period: new FormControl([]),
   })
  public user = new User()
  @ViewChild('resumeStatusUploadedSf4cByDayChartRef') resumeStatusUploadedSf4cByDayChartRef: any;
  @ViewChild('statusUploadSuccessFactorsChartRef') statusUploadSuccessFactorsChartRef: any;
  
  public statusUploadSuccessFactorsChartoptions;
  public resumeStatusUploadedSf4cByDayChartoptions;
  public displayLast7day = false
  public is_superuser = false
  public errorMessagesStat: any
  //matching
  public stat_resume_matching_recruitery: StatResumeMatching = new StatResumeMatching()
  public errorMessagesStatMatchingResumeRecruitery: any
  public page = 1
  public pageSize = 5
  public totalItems = 0
  public pageSizes = [5, 10, 15]
  public view_resume_check = -1
  public view_resume = false
  view_detail_resume(i: any){
    this.view_resume_check = i
    this.view_resume = !this.view_resume
  }
  public isLoading = false
  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private authService: AuthenticationService,
    private _authenticationService: AuthenticationService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this.getStatusUploadSuccessFactors();
    this.getSuccessfactorStatusByDay()
   }
  getStatusUploadSuccessFactors(){
    this.statusUploadSuccessFactorsChartoptions = {
      series: [
        {
          name: '',
          data: []
        },
        {
          name: '',
          data: []
        }
      ],
      chart: {
        type: 'bar',
        height: 400,
        stacked: false,
        toolbar: {
          show: false
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center'
      },
      plotOptions: {
        bar: {
          distributed: true,
          columnWidth: '15%',
          colors: {
            backgroundBarColors: [
              
            ],
            backgroundBarRadius: 10
          }
        }
      },
      colors: [],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: []
      },
      yaxis: {
        title: {
          text: ''
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          }
        }
      }
    };
  }
  getSuccessfactorStatusByDay(){
    this.resumeStatusUploadedSf4cByDayChartoptions = {
      series: [
        {
          data: []
        }
      ],
      chart: {
        height: 400,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: ['#fff'],
        colors: ['FF9F43']
      },
      colors: ['FF9F43'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        categories: [
          
        ]
      },
      tooltip: {
        custom: function (data) {
          return (
            '<div class="px-1 py-50">' +
            '<span>' +
            data.series[data.seriesIndex][data.dataPointIndex] +
            '</span>' +
            '</div>'
          );
        }
      }
    };
  }
  changeCompany(e: any) {
    this.company?.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  get company() {
    return this.FilterStatForm.get('company');
  }
  get period():any{
    return this.FilterStatForm.get('period')
  }
  onSubmit(){
    this.FilterStat();
    
  }
  onReset(){
    this.displayLast7day = true
    this.period.reset()
    let url_dynamic = ""
    let company= this.FilterStatForm.value.company
    if(company!=undefined&& company!=null&&company!=''){
      url_dynamic = url_dynamic+ "&company="+company
    }
    this.statistic(url_dynamic)
  }
  FilterStat(){
    let url_dynamic = ""
    let company= this.FilterStatForm.value.company
    if(this.FilterStatForm.value.period!=undefined){
      
      let start_time_raw = this.FilterStatForm.value.period[0]
      let end_time_raw = this.FilterStatForm.value.period[1]

      const gmtOffsetStart = 7;
      const gmtOffsetEnd = 30;

      let start_time = this.FilterStatForm.value.period[0]==undefined?undefined:new Date(new Date(start_time_raw.getFullYear(),start_time_raw.getMonth(),start_time_raw.getDate()).getTime()+ gmtOffsetStart * 60 * 60 * 1000).toISOString()
      let end_time = this.FilterStatForm.value.period[1]==undefined?undefined:new Date(new Date(end_time_raw.getFullYear(),end_time_raw.getMonth(),end_time_raw.getDate()).getTime()+ gmtOffsetEnd * 60 * 60 * 1000).toISOString()
      if(company!=undefined&& company!=null&&company!=''){
        url_dynamic = url_dynamic+ "&company="+company
      }
      if(start_time!=undefined&& start_time!=null){
        this.displayLast7day = false
        url_dynamic =url_dynamic+ "&start="+start_time
      }
      if(end_time!=undefined&& end_time!=null){
        this.displayLast7day = false
        url_dynamic =url_dynamic+ "&end="+end_time
      }
      this.statistic(url_dynamic);
    }else{
      this.statistic(url_dynamic);
    }
    
    
  }
  ListCompany(){
    this.authService.list_company().subscribe(
      (res)=>{
        this.companys = res.results
      },
      (err)=>{
        console.log("err",err)
      }
    )
  }
  statistic(url_dynamic:string){
    this.isLoading = true;
    this.dashboardService.getStat(url_dynamic).subscribe(
      (res)=>{
        this.isLoading = false;
        this.stat = res;
        
      
      //group sf4c status
      let label_group_sf4c_status: string[]=[]
      let value_group_sf4c_status: number[]=[]
      if(this.stat.group_sf4c_status.length>0){
        for(let i=0; i<this.stat.group_sf4c_status.length;i++){
          label_group_sf4c_status.push(this.stat.group_sf4c_status[i].status)
          value_group_sf4c_status.push(this.stat.group_sf4c_status[i].total)
        }
  
      }
      this.statusUploadSuccessFactorsChartoptions.series = [{data: value_group_sf4c_status, name: "resume"}]
      this.statusUploadSuccessFactorsChartoptions.xaxis.categories = label_group_sf4c_status
      this.statusUploadSuccessFactorsChartoptions.colors = ['#198754','#6c757d']

      //line chart uploaded to sf4c
      let successfactor_value_status_by_day_success: number[]=[]
      let successfactor_value_status_by_day_failed: number[]=[]
      let successfactor_label_status_by_day: string[]=[]
      
      if(this.stat.successfactor_status_by_day.length>0){
        
        for(let i=0; i<this.stat.successfactor_status_by_day.length;i++){
          successfactor_label_status_by_day.push(this.stat.successfactor_status_by_day[i].index)
          successfactor_value_status_by_day_success.push(this.stat.successfactor_status_by_day[i].Success)
          successfactor_value_status_by_day_failed.push(this.stat.successfactor_status_by_day[i].Failed)
        }
      }
      this.resumeStatusUploadedSf4cByDayChartoptions.series = [{data: successfactor_value_status_by_day_success, name:'Success'}
      ,{data: successfactor_value_status_by_day_failed, name: "Failure"}]
      this.resumeStatusUploadedSf4cByDayChartoptions.xaxis.categories = successfactor_label_status_by_day
      this.resumeStatusUploadedSf4cByDayChartoptions.colors = ['#198754','#6c757d']
      },
      (err)=>{
        this.errorMessagesStat = err;
        this.isLoading = false
      }
    )
  }
  statistic_resume_matching_recruitery(){
    this.isLoading = true;
    this.dashboardService.getStatMatchingRecruitery(this.page, this.pageSize).subscribe(
      (res)=>{
        this.stat_resume_matching_recruitery = res;
        this.totalItems = res.count;
        this.isLoading = false;
      },
      (err)=>{
        console.error("Error "+err);
        this.isLoading = false
      }
    )
  }
  sort_data(data: ResultDetailResumeMatching[]):ResultDetailResumeMatching[]{
    let dataSort = data.slice().sort((a,b)=>b.overall_score-a.overall_score)
    return dataSort
  }
  pageChangeEvent(event: number){
    this.page = event;
    this.statistic_resume_matching_recruitery();

  }
  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.statistic_resume_matching_recruitery();

  }
  ngOnInit(): void {
    this.displayLast7day = true
    this.user = this._authenticationService.currentUserValue

    if(this.user.is_superuser){
      this.is_superuser = true
      this.ListCompany()
    }
    
    let url_dynamic = ""
    this.statistic(url_dynamic);
    this.statistic_resume_matching_recruitery();
  }


}
