import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { locale as localeEn } from "../i18n/en";
import { locale as localeVn } from "../i18n/vn";
import { CoreConfigService } from '@core/services/config.service';
import { CoreTranslationService } from '@core/services/translation.service';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from '../services/payment.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService } from "ngx-clipboard";
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';

enum PaymentStatus {
  PENDING = "PENDING",
  SUCCESS = "DONE",
  FAILED = "FAILED",
  CANCELED = "CANCELED",
}
@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnInit {

  public coreConfig: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  public transactionInfo: any;

  public PaymentStatus = PaymentStatus;
  public linkImage: string = "";
  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _CoreTranslateService: CoreTranslationService,
    private route: ActivatedRoute,
    public _paymentService: PaymentService,
    private _translateService: TranslateService,
    private _clipboardService: ClipboardService,
    private _toastrService: ToastrService
  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };

    this._CoreTranslateService.translate(localeEn);
    this._CoreTranslateService.translate(localeVn);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });

    var transactionId = this.route.snapshot.paramMap.get("transactionId");

    this._paymentService
      .getTransactionInfo(transactionId)
      .subscribe((transactionData) => {
        console.log(transactionData);
        this.transactionInfo = {
          ...transactionData,
          amount: transactionData.amount.toLocaleString("en-US", {
            maximumFractionDigits: 0,
          }),
          date: moment(transactionData.updated_at).format("DD/MM/YYYY"),
          time: moment(transactionData.updated_at).format("HH:mm A"),
        };

        this.linkImage =
          this.transactionInfo?.status == PaymentStatus.SUCCESS
            ? "assets/images/icons/check.png"
            : this.transactionInfo?.status == PaymentStatus.PENDING
            ? "assets/images/icons/pending-icon.svg"
            : this.transactionInfo?.status == PaymentStatus.CANCELED ||
              this.transactionInfo?.status == PaymentStatus.FAILED
            ? "assets/images/icons/error.png"
            : "assets/images/icons/error.png";
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  copyToThisClipboard(content: string) {
    this._clipboardService.copy(content);
    this._toastrService.success(`${content}`);
  }

}
