import { colors } from './../../colors.const';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ResultDetailResumeMatching, Stat, StatResumeMatching } from '../resume-parser/models/resume.model';
import { FormBuilder, FormControl } from '@angular/forms';
import { ListCompany, User } from 'app/auth/models';
import { DashboardService } from './services/dashboard.service';
import { AuthenticationService } from 'app/auth/service';
import { ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexMarkers, ApexNonAxisChartSeries, ApexPlotOptions, ApexResponsive, ApexStates, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as enLocale } from "./i18n/en";
import { locale as vnLocal } from "./i18n/vn";
export interface ChartOptions2 {
  // Apex-non-axis-chart-series
  series?: ApexNonAxisChartSeries;
  chart?: ApexChart;
  stroke?: ApexStroke;
  tooltip?: ApexTooltip;
  dataLabels?: ApexDataLabels;
  fill?: ApexFill;
  colors?: string[];
  legend?: ApexLegend;
  labels?: any;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  markers?: ApexMarkers[];
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis;
  states?: ApexStates;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
  public isAuthenticated=false
  public stat: Stat = new Stat()
  public stat_resume_matching: Stat = new Stat()
  public stat_resume_matching_recruitery: StatResumeMatching = new StatResumeMatching()
  public errorMessagesStat: any
  public errorMessagesStatMatchingResumeRecruitery: any
  public label_owner:string[] = []
  public loading=false
  public typeTime = 0
  //Period
  public companys = [{id:19, name:""}]
  FilterStatForm = this.fb.group({
    company: new FormControl(''),
    period: new FormControl([]),
  })
  public displayLast7day = false
  public user = new User()
  public is_superuser = false
  public list_compay: ListCompany = new ListCompany()
  public options:any

  tab_successfactors = false
  tab_resume = false
  tab_resume_matching = false
  //Table resume matching recruitery
  public page = 1
  public pageSize = 5
  public totalItems = 0
  public pageSizes = [5, 10, 15]
  public view_resume_check = -1
  public view_resume = false

  @ViewChild('statusChartRef') statusChartRef: any;
  @ViewChild('ownerChartRef') ownerChartRef: any;
  @ViewChild('lineStatusChartRef') lineStatusChartRef: any;


  public statusChartoptions;
  public ownerChartoptions;
  public resumeStatusChartoptions;

  public apexDonutChart: Partial<ChartOptions2>;
   // Color Variables
   chartColors = {
    donut: {
      series1: '#219cd7',
      series2: '#2b9bf4',
      series3: '#ff9f43'
    }
  };
  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private authService: AuthenticationService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this.getStatus();
    this.getOwner();
    this.getResumeStatus();
   }
  getStatus(){
    this.statusChartoptions = {
      chart: {
        type: 'donut',
        height: 400,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true
      },
      series: [],
      legend: { show: true },
      comparedResult: [2, -3, 8],
      labels: [],
      stroke: { width: 0 },
      colors: ['#219cd7', '#28c76f','#ff9f43'],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val) {
                  return parseInt(val);
                }
              },
              // total: {
              //   show: true,
              //   offsetY: 15,
              //   label: 'admin',
              //   formatter: function (w) {
              //     return parseInt(w);
              //   }
              // }
            }
          }
        }
      },
    };
  }
  getOwner(){
    this.ownerChartoptions = {
      chart: {
        type: 'donut',
        height: 400,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true
      },
      series: [],
      legend: { show: true },
      comparedResult: [2, -3, 8],
      labels: [],
      stroke: { width: 0 },
      colors: ['#219cd7', '#28c76f','#ff9f43'],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val) {
                  return parseInt(val);
                }
              },
              // total: {
              //   show: true,
              //   offsetY: 15,
              //   label: 'admin',
              //   formatter: function (w) {
              //     return parseInt(w);
              //   }
              // }
            }
          }
        }
      },
    };
  }
  getResumeStatus(){
    this.resumeStatusChartoptions = {
      series: [
        {
          data: []
        }
      ],
      chart: {
        height: 400,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: [colors.solid.white],
        colors: [colors.solid.warning]
      },
      colors: [colors.solid.warning],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        categories: [
          
        ]
      },
      tooltip: {
        custom: function (data) {
          return (
            '<div class="px-1 py-50">' +
            '<span>' +
            data.series[data.seriesIndex][data.dataPointIndex] +
            '</span>' +
            '</div>'
          );
        }
      }
    };
  }
  changeCompany(e: any) {
    this.company?.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  get company() {
    return this.FilterStatForm.get('company');
  }
  get period():any{
    return this.FilterStatForm.get('period')
  }
  onSubmit(){
    this.FilterStat();
    
  }
  onReset(){
    this.displayLast7day = true
    this.period.reset()
    let url_dynamic = ""
    let company= this.FilterStatForm.value.company
    if(company!=undefined&& company!=null&&company!=''){
      url_dynamic = url_dynamic+ "&company="+company
    }
    this.statistic(url_dynamic)
  }
  FilterStat(){
    let url_dynamic = ""
    let company= this.FilterStatForm.value.company
    if(this.FilterStatForm.value.period!=undefined){
      
      let start_time_raw = this.FilterStatForm.value.period[0]
      let end_time_raw = this.FilterStatForm.value.period[1]

      const gmtOffsetStart = 7;
      const gmtOffsetEnd = 30;

      let start_time = this.FilterStatForm.value.period[0]==undefined?undefined:new Date(new Date(start_time_raw.getFullYear(),start_time_raw.getMonth(),start_time_raw.getDate()).getTime()+ gmtOffsetStart * 60 * 60 * 1000).toISOString()
      let end_time = this.FilterStatForm.value.period[1]==undefined?undefined:new Date(new Date(end_time_raw.getFullYear(),end_time_raw.getMonth(),end_time_raw.getDate()).getTime()+ gmtOffsetEnd * 60 * 60 * 1000).toISOString()
      if(company!=undefined&& company!=null&&company!=''){
        url_dynamic = url_dynamic+ "&company="+company
      }
      if(start_time!=undefined&& start_time!=null){
        this.displayLast7day = false
        url_dynamic =url_dynamic+ "&start="+start_time
      }
      if(end_time!=undefined&& end_time!=null){
        this.displayLast7day = false
        url_dynamic =url_dynamic+ "&end="+end_time
      }
      this.statistic(url_dynamic);
    }else{
      this.statistic(url_dynamic);
    }
    
    
  }
  ListCompany(){
    this.authService.list_company().subscribe(
      (res)=>{
        this.list_compay = res;
        this.companys = res.results
      },
      (err)=>{
        this.errorMessagesStat = err
      }
    )
  }
  ngOnInit(): void {
    this.tab_resume = true
    this.displayLast7day = true
    this.user = JSON.parse(localStorage.getItem("user")|| '{}');
    if(this.user.is_superuser){
      this.is_superuser = true
      this.ListCompany()
    }
    
    let url_dynamic = ""
    this.statistic(url_dynamic);
    
  }
  sort_data(data: ResultDetailResumeMatching[]):ResultDetailResumeMatching[]{
    let dataSort = data.slice().sort((a,b)=>b.overall_score-a.overall_score)
    return dataSort
  }
  pageChangeEvent(event: number){
    this.page = event;
    this.loading = true
    // this.statistic_resume_matching_recruitery();

  }
  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.statistic_resume_matching_recruitery();

  }
  statistic(url_dynamic:string){
    this.loading = true
    this.dashboardService.getStat(url_dynamic).subscribe(
      (res)=>{
        this.loading = false
        this.stat = res;
        let label_status = []
        let value_status = []
      //pie chart group status
      if(this.stat.group_status.length>0){
        for(let i=0; i<this.stat.group_status.length;i++){
          label_status.push(this.stat.group_status[i].status)
          value_status.push(this.stat.group_status[i].total)
        }
      }else{
        label_status=[]
        value_status=[]
      }
      let backgroundColorStatus = []
      for(let i=0;i<label_status.length;i++){
        if(label_status[i]=='Done'){
          backgroundColorStatus.push('#0d6efd')
        }else if(label_status[i]=='Approved'){
          backgroundColorStatus.push('#20c997')
        }else if(label_status[i]=='Failure'){
          backgroundColorStatus.push('#dc3545')
        }else if(label_status[i]=='SF4C_uploaded'){
          backgroundColorStatus.push('#198754')
        }else if(label_status[i]=='SF4C_failed'){
          backgroundColorStatus.push('#6c757d')
        }else if(label_status[i]=='Modified'){
          backgroundColorStatus.push('#ffc107')
        }else if(label_status[i]=='Wait_to_process'){
          backgroundColorStatus.push('#dee2e6')
        }else if(label_status[i]=='SF4C_uploading'){
          backgroundColorStatus.push('#0dcaf0')
        }else if(label_status[i]=='Processing'){
          backgroundColorStatus.push('#212529')
        }
      }
      this.statusChartoptions.labels = label_status;
      this.statusChartoptions.series = value_status;
      this.statusChartoptions.colors = backgroundColorStatus;
      
      let label_owner=[]
      let value_owner=[]
      if(this.stat.group_owner.length>0){
        for(let i=0; i<this.stat.group_owner.length;i++){
          label_owner.push(this.stat.group_owner[i].owner__email)
          value_owner.push(this.stat.group_owner[i].total)
        }
        this.label_owner = label_owner
        
      }else{
        label_owner=[]
        value_owner=[]
      }
      this.ownerChartoptions.labels = label_owner;
      this.ownerChartoptions.series = value_owner;
      this.ownerChartoptions.colors = ['#0d6efd','#6610f2',"#dc3545",'#ffc107','#198754','#0dcaf0','#fff','#6c757d','#fd7e14','#212529'];
      //group sf4c status
      let label_group_sf4c_status: string[]=[]
      let value_group_sf4c_status: number[]=[]
      if(this.stat.group_sf4c_status.length>0){
        for(let i=0; i<this.stat.group_sf4c_status.length;i++){
          label_group_sf4c_status.push(this.stat.group_sf4c_status[i].status)
          value_group_sf4c_status.push(this.stat.group_sf4c_status[i].total)
        }
        label_group_sf4c_status=[...label_group_sf4c_status]
        value_group_sf4c_status=[...value_group_sf4c_status]
      }

      //line chart status by day
      let label_status_by_day: string[] = []
      let value_status_by_day_success: number[]=[]
      let value_status_by_day_failed: number[]=[]
      if(this.stat.status_by_day.length>0){
        for(let i=0; i<this.stat.status_by_day.length;i++){

          label_status_by_day.push(this.stat.status_by_day[i].index)
          value_status_by_day_success.push(this.stat.status_by_day[i].Success)
          value_status_by_day_failed.push(this.stat.status_by_day[i].Failed)

        }
        
        this.resumeStatusChartoptions.xaxis.categories = label_status_by_day;
        this.resumeStatusChartoptions.series = [{data: value_status_by_day_success, name: 'Success'},{data: value_status_by_day_failed,name: 'Failure'}];
        this.resumeStatusChartoptions.colors = [colors.solid.success, colors.solid.danger]
      }
      //line chart uploaded to sf4c
      let successfactor_value_status_by_day_success: number[]=[]
      let successfactor_value_status_by_day_failed: number[]=[]
      let successfactor_label_status_by_day: string[]=[]
      
      if(this.stat.successfactor_status_by_day.length>0){
        
        for(let i=0; i<this.stat.successfactor_status_by_day.length;i++){
          successfactor_label_status_by_day.push(this.stat.successfactor_status_by_day[i].index)
          successfactor_value_status_by_day_success.push(this.stat.successfactor_status_by_day[i].Success)
          successfactor_value_status_by_day_failed.push(this.stat.successfactor_status_by_day[i].Failed)
        }
        successfactor_label_status_by_day=[...successfactor_label_status_by_day]

        successfactor_value_status_by_day_success=[...successfactor_value_status_by_day_success]
        successfactor_value_status_by_day_failed=[...successfactor_value_status_by_day_failed]
        
        
      }
      },
      (err)=>{
        this.errorMessagesStat = err
        this.loading = false
      }
    )
  }

}
