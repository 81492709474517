<div class="content-wrapper container-xxxl p-0 card">
    <div class="content-body card-body" id="ngx-datatable-row-details">
        <div class="row mb-1">
            <!-- <div class="col-md-6 col-12">
              <div class="d-flex justify-content-between align-items-center m-1">
                <label
                  class="d-flex align-items-center"
                  style="max-width: 100%; flex-wrap: nowrap"
                  ><span [translate]="'TALENT_POOL.MANAGE_JOB.SHOW'"></span>
                  <select
                    class="form-control mx-25 pr-3 show-doc"
                    [(ngModel)]="basicSelectedOption"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="40">40</option>
                  </select>
                  <span [translate]="'TALENT_POOL.MANAGE_JOB.JOB'"></span>
                </label>
              </div>
            </div> -->
            <div class="col-12 d-flex justify-content-md-end align-items-center">
                <div class="">
                    <a routerLink="/manage-job/create-job" class="btn btn-primary mr-2" [translate]="'TALENT_POOL.MANAGE_JOB.ADD_JOB'">
                        
                    </a>
                </div>
                <div class="">
                    <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                    <input
                        type="text"
                        class="form-control"
                        id="job-search"
                        
                        [placeholder]="'TALENT_POOL.MANAGE_JOB.PLACEHOLDER'|translate"
                        
                        [(ngModel)]="searchText"
                        (input)="Search()"
                    />
                </div>
            </div>
        </div>
        <ngx-datatable
          *ngIf="list_all_jd.length>0"
          id="table-jd"
          #tableJd
          class="bootstrap core-bootstrap"
          [rows]="list_all_jd"
          [rowHeight]="50"
          class="bootstrap core-bootstrap"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [scrollbarH]="true"
          [limit]="basicSelectedOption"
        >
          <ngx-datatable-column
            [name]="'TALENT_POOL.MANAGE_JOB.JOB'|translate"
            
            width="300"
          >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
                <a routerLink="/manage-job/detail-job/{{row.id}}" class="d-inline-block text-truncate max-width-custom w-100">{{ row.job_title }}</a>
            </div>
          </ng-template>
          </ngx-datatable-column>
          
         
          <ngx-datatable-column [name]="'TALENT_POOL.CREATE_JOB.LOCATION'|translate" prop="location" width="150">
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'TALENT_POOL.CREATE_JOB.INDUSTRY'|translate" width="400">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="w-100">
                  <ul class="list-unstyled">
                    <li *ngFor="let _row of row.industry">{{ _row }}</li>
                  </ul>
              </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'TALENT_POOL.CREATE_JOB.JOB_TYPE'|translate" prop="job_type" width="150">
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'TALENT_POOL.CREATE_JOB.JOB_LEVEL'|translate" prop="job_level" width="150">
        </ngx-datatable-column>
        <ngx-datatable-column
            [name]="'TALENT_POOL.MANAGE_JOB.APPLICATION1'|translate"
            
            width="150"
          >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
                <a routerLink="/search-resume/recommend-resume/{{row.id}}" class="d-inline-block text-truncate max-width-custom w-100" [translate]="'TALENT_POOL.MANAGE_JOB.RECOMMEND'"></a>
            </div>
          </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
          [name]="'TALENT_POOL.MANAGE_JOB.APPLICATION2'|translate"
          
          width="150"
        >
        <ng-template ngx-datatable-cell-template let-row="row">
          <div class="w-100">
              <a routerLink="/search-resume/saved-cvs/{{row.id}}?hcm_system_id={{row.hcm_system_id}}" class="d-inline-block text-truncate max-width-custom w-100" [translate]="'TALENT_POOL.MANAGE_JOB.SAVED'"></a>
          </div>
        </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'TALENT_POOL.MANAGE_JOB.NUM_SAVED_RESUMES'|translate" prop="num_talent_pool_saved_cvs" width="150">
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'TALENT_POOL.MANAGE_JOB.HCM_SYSTEM_ID'|translate" prop="hcm_system_id" width="150">
        </ngx-datatable-column>
        <ngx-datatable-column
        [name]="'TALENT_POOL.MANAGE_JOB.ACTION'|translate"
        width="150"
      >
      <ng-template ngx-datatable-cell-template let-row="row">
        <div class="w-100">
            <a routerLink="/manage-job/edit-job/{{row.id}}" class="d-inline-block text-truncate max-width-custom w-100" [translate]="'TALENT_POOL.MANAGE_JOB.EDIT'"></a>
        </div>
      </ng-template>
      </ngx-datatable-column> 
         
        </ngx-datatable>
    </div>
  </div>