import { Subject, Subscription, interval } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ListResumeMatching, ResumeMatching } from '../../models/resume.model';
import { FormBuilder, FormControl } from '@angular/forms';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { TalentPoolService } from '../../services/talent-pool.service';
import { switchMap } from 'rxjs/operators';
import { locale as enLocale } from "../../i18n/en";
import { locale as vnLocal } from "../../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
@Component({
  selector: 'app-resume-matching',
  templateUrl: './resume-matching.component.html',
  styleUrls: ['./resume-matching.component.scss']
})
export class ResumeMatchingComponent implements OnInit {
  public view_resume_check = -1
  public view_resume = false
  view_detail_resume(i: any){
    this.view_resume_check = i
    this.view_resume = !this.view_resume
  }
  public ResumeMatching = 'pxp-active'
  public errorMessagesListMatchResume!: any
  public list_resume_match: ListResumeMatching = new ListResumeMatching()
  public result_resume_match: ResumeMatching[] = []
  public searchText!: string
  public page = 1
  public pageSize = 5
  public totalItems = 0
  public pageSizes = [5, 10, 15]
  public loading = false
  public status_color: any = {
    "created": 'success',
    "low score": 'warning',
    "matched": "primary",
    "matching": "info",
    "failed":"dark",
    "Approved": 'success',
    "SF4C_background_failed": 'warning',
    "Done": "primary",
    "Failure": "danger",
    "Processing": "info",
    "Wait_to_process":"dark",
    "Suspended":"muted",
    "Modified":"warning",
    "SF4C_wait_to_upload":"dark",
    "SF4C_failed":"secondary",
    "SF4C_uploading":"info",
    "SF4C_uploaded":"success",
    "Base_upload_failed":"secondary",
    "Base_uploading":"info",
    "Base_upload_success":"success"
  }
  public job_status_color: any = {
    "Success": 'success',
    "Fail": "danger",
    "Check": "warning"
  }
  INTERVAL_TIME = 3000;
  intervalSubscription: Subscription;
  public url_dynamic = ''
  FilterMonitorUploadForm = this.fb.group({
    status: new FormControl(''),
    created_by__email: new FormControl('')
  })
  public StatusItems = [
    {name: "matched", value: "matched"},
    {name: "low score", value: "low score"},
    {name: "failed", value: "failed"},
  ]
  public createdby__email_items: any[]=[]
  public errorMessagesListUser!: any
  public users: User[] = []

  changeStatus(e: any) {
    this.stauts?.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  get stauts() {
    return this.FilterMonitorUploadForm.get('status');
  }
  changeCreateByEmail(e: any) {
    this.created_by__email?.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  get created_by__email() {
    return this.FilterMonitorUploadForm.get('created_by__email');
  }
  onSubmit(){
    this.page = 1
    this.FilterMonitorUpload();
    
  }
  FilterMonitorUpload(){
    let url_dynamic = ""
    let status= this.FilterMonitorUploadForm.value.status
    let created_by__email= this.FilterMonitorUploadForm.value.created_by__email
    if(status!=undefined&& status!=null&&status!=''){
      url_dynamic = url_dynamic+ "&status="+status
    }
    if(created_by__email!=undefined&& created_by__email!=null&&created_by__email!=''){
      url_dynamic = url_dynamic+ "&created_by__email="+created_by__email
    }
    this.url_dynamic = url_dynamic
    this.list_match_resume(url_dynamic);
  }
  GetListUser(){
    this._authenticationService.listUsers().subscribe(
      (res)=>{
        this.users = res.results
      
        for (let i=0;i<this.users.length;i++){
          let createdby__email_item = this.users[i].first_name + ' '+ this.users[i].last_name + ': '+ this.users[i].email
          this.createdby__email_items.push({"name":createdby__email_item, "value": this.users[i].email})
          
        }
        this.createdby__email_items = [...this.createdby__email_items] 
      },
      (err)=>{
        this.errorMessagesListUser = err
      }
    )
  }
  constructor(
    private fb: FormBuilder,
    private talentpoolService: TalentPoolService,
    private _authenticationService: AuthenticationService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,

  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
   }

  list_match_resume(url_dynamic: string){
    this.loading = true
    let page = this.page
    let page_size = this.pageSize
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
    const intervalAfterCallApi = interval(this.INTERVAL_TIME).pipe(
      switchMap(() =>
      this.talentpoolService.list_match_resume(page,page_size, url_dynamic)
      )
    );
    this.intervalSubscription = intervalAfterCallApi.subscribe(
      (res)=>{
        this.list_resume_match = res
        this.result_resume_match = res.results
        this.totalItems = res.count
        this.loading = false
      },
      (err) =>{
        this.errorMessagesListMatchResume= err;
        if (this.intervalSubscription) this.intervalSubscription.unsubscribe();

      }
    )
  }
  ngOnDestroy(): void {
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
  }
  pageChangeEvent(event: number,url_dynamic: string){
    this.page = event;
    this.loading = true
    this.list_match_resume(url_dynamic);

  }
  handlePageSizeChange(event: any, url_dynamic: string): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.list_match_resume(url_dynamic);

  }
  Search(){
    // alert(this.searchText)
    if(this.searchText!== ""){
      let searchValue = this.searchText.toLocaleLowerCase();
      

      this.result_resume_match = this.list_resume_match.results.filter((result_resume_match_:any) =>{
        return result_resume_match_.resume_status.toLocaleLowerCase().match(searchValue )
        ;
      // you can keep on adding object properties here

            });
            
          }
          
          
          else{
            this.list_match_resume("")
          }
        }
  ngOnInit(): void {

    let url_dynamic = ''
    this.GetListUser()
    this.list_match_resume(url_dynamic)
  }

}
