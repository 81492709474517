import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CoreTranslationService } from '@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Page } from 'app/main/resume-parser/models/paging.model';
import { ResumeParser } from 'app/main/resume-parser/models/resume.model';
import { ResumeParserService } from 'app/main/resume-parser/services/resume-parser.service';
import { environment } from 'environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { Subject, Subscription, concat, interval } from 'rxjs';
import { locale as enLocale } from "../../resume-parser/i18n/en";
import { locale as vnLocal } from "../../resume-parser/i18n/vn";
import saveAs from 'file-saver';
import { switchMap } from 'rxjs/operators';
import { CoreConfigService } from '@core/services/config.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-try',
  templateUrl: './try.component.html',
  styleUrls: ['./try.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TryComponent implements OnInit {
  public isLoading = false
  public isAuthenticated=false
  public resume_parser: ResumeParser[]=[]
  public selectedPdf: string=""
  public myFiles:File[]=[];
  basicSelectedOption = 5
  public page = new Page({});
  ColumnMode = ColumnMode;
  widthColumn: number = 0;
  @ViewChild(DatatableComponent) tableRowDetails: DatatableComponent;
  INTERVAL_TIME = 3000;
  intervalSubscription: Subscription;
  public id_resume_upload!: number
  public displayTable = false
  private _unsubscribeAll: Subject<any>;

  // page = 1;
  // pageSize = 5;
  // pageSizes = [5, 10, 15];
  // totalItems: number = 0

  public status_color: any = {
    "Approved": 'success',
    "SF4C_background_failed": 'warning',
    "Done": "primary",
    "Failure": "danger",
    "Processing": "info",
    "Wait_to_process":"dark",
    "Suspended":"secondary",
    "Modified":"warning",
    "SF4C_wait_to_upload":"dark",
    "SF4C_failed":"secondary",
    "SF4C_uploading":"info",
    "SF4C_uploaded":"success"

  }
  public status: string = ''
  form!: FormGroup
  public success=false
  public src_images: any;
 
  public range_conf: any = {
    'success': 0.6,
    'warning': 0.4,
  }
  public tag_export = false
  public limitedError!: string
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  public hasBaseDropZoneOver: boolean = false;
  public uploader: FileUploader = new FileUploader({
    url: environment.apiUrl,
    isHTML5: true,
  });
  constructor(
    private resumesService: ResumeParserService,
    private elementRef: ElementRef,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private _toastrService: ToastrService,


  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this.form = new FormGroup({
      filePdf: new FormControl(null),
    });
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
   }
   selectedFiles?: FileList;

    onFileDrop(fileList: File[]) {
      if (fileList.length > 50){
        alert('To many files')
        this.myFiles = []
      }else if(this.page.totalElements>=20){
        alert('You have tried all 20 files')
        this.myFiles = []
      }else{
        this.myFiles = fileList
        this.form.patchValue({ filePdf: fileList });
        this.ParseListResume()
      }  
    }
  
    async handleFile(e: any) {
      this.myFiles = []

      this.selectedFiles = e.target.files;
      if (e.target.files.length > 50){
        alert('To many files')
        this.myFiles = []
      }else if(this.page.totalElements>=20){
        alert('You have tried all 20 files')
        this.myFiles = []
      }
      else{
        for (var i =0; i< e.target.files.length;i++){
          
          if(e.target.files[i].size<10000000){
            this.myFiles.push(e.target.files[i])
            
          }
          
        }
        if (this.selectedFiles) {
          
          this.form.patchValue({ filePdf: this.selectedFiles });
    
          
        }
        this.ParseListResume()
      }
    }
    ParseListResume(){
      this.isLoading = true
      this.resume_parser = []
      
      const resumeSubscr = this.resumesService.uploadResume(this.myFiles)
      .subscribe(
        (res)=>{
          this.id_resume_upload = res.id;
          const resizeTableObserver = new ResizeObserver((entries) => {
            this.widthColumn = entries[0].contentRect.width / 7;
          });
          resizeTableObserver.observe(
            this.elementRef.nativeElement.querySelector(
              "#ngx-datatable-row-details"
            )
          );
          this.getListResume();
          // if(this.resumesService.statusValue != "Done"){
          //   setTimeout(() => {this.getTaskStatus();
          //     this.getFillListResume();
          //       },1000 );
          // }
          this.form.reset();

        },
        (err)=>console.error("err "+err)
      )
      this.unsubscribe.push(resumeSubscr);
    }
    getListResume(){
      if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
      const api$ = this.resumesService.getFilterListResume('',this.page.pageNumber, this.page.size)
      const intervalAfterCallApi = interval(this.INTERVAL_TIME).pipe(
        switchMap(() =>
          this.resumesService.getFilterListResume('',this.page.pageNumber, this.page.size)
        )
      );
      this.intervalSubscription = concat(api$, intervalAfterCallApi).subscribe(
        (res)=>{
          this.resume_parser = res.results;
          // this.resume_parser = this.resume_parser.slice(0, 20);
          this.page.totalElements = res.count
          this.isLoading = false
          if(res.count>0){
            this.displayTable = true
          }
        }
      )
      
    }
    setPage(pageInfo) {
      this.page.pageNumber = pageInfo.offset;
      this.getListResume();

    }
    export_csv(){
      this.tag_export = true
      let type_file="csv"
      this.resumesService.downloadResume(type_file).subscribe(x=>{
        const filename = `Result parse resumes.csv`;
        let blob = new Blob([x],{type: 'csv;charset=utf-8'});
        this.tag_export = false;
        saveAs(blob, filename);
      })
    }
    export_excel(){
      this.tag_export = true
      let type_file="excel"
      this.resumesService.downloadResume(type_file).subscribe(x=>{
        const filename = `Result parse resumes.xlsx`;
        let blob = new Blob([x],{type: 'application/vnd.ms-excel;charset=utf-8'});
        this.tag_export = false;
        saveAs(blob, filename);
      })
    }
    deleteResume(id: number) {
      Swal.fire({
        title: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.DELETE_R"),
        text: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CANT_UNDO"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#7367F0",
        cancelButtonColor: "#E42728",
        cancelButtonText: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL"),
        confirmButtonText: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CONFIRM"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
        },
        preConfirm: async () => {
          this.resumesService.deleteResume(id).subscribe(
            (res) => {
              this._toastrService.success(
                this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.DELETE_R_SUC"),
                this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
                {
                  toastClass: "toast ngx-toastr",
                  closeButton: true,
                }
              );
              this.getListResume();
            },
            (err) => {
              this._toastrService.error(
                this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.ERROR"),
                this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.FAILED"),
                {
                  toastClass: "toast ngx-toastr",
                  closeButton: true,
                }
              );
            }
          );
        },
        allowOutsideClick: () => {
          return !Swal.isLoading();
        },
      });
    }
    ngOnDestroy() {
      this.unsubscribe.forEach((sb) => sb.unsubscribe());
      if (this.intervalSubscription) this.intervalSubscription.unsubscribe();

    }
  ngOnInit(): void {
    const resizeTableObserver = new ResizeObserver((entries) => {
      this.widthColumn = entries[0].contentRect.width / 4;
    });
    resizeTableObserver.observe(
      this.elementRef.nativeElement.querySelector(
        "#ngx-datatable-row-details"
      )
    );

    this.getListResume()
  }

}
