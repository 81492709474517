<div class="content-wrapper container-xxxl p-0">
    <div class="content-body">
      <section id="pricing-plan">
        <!-- title text and switch button -->
        <div class="card">
          <div class="text-center">
            <h1 class="mt-5" translate="PAYMENT.TITLE"></h1>
            <br />
            <p class="mb-2 pb-75" translate="PAYMENT.DESCRIPTION"></p>
            <br />
          </div>
          <!--/ title text and switch button -->
          <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
            <h6 class="mr-1 mb-0" [translate]="'PAYMENT.PARSE'"></h6>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                [checked]="no_full_service"
                [(ngModel)]="no_full_service"
                class="custom-control-input"
                id="priceSwitch"
                name="priceSwitch"
              />
              <label class="custom-control-label" for="priceSwitch"></label>
            </div>
            <h6 class="ml-50 mb-0" [translate]="'PAYMENT.FULL_SERVICE'"></h6>
          </div>
          <!-- pricing plan cards -->
          <div class="row pricing-card">
            <div
              class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto"
            >
              <div class="row" *ngIf="!no_full_service;else FullService">
                <!-- standard plan -->
                <div class="col-12 col-md-4" id="price-basic">
                  <div class="card card-pricing text-center mb-5 border">
                    <div class="card-body">
                      <!-- <img
                        src="assets/images/folder/payment.png"
                        alt=""
                        class="w-50"
                      /> -->
                      <h3 class="card-title" [translate]="'PAYMENT.PLAN_1.BASIC'"></h3>
                      <div class="sub-title">
                        <span [translate]="'PAYMENT.PLAN_1.SUB_BASIC'"></span>
                      </div>
                      <div class="annual-plan">
                        <div class="plan-price mt-2">
                          <div class="count-doc">
                            <div class="plan-price mt-2">
                              <h3 style="color: #00A5F4;"
                                class="pricing-basic-value font-weight-bolder"
                                >2500</h3
                              >
                            </div>
                            <div>
                              <p [translate]="'PAYMENT.PLAN_1.COUNT_BASIC'"></p>
                            </div>
                          </div>
                          <br />
                          <div class="quota-price" [translate]="'PAYMENT.PLAN_1.PRICING_BASIC_PARSING'"></div>
                        </div>
                      </div>
                      <!-- <button
                        class="btn btn-block btn-outline-primary mt-2 btn-w"
                        (click)="redirectToPagePricing()"
                        rippleEffect
                      >
                        <span translate="PAYMENT.BUY_NOW"></span>
                      </button> -->
                      <ul
                        class="font-italic mt-4 list-feature"
                        
                      >
                        <li class="h-feature-1"><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_1.FEATURE_BASIC1'"></span></li>
                        <li class="h-feature-1"><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_1.FEATURE_BASIC2'"></span></li>
                        <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_1.FEATURE_BASIC3'"></span></li>
                        <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_1.FEATURE_BASIC4'"></span></li>
                        <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_1.FEATURE_BASIC5'"></span></li>
                        <li><i class="fa fa-times mr-1" style="color: #f34725"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD5'"></span></li>
                        <li><i class="fa fa-times mr-1" style="color: #f34725;"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD7'"></span></li>
                        
    
    
                      </ul>
                      <div class="d-flex justify-content-center">
                        <button
                          class="btn btn-block btn-outline-primary mt-2"
                          (click)="buyQuota('120K_200TAILIEU')"
                          rippleEffect
                        >
                          <span translate="PAYMENT.BUY_NOW"></span>
                        </button>
                      </div>
                      
    
                    </div>
                  </div>
                </div>
                <!--/ standard plan -->
    
                <!-- standard plan -->
                <div class="col-12 col-md-4" id="price-standard">
                  <div class="card card-pricing text-center mb-5 border">
                    <div class="ribbon">
                      <span [translate]="'PAYMENT.PLAN_2.MOST_POPULAR'"></span>
                    </div>
                    <div class="card-body">
                      <!-- <img
                        src="assets/images/folder/payment1.png"
                        alt=""
                        class="w-50"
                      /> -->
                      <h3 class="card-title" [translate]="'PAYMENT.PLAN_2.STANDARD'"></h3>
                      <div class="sub-title">
                        <span [translate]="'PAYMENT.PLAN_2.SUB_STANDARD'"></span>
                      </div>
                      <div class="annual-plan">
                        <div class="plan-price mt-2">
                          <div class="count-doc">
                            <div class="plan-price mt-2">
                              <h3 style="color: #00A5F4;"
                                class="pricing-basic-value font-weight-bolder"
                                >5000</h3
                              >
                            </div>
                            <div>
                              <p [translate]="'PAYMENT.PLAN_2.COUNT_STANDARD'"></p>
                            </div>
                          </div>
                          <br />
                          <div class="quota-price" [translate]="'PAYMENT.PLAN_2.PRICING_STANDARD_PARSING'"></div>
                        </div>
                      </div>
                      <!-- <button
                        class="btn btn-block btn-primary mt-2 btn-w"
                        (click)="redirectToPagePricing()"
                      >
                        <span [translate]="'PAYMENT.BUY_NOW"></span>
                      </button> -->
                      <ul
                        class="font-italic mt-4 list-feature"
                        
                      >
                        <li class="h-feature-1"><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD1'"></span></li>
                        <li class="h-feature-1"><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD2'"></span></li>
                        <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD3'"></span></li>
                        <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD4'"></span></li>
                        <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD5'"></span></li>
                        <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD6'"></span></li>
                        <li><i class="fa fa-check mr-1" style="color: #219cd7;"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD7'"></span></li>
                        <li><i class="fa fa-times mr-1" style="color: #f34725;"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE7'"></span></li>
                        <li><i class="fa fa-times mr-1" style="color: #f34725;"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE8'"></span></li>
  
  
                      </ul>
                      <div class="d-flex justify-content-center">
                        <button
                          class="btn btn-block btn-primary mt-2"
                          (click)="buyQuota('500K_1000TAILIEU')"
                          rippleEffect
                        >
                          <span translate="PAYMENT.BUY_NOW"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--/ standard plan -->
    
                <!-- standard plan -->
                <div class="col-12 col-md-4" id="price-enterprise">
                  <div class="card card-pricing text-center mb-5 border">
                    <div class="card-body">
                      <!-- <img
                        src="assets/images/folder/payment2.png"
                        alt=""
                        class="w-50"
                      /> -->
                      <h3 class="card-title" [translate]="'PAYMENT.PLAN_3.ENTERPRISE'"></h3>
                      <div class="sub-title">
                        <span [translate]="'PAYMENT.PLAN_3.SUB_ENTERPRISE'"></span>
                      </div>
                      <div class="annual-plan">
                        <div class="plan-price mt-2">
                          <div class="count-doc">
                            <div class="plan-price mt-2">
                              <h3
                                class="pricing-basic-value font-weight-bolder text-primary"
                                ></h3
                              >
                            </div>
                            <div>
                              <h3 [translate]="'PAYMENT.PLAN_3.COUNT_ENTERPRISE'"></h3>
                            </div>
                          </div>
                          <br />
                          <div class="quota-price" [translate]="'PAYMENT.PLAN_3.PRICING_ENTERPRISE'"></div>
                        </div>
                      </div>
                      <!-- <button
                        class="btn btn-block btn-outline-primary mt-2 btn-w"
                        (click)="redirectToPagePricing()"
                        rippleEffect
                      >
                        <span translate="PAYMENT.BUY_NOW"></span>
                      </button> -->
                      <ul
                        class="font-italic mt-4 list-feature"
                        
                      >
                      <li class="h-feature-1"><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE1'"></span></li>
                        <li class="h-feature-1"><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE2'"></span></li>
                        <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE3'"></span></li>
                        <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE4'"></span></li>
                        <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE5'"></span></li>
                        <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE6'"></span></li>
                        <li><i class="fa fa-check mr-1" style="color: #219cd7;"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE7'"></span></li>
                        <li><i class="fa fa-check mr-1" style="color: #219cd7;"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE8'"></span></li>
  
    
    
                      </ul>
                      <div class="d-flex justify-content-center">
                        <a
                          class="btn btn-block btn-outline-primary mt-2"
                          href="#"
                          rippleEffect
                        >
                          <span translate="PAYMENT.PLAN_3.COUNT_ENTERPRISE"></span>
                      </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!--/ standard plan -->
              </div>
              <ng-template #FullService>
                <div class="row">
                  <!-- standard plan -->
                  <div class="col-12 col-md-4" id="price-basic">
                    <div class="card card-pricing text-center mb-5 border">
                      <div class="card-body">
                        <!-- <img
                          src="assets/images/folder/payment.png"
                          alt=""
                          class="w-50"
                        /> -->
                        <h3 class="card-title" [translate]="'PAYMENT.PLAN_1.BASIC'"></h3>
                        <div class="sub-title">
                          <span [translate]="'PAYMENT.PLAN_1.SUB_BASIC'"></span>
                        </div>
                        <div class="annual-plan">
                          <div class="plan-price mt-2">
                            <div class="count-doc">
                              <div class="plan-price mt-2">
                                <h3 style="color: #00A5F4;"
                                  class="pricing-basic-value font-weight-bolder"
                                  >2500</h3
                                >
                              </div>
                              <div>
                                <p [translate]="'PAYMENT.PLAN_1.COUNT_BASIC'"></p>
                              </div>
                            </div>
                            <br />
                            <div class="quota-price" [translate]="'PAYMENT.PLAN_1.PRICING_BASIC'"></div>
                          </div>
                        </div>
                        <!-- <button
                          class="btn btn-block btn-outline-primary mt-2 btn-w"
                          (click)="redirectToPagePricing()"
                          rippleEffect
                        >
                          <span translate="PAYMENT.BUY_NOW"></span>
                        </button> -->
                        <ul
                          class="font-italic mt-4 list-feature"
                          
                        >
                          <li class="h-feature-1"><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_1.FEATURE_BASIC1'"></span></li>
                          <li class="h-feature-1"><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_1.FEATURE_BASIC2'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_1.FEATURE_BASIC3'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_1.FEATURE_BASIC4'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_1.FEATURE_BASIC5'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_1.FEATURE_BASIC6'"></span></li>
                          <li><i class="fa fa-times mr-1" style="color: #f34725;"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_1.FEATURE_BASIC7'"></span></li>
                          <li><i class="fa fa-times mr-1" style="color: #f34725;"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_1.FEATURE_BASIC8'"></span></li>
      
      
                        </ul>
                        <div class="d-flex justify-content-center">
                          <button
                            class="btn btn-block btn-outline-primary mt-2"
                            (click)="buyQuota('120K_200TAILIEU')"
                            rippleEffect
                          >
                            <span translate="PAYMENT.BUY_NOW"></span>
                          </button>
                        </div>
      
                      </div>
                    </div>
                  </div>
                  <!--/ standard plan -->
      
                  <!-- standard plan -->
                  <div class="col-12 col-md-4" id="price-standard">
                    <div class="card card-pricing text-center mb-5">
                      <div class="ribbon">
                        <span [translate]="'PAYMENT.PLAN_2.MOST_POPULAR'"></span>
                      </div>
                      <div class="card-body">
                        <!-- <img
                          src="assets/images/folder/payment1.png"
                          alt=""
                          class="w-50"
                        /> -->
                        <h3 class="card-title" [translate]="'PAYMENT.PLAN_2.STANDARD'"></h3>
                        <div class="sub-title">
                          <span [translate]="'PAYMENT.PLAN_2.SUB_STANDARD'"></span>
                        </div>
                        <div class="annual-plan">
                          <div class="plan-price mt-2">
                            <div class="count-doc">
                              <div class="plan-price mt-2">
                                <h3 style="color: #00A5F4;"
                                  class="pricing-basic-value font-weight-bolder"
                                  >5000</h3
                                >
                              </div>
                              <div>
                                <p [translate]="'PAYMENT.PLAN_2.COUNT_STANDARD'"></p>
                              </div>
                            </div>
                            <br />
                            <div class="quota-price" [translate]="'PAYMENT.PLAN_2.PRICING_STANDARD'"></div>
                          </div>
                        </div>
                        <!-- <button
                          class="btn btn-block btn-primary mt-2 btn-w"
                          (click)="redirectToPagePricing()"
                        >
                          <span [translate]="'PAYMENT.BUY_NOW"></span>
                        </button> -->
                        <ul
                          class="font-italic mt-4 list-feature"
                          
                        >
                          <li class="h-feature-1"><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD1'"></span></li>
                          <li class="h-feature-1"><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD2'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD3'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD4'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD5'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD6'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7;"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD7'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7;"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD8'"></span></li>
                          <li><i class="fa fa-times mr-1" style="color: #f34725;"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD9'"></span></li>
                          <li><i class="fa fa-times mr-1" style="color: #f34725;"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_2.FEATURE_STANDARD10'"></span></li>
    
                        </ul>
                        <div class="d-flex justify-content-center">
                          <button
                            class="btn btn-block btn-primary mt-2"
                            (click)="buyQuota('500K_1000TAILIEU')"
                            rippleEffect
                          >
                            <span translate="PAYMENT.BUY_NOW"></span>
                          </button>
                        </div>
  
                      </div>
                    </div>
                  </div>
                  <!--/ standard plan -->
      
                  <!-- standard plan -->
                  <div class="col-12 col-md-4" id="price-enterprise">
                    <div class="card card-pricing text-center mb-5 border">
                      <div class="card-body">
                        <!-- <img
                          src="assets/images/folder/payment2.png"
                          alt=""
                          class="w-50"
                        /> -->
                        <h3 class="card-title" [translate]="'PAYMENT.PLAN_3.ENTERPRISE'"></h3>
                        <div class="sub-title">
                          <span [translate]="'PAYMENT.PLAN_3.SUB_ENTERPRISE'"></span>
                        </div>
                        <div class="annual-plan">
                          <div class="plan-price mt-2">
                            <div class="count-doc">
                              <div class="plan-price mt-2">
                                <h3
                                  class="pricing-basic-value font-weight-bolder text-primary"
                                  ></h3
                                >
                              </div>
                              <div>
                                <h3 [translate]="'PAYMENT.PLAN_3.COUNT_ENTERPRISE'"></h3>
                              </div>
                            </div>
                            <br />
                            <div class="quota-price" [translate]="'PAYMENT.PLAN_3.PRICING_ENTERPRISE'"></div>
                          </div>
                        </div>
                        <!-- <button
                          class="btn btn-block btn-outline-primary mt-2 btn-w"
                          (click)="redirectToPagePricing()"
                          rippleEffect
                        >
                          <span translate="PAYMENT.BUY_NOW"></span>
                        </button> -->
                        <ul
                          class="font-italic mt-4 list-feature"
                          
                        >
                        <li class="h-feature-1"><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE1'"></span></li>
                          <li class="h-feature-1"><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE2'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE3'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE4'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE5'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE6'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7;"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE7'"></span></li>
                          <li><i class="fa fa-check mr-1" style="color: #219cd7;"></i><span class="h-feature" [translate]="'PAYMENT.PLAN_3.FEATURE_ENTERPRISE8'"></span></li>
    
      
      
                        </ul>
                        <div class="d-flex justify-content-center">
                          <a
                            class="btn btn-block btn-outline-primary mt-2"
                            href="#"
                            rippleEffect
                          >
                            <span translate="PAYMENT.PLAN_3.COUNT_ENTERPRISE"></span>
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--/ standard plan -->
                </div>
              </ng-template>
            </div>
          </div>
          <!--/ pricing plan cards -->
        </div>
  
        <br /><br />
        <!-- pricing faq -->
        <div class="pricing-faq">
          <h3 class="text-center">FAQ's</h3>
          <p class="text-center" translate="PAYMENT.FAQ.DESCRIPTION"></p>
          <div class="row my-2">
            <div class="col-12 col-lg-10 col-lg-offset-2 mx-auto">
              <!-- faq collapse -->
              <div class="accordion collapse-margin collapse-icon">
                <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                  <ngb-panel
                    id="deliveryId{{ i }}"
                    [cardClass]="'collapse-margin'"
                    *ngFor="let qandARef of qandA; let i = index"
                  >
                    <ng-template ngbPanelTitle>
                      <span>{{ qandARef.question | translate }}</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <span>{{ qandARef.ans | translate }}</span>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>
            </div>
          </div>
        </div>
        <!--/ pricing faq -->
      </section>
    </div>
  </div>
  