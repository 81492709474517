import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageUserComponent implements OnInit {
  public errorMessagesListUser!:any
  public users: User[] = []
  public currentUser: User;
  public searchText: string = '';
  page = 1;
  pageSize = 5;
  basicSelectedOption = 5
  pageAssignUser = 1;
  pageSizeAssignUser = 5;
  basicSelectedOptionAssignUser = 5
  createUserForm: FormGroup
  public _checkbox!:string

  deleteUserForm = this.fb.group({
    checkUser: ["",Validators.required]
  })
  UpdateUserTalentPoolForm = this.fb.group({
    is_accessible: false
  })
  public editBoolean = false

  passwordTextType: boolean=false;
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _authenticationService: AuthenticationService,
    private toastr: ToastrService,
  ) {
    this.createUserForm = this.fb.group({
      first_name: ["",Validators.required],
      last_name: ["", Validators.required],
      email: ["", Validators.email],
      password: ["", Validators.required],
      checkSearchEngine: false,
      checkParseResume: false,
      is_site_admin: false,
      id: [""]
    })
   }

  Search(){
    // alert(this.searchText)
    if(this.searchText!== ""){
      let searchValue = this.searchText.toLocaleLowerCase();
      
      this.users = this.users.filter((user:any) =>{
        return user.email.toLocaleLowerCase().match(searchValue )
        ;
      // you can keep on adding object properties here

            });

          }
          else{
            this.listUser();
          }
        }
  ngOnInit(): void {
    this.listUser();
    this.currentUser = this._authenticationService.currentUserValue;
  }
  listUser(){
    this._authenticationService.listUsers().subscribe(
      (res)=>{
        this.users = res.results;
        this.users = Object.assign([], this.users);
      },
      (err)=>{
        this.errorMessagesListUser = err
      }
    )
  }
  get f() {
    return this.createUserForm.controls;
  }
  modalOpenCreateUser(modalBasic:any, info: any = null) {
    if(info){
      this.editBoolean = true
      this.createUserForm = this.fb.group({
        first_name: ["",Validators.required],
        last_name: ["", Validators.required],
        email: ["", Validators.email],
        password: [""],
        checkSearchEngine: false,
        checkParseResume: false,
        is_site_admin: false,
        id: [""]
      })
      this.createUserForm.get("first_name").setValue(info.first_name);
      this.createUserForm.get("last_name").setValue(info.last_name);
      this.createUserForm.get("email").setValue(info.email);
      // this.createUserForm.get("password").setValue(info.password);
      this.createUserForm.get("is_site_admin").setValue(info.is_site_admin);
      this.createUserForm.get("checkSearchEngine").setValue(info.checkSearchEngine)
      this.createUserForm.get("checkParseResume").setValue(info.checkParseResume)
      this.createUserForm.get("id").setValue(info.id)

    }
    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  CreateUser(){
    let formData: any = {}
    let permissions: string[]=[]
    if(this.createUserForm.value.checkSearchEngine){
      permissions.push("search_engine")
    }
    if(this.createUserForm.value.checkParseResume){
      permissions.push("parse_cv")
    }
    let first_name= this.createUserForm.value.first_name
    let last_name= this.createUserForm.value.last_name
    let email= this.createUserForm.value.email
    let password= this.createUserForm.value.password
    let is_site_admin= this.createUserForm.value.is_site_admin
    let id = this.createUserForm.get("id").value
    formData["first_name"] = this.createUserForm.value.first_name
    formData["last_name"] = this.createUserForm.value.last_name
    formData["email"] = this.createUserForm.value.email
    formData["permissions"] = permissions
    formData["is_site_admin"] = this.createUserForm.value.is_site_admin

    if(id){
      this._authenticationService.adminUpdateUser(id,first_name, last_name,email,permissions,is_site_admin).subscribe(
        (res)=>{
          this.toastr.success('Edit user success');
          this.createUserForm.reset();
          this.modalService.dismissAll();
          this.listUser();
          
        },
        (err)=>{
          this.toastr.error(err)
          this.createUserForm.reset();
          this.modalService.dismissAll()
        }
      )
    }else{
      this._authenticationService.createUser(formData).subscribe(
        (res)=>{
          this.toastr.success('Create user success');
          this.createUserForm.reset();
          this.modalService.dismissAll();
          this.listUser();
        },
        (err)=>{
          this.toastr.error(err)
          this.createUserForm.reset();
          this.modalService.dismissAll()
        }
      )
    }
  }
  
  modalOpenDeleteUser(modalBasic:any) {
    this.page = 1
    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  onCheckboxChange(e:any) {
    let checkUser: any = this.deleteUserForm.get('checkUser');
    if (e.target.checked) {
      checkUser=new FormControl(e.target.value);
      this._checkbox = e.target.value
      
    } else {
      checkUser = new FormControl()
      this._checkbox = ""
    }
  }
  DeleteUser(id: any){
    this._authenticationService.deleteUser(id, this._checkbox).subscribe(
      (res)=>{
        this.toastr.success('Delete user success');
        this.modalService.dismissAll();
        this.listUser();
      },
      (err)=>{
        this.toastr.error(err);
        this.modalService.dismissAll();
      }
    )
  }
  //Talent pool
  modalOpenUpdatePermissionTalentPool(modalBasic:any) {
    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  UpdateUserPermissionTalentPool(id:any){
    let is_accessible = this.UpdateUserTalentPoolForm.value.is_accessible;
    this._authenticationService.update_user_permission_talent_pool(id, is_accessible).subscribe(
      (res)=>{
        this.toastr.success("Update user permission talent pool success");
        this.modalService.dismissAll();
        this.UpdateUserTalentPoolForm.reset();
        this.listUser();
      },
      (err)=>{
        this.toastr.error(err);
        this.UpdateUserTalentPoolForm.reset();
        this.modalService.dismissAll();
      }
    )
  }
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

}
