<app-header></app-header> 
<div class="container-fluid block3 bg-image" id="about-us">
    <div
      class="container mb-5"
      style="text-align: center"
    >
      <h1 [translate]="'ABOUT_US.TITLE'"></h1>
      
                

                <div class="mt-4 mt-md-5 text-center">
                    <p [translate]="'ABOUT_US.C_HR'"></p>
                    <p [translate]="'ABOUT_US.CMC_ATI'"></p>
                    <p [translate]="'ABOUT_US.CORE_VALUE'"></p>
                </div>
                <div class="mt-5">
                    <h2 class="text-center" [translate]="'ABOUT_US.VALUE'"></h2>
                    <p class=" text-center" [translate]="'ABOUT_US.DES_VALUE'"></p>
            
                    <div class="d-flex justify-content-center mt-4 mt-md-5">
                        <div class="col-lg-4 col-xl-3">
                            <!-- <div class="text-center">
                                <div>
                                    <span>100
                                        +</span>
                                </div>
                                <div>
                                    <span>Chuyên gia và kỹ sư công nghệ hàng đầu Việt Nam</span>
                                </div>
                            </div> -->
                            <div class=" text-center ">
                                <div class="">
                                    <img class="card-img-top" src="assets/images/landing-page/community.svg" alt="Candidate">
                                </div>
                                <div class="">Community</div>
                                <div class=" ">We realize that no great work is done alone. Every one of us has a unique value and, when put in the right position, will maximize our potential. We believe that is the foundation to achieve greatness.</div>
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-xl-3">
                            <div class=" text-center ">
                                <div class="">
                                    <img class="card-img-top" src="assets/images/landing-page/understand.svg" alt="Employer">
                                </div>
                                <div class="">Understanding</div>
                                <div class=" ">No connection is at its fullest without understanding. We put no one as we strive to listen and deeply understand each of our connection to build a strong bond in the community of C-HR.</div>
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-xl-3">
                            <div class=" text-center ">
                                <div class="">
                                    <img class="card-img-top" src="assets/images/landing-page/growth.svg" alt="Press">
                                </div>
                                <div class="">Growth</div>
                                <div class=" ">Our company can only grow when each of its connections grows. Therefore, we deeply understand each of our connection to give the best possible platform for growth, subsequently, to achieve greatness as a whole.</div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pricing-faq mt-5">
                    <h3 class="text-center" [translate]="'ABOUT_US.FAQ.TITLE'">Frequently Asked Questions</h3>
                    
                    <div class="row my-2">
                      <div class="col-12 col-lg-10 col-lg-offset-2 mx-auto">
                        <!-- faq collapse -->
                        <div class="accordion collapse-margin collapse-icon">
                          <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                            <ngb-panel                
                              [cardClass]="'collapse-margin'" 
                            >
                              <ng-template ngbPanelTitle>
                                <span [translate]="'ABOUT_US.FAQ.QUES1'"></span>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <span [translate]="'ABOUT_US.FAQ.ANS1'"></span>
                              </ng-template>
                              
                            </ngb-panel>
                            <ngb-panel                
                              [cardClass]="'collapse-margin'" 
                            >
                              
                              <ng-template ngbPanelTitle>
                                <span [translate]="'ABOUT_US.FAQ.QUES2'"></span>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <span [translate]="'ABOUT_US.FAQ.ANS2'"></span>
                              </ng-template>
                              
                            </ngb-panel>
                            <ngb-panel                
                              [cardClass]="'collapse-margin'" 
                            >
                              
                              <ng-template ngbPanelTitle>
                                <span [translate]="'ABOUT_US.FAQ.QUES3'"></span>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <span [translate]="'ABOUT_US.FAQ.ANS3'"></span>
                              </ng-template>
                              
                            </ngb-panel>
                            <ngb-panel                
                              [cardClass]="'collapse-margin'" 
                            >
                              
                              <ng-template ngbPanelTitle>
                                <span [translate]="'ABOUT_US.FAQ.QUES4'"></span>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <span [translate]="'ABOUT_US.FAQ.ANS4'"></span> 
                              </ng-template>
                              
                            </ngb-panel>
                            <ngb-panel                
                              [cardClass]="'collapse-margin'" 
                            >
                             
                              <ng-template ngbPanelTitle>
                                <span [translate]="'ABOUT_US.FAQ.QUES5'"></span>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <span [translate]="'ABOUT_US.FAQ.ANS5'"></span>
                              </ng-template>
                            </ngb-panel>
                            
                            
                          </ngb-accordion>
                        </div>
                      </div>
                    </div>
                  </div>
    </div>
    
  </div>
  <app-footer-landing></app-footer-landing>