<form ngNativeValidate #NewCvTaskForm="ngForm">
<div class="row" >

    <div class="col-md-7">
      <div class="content-wrapper container-xxxl p-0 card ">
        <div class="content-body card-body">
          <h3 [translate]="'TALENT_POOL.CREATE_JOB.BASIC_INFO'"></h3>
          <div class="mb-2">
            <label for="job_title" class="form-label"><span [translate]="'TALENT_POOL.SEARCH_RESUME.JOB_TITLE'"></span><b style="color:red">*</b></label>
            <input
                [(ngModel)]="job_title"
                [class.error]="!JobTitleRef.valid && JobTitleRef.touched"
                #JobTitleRef="ngModel"
                type="text"
                class="form-control"
                id="job_title"
                name="job_title"
                [placeholder]="'TALENT_POOL.SEARCH_RESUME.JOB_TITLE'|translate"
                
                aria-label="Search..."
                aria-describedby="job_title"
                required
            />
            <span *ngIf="!JobTitleRef.valid && JobTitleRef.touched" class="invalid-form">
              <small class="form-text text-danger" *ngIf="JobTitleRef?.errors?.['required']" [translate]="'TALENT_POOL.CREATE_JOB.NEW_JOB'"
                >ThisFieldIsRequired</small
              >
            </span>

        </div>
          <div class="mb-2">
            <label for="job_description" class="form-label"><span [translate]="'TALENT_POOL.CREATE_JOB.JOB_DESCRIPTION'"></span><b style="color:red">*</b></label>
                        
              <quill-editor [required]="true" [styles]="{height: '250px'}" [(ngModel)]="job_description" [modules]="{ syntax: false, toolbar: [['code-block']] }" name="job_description" [placeholder]="'TALENT_POOL.CREATE_JOB.JOB_DESCRIPTION'|translate" [class.error]="!JobDescriptionRef.valid && JobDescriptionRef.touched" #JobDescriptionRef="ngModel">
                <div quill-editor-toolbar>
                  <span class="ql-formats">
                    <button class="ql-bold" [title]="'Bold'"></button>
                    <button class="ql-italic" [title]="'italic'"></button>
                    <button class="ql-underline" [title]="'underline'"></button>
                  </span>
                  <span class="ql-formats">
                    <select class="ql-align" [title]="'Alignment'">
                      <option selected></option>
                      <option value="center"></option>
                      <option value="right"></option>
                      <option value="justify"></option>
                    </select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-list" value="ordered" type="button"></button>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-link" value="ordered" type="button"></button>
                    <button class="ql-image" value="ordered" type="button"></button>
                    <button class="ql-video" value="ordered" type="button"></button>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-formula" value="ordered" type="button"></button>
                    <button class="ql-code-block" value="ordered" type="button"></button>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-clean" value="ordered" type="button"></button>
                  </span>
                </div>
              </quill-editor>
              <span *ngIf="!JobDescriptionRef.valid && JobDescriptionRef.touched" class="invalid-form">
                <small class="form-text text-danger" *ngIf="JobDescriptionRef?.errors?.['required']"
                [translate]="'TALENT_POOL.CREATE_JOB.REQUIRED'">ThisFieldIsRequired</small
                >
              </span>



          </div>
          <div class="mb-2">
            <label for="job-require" class="form-label"><span [translate]="'TALENT_POOL.CREATE_JOB.JOB_REQUIREMENT'"></span><b style="color:red">*</b></label>

            <quill-editor [required]="true" [styles]="{height: '250px'}" [(ngModel)]="requirements" [modules]="{ syntax: false, toolbar: [['code-block']] }" name="requirements" [placeholder]="'TALENT_POOL.CREATE_JOB.JOB_REQUIREMENT'|translate" [class.error]="!JobRequirementsRef.valid && JobRequirementsRef.touched" #JobRequirementsRef="ngModel">
              <div quill-editor-toolbar>
                <span class="ql-formats">
                  <button class="ql-bold" [title]="'Bold'"></button>
                  <button class="ql-italic" [title]="'italic'"></button>
                  <button class="ql-underline" [title]="'underline'"></button>
                </span>
                <span class="ql-formats">
                  <select class="ql-align" [title]="'Alignment'">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-list" value="ordered" type="button"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-link" value="ordered" type="button"></button>
                  <button class="ql-image" value="ordered" type="button"></button>
                  <button class="ql-video" value="ordered" type="button"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-formula" value="ordered" type="button"></button>
                  <button class="ql-code-block" value="ordered" type="button"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-clean" value="ordered" type="button"></button>
                </span>
              </div>
            </quill-editor>
            <span *ngIf="!JobRequirementsRef.valid && JobRequirementsRef.touched" class="invalid-form">
              <small class="form-text text-danger" *ngIf="JobRequirementsRef?.errors?.['required']"
              [translate]="'TALENT_POOL.CREATE_JOB.REQUIRED'"></small
              >
            </span>
        </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="content-wrapper container-xxxl p-0 card ">
        <div class="content-body card-body">
          <div class="">
            <button [disabled]="!NewCvTaskForm.form.valid" type="submit" class="btn btn-primary" (click)="onSubmit()" rippleEffect [translate]="'TALENT_POOL.CREATE_JOB.SUBMIT_ADD_JOB'"></button>
          </div>
          <div *ngIf="errorMessagesCreateNewJd"><span style="color: red;">Error: </span>{{errorMessagesCreateNewJd}}</div>

        </div>
      </div>
      <div class="content-wrapper container-xxxl p-0 card ">
        <div class="content-body card-body">
          <h3 [translate]="'TALENT_POOL.CREATE_JOB.BASIC_REQUIRED'"></h3>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-2">
                  <label for="location" class="form-label" [translate]="'TALENT_POOL.CREATE_JOB.LOCATION'"></label>
                  <ng-select [items]="location"
                    bindLabel="name"
                    bindValue="value"
                    [placeholder]="'TALENT_POOL.CREATE_JOB.LOCATION'|translate"
                    
                    name="selectedLocation"
                    [(ngModel)]="selectedLocation">
    
                </ng-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-2">
                  <label for="gender" class="form-label" [translate]="'TALENT_POOL.CREATE_JOB.GENDER'"></label>
                  <ng-select [items]="gender"
                      bindLabel="name"
                      bindValue="value"
                      [placeholder]="'TALENT_POOL.CREATE_JOB.GENDER'|translate"
                      
                      name="selectedGender"
                      [(ngModel)]="selectedGender">
                  </ng-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                  <label for="sliderYob" class="form-label" [translate]="'TALENT_POOL.CREATE_JOB.YOB'"></label>
                  <div class="d-flex align-items-center">
                    <div>
                      <input id="yob_from" name="yob_from" [(ngModel)]="yob_from" [placeholder]="'TALENT_POOL.CREATE_JOB.PLACEHOLDER.YOB_FROM'|translate"
                            type="text" class="form-control"
                            >
                    </div>
                            <div>-</div>
                            <div>
                              <input id="yob_to" name="yob_to" [(ngModel)]="yob_to" [placeholder]="'TALENT_POOL.CREATE_JOB.PLACEHOLDER.YOB_TO'|translate"
                            type="text" class="form-control"
                            >
                            </div>
                  </div>
                  
              </div>
            </div>
          </div>
          
          <h3 [translate]="'TALENT_POOL.CREATE_JOB.REQUIRES_EXPERTISE'"></h3>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-2">
                  <label for="job_level" class="form-label" [translate]="'TALENT_POOL.CREATE_JOB.JOB_LEVEL'"></label>
                  <ng-select [items]="job_level"
                      bindLabel="name"
                      bindValue="value"
                      [placeholder]="'TALENT_POOL.CREATE_JOB.JOB_LEVEL'|translate"
                      
                      name="selectedRank"
                      [(ngModel)]="selectedRank">
                  </ng-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-2">
                  <label for="industry" class="form-label" [translate]="'TALENT_POOL.CREATE_JOB.INDUSTRY'"></label>
                  <ng-select [items]="industry"
                      bindLabel="name"
                      bindValue="value"
                      [placeholder]="'TALENT_POOL.CREATE_JOB.PLACEHOLDER_INDUSTRY'|translate"
                      
                      name="selectedBusiness"
                      [(ngModel)]="selectedBusiness"
                      [multiple]="true">
                  </ng-select>
              </div>
          </div>
          <div class="col-md-6">
            <div class="mb-2">
                <label for="job_type" class="form-label" [translate]="'TALENT_POOL.CREATE_JOB.JOB_TYPE'"></label>
                <ng-select [items]="job_type"
                    bindLabel="name"
                    bindValue="value"
                    [placeholder]="'TALENT_POOL.CREATE_JOB.JOB_TYPE'|translate"
                    
                    name="selectedTypeWork"
                    [(ngModel)]="selectedTypeWork">
                </ng-select>
            </div>
        </div>
        <div class="col-md-6">
          <div class="mb-2">
              <label for="sliderYearsOfExperience" class="form-label" [translate]="'TALENT_POOL.CREATE_JOB.YEARS_OF_EXPERIENCE'"></label>
              <div class="d-flex align-items-center">
                <div>
                  <input id="yearOfExperienceFrom" name="yearOfExperienceFrom" [(ngModel)]="yearOfExperienceFrom" [placeholder]="'TALENT_POOL.CREATE_JOB.PLACEHOLDER.YEAR_OF_EXPERIENCE_FROM'|translate"
                        type="text" class="form-control"
                        >
                </div>
                        <div>-</div>
                        <div>
                          <input id="yearOfExperienceTo" name="yearOfExperienceTo" [(ngModel)]="yearOfExperienceTo" [placeholder]="'TALENT_POOL.CREATE_JOB.PLACEHOLDER.YEAR_OF_EXPERIENCE_TO'|translate"
                        type="text" class="form-control"
                        >
                        </div>
              </div>
              
          </div>
      </div>
          </div>
        
        

        </div>
      </div>
    </div>
  
</div>
</form>