<app-header></app-header> 

<div class="content-wrapper container-xxxl block-resume">
    <div class="content-body">
        <div class="row">
              <div class="col-md-6">
                <div class="position-fixed" style="width: 44%;">
                    <iframe *ngIf="pdf_content" [cachedSrc]="url" style="width: 100%;height: 80vh;border: none;margin-top: 10px;margin-bottom: 10px; "></iframe>
                </div>
                
                <!-- <object id="pdf" [data]="sanitizer.bypassSecurityTrustResourceUrl(url)" type="application/pdf"></object> -->
                
                    <!-- <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,'+pdf_content)" style="width: 100%;height: 50rem;border: none;margin-top: 10px;margin-bottom: 10px; "></iframe> -->
                    
            </div>
            
            <div class="col-md-6">
                <div *ngIf="tag_detail">
                    
                        <div class="" style="padding: 10px;">
                            <div class="d-flex justify-content-start align-items-center">
                                <h1 [translate]="'RESUME_PARSER.DETAIL_RESUME.CANDIDATE_INFORMATION'"></h1>
                                
                               <span class="badge bg-{{status_color[resume_parser.status]}}" data-toggle="tooltip" data-placement="bottom" title="{{resume_parser.sf4c_status}}" style="font-size: 15px;margin: 15px;">{{resume_parser.status}}</span>
                                
                            </div>
                            
                            
                            
                            <div id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" [translate]="'RESUME_PARSER.DETAIL_RESUME.META_DATA'">
                                        
                                        </button>
                                    </h5>
                                    </div>
                                
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.FIRST_NAME'"></span>: {{resume_parser.first_name}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.LAST_NAME'"></span>: {{resume_parser.last_name}}</p></div>
    
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.DOB'"></span>: {{resume_parser.date_of_birth|date:'longDate'}}</p></div>
    
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.GENDER'"></span>: {{resume_parser.gender}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.ADDRESS'"></span>: {{resume_parser.address}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.EMAIL'"></span>: {{resume_parser.email}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'"></span>: {{resume_parser.phone_number}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY'"></span>: {{resume_parser.category}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXPERIENCE'"></span>: {{resume_parser.year_exp}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'"></span>: {{resume_parser.current_title}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.CURRENT_COMPANY'"></span>: {{resume_parser.current_company}}</p></div>
                                        <div *ngIf="resume_parser.skills.length>0">
                                            <p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.SKILLS'"></span><span>:</span></p>
                                            <div *ngFor="let skill of resume_parser.skills" class="list-inline" style="display: inline-block">
                                                <span class="badge bg-primary" style="margin-left: 2px;margin-top: 2px;">{{skill}}</span>
                                            </div>
                                        </div>
    
                                        <br>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.CONF_SCORE'"></span><span>: </span><span class="badge" [ngClass]="{
                                            'badge-success':resume_parser.conf_score>range_conf.success,
                                            'badge-warning':resume_parser.conf_score>range_conf.warning && resume_parser.conf_score < range_conf.success,
                                            'badge-danger':resume_parser.conf_score<range_conf.warning
                                        }">{{resume_parser.conf_score*100|number:'1.2-2'}}%</span></p></div>

                                    </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" [translate]="'RESUME_PARSER.DETAIL_RESUME.WORK_EXPERIENCE'">
                                        
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <div class="" *ngFor="let experience of resume_parser.experience">
                                            <div class="main" [ngClass]="{'border border-success':experience.conf_score>range_conf.success,'border border-warning':
                                                experience.conf_score>range_conf.warning && experience.conf_score < range_conf.success, 'border border-danger':
                                                experience.conf_score<range_conf.warning}" style="border-style: dashed !important;">
                                                <div class=""></div>
                                                <div class="ms-3">
                                                    <div><span class="text-dark" data-feather="calendar"></span><span style="width:fit-content"> {{experience.start_time|date:'longDate'}} - {{experience.end_time|date:'longDate'}}</span></div>
                                                    <div><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.POSITION'"></span><span>: {{experience.position}}</span></div>
                                                    <div><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.COMPANY'"></span>: {{experience.company}}</div>
                                                    <div><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.INDUSTRY'"></span>: {{experience.industry}}</div>
    
                                                    <div class="ml-1" *ngIf="experience.detail!=null"  [innerHTML]="experience.detail.trim().split('\n').join('<br>')"></div>
                                                    
                                                </div>
                                                <div class="sub" [ngClass]="{'bg-success':experience.conf_score>range_conf.success,'bg-warning':
                                                    experience.conf_score>range_conf.warning && experience.conf_score < range_conf.success, 'bg-danger':
                                                    experience.conf_score<range_conf.warning}">
                                                    <div class="">{{experience.conf_score*100|number:'1.2-2'}}%</div>
                                                    
                                                </div>
                                            </div>

                                            
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" [translate]="'RESUME_PARSER.DETAIL_RESUME.EDUCATION'">
                                        
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="" *ngFor="let education of resume_parser.education">
                                                <div class="main" [ngClass]="{'border border-success':education.conf_score>range_conf.success,'border border-warning':
                                                    education.conf_score>range_conf.warning && education.conf_score < range_conf.success, 'border border-danger':
                                                    education.conf_score<range_conf.warning}" style="border-style: dashed !important;">
                                                    <div class=""></div>
                                                    <div class="ms-3">
                                                        <div><span class="text-dark" data-feather="calendar"></span><span style="width:fit-content"> {{education.start_time|date:'longDate'}} - {{education.end_time|date:'longDate'}}</span></div>
                                                        <div><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.MAJOR'"></span>: {{education.major}}</div>
                                                        <div><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.SCHOOL'"></span>: {{education.school}}</div>
                                                        <div *ngIf="education.diploma!=null && education.diploma!=''"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.DIPLOMA'"></span>: {{education.diploma}}</div>
                                                        <div *ngIf="education.gpa!=null && education.gpa!=''"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.GPA'"></span>: {{education.gpa}}</div>
                                                        <div *ngIf="education.picklist_major!=null && education.picklist_major!=''"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.PICKLIST_MAJOR'"></span>: {{education.picklist_major}}</div>
    
                                                        
                                                    </div>
                                                    <div class="sub" [ngClass]="{'bg-success':education.conf_score>range_conf.success,'bg-warning':
                                                        education.conf_score>range_conf.warning && education.conf_score < range_conf.success, 'bg-danger':
                                                        education.conf_score<range_conf.warning}">
                                                        <div class="">{{education.conf_score*100|number:'1.2-2'}}%</div>
                                                        
                                                    </div>
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="card" *ngIf="tag_resultConnectSf4c_exit">
                                   
                                    <div class="card-header" id="headingFour">
                                        <h5 class="mb-0">
                                            
                                            <button (click)="Matching_jr()" class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" [translate]="'RESUME_PARSER.DETAIL_RESUME.JOB_REQ_MATCHING'">
                                            
                                            </button>
                                        </h5>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <div
                                            *ngIf="loading_match"
                                            id="loading"
                                            style="margin: 50px auto"
                                        >
                                            <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <!-- <app-loading *ngIf="loading_match"></app-loading> -->
                                    </div>
                                    
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample" >
                                        <div class="card-body">
                                            <div *ngIf="matching_jr.match_result!=null">
                                                <form>
                                                    
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        
                                                        
                                                        <div class="col-md-2" [translate]="'RESUME_PARSER.DETAIL_RESUME.SELECT_JR'">:</div>
                                                        
                                                        <div class="col-md-7">
                                                            
                                                            <ng-select id="JR" [items]="items_jr"
                                                            bindLabel="jobTitle"
                                                            bindValue="jobReqId"
                                                            placeholder=". Select Job Requisition-"
                                                            name="selectedJr"
                                                            [(ngModel)]="selectedJr"
                                                            
                                                            >
                                                            
                                                            </ng-select>
                                                        </div>
                                                        <div class="col-md-3"><button type="submit" class="btn btn-primary rounded-pill btn-sm" style="margin-left: 10px;font-size:12px;padding: 0.5rem 0.5rem;"  (click)="Post_matching_jr()"rippleEffect [translate]="'RESUME_PARSER.DETAIL_RESUME.ANALYSIS'"></button></div>
                                                        
                                                    </div>
                                                   
                                                </form>
                                                <hr>
                                                <p *ngIf="errorMessagesMatchingJr!=null; else NoError" [translate]="'RESUME_PARSER.DETAIL_RESUME.NO_MATCHING'"></p>
                                                <ng-template #NoError>
                                                    <p *ngIf="loading_match;else Result" style="margin-top: 30px;" [translate]="'RESUME_PARSER.DETAIL_RESUME.SEARCHING'"></p>
                                                    
                                                    <ng-template #Result>
                                                        <div *ngIf="matching_jr.match_result!=null">
                                                            <div class="d-flex align-items-center">
                                                                <div class="col-md-6">
                                                                    <div class="d-flex justify-content-start align-items-center" *ngIf="matching_jr.match_result.overall_score">
                                                                        <div class="col-md-6"><span [translate]="'RESUME_PARSER.DETAIL_RESUME.MATCHING_POINT'"></span><span>: </span> </div>
                                                                        <div class="col-md-6">
                                                                            <mwl-gauge
                                                                    
                                                                                [max]="100"
                                                                                [dialStartAngle]="-90"
                                                                                [dialEndAngle]="-90.001"
                                                                                [value]="matching_jr.match_result.overall_score*100"
                                                                                [animated]="true"
                                                                                [animationDuration]="1"
                                                                                [color]="gaugeColor"
                                                                                
                                                                            >
                                                                            </mwl-gauge>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="d-flex justify-content-start align-items-center" *ngIf="matching_jr.match_result.experience">
                                                                        <div class="col-md-6"><span [translate]="'RESUME_PARSER.DETAIL_RESUME.EXPERIENCE'"></span><span>: </span> </div>
                                                                        <div class="progress-wrapper col-md-6">
                                                                            <ngb-progressbar
                                                                                showValue="true"
                                                                                
                                                                                [type]="{
                                                                                    'success':matching_jr.match_result.experience.score>0.8,
                                                                                    'warning':matching_jr.match_result.experience.score<0.8 && matching_jr.match_result.experience.score>0.5,
                                                                                    'danger':matching_jr.match_result.experience.score<0.5
                                                                                }"
                                                                                [ngClass]="{
                                                                                    'progress-bar-success':matching_jr.match_result.experience.score>0.8,
                                                                                    'progress-bar-warning':matching_jr.match_result.experience.score<0.8 && matching_jr.match_result.experience.score>0.5,
                                                                                    'progress-bar-danger':matching_jr.match_result.experience.score<0.5
                                                                                }"
                                                                                [height]="progressbarHeight"
                                                                                [value]="matching_jr.match_result.experience.score*100"
                                                                            ></ngb-progressbar>
                                                                
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div class="d-flex justify-content-start align-items-center" *ngIf="matching_jr.match_result.education">
                                                                        <div class="col-md-6"><span [translate]="'RESUME_PARSER.DETAIL_RESUME.EDUCATION_'"></span><span>: </span> </div>
                                                                        <div class="progress-wrapper col-md-6">
                                                                            <ngb-progressbar
                                                                                showValue="true"
                                                                                
                                                                                [type]="{
                                                                                    'success':matching_jr.match_result.education.score>0.8,
                                                                                    'warning':matching_jr.match_result.education.score<0.8 && matching_jr.match_result.education.score>0.5,
                                                                                    'danger':matching_jr.match_result.education.score<0.5
                                                                                }"
                                                                                [ngClass]="{
                                                                                    'progress-bar-success':matching_jr.match_result.education.score>0.8,
                                                                                    'progress-bar-warning':matching_jr.match_result.education.score<0.8 && matching_jr.match_result.education.score>0.5,
                                                                                    'progress-bar-danger':matching_jr.match_result.education.score<0.5
                                                                                }"
                                                                                [height]="progressbarHeight"
                                                                                [value]="matching_jr.match_result.education.score*100"
                                                                            ></ngb-progressbar>
                                                                
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div class="d-flex justify-content-start align-items-center" *ngIf="matching_jr.match_result.language">
                                                                        <div class="col-md-6"><span [translate]="'RESUME_PARSER.DETAIL_RESUME.FOREIGN_LANGUAGE'"></span><span>: </span> </div>
                                                                        <div class="progress-wrapper col-md-6">
                                                                            <ngb-progressbar
                                                                                showValue="true"
                                                                                
                                                                                [type]="{
                                                                                    'success':matching_jr.match_result.language.score>0.8,
                                                                                    'warning':matching_jr.match_result.language.score<0.8 && matching_jr.match_result.language.score>0.5,
                                                                                    'danger':matching_jr.match_result.language.score<0.5
                                                                                }"
                                                                                [ngClass]="{
                                                                                    'progress-bar-success':matching_jr.match_result.language.score>0.8,
                                                                                    'progress-bar-warning':matching_jr.match_result.language.score<0.8 && matching_jr.match_result.language.score>0.5,
                                                                                    'progress-bar-danger':matching_jr.match_result.language.score<0.5
                                                                                }"
                                                                                [height]="progressbarHeight"
                                                                                [value]="matching_jr.match_result.language.score*100"
                                                                            ></ngb-progressbar>
                                                                
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                            <div *ngIf="matching_jr.match_result.skill.matched.length>0">
                                                                <p><span [translate]="'RESUME_PARSER.DETAIL_RESUME.CANDIATE_SKILL'"></span><span>: </span></p>
                                                                <div *ngFor="let skill of matching_jr.match_result.skill.matched" class="list-inline" style="display: inline-block">
                                                                    <span class="badge bg-warning" style="margin-left: 2px;">{{skill.value}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    
                                                    </ng-template>
                                                </ng-template>
                                            </div>    
                                              
                                                
                                                
                                        </div>
                    
                                    </div>
                                </div>
                            </div>
                       
                        <div class="d-flex mt-1 card">
                            <div class="m-2">
                                <button  *ngIf="(role=='Site Admin' || role=='Admin')&&!display_decensor" (click)="CensorResume()" class="btn rounded-pill btn-warning"rippleEffect><span class="fa fa-lock mr-1"></span> <span [translate]="'RESUME_PARSER.DETAIL_RESUME.CENSOR'"></span></button>
                                <button  *ngIf="(role=='Site Admin' || role=='Admin')&&display_decensor" (click)="DeCensorResume()" style="background-color: #E4572E;" class="btn rounded-pill text-light"rippleEffect><span class="fa fa-unlock mr-1"></span> <span [translate]="'RESUME_PARSER.DETAIL_RESUME.DECENSOR'"></span></button>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                           
            </div>
        </div>
    </div>
</div>
<!-- <app-footer-landing></app-footer-landing> -->

