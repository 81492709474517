<div class="content-wrapper container-xxxl p-0 card">
    <div class="content-body card-body">
        <div class="row" style="min-height: 640px;">
            <div class="col-md-4">
                <div class="position-fixed" style="width: 25%;">

                    <h1 [translate]="'TALENT_POOL.RECOMMEND_RESUME.RECOMMENDED_RESUMES'"></h1>
                    <span [translate]="'TALENT_POOL.RECOMMEND_RESUME.FOR_THE_JOB_OF'"></span> <a routerLink="/manage-job/edit-job/{{jd_id}}" target="_blank"> {{jobTitle}}</a>
                    <p> <a href="/search-resume/saved-cvs/{{jd_id}}?hcm_system_id={{hcm_system_id}}" target="_blank"> {{items}} <span [translate]="'TALENT_POOL.SEARCH_RESUME.RESUMES'"></span></a>&nbsp; <span [translate]="'TALENT_POOL.RECOMMEND_RESUME.SAVED'"></span></p>
                    <div>
                        <form (ngSubmit)="(SearchCv.form.valid)" #SearchCv="ngForm">
                            <!-- <div class="form-group">
                                <div class=" col-form-label">
                                    <label for="job_title"><b>JobTitle</b></label>
                                </div>
                                <div>
                                    <input
                                    [(ngModel)]="job_title"
        
                                    type="text"
                                    class="form-control"
                                    id="job_title"
                                    name="job_title"
                                    placeholder="Enter job title"
                                />
                                </div>
                            </div> -->
                            <div class="form-group">
                                <div class=" col-form-label">
                                    <label for="fulltext_bool_expression"><b [translate]="'TALENT_POOL.SEARCH_RESUME.FULLTEXT_FILTER'"></b></label>
                                </div>
                                <div>
                                    <input [(ngModel)]="fulltext_bool_expression" type="text" class="form-control"
                                        id="fulltext_bool_expression" name="fulltext_bool_expression"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate" />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class=" col-form-label">
                                    <label for="experience_bool_expression"><b [translate]="'TALENT_POOL.SEARCH_RESUME.EXPERIENCE_FILTER'"></b></label>
                                </div>
                                <div>
                                    <input [(ngModel)]="experience_bool_expression" type="text" class="form-control"
                                        id="experience_bool_expression" name="experience_bool_expression"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class=" col-form-label">
                                    <label for="education_bool_expression"><b [translate]="'TALENT_POOL.SEARCH_RESUME.EDUCATION_FILTER'"></b></label>
                                </div>
                                <div>
                                    <input [(ngModel)]="education_bool_expression" type="text" class="form-control"
                                        id="education_bool_expression" name="education_bool_expression"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate" style="width: 100%;" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class=" col-form-label">
                                    <label for="current_title_bool_expression"><b [translate]="'TALENT_POOL.SEARCH_RESUME.CURRENT_TITLE_FILTER'"></b></label>
                                </div>
                                <div>
                                    <input [(ngModel)]="current_title_bool_expression" type="text" class="form-control"
                                        id="current_title_bool_expression" name="current_title_bool_expression"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate" style="width: 100%;" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class=" col-form-label">
                                    <label for="current_company_bool_expression"><b [translate]="'TALENT_POOL.SEARCH_RESUME.CURRENT_COMPANY_FILTER'"></b></label>
                                </div>
                                <div>
                                    <input [(ngModel)]="current_company_bool_expression" type="text"
                                        class="form-control" id="current_company_bool_expression"
                                        name="current_company_bool_expression"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate" style="width: 100%;" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class=" col-form-label">
                                    <label for="selectedCity"><b [translate]="'TALENT_POOL.SEARCH_RESUME.ADDRESS'"></b></label>
                                </div>
                                <div>
                                    <ng-select [items]="city" bindLabel="name" bindValue="value"
                                    [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER_ADDRESS'|translate" name="selectedCity"
                                        [(ngModel)]="selectedCity" [multiple]="true">

                                    </ng-select>
                                </div>
                            </div>
                            <div class="mt-2">
                                <button style="height: 38px;padding:6px" type="submit" (click)="onSubmit()"
                                    class="btn btn-primary" rippleEffect> <span [translate]="'TALENT_POOL.SEARCH_RESUME.SEARCH'"></span> <i class="fa fa-search"
                                        aria-hidden="true"></i></button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-8">


               
                    <div *ngIf="loading;else noLoading" id="loading">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        
                      </div>
                    
                    <ng-template #noLoading>
                        <div class=" mb-2"><span [translate]="'TALENT_POOL.SEARCH_RESUME.FOUND'"></span> <span> {{num_result}} </span> <span [translate]="'TALENT_POOL.SEARCH_RESUME.RESUMES'"></span></div>
                        <div class="mb-2"
                        *ngFor="let resultRecommendCvs_ of resultRecommendCvs| paginate: { itemsPerPage: pageSizeNum, currentPage: page_num, totalItems: num_page }; let i=index">
                            <div class="col-12">
                                <div class="card card-employee-task" style="border: 1px solid #dedede;">
                                    <div class="card-body">
                                        <app-resume-talent-pool (newItemEvent)="addItem($event)" [ResultSearchCv]="resultRecommendCvs_" [jd_id]="jd_id"></app-resume-talent-pool>
    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4 mt-lg-5 justify-content-between align-items-center">
                            <div class="col-auto">
                                <pagination-controls (pageChange)="pageChangeEventGetCv($event)"></pagination-controls>
                            </div>
                        </div>
                    </ng-template>

                    
                
                

            </div>

        </div>
    </div>
</div>