<div class="content-wrapper container-xxxl p-0">
    <div>

        <div class=" align-items-center">
            
                <div class="">
                    <form (ngSubmit)="onSubmit()" [formGroup]="FilterStatForm">

                        <div class="row">
                            <div class="col-md-4 d-flex align-items-center" *ngIf="is_superuser">
                                <select id="company" class="form-select" (change)="changeCompany($event)"
                                    formControlName="company">
                                    <option value="" [translate]="'DASHBOARD.ALL_COMPANY'"></option>
                                    <option *ngFor="let company of companys" [value]="company.id">
                                        {{ company.name }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-md-4 d-flex align-items-center">
                                <input id="period" formControlName="period" [placeholder]="'DASHBOARD.CHOOSE_PERIOD'|translate" bsDaterangepicker
                                    type="text" class="form-control"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" />

                            </div>
                            <div class="col-md-4">


                                <button type="submit" class="btn btn-primary" rippleEffect [translate]="'DASHBOARD.FILTER'"></button>
                                <button type="button" (click)="onReset()" class="btn btn-secondary m-2"
                                     rippleEffect [translate]="'DASHBOARD.RESET'"></button>
                            </div>
                        </div>

                    </form>
                </div>

        </div>
        <div *ngIf="isLoading;else noLoading" id="loading" style="margin: 50px auto">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <ng-template #noLoading>
            
            <div class="row align-items-center">
    
                <div class="col-xl-6 col-12">
                    <div class="card card-statistics">
                        <div class="card-header flex-column align-items-start">
                            <h4 class="card-title mb-75" [translate]="'DASHBOARD.RESUME_STATUS_SF4C'"></h4>
                            <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
                        </div>
                        <div class="card-body">
                            <div id="column-chart" #statusUploadSuccessFactorsChartRef>
                                <apx-chart
                                  [series]="statusUploadSuccessFactorsChartoptions.series"
                                  [chart]="{
                                    type: 'bar',
                                    height: 400,
                                    stacked: false,
                                    toolbar: {
                                      show: false
                                    }
                                  }"
                                  [xaxis]="statusUploadSuccessFactorsChartoptions.xaxis"
                                  [stroke]="statusUploadSuccessFactorsChartoptions.stroke"
                                  [tooltip]="statusUploadSuccessFactorsChartoptions.tooltip"
                                  [colors]="statusUploadSuccessFactorsChartoptions.colors"
                                  [legend]="statusUploadSuccessFactorsChartoptions.legend"
                                  [dataLabels]="statusUploadSuccessFactorsChartoptions.dataLabels"
                                  [plotOptions]="statusUploadSuccessFactorsChartoptions.plotOptions"
                                  [grid]="statusUploadSuccessFactorsChartoptions.grid"
                                ></apx-chart>
                              </div>
    
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12">
                    <div class="card card-statistics">
                      <div class="card-header flex-column align-items-start">
                        <h4 class="card-title mb-75" [translate]="'DASHBOARD.RESUME_STATUS_SF4C_DAY'"></h4>
                        <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
                      </div>
                      <div class="card-body">
                        <div id="line-chart" #resumeStatusUploadedSf4cByDayChartRef>
                            <apx-chart
                              [series]="resumeStatusUploadedSf4cByDayChartoptions.series"
                              [chart]="{
                                height: 400,
                                
                                type: 'line',
                                
                                toolbar: {
                                  show: false
                                }
                              }"
                              [xaxis]="resumeStatusUploadedSf4cByDayChartoptions.xaxis"
                              [stroke]="resumeStatusUploadedSf4cByDayChartoptions.stroke"
                              [tooltip]="resumeStatusUploadedSf4cByDayChartoptions.tooltip"
                              [colors]="resumeStatusUploadedSf4cByDayChartoptions.colors"
                              [dataLabels]="resumeStatusUploadedSf4cByDayChartoptions.dataLabels"
                              [grid]="resumeStatusUploadedSf4cByDayChartoptions.grid"
                            ></apx-chart>
                          </div>
                    </div>
                  </div>
                </div>
                
            </div>
            <div class="row">
                <div class="col-xl-12 col-12">
                    <div class="card card-statistics">
                        <div class="card-header flex-column align-items-start">
                            <h4 class="card-title mb-75" [translate]="'DASHBOARD.RESUME_MATCHING'"></h4>
                            <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
                        </div>
                        <div class="card-body">
                            <div class="table-responsive" *ngIf="stat_resume_matching_recruitery.count>0">
                                <table class="table table-hover align-middle">
                                  <thead>
                                    <tr>
                                      <th [translate]="'DASHBOARD.JOB_ID'"></th>
                                      <th [translate]="'DASHBOARD.NAME_JOB'"></th>
                                      <th [translate]="'DASHBOARD.CREATE_AT'"></th>
                                      <th [translate]="'DASHBOARD.CREATE_BY'"></th>
                                      <th [translate]="'DASHBOARD.COUNT_RECOMMEND'"></th>
                                      <th style="width: 5px;"></th>
                                    </tr>
                                  </thead>
                                  <tbody *ngFor="let item of stat_resume_matching_recruitery.results
                                    | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: totalItems }; let i=index">
                                    <tr (click)="view_detail_resume(i)">
                    
                                      <td>
                                        <div class="">{{item.job_id}}</div>
                                      </td>
                                      <td>
                                        <div class="">{{item.jd_detail.title}}</div>
                                      </td>
                                      <td>
                                        <div class="">{{item.created_at|date:'medium'}}</div>
                                      </td>
                                      <td>
                                        <div class="">{{item.created_by}}</div>
                                      </td>
                                      <td>
                                        <div class="">{{item.result.length}}</div>
                                      </td>
                                      <td>
                                        <i id="{{i}}" class="fa" [ngClass]="{
                                                        'fa-chevron-circle-up': !view_resume || view_resume_check!=i,
                                                        'fa-chevron-circle-down': (view_resume && view_resume_check==i)
                                                    }"></i>
                    
                                      </td>
                    
                                    </tr>
                                    <tr *ngIf="view_resume_check==i && view_resume">
                                      <td colspan="12">
                                        <div class="table-container">
                                          <table class="table table-hover align-middle">
                                            <thead>
                                              <tr>
                                                <th id="scroll-table" [translate]="'DASHBOARD.RESUME_ID'">
                                                  
                                                </th>
                                                <th id="scroll-table" [translate]="'DASHBOARD.OVERALL_SCORE'">
                                                  
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody *ngFor="let itemDetail of sort_data(item.result)">
                                              <tr>
                                                <td>
                                                  <a routerLink="/manage-resume/detail-resume/{{itemDetail.resume_id}}" target="_blank">
                                                    <p>{{itemDetail.resume_id}}</p>
                                                  </a>
                                                </td>
                                                <td>
                                                  <span class="badge" [ngClass]="{
                                                    'badge-success':itemDetail.overall_score*100 > 65,
                                                    'badge-warning':itemDetail.overall_score*100 > 45 && itemDetail.overall_score*100 < 65,
                                                    'badge-danger':itemDetail.overall_score*100 < 45
                                                  }"
                                                    >{{itemDetail.overall_score*100|number:'1.2-2'}}%</span>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </td>
                                
                                    </tr>
                                  </tbody>
                                </table>
                    
                              </div>
                              <div class="d-flex mt-4 mt-lg-5 justify-content-between align-items-center"
                                *ngIf="stat_resume_matching_recruitery.count>0">
                                <div>
                    
                                  <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
                                </div>
                                <div class="d-flex align-items-center">
                                  <div>
                                    <span [translate]="'DASHBOARD.ITEM_PAGE'"></span><span>:</span>
                                  </div>
                                  <div>
                                    <select (change)="handlePageSizeChange($event)" class="form-control" style="width: auto">
                                      <option *ngFor="let size of pageSizes" [ngValue]="size">
                                        {{ size }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
        
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>