<div class="d-flex">
    <div class="col-md-2">
            <div *ngIf="ResultSearchCv.avatar!=null && !ResultSearchCv.avatar.includes('https://media-exp1.licdn.com'); else customAvatar">

                <img
                    [src]="ResultSearchCv.avatar"
                    [alt]="ResultSearchCv.avatar"
                    height="80px"
                    width="80px"
                    style="border-radius: 50%;object-fit: cover;"
                    />
          </div>

          <ng-template #customAvatar>
            <img
            src="assets/images/avatars/iconPersion.png"

            height="80px"
            width="80px"
            style="border-radius: 50%;object-fit: cover;"
            />
        </ng-template>
    </div>
    <div class="col-md-8">
        <a *ngIf="ResultSearchCv.fullname!=null && jd_id!=null" routerLink="/search-resume/detail-resume/{{ResultSearchCv.resume_id}}?jd_id={{jd_id}}" target='_blank' style="font-weight: 600;">{{ResultSearchCv.fullname}}</a>
        <a *ngIf="ResultSearchCv.fullname!=null && jd_id==null" routerLink="/search-resume/detail-resume/{{ResultSearchCv.resume_id}}" target='_blank' style="font-weight: 600;">{{ResultSearchCv.fullname}}</a>
        
        <a *ngIf="ResultSearchCv.full_name!=null" routerLink="/search-resume/detail-resume/{{ResultSearchCv.id}}" target='_blank' style="font-weight: 600;">{{ResultSearchCv.full_name}}</a>
        
        <ng-container *ngFor="let currentTitle of currentTitles;let idx =index;">
            <p style="font-weight: 600;margin-bottom: 0px;">{{currentTitle}} at {{currentCompanies[idx]}}</p>
        </ng-container>
        <span class="fa fa-globe" style="margin-right: 5px"></span><span style="font-size: 14px;">{{ResultSearchCv.address}}</span>

            
    </div>
    <div class="col-md-2 text-end">
        <a *ngIf="ResultSearchCv.fullname!=null && jd_id!=null" type="button" target="_blank" routerLink="/search-resume/detail-resume/{{ResultSearchCv.resume_id}}?jd_id={{jd_id}}" class="btn btn-outline-primary mb-1"><i class="fa fa-info mr-1" aria-hidden="true"></i> <span [translate]="'TALENT_POOL.RESUME_TALENT_POOL.DETAIL'"></span></a>
        <a *ngIf="ResultSearchCv.fullname!=null && jd_id==null" type="button" target="_blank" routerLink="/search-resume/detail-resume/{{ResultSearchCv.resume_id}}" class="btn btn-outline-primary mb-1"><i class="fa fa-info mr-1" aria-hidden="true"></i> <span [translate]="'TALENT_POOL.RESUME_TALENT_POOL.DETAIL'"></span></a>
        
        <a *ngIf="ResultSearchCv.full_name!=null" type="button" target="_blank" routerLink="/search-resume/detail-resume/{{ResultSearchCv.id}}" class="btn btn-outline-primary mb-1"><i class="fa fa-info mr-1" aria-hidden="true"></i> <span [translate]="'TALENT_POOL.RESUME_TALENT_POOL.DETAIL'"></span></a>
        <br *ngIf="ResultSearchCv.full_name!=null">
        <ng-container *ngIf="ResultSearchCv.full_name!=null"><span [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS'"> </span><span class="badge bg-{{status_color[ResultSearchCv.status]}}">{{ResultSearchCv.status}}</span></ng-container>
        
        <br>
        <button *ngIf="jd_id!=null && !saved" type="button" (click)="SaveResumeByJd(ResultSearchCv.resume_id)" class="btn btn-outline-success mt-1 btn-sm"><i class="fa fa-floppy-o mr-1" aria-hidden="true"></i> <span [translate]="'TALENT_POOL.RESUME_TALENT_POOL.SAVE'"></span></button>
        <button *ngIf="jd_id==null && !saved" type="button" (click)="modalOpenSaveCv(modalBasicSaveCv)" class="btn btn-outline-success mt-1 btn-sm"><i class="fa fa-floppy-o mr-1" aria-hidden="true"></i> <span [translate]="'TALENT_POOL.RESUME_TALENT_POOL.SAVE'"></span></button>
        <ng-template #modalBasicSaveCv let-modal>
            <div class="modal-header">
                <h4 class="modal-title" [translate]="'TALENT_POOL.SEARCH_RESUME.SELECT_JD'"></h4>
                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form (ngSubmit)="FormCheckBoxSaveCv(ResultSearchCv.resume_id)" [formGroup]="formCheckBoxSaveCv">
                <div class="modal-body" ngbAutofocus>
                    <ng-container *ngIf="list_all_jd.length===0">
                        <p [translate]="'TALENT_POOL.SEARCH_RESUME.NOT_JOB'"> <a target="_blank" routerLink="/manage-job/create-job" [translate]="'TALENT_POOL.SEARCH_RESUME.CREATE_JOB'"></a> </p>
                    </ng-container>
                    <ng-container *ngIf="list_all_jd.length!==0">
                        
                        <table class="table table-hover align-middle">
                            <thead>
                                <tr>
                                    <th class="pxp-is-checkbox" style="width: 1%;"></th>
                                    <th [translate]="'TALENT_POOL.MANAGE_JOB.JOB'"></th>
                                    <th [translate]="'TALENT_POOL.MANAGE_JOB.CATEGORY'"></th>
                                    <th [translate]="'TALENT_POOL.MANAGE_JOB.TYPE'"></th>
                                    <th [translate]="'TALENT_POOL.MANAGE_JOB.LEVEL'"></th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                <tr *ngFor="let list_all_jd_ of list_all_jd| slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
                                    <td *ngIf="arr_checkboxSaveCv.includes(list_all_jd_.id.toString());else noChecked">
                                        <input [value]="list_all_jd_.id" (change)="onCheckboxChangeSaveCv($event)" type="checkbox" class="form-check" checked>
                                        
                                    </td>
                                    <ng-template #noChecked>
                                        <td>
                                            <input [value]="list_all_jd_.id" (change)="onCheckboxChangeSaveCv($event)" type="checkbox" class="form-check">
                                            
                                        </td>
                                    </ng-template>
                                
                                    
                                    <td>
                                        <div class="" >{{list_all_jd_.job_title}}</div>
                                    </td>
                                    <td>
                                        <div class="">{{list_all_jd_.industry}}</div>
                                    </td>
                                    <td>
                                        <div class="">{{list_all_jd_.job_type}}</div>
                                    </td>
                                    <td>
                                        <div class="">{{list_all_jd_.job_level}}</div>
                                    </td>
                                
                                </tr>

                            </tbody>
                        </table>
                        <div class="row justify-content-between align-items-center">
                            <div class="col-auto">
    
                                <nav class="mt-2 mt-sm-0" aria-label="Jobs list pagination">
    
                                    <ngb-pagination [collectionSize]="list_all_jd.length" [(page)]="page" [pageSize]="pageSize"
                                    [maxSize]="10" [rotate]="true" [boundaryLinks]="true">
    
    
                                    </ngb-pagination>
                                </nav>
                            </div>

                        </div>
                    </ng-container>

                    </div>
                
                <div class="modal-footer">
                <button type="submit" class="btn btn-primary" rippleEffect (click)="modal.dismiss('Cross click')" [translate]="'TALENT_POOL.CREATE_JOB.SUBMIT'">
                    
                </button>

                </div>
        </form>
        </ng-template>
        <div class="mt-2 " *ngIf="overall_score!=null">
            <div class="text-end font-weight-bolder mb-1" [translate]="'TALENT_POOL.RESUME_TALENT_POOL.OVERALL_SCORE'"> </div>
            <div class="">
                <div class="progress-wrapper">
                    <ngb-progressbar
                        showValue="true"
                        
                        [type]="{
                            'success':overall_score>0.7,
                            'warning':overall_score<0.7 && overall_score>0.5,
                            'danger':overall_score<0.5
                        }"
                        [ngClass]="{
                            'progress-bar-success':overall_score>0.7,
                            'progress-bar-warning':overall_score<0.7 && overall_score>0.5,
                            'progress-bar-danger':overall_score<0.5
                        }"
                        [height]="progressbarHeight"
                        [value]="overall_score*100"
                    ></ngb-progressbar>
                </div>
            </div>
            
        </div>
    </div>
</div>
<div class="d-flex mt-2" *ngIf="ResultSearchCv.experiences.length>0">
    <div class="col-xxl-2" >
        <p style="font-weight: 600;margin-bottom: 0px;">Experience</p>

    </div>
    <div class="col-xxl-8">
        <div *ngFor="let experience of ResultSearchCv.experiences">
            <div [innerHtml]="experience" class="candidate-experience"></div>
        </div>
    </div>
</div>

<div class="d-flex mt-2" *ngIf="ResultSearchCv.educations.length > 0">
    <div class="col-xxl-2">
        <p style="font-weight: 600;margin-bottom: 0px;">Education</p>
    </div>
    <div class="col-xxl-8">
        <div *ngFor="let education of ResultSearchCv.educations">
            <div [innerHtml]="education" class="candidate-education"></div>
        </div>
    
    </div>
</div>