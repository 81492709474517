<div class="content-wrapper container-xxxl p-0 card">
    <div class="content-body card-body">
        <h1>Saved Resumes</h1>
         
          <div *ngIf="errorMessagesListSavedResumeByJd; else Saved_cvs">
            <p>Errors: <b style="color: red;">{{errorMessagesListSavedResumeByJd}}</b></p>
          </div>
            <ng-template #Saved_cvs>
                <div class="col-auto d-flex justify-content-between">
                    <div>
                        <button (click)="toggle_list_form_table()" *ngIf="!list_form_table" class="btn btn-outline-primary"><span class="fa fa-th-list"></span></button>
                        <button (click)="toggle_list_form_table()" *ngIf="list_form_table" class="btn btn-outline-primary"><span class="fa fa-th-large"></span></button>
                        <span class="ml-3" style="margin-left: 1rem;" [translate]="'TALENT_POOL.RECOMMEND_RESUME.RESUME_HAD_SAVED'"> {{saved_cvs.length}} <span [translate]="'TALENT_POOL.SEARCH_RESUME.RESUMES'"></span></span>
                    </div>
                    <button class="btn btn-outline-info" *ngIf="saved_cvs.length>0 && have_hcm_system_id" (click)="upload_sf4c()"><span class="fa fa-upload"></span>&nbsp; <span [translate]="'TALENT_POOL.SAVED_RESUME.UPLOAD_SF4C'"></span></button>
                </div>
            <div class="mt-2" *ngIf="list_form_table && saved_cvs.length>0">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                
                                
                                <th [translate]="'TALENT_POOL.RESUME_MATCHING.FULL_NAME'"></th>
                                <th [translate]="'TALENT_POOL.SEARCH_RESUME.ADDRESS'"></th>
                                <th [translate]="'TALENT_POOL.RESUME_MATCHING.CURRENT_TITLE'"></th>
                                <th [translate]="'TALENT_POOL.RESUME_MATCHING.CURRENT_COMPANY'"></th>
                                <th [translate]="'TALENT_POOL.RESUME_MATCHING.STATUS'"></th>
                               
                            
                            </tr>
                            <tr>
                                
                                
                                <th><input
                                    type="text"
                                    class="form-control"
                                    id="searchFullname"
                                    placeholder="Search fullname"
                                    
                                    [(ngModel)]="searchFullname"
                                    (input)="SearchFullname()"
                                /></th>
                                <th><input
                                    type="text"
                                    class="form-control"
                                    id="searchAddress"
                                    placeholder="Search address"
                                    [(ngModel)]="searchAddress"
                                    (input)="SearchAddress()"
                                /></th>
                                
                                <th><input
                                    type="text"
                                    class="form-control"
                                    id="searchCurrentTitle"
                                    placeholder="Search current title"
                                    
                                    [(ngModel)]="searchCurrentTitle"
                                    (input)="SearchCurrentTitle()"
                                /></th>
                                <th><input
                                    type="text"
                                    class="form-control"
                                    id="searchCurrentCompany"
                                    placeholder="Search current company"
                                    
                                    [(ngModel)]="searchCurrentCompany"
                                    (input)="SearchCurrentCompany()"
                                /></th>
                                <th></th>
                            
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let saved_cvs_ of saved_cvs
                            | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
                                
                                <td>
                                    <a target="_blank" routerLink="/search-resume/detail-resume/{{saved_cvs_.id}}">

                                        <div class="">{{saved_cvs_.full_name}}</div>
                                        
                                    </a>
                                </td>
                                <td>
                                    <div class="">{{saved_cvs_.address}}</div>
                                </td>
                                <td>
                                    <div class="">{{saved_cvs_.current_title}}</div>
                                </td>
                                <td><div class="">{{saved_cvs_.current_company}}</div></td>
        
                                <td><span class="badge bg-{{status_color[saved_cvs_.status]}}">{{saved_cvs_.status}}</span></td>
                                
                                
                                
                            </tr>

                        </tbody>
                    </table>

                    <div class="d-flex mt-4 mt-lg-5 justify-content-between align-items-center" *ngIf="saved_cvs.length>0">
                        <div class="col-auto">

                            <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">

                                <ngb-pagination [collectionSize]="saved_cvs.length" [(page)]="page" [pageSize]="pageSize"
                                [maxSize]="10" [rotate]="true" [boundaryLinks]="true">


                                </ngb-pagination>
                            </nav>
                        </div>
                        <div class="col-auto">
                            <select class="form-control" style="width: auto" [(ngModel)]="pageSize">
                                <option [ngValue]="8">8 <span [translate]="'TALENT_POOL.MANAGE_JOB.ITEMS_PER_PAGE'"></span></option>
                                <option [ngValue]="10">10 <span [translate]="'TALENT_POOL.MANAGE_JOB.ITEMS_PER_PAGE'"></span></option>
                                <option [ngValue]="15">15 <span [translate]="'TALENT_POOL.MANAGE_JOB.ITEMS_PER_PAGE'"></span></option>
                            </select>
    

                        </div>
                    </div>
                </div>    
            </div>
            <div class="mt-2" *ngIf="!list_form_table  && saved_cvs.length>0">
                <div class="mb-2"
                *ngFor="let saved_cvs_ of saved_cvs| slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
                        <div class="col-12">
                            <div class="card card-employee-task" style="border: 1px solid #dedede;">
                                <div class="card-body">
                                    <app-resume-talent-pool [ResultSearchCv]="saved_cvs_" [saved]="true"></app-resume-talent-pool>

                                </div>
                            </div>
                        </div>
                    </div>
                
                <div class="d-flex mt-4 mt-lg-5 justify-content-between align-items-center" *ngIf="saved_cvs.length>0">
                    <div class="col-auto">

                        <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">

                            <ngb-pagination [collectionSize]="saved_cvs.length" [(page)]="page" [pageSize]="pageSize"
                            [maxSize]="10" [rotate]="true" [boundaryLinks]="true">


                            </ngb-pagination>
                        </nav>
                    </div>
                    <div class="col-auto">
                        <select class="form-control" [(ngModel)]="pageSize">
                            <option [ngValue]="8">8 <span [translate]="'TALENT_POOL.MANAGE_JOB.ITEMS_PER_PAGE'"></span></option>
                            <option [ngValue]="10">10 <span [translate]="'TALENT_POOL.MANAGE_JOB.ITEMS_PER_PAGE'"></span></option>
                            <option [ngValue]="15">15 <span [translate]="'TALENT_POOL.MANAGE_JOB.ITEMS_PER_PAGE'"></span></option>
                        </select>


                    </div>
                </div>
                    
            </div>
            </ng-template>
    </div>
</div>