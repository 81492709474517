import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { User } from 'app/auth/models/user';
import { Jr, Matching_JR, ResumeParser, Sf4cCandidate } from '../../models/resume.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResumeParserService } from '../../services/resume-parser.service';

import { locale as enLocale } from "../../i18n/en";
import { locale as vnLocal } from "../../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
@Component({
  selector: 'app-detail-resume',
  templateUrl: './detail-resume.component.html',
  styleUrls: ['./detail-resume.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailResumeComponent implements OnInit {
  public user!: User
  public isAuthenticated=false
  public resume_parser: ResumeParser = new ResumeParser()
  public errorMessagesParseResume!: any
  public errorMessagesApproveResume!: any
  public errorMessagesCensorResume!: any
  public errorMessagesDeCensorResume!:any
  public tag_status= false
  public tag_detail = false
  public pdf_content!: any
  public url!: any

  
  dateMaxDate = new Date(1960, 0, 1);
  dateMinDate = new Date(2010,0,1);
  public status_color: any = {
    "Approved": 'success',
    "SF4C_background_failed": 'warning',
    "Done": "primary",
    "Failure": "danger",
    "Processing": "info",
    "Wait_to_process":"dark",
    "Suspended":"grey",
    "Modified":"warning",
    "SF4C_wait_to_upload":"dark",
    "SF4C_failed":"secondary",
    "SF4C_uploading":"info",
    "SF4C_uploaded":"success",



  }
  public range_conf: any = {
    'success': 0.6,
    'warning': 0.4,
  }
  //Tab 
  public tab_sf4c = false
  //sf4c candidate
  public sf4cCandidate: Sf4cCandidate = new Sf4cCandidate()
  public errorMessagesGetSf4cCandidate!: any
  public tag_sf4c_candidate = false
  public dateOfBirth!: any
  public resultsEducation: any[] = []
  public resultsExperience: any[] = []
  public pdf_content_sf4c!: any
  public url_sf4c!: any
  public errorMessagesResultConnectSf4cs!: any
  public tag_resultConnectSf4c_exit =false
  public Successfactor_update_status!: string
  public errorMessagesSf4cSync!: any
  public email!: string
  public loading = false
  public loading_ = false
  //modal jobReqId
  public arr_checkboxJobReqId:string[]=[]
  formCheckBoxJobReqId = this.fb.group({
    checkResumeArrayJobReqId: this.fb.array([])

  })
  errorMessagesGetJr: any
  list_jr: Jr[] = []
  public page=1
  public pageSize=8
  //matching jr
  public matching_jr: Matching_JR = new Matching_JR()
  public errorMessagesMatchingJr: any
  public selectedJr!: string
  public items_jr:any[] = []
  public loading_match = false
  //sf4c upload
  public arr_checkboxJr:string[]=[]
  public arr_checkbox_current_title:string[]=[]
  public resultJr: Jr[]=[]
  totalItemsJr: number = 0
  public have_Jr = false
  errorMessagesSf4cUpload: any
  public searchText: string = '';
  public basicSelectedOption: number = 5;
  public pageJr=1
  public status!: string
  //Censor
  public display_decensor = false
  formCheckBoxJr = this.fb.group({
    checkResumeArrayJr: this.fb.array([],Validators.required)
  })
  public role: string;
  public progressbarHeight = '.857rem';

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private resumeService: ResumeParserService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
   }
  base64ToBlob( base64: any, type = "application/octet-stream" ) {
    const binStr = atob( base64 );
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[ i ] = binStr.charCodeAt( i );
    }
    return new Blob( [ arr ], { type: type } );
  }
  gaugeColor(value:number) {
    if (value > 80) {
      return '#198754'
    } else if(value > 50) {
      return '#ffc107'
    } else{
      return '#dc3545'
    }
  }
  Matching_jr(){
    if(this.matching_jr.jobReqId==null){
      this.loading_match = true
      let url_dynamic = "?jobReqId="
      this.resumeService.matching_jr(this.route.snapshot.params["id"],url_dynamic).subscribe(
        (res)=>{
          this.matching_jr = res;
          this.selectedJr = this.matching_jr.jobReqId;
          for (let i=0;i<this.matching_jr.all_jrs.length;i++){
            this.items_jr.push({"jobReqId":this.matching_jr.all_jrs[i].jobReqId, "jobTitle":this.matching_jr.all_jrs[i].jobReqId+': '+this.matching_jr.all_jrs[i].jobTitle})
          }
          this.loading_match = false;
        },
        (err)=>{
          this.errorMessagesMatchingJr = err?.statusText;
          this.loading_match = false
        }
      )
    }
  }
  Post_matching_jr(){
    this.loading_match = true
    let url_dynamic = "?jobReqId="+this.selectedJr
    const payload = {
      id: this.route.snapshot.params["id"],
      url_dynamic: url_dynamic
    }
    this.resumeService.matching_jr(this.route.snapshot.params["id"],url_dynamic).subscribe(
      (res)=>{
        this.matching_jr = res;
        this.selectedJr = this.matching_jr.jobReqId;
        this.loading_match = false;
      },
      (err)=>{
        this.errorMessagesMatchingJr = err?.statusText
      }
    )
  }
  GetDetailResume(){
    const payload = {
      id: this.route.snapshot.params["id"]
    }
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
      });
    this.resumeService.getDetailResume(this.route.snapshot.params["id"]).subscribe(
      (res)=>{
        this.pdf_content = res.pdf_content;
        this.resume_parser = res;
        this.status = res.status;
        const blob = this.base64ToBlob( this.pdf_content, 'application/pdf' );
        this.url = URL.createObjectURL( blob );
        if (res.email!=null && (res.status=='SF4C_failed' ||res.status=='SF4C_uploaded'||res.status=='SF4C_background_failed')) {
          this.email = res.email;
          this.GetSf4cCandidate();
        }
        
        this.tag_detail = true
      },
      (err)=>{
        this.errorMessagesParseResume = err?.statusText
      }
    )
  }
  ApproveResume(){
    this.resumeService.approveResume(this.route.snapshot.params["id"]).subscribe(res=>{
      if(res.status!=null){
        this.toastr.success('Approved Success');
        this.GetDetailResume()
      }
    })
  }
  CensorResume(){
    this.resumeService.getCensorResume(this.route.snapshot.params["id"]).subscribe(
      (res)=>{
        this.display_decensor = true;
        this.pdf_content = res.pdf_content;
        const blob = this.base64ToBlob( this.pdf_content, 'application/pdf' );
        this.url = URL.createObjectURL( blob );
      },
      (err)=>{
        this.errorMessagesDeCensorResume = err
      }
    )
  }
  DeCensorResume(){
    this.resumeService.getDeCensorResume(this.route.snapshot.params["id"]).subscribe(
      (res)=>{
        this.display_decensor = false;
        this.pdf_content = res.pdf_content;
        const blob = this.base64ToBlob( this.pdf_content, 'application/pdf' );
        this.url = URL.createObjectURL( blob );
      },
      (err)=>{
        this.errorMessagesDeCensorResume = err
      }
    )
  }
  Tab(){
    this.tab_sf4c = false
    // window.location.reload()
  }
  Tab_sf4c(){
    this.tab_sf4c = true
  }
  GetSf4cCandidate(){
    const payload = {
      email: this.email
    }
    this.resumeService.getSf4cCandidate(this.email).subscribe(
      (res)=>{
        this.sf4cCandidate = res;
        this.pdf_content_sf4c = res.resume!=null?res.resume.fileContent:this.pdf_content;
        const blob = this.base64ToBlob( this.pdf_content_sf4c, 'application/pdf' );
        this.url_sf4c = URL.createObjectURL( blob );
        this.tag_sf4c_candidate = true
      },
      (err)=>{
        this.errorMessagesGetSf4cCandidate = err
      }
    )
  }
  //mapping
  onCheckboxChangeJobReqId(e:any) {
    const checkResumeArrayJobReqId: FormArray = this.formCheckBoxJobReqId.get('checkResumeArrayJobReqId') as FormArray;
    if (e.target.checked) {
  
      checkResumeArrayJobReqId.push(new FormControl(e.target.value));
      this.arr_checkboxJobReqId.push(e.target.value)
      
  
    } else {
      let i: number = 0;
      checkResumeArrayJobReqId.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArrayJobReqId.removeAt(i);
          this.arr_checkboxJobReqId=this.arr_checkboxJobReqId.filter(item=>item!==e.target.value)
          return;
        }
        i++;
      });
    }
  }
  modalOpenJobReqId(modalBasicJobReqId:any) {
    this.ListJr()
    this.modalService.open(modalBasicJobReqId, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
    }
    ListJr(){
      this.loading = true
      this.resumeService.getJr().subscribe(
        (res)=>{
          this.list_jr = res;
          this.loading = false;
        },
        (err)=>{
          this.errorMessagesGetJr = err
        }
      ) 
    }
  
  Mapping(){
    this.loading_ = true
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
      });
    let resume = {
      id: Number(this.route.snapshot.paramMap.get('id')),
      file_name: this.resume_parser.file_name,
      pdf_content: this.resume_parser.pdf_content,
      first_name: this.resume_parser.first_name,
      last_name: this.resume_parser.last_name,
      date_of_birth: this.resume_parser.date_of_birth,
      gender: this.resume_parser.gender,
      address: this.resume_parser.address,
      email: this.resume_parser.email,
      phone_number: this.resume_parser.phone_number,
      category: this.resume_parser.category,
      year_exp: this.resume_parser.year_exp,
      current_title: this.resume_parser.current_title,
      current_company: this.resume_parser.current_company,
      experience: this.resume_parser.experience,
      education: this.resume_parser.education
    }
    let _education = []
    let _educations = this.sf4cCandidate.education
      for (let i=0; i<_educations.length;i++){
        _education.push({'backgroundElementId': _educations[i].backgroundElementId,'startDate':_educations[i].startDate,'endDate':_educations[i].endDate, 'school':_educations[i].school, 'major1':_educations[i].major1})
      }
    let _experience = []
    let _experiences = this.sf4cCandidate.outsideWorkExperience
    for (let i=0; i<_experiences.length;i++){
      _experience.push({'backgroundElementId': _experiences[i].backgroundElementId,'startDate':_experiences[i].startDate,'endDate':_experiences[i].endDate, 'startTitle':_experiences[i].startTitle, 'employer':_experiences[i].employer})
    }
    
    let sf4c_resume = {
      candidateId: this.sf4cCandidate.candidateId,
      education: _education,
      outsideWorkExperience: _experience
    }
    //JobReqId
    let arr_checkbox_JobReqId=[]
    for (let i=0;i<this.arr_checkboxJobReqId.length;i++){
      arr_checkbox_JobReqId.push(parseInt(this.arr_checkboxJobReqId[i]))
    }
    const id = Number(this.route.snapshot.paramMap.get('id'))
    let jobReqId= arr_checkbox_JobReqId
    this.resumeService.sf4c_sync(id, resume, sf4c_resume,jobReqId).subscribe(
      (res)=>{
        if(res.successfactor_update_status!=null){
          this.loading_ = false;
          if(this.resume_parser.status!='SF4C_uploaded'&&!this.resume_parser.sf4c_status.includes('Candidate upsert failed')){
            setTimeout(() => this.GetDetailResume(), );
          }
        }
      }
    )
  }
  //sf4c upload
  modalOpenUploadSf4c(modalBasic:any) {
    this.have_Jr = false
    this.getJr();
    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
    }
    getJr(){
      let url_dynamic = ""
      for (let i=0;i<this.arr_checkbox_current_title.length;i++){
        let url_current = "job_title="+this.arr_checkbox_current_title[0]
        url_dynamic = url_dynamic ? url_dynamic + "&" + url_current : url_current
      }
      this.loading=true
      this.resumeService.getJrWithQuery(url_dynamic).subscribe(
        (res)=>{
          this.resultJr = res;
          this.totalItemsJr = res.length;
          this.have_Jr = true;
          this.loading = false
        },
        (err)=>{
          console.error("Error: "+err);
          this.loading = false
        }
      )
    }
  
  onCheckboxChangeJr(e:any) {
    const checkResumeArrayJr: FormArray = this.formCheckBoxJr.get('checkResumeArrayJr') as FormArray;
    if (e.target.checked) {

      checkResumeArrayJr.push(new FormControl(e.target.value));
      
      this.arr_checkboxJr.push(e.target.value)

    } else {
      let i: number = 0;
      checkResumeArrayJr.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArrayJr.removeAt(i);
          this.arr_checkboxJr=this.arr_checkboxJr.filter(item=>item!==e.target.value)
          return;
        }
        i++;
      });
    }
  }
  FormCheckBoxJr(){
    this.loading_=true
    
    const id= [Number(this.route.snapshot.paramMap.get('id'))]
    let jobReqId= this.arr_checkboxJr
    
    this.resumeService.sf4c_upload(id, jobReqId).subscribe(
      (res)=>{
        if(res.status!=null){
          this.loading_ = false;
          if(this.resume_parser.status!='SF4C_uploaded'&&this.resume_parser.status!='SF4C_failed'){
            setTimeout(() => this.GetDetailResume(),1000 ); 
          }
        }
      },
      (err)=>{
        this.errorMessagesSf4cUpload = err;
        this.loading_ = false
      }
    )
  }
  Search(){
    // alert(this.searchText)
    if(this.searchText!== ""){
      let searchValue = this.searchText.toLocaleLowerCase();
      this.resultJr = this.resultJr.filter((resultJr_:any) =>{
        return resultJr_.jobTitle.toLocaleLowerCase().match(searchValue )
        ;
      // you can keep on adding object properties here

            });
            
          }
          
          
          else{
            this.getJr()
          }
        }
  getListCredential(){
    this.resumeService.getListCredential().subscribe(
      (res)=>{
        this.tag_resultConnectSf4c_exit = true
      }
    )

  }
  ngOnInit(): void {
    // this.user = JSON.parse(localStorage.getItem("user")|| '{}');
    this.role = localStorage.getItem("role");
    this.GetDetailResume();
    this.getListCredential();
  }

}
