import { Component, OnInit } from '@angular/core';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  public no_full_service = false
  constructor(
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
   }
  
  ngOnInit(): void {
  }
  buyQuota(packageName: string) {
    // var paymentInfo = new PaymentInfo(packageName);
    // this._paymentService.setPaymentInfoValue(paymentInfo);
    // this._router.navigate(["/pricing/order"]);
  }
  redirectToPagePricing() {
   
  }

}
