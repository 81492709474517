import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CV_task } from '../../models/job.model';
import { TalentPoolService } from '../../services/talent-pool.service';
import { ActivatedRoute } from '@angular/router';
import { locale as enLocale } from "../../i18n/en";
import { locale as vnLocal } from "../../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
@Component({
  selector: 'app-detail-job',
  templateUrl: './detail-job.component.html',
  styleUrls: ['./detail-job.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailJobComponent implements OnInit {
  public errorMessagesDetailJd!: any
  public detail_jd: CV_task=new CV_task()
  constructor(
    private talentpoolService: TalentPoolService,
    private route: ActivatedRoute,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
   }
  getJob(){
    const jd_id= Number(this.route.snapshot.paramMap.get('id'))
    this.talentpoolService.detail_jd(jd_id).subscribe(
      (res)=>{
        this.detail_jd = res;
      },
      (err)=>{
        this.errorMessagesDetailJd = err
      }
    )
  }
  ngOnInit(): void {
    this.getJob();
  }

}
