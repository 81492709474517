import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreTranslationService } from '@core/services/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { Jr, Matching_JR, ResumeParser } from 'app/main/resume-parser/models/resume.model';
import { ResumeParserService } from 'app/main/resume-parser/services/resume-parser.service';
import { ToastrService } from 'ngx-toastr';
import { locale as enLocale } from "../../../resume-parser/i18n/en";
import { locale as vnLocal } from "../../../resume-parser/i18n/vn";
import { Subject } from 'rxjs';
import { CoreConfigService } from '@core/services/config.service';
@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResumeComponent implements OnInit {

  public user!: User
  public isAuthenticated=false
  public resume_parser: ResumeParser = new ResumeParser()
  public errorMessagesParseResume!: any
  public errorMessagesApproveResume!: any
  public errorMessagesCensorResume!: any
  public errorMessagesDeCensorResume!:any
  public tag_status= false
  public tag_detail = false
  public pdf_content!: any
  public url!: any
  public status!: string
  private _unsubscribeAll: Subject<any>;

  
  dateMaxDate = new Date(1960, 0, 1);
  dateMinDate = new Date(2010,0,1);
  public status_color: any = {
    "Approved": 'success',
    "SF4C_background_failed": 'warning',
    "Done": "primary",
    "Failure": "danger",
    "Processing": "info",
    "Wait_to_process":"dark",
    "Suspended":"grey",
    "Modified":"warning",
    "SF4C_wait_to_upload":"dark",
    "SF4C_failed":"secondary",
    "SF4C_uploading":"info",
    "SF4C_uploaded":"success",



  }
  public range_conf: any = {
    'success': 0.6,
    'warning': 0.4,
  }
  //Tab 
  public tab_sf4c = false
  //modal jobReqId
  public arr_checkboxJobReqId:string[]=[]
  formCheckBoxJobReqId = this.fb.group({
    checkResumeArrayJobReqId: this.fb.array([])

  })
  public page=1
  public pageSize=8
  //matching jr
  public matching_jr: Matching_JR = new Matching_JR()
  public errorMessagesMatchingJr: any
  public selectedJr!: string
  public items_jr:any[] = []
  public loading_match = false
  //Censor
  public display_decensor = false
  public role: string;
  public progressbarHeight = '.857rem';

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private resumeService: ResumeParserService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,

  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
   }
  base64ToBlob( base64: any, type = "application/octet-stream" ) {
    const binStr = atob( base64 );
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[ i ] = binStr.charCodeAt( i );
    }
    return new Blob( [ arr ], { type: type } );
  }
  gaugeColor(value:number) {
    if (value > 80) {
      return '#198754'
    } else if(value > 50) {
      return '#ffc107'
    } else{
      return '#dc3545'
    }
  }
  Matching_jr(){
    if(this.matching_jr.jobReqId==null){
      this.loading_match = true
      let url_dynamic = "?jobReqId="
      this.resumeService.matching_jr(this.route.snapshot.params["id"],url_dynamic).subscribe(
        (res)=>{
          this.matching_jr = res;
          this.selectedJr = this.matching_jr.jobReqId;
          for (let i=0;i<this.matching_jr.all_jrs.length;i++){
            this.items_jr.push({"jobReqId":this.matching_jr.all_jrs[i].jobReqId, "jobTitle":this.matching_jr.all_jrs[i].jobReqId+': '+this.matching_jr.all_jrs[i].jobTitle})
          }
          this.loading_match = false;
        },
        (err)=>{
          this.errorMessagesMatchingJr = err?.statusText;
          this.loading_match = false
        }
      )
    }
  }
  Post_matching_jr(){
    this.loading_match = true
    let url_dynamic = "?jobReqId="+this.selectedJr
    const payload = {
      id: this.route.snapshot.params["id"],
      url_dynamic: url_dynamic
    }
    this.resumeService.matching_jr(this.route.snapshot.params["id"],url_dynamic).subscribe(
      (res)=>{
        this.matching_jr = res;
        this.selectedJr = this.matching_jr.jobReqId;
        this.loading_match = false;
      },
      (err)=>{
        this.errorMessagesMatchingJr = err?.statusText
      }
    )
  }
  GetDetailResume(){
    const payload = {
      id: this.route.snapshot.params["id"]
    }
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
      });
    this.resumeService.getDetailResume(this.route.snapshot.params["id"]).subscribe(
      (res)=>{
        this.pdf_content = res.pdf_content;
        this.resume_parser = res;
        this.status = res.status;
        const blob = this.base64ToBlob( this.pdf_content, 'application/pdf' );
        this.url = URL.createObjectURL( blob );
        this.tag_detail = true
      },
      (err)=>{
        this.errorMessagesParseResume = err?.statusText
      }
    )
  }
  ApproveResume(){
    this.resumeService.approveResume(this.route.snapshot.params["id"]).subscribe(res=>{
      if(res.status!=null){
        this.toastr.success('Approved Success');
        this.GetDetailResume()
      }
    })
  }
  CensorResume(){
    this.resumeService.getCensorResume(this.route.snapshot.params["id"]).subscribe(
      (res)=>{
        this.display_decensor = true;
        this.pdf_content = res.pdf_content;
        const blob = this.base64ToBlob( this.pdf_content, 'application/pdf' );
        this.url = URL.createObjectURL( blob );
      },
      (err)=>{
        this.errorMessagesDeCensorResume = err
      }
    )
  }
  DeCensorResume(){
    this.resumeService.getDeCensorResume(this.route.snapshot.params["id"]).subscribe(
      (res)=>{
        this.display_decensor = false;
        this.pdf_content = res.pdf_content;
        const blob = this.base64ToBlob( this.pdf_content, 'application/pdf' );
        this.url = URL.createObjectURL( blob );
      },
      (err)=>{
        this.errorMessagesDeCensorResume = err
      }
    )
  }
  
  ngOnInit(): void {
    // this.user = JSON.parse(localStorage.getItem("user")|| '{}');
    this.role = localStorage.getItem("role");
    this.GetDetailResume();
  }

}
