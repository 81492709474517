import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthenticationService } from "app/auth/service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {}

  // Bảo vệ khỏi truy cập trang trước các nguồn không được cho phép  hàm canActivate trả về true để truy cập, false thì không được truy cập.
  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this._authenticationService.tokenValue;
    if(token||localStorage.getItem('token') ){
      return true;
    }
    this._router.navigate(["/login"],{
      queryParams: { returnUrl: state.url}
    });
    return false;
    // const currentCsrfToken = this._authenticationService.csrfTokenValue;
    // if (!currentCsrfToken || currentCsrfToken === "") {
    //   this._router.navigate(["/login"], {
    //     queryParams: { returnUrl: state.url },
    //   });
    // }
    // return true;
  }
}
