import { Injectable } from '@angular/core';
import { PaymentInfo } from '../models/payment.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private paymentInfo: Observable<PaymentInfo>;
  private paymentSubject: BehaviorSubject<PaymentInfo>;
  public paymentOption: Observable<string>;
  private paymentOptionSubject: BehaviorSubject<string>;
  // private csrfToken: string;
  constructor(
    private _httpClient: HttpClient,
  ) {
      this.paymentSubject = new BehaviorSubject<PaymentInfo>(
        null
      );
      this.paymentInfo = this.paymentSubject.asObservable();
      this.paymentOptionSubject = new BehaviorSubject<string>(
        null
      );
      this.paymentOption = this.paymentOptionSubject.asObservable();
  }

  public get paymentInfoValue(): PaymentInfo {
    return this.paymentSubject.value;
  }
  public setPaymentInfoValue(value: PaymentInfo){
    this.paymentSubject.next(value)
  }
  public get paymentOptionValue(): string {
    return this.paymentOptionSubject.value;
  }
  public setPaymentOptionValue(value: string){
    this.paymentOptionSubject.next(value)
  }

  public buyQuota(packageName: string, paymentMethod: string): Observable<any> {
    const formData = new FormData();
    formData.append("package_name", packageName);
    formData.append("type", 'buy_quota');
    return this._httpClient
      .post<any>(`${environment.apiUrl}/billing/${paymentMethod}`, formData)
      .pipe(first());
  }

  public getUserInfo(): any{
    return this._httpClient.get(`${environment.apiUrl}/user/`);
  }

  public getTransactionInfo(transaction_id: string): any{
    return this._httpClient.get(`${environment.apiUrl}/transaction/${transaction_id}`);
  }
  //billing
  getBilling(){
    return this._httpClient.get<any>(`${environment.apiUrl}/transaction/get_billing`);
  }
}
