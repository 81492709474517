import { _education, _experience } from "app/main/resume-parser/models/resume.model"

export class ResultSearchResume{
    result!: SearchResume[]
    next_page!: boolean
    num_result!: number
}
export class SearchResume{
    id!: number
    address!: string
    city!: string
    current_company!: string
    current_title!: string
    education_fulltext!: string
    experience_fulltext!: string
    resume_fulltext!: string
    educations: string[]=[]
    experiences: string[]=[]
    fullname!: string
    resume_id!: any
    title_score!: number
    score!: number
    full_name!:string
    status!: string
    avatar!: string
    source!: string
    seen_CV!: number
    seen_CV_lasttime!: string
    C_HR_resume!: C_Hr_resume
    seen_by: string[]=[]
    last_seen!: string
}
export class C_Hr_resume{
    id!: number
    status!: string
}
export class DetailResume{
    avatar!: string

    id!: any
    pdf_content!: string
    education: _education[]=[]
    experience: _experience[]=[]
    source!: string
    file_name!: string
    status!: string
    created_at!: string
    modified_at!: string
    first_name!: string
    last_name!: string
    email!: string
    phone_number!: string
    sf4c_status!: string
    webhook_status!: number
    fulltext!: string
    education_fulltext!: string
    experience_fulltext!: string
    conf_score!: number
    title!: any
    skills: string[]=[]
    gender!: string
    date_of_birth!: string
    address!: string
    category!: string
    talent_pool_indexed!: boolean
    year_exp!: string
    task!: number
    owner!: number
    successfactor_resume_id!: any
    current_title!: string
    current_company!: string
    full_name!: string
}
export class SaveResumeByJd{
    message!: string
}
export class ListResumeMatching{
    count!: number
    results: ResumeMatching[] = []
}
export class ResumeMatching{
    id!: number
    jobReqId!: string
    jobReqId_detail!: number
    created_at!: string
    result!: ResultOverall_score
    status!: string
    resume!: number
    created_by!: number
    resume_status!: string
    resume_detail_status!: string
    job_application_status!: string
    job_application_detail_status!: string
    resume_detail!: DetailResume
}
export class ResultOverall_score{
    overall_score!: number
}
//Sync sf4c JR
export class ResultSyncSf4cJr{
    status!: string
}
//Matching CV-JD
export class ResultMatchingCV{
    overall_score!: number
}