import { Locale } from "@core/services/translation.service";

export const locale: Locale = {
  lang: "en",
  data: {
    PAYMENT: {
      TITLE: "Choose the best package to fit your needs.",
      DESCRIPTION:
        "",
        PARSE: "Parsing",
        FULL_SERVICE: "Full service",
        PLAN_1: {
          BASIC: "Basic",
          SUB_BASIC: "Regular business customers",
          COUNT_BASIC: "document",
          PRICING_BASIC_PARSING: "9.999.000đ/month",
          PRICING_BASIC: "15.999.000đ/month",
          FEATURE_BASIC1: "Extracting 14 Fields of CV Information",
          FEATURE_BASIC2: "Format conversion: PDF, Doc, Docx, png, jpeg",
          FEATURE_BASIC3: "3 Users",
          FEATURE_BASIC4: "20 CVs Extracted Simultaneously",
          FEATURE_BASIC5: "Configure Callback After CV Extraction",
          FEATURE_BASIC6: "Manage job description",
          FEATURE_BASIC7: "Suggesting Candidates Matching the Job Requirements",
          FEATURE_BASIC8: "Scoring CV based on criteria: education, skills, work experience",
          
        },
        PLAN_2: {
          STANDARD: "Standard",
          SUB_STANDARD: "Perfect for businesses that need to capture specific data points from documents and train on their data",
          COUNT_STANDARD: "document",
          PRICING_STANDARD_PARSING: "19.999.000đ/month",
          PRICING_STANDARD: "24.999.000đ/month",
          FEATURE_STANDARD1: "Extracting 14 Fields of CV Information",
          FEATURE_STANDARD2: "Format conversion: PDF, Doc, Docx, png, jpeg",
          FEATURE_STANDARD3: "10 Users",
          FEATURE_STANDARD4: "50 CVs Extracted Simultaneously",
          FEATURE_STANDARD5: "Machine learning capability",
          FEATURE_STANDARD6: "Configure Callback After CV Extraction",
          FEATURE_STANDARD7: "Technical Support",
          FEATURE_STANDARD8: "Manage job description",
          FEATURE_STANDARD9: "Suggesting Candidates Matching the Job Requirements",
          FEATURE_STANDARD10: "Scoring CV based on criteria: education, skills, work experience, advanced matching",
          
          MOST_POPULAR: "Most Popular",
          },
        PLAN_3: {
          ENTERPRISE: "Enterprise",
          SUB_ENTERPRISE: "Ideal for enterprises that need to process multiple document types, train on their data, and implement custom workflows",
          COUNT_ENTERPRISE: "Contact",
          PRICING_ENTERPRISE: "Custom Pricing",
          FEATURE_ENTERPRISE1: "Extracting 14 Fields of CV Information",
          FEATURE_ENTERPRISE2: "Format conversion: PDF, Doc, Docx, png, jpeg",
          FEATURE_ENTERPRISE3: "Unlimited users",
          FEATURE_ENTERPRISE4: "50 CVs Extracted Simultaneously",
          FEATURE_ENTERPRISE5: "Machine learning capability",
          FEATURE_ENTERPRISE6: "Technical Support",
          FEATURE_ENTERPRISE7: "Integration support for SAP, CRM",
          FEATURE_ENTERPRISE8: "Synchronize Customer Database Support",
          
          
  
        },
      PRICING: "Custom Pricing",
      BASIC: "Basic",
      DOCUMENTS: "Documents",
      MOST_POPULAR: "Most Popular",
      STANDARD: "Standard",
      BUSINESS: "Business",
      BUY_NOW: "Buy now",
      FAQ: {
        DESCRIPTION: "Let us help answer the most common questions.",
        Q1: "Does the system charges for failed file?",
        A1: "You won’t be charged for your failed files.",
        Q2: "Can I use the system when I run out of quota?",
        A2: "You still have access to your processed files when run out of quota",
      },
    },
    NOTE: {
        TITLE: "Payment notes",
        ONE: "1. User pays money to buy quota, when run out of quota you can't upload more document.",
        TWO: "2. Each package has different price and number of document page. Please check before placing payment."
      },
      OPTIONS: {
        TITLE: "Payment options",
        VNPAY: "Pay via VNPay wallet",
        MOMO: "Pay via MoMo wallet",
        PAYPAL: "Pay via Paypal wallet"
      },
      DETAILS: {
        TITLE: "Payment Details",
        BUYER: "Buyer",
        EMAIL: "Email",
        PACKAGE: "Package",
        NUM_PAGE: "Quota",
        PAGE: "pages",
        PRICE: "Price",
        TOTAL_AMOUNT: "Total amount",
        PAY: "Pay"
      },
      PAYMENT_STATUS: {
        SUCCESS: "Success",
        PENDING: "Pending",
        ERROR: "Error",
        TITLE: "Your purchase at ",
        QUOTA: "Quota",
        PAYMENT_ID: "Payment Id",
        DATE: "Date",
        TIME: "Time",
        VIA: "Payment Via",
        TOTAL: "Total",
        CREATE_NEW: "Create a new transaction",
      },
      BILLING:{
        AMOUNT: "Amount",
        TYPE: "Method",
        QUOTA_BUY: "Quota buy",
        PREV_QUOTA: "Previous quota",
        UPDATED_QUOTA: "Updated quota",
        STATUS: "Status",
        CREATED_AT: "Created at",
        UPDATED_AT: "Updated at",
        SHOW: "Show",
        ENTRIES: "Entries"
      }

  },
};
