export const locale = {
    lang: 'en',
    data: {
      TALENT_POOL: {
        MANAGE_JOB: {
            JOB: "Job",
            ADD_JOB: "Add job",
            SHOW: "Show",
            CATEGORY: "Category",
            TYPE: "Type",
            LEVEL: "Level",
            APPLICATION1: "Application 1",
            APPLICATION2: "Application 2",

            NUM_SAVED_RESUMES: "Number saved resumes",
            HCM_SYSTEM_ID: "Hcm system id",
            ITEMS_PER_PAGE: "Items per page",
            SYNC_SUCCESSFACTORS: "Sync SuccessFactorsJR",
            PLACEHOLDER: 'Search job title',
            RECOMMEND: "Recommend",
            SAVED: "Saved",
            EDIT: "Edit",
            ACTION: "Action"
        },
        CREATE_JOB: {
            NEW_JOB: "New Job Offer",
            ADD_JOB: "Add a new job to your company's jobs list.",
            CHANGE_JOB: "Change Job Offer",
            
            REQUIRED: "This field is required!",
            LOCATION: "Location",
            JOB_LEVEL: "Job level",
            INDUSTRY: "Industry",
            JOB_TYPE: "Job type",
            GENDER: "Gender",
            YEARS_OF_EXPERIENCE: "Years of experience",
            YOB: "Yob",
            JOB_DESCRIPTION: "Job description",
            JOB_REQUIREMENT: "Job requirement",
            SUBMIT: "Submit",
            SUBMIT_ADD_JOB: "Add job posting",
            PLACEHOLDER_INDUSTRY: "Industry- Multiple Choice",
            BASIC_INFO: "Basic information",
            BASIC_REQUIRED: "Basic condition",
            REQUIRES_EXPERTISE: "Requires expertise",
            PLACEHOLDER:{
                YOB_FROM: " from",
                YOB_TO: " to",
                YEAR_OF_EXPERIENCE_FROM: " from",
                YEAR_OF_EXPERIENCE_TO: " to"

            }
        },
        DETAIL_JOB: {
            EDIT_JOB: "Edit job",
            RECOMMEND_RESUME: "Recommended resume",
            SAVED_RESUME: "Saved resume",
            SEARCH_RESUME: "Search resume",
            DESCRIPTION: "Description",
            REQUIREMENTS: "Requirements",
            KEYWORD_EXPRESSION: "Keyword expression",
            EXPERIENCE: "Experience",
            WORK_LEVEL: "Work level",
            EMPLOYMENT_TYPE: "Employment type",
            BIRTHDAY: "Birthday",
            ALL: "All",

        },
        SEARCH_RESUME: {
            JOB_TITLE: "Job Title",
            FULLTEXT_FILTER: "Fulltext Filter",
            EXPERIENCE_FILTER: "Experience Filter",
            EDUCATION_FILTER: "Education Filter",
            CURRENT_TITLE_FILTER: "Current Title Filter",
            CURRENT_COMPANY_FILTER: "Current Company Filter",
            ADDRESS: "Address",
            SEARCH: "Search",
            LOADING: "Loading",
            FOUND: "Found",
            RESUMES: "resumes",
            OWNER: "Owner",
            SKILL_SET: "Skillset",
            SAVE_CV: "Save CV",
            SAVED_CV: "Saved CV",
            SELECT_JD: "Select JD",
            NOT_JOB: "You have not created any job request yet, create more here:",
            CREATE_JOB: "Create job",
            PLACEHOLDER: "Enter keyword eg: (keyword 1 & keyword 2)||keyword 3",
            PLACEHOLDER_ADDRESS: ". Select address- Multiple choice"

        },
        RESUME_MATCHING: {
            CHOOSE_STATUS: "Choose status",
            CHOOSE_BY_EMAIL: "Choose created by email",
            RESUME_MATCH: "recommend times",
            CREATE_AT: "Created at",
            OVERALL_SCORE: "Overall score",
            STATUS: "Status",
            JOB_ID: "Job id",
            RESUME_ID: "Resume id",
            CREATED_BY: "Created by",
            RESUME_STATUS: "Resume status",
            JOB_APP_STATUS: "Job application status",
            FULL_NAME: "Full name",
            CURRENT_TITLE: "Current title",
            CURRENT_COMPANY: "Current company",
            FILTER: "Filter"
        },
        RECOMMEND_RESUME: {
            RECOMMENDED_RESUMES: "Recommended Resumes",
            FOR_THE_JOB_OF: "for the job of",
            SAVED: "saved",
            
            SAVED_RESUMES: "Saved Resumes",
            RESUME_HAD_SAVED: "Resume number has been saved:",
            TOASTR: {
                SCORE: "The system starts scoring compatibility",
                SUCCESS: "Success"
            }
        },
        SAVED_RESUME: {
            UPLOAD_SF4C: "Upload SuccessFactors"
        },
        RESUME_TALENT_POOL: {
            AT: "at",
            DETAIL: "Details",
            SAVE: "Save",
            STATUS: "Status:",
            OVERALL_SCORE: "Overall score: ",
            LOADING: "Calculating the candidate's suitability score"
        }

      }
      
    }
  };
  