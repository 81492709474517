import { Role } from './role';

// export class User {
//   id: number;
//   email: string;
//   password: string;
//   firstName: string;
//   lastName: string;
//   avatar: string;
//   role: Role;
//   token?: string;
// }
export class User {
  id! : number;
  email: string ='';
  password: string ='';
  access ?: string ;
  refresh ?: string;
  first_name!: string;
  last_name !: string;
  company_taxcode!: string;
  is_superuser!: boolean;
  is_staff!: boolean
  is_site_admin!: boolean
  last_login!: string
  permissions: string[] =[]
  company!: number
  role: string;
  //role: Role;
}
export class ListUser{
  results: User[]=[]
}
export class ResultChangePassword{
  status!: string
  message!: string
}
export class Company{
  id!: number
  company_taxcode!: string
  name!: string
}
export class ListCompany{
  results: Company[] = []
  count!: number
}
//User permission
export class UserPermissionTalentPool{
  id!: number
  is_accessible!: boolean
  user!: number
}
