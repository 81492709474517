import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResumeParserService } from '../services/resume-parser.service';
import { Jr, ResumeParser } from '../models/resume.model';
import { User } from 'app/auth/models/user';
import { Page } from '../models/paging.model';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { Subscription, concat, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IntegrationComponent implements OnInit {
  public isAuthenticated=false
  public resume_parser: ResumeParser[]=[]
  public resume_parserJA: ResumeParser[]=[]

  public myFiles:File[]=[];
  public errorMessagesGetJr!: any
  public errorMessagesGetFilterListResume!:any
  SearchForm!: FormGroup
  public file_name!: string
  public status=[ 
    {name: "Approved", value: "Approved"},
    {name: "Done", value: "Done"},
    {name: "Failure", value: "Failure"},
    {name: "Processing", value: "Processing"},
    {name: "Wait to process", value: "Wait_to_process"},
    {name: "Suspended", value: "Suspended"},
    {name: "Modified", value: "Modified"},
  ]
  public sf4c_status=[ 
    
    {name: "SF4C background failed", value: "SF4C_background_failed"},
    {name: "SF4C wait to upload", value: "SF4C_wait_to_upload"},
    {name: "SF4C failed", value: "SF4C_failed"},
    {name: "SF4C uploading", value: "SF4C_uploading"},
    {name: "SF4C uploaded", value: "SF4C_uploaded"},
    // {name: "Base upload failed", value: "Base_upload_failed"},
    // {name: "Base uploading", value: "Base_uploading"},
    // {name: "Base upload success", value: "Base_upload_success"},

  ]
  public selectedStatus:string[]=[]
  public selectedSf4cStatus:string[]=[]
  public email!: string
  public phone_number!: string
  public owner__email!: string
  public owner__email_items: any[]=[]
  public user: User = new User()
  public users: User[] = []
  public errorMessagesListUser: any
  public searchText!: string
  public page = new Page({});
  public pageJA = new Page({});
  ColumnMode = ColumnMode;
  widthColumn: number = 0;
  @ViewChild(DatatableComponent) tableRowDetails: DatatableComponent;
  INTERVAL_TIME = 3000;
  intervalSubscription: Subscription;
  public isLoading = false;
  public isLoadingJA = false;
  public loadingFormJA = false;

  public url_dynamic = ""
  public url_dynamicJA = ""

  public status_color: any = {
    "Approved": 'success',
    "SF4C_background_failed": 'warning',
    "Done": "primary",
    "Failure": "danger",
    "Processing": "info",
    "Wait_to_process":"dark",
    "Suspended":"muted",
    "Modified":"warning",
    "SF4C_wait_to_upload":"dark",
    "SF4C_failed":"secondary",
    "SF4C_uploading":"info",
    "SF4C_uploaded":"success"


  }
  public job_status_color: any = {
    "Success": 'success',
    "Fail": "danger",
    "Check": "warning"
  }

  public loading= false
  public loading_page = false
  public loading_page_filter = false
  public tag_export = false
  public errorMessagesResultConnectSf4cs!: any
  public tag_resultConnectSf4c_exit =false
  public allRowsSelected: boolean = false;

  //upload
 
  public arr_checkboxJr:string[]=[]
  public arr_checkbox:string[]=[]
  public arr_checkbox_current_title:string[]=[]


  formCheckBox = this.fb.group({
    checkResumeArray: this.fb.array([], Validators.required)

  })
  formCheckBoxJr = this.fb.group({
    checkResumeArrayJr: this.fb.array([],Validators.required)
  })
  
  public errorMessagesSf4cUpload!: any
  public pageJr=1
  public basicSelectedOption: number = 5;
  totalItemsJr: number = 0
  public resultJr: Jr[]=[]
  public have_Jr = false
  public loading_=false
  public status_sf4c_upload:string=''
  public tag_list_resume_upload=false

  public tag_filter_list_resume = false

  public range_conf: any = {
    'success': 0.6,
    'warning': 0.4,
  }
  conf_score__gte! : number
  conf_score__lte!:number
  public allSelected = false
  public resumeParserAll:ResumeParser[]=[]
  public selectedRows: ResumeParser[] = [];
  public selectedRowsJA: ResumeParser[] = [];
  public SelectionType = SelectionType;
  public role: string;
  public isLoadingJr = false;

  constructor(
    private modalService: NgbModal,
    private _router: Router,
    private fb: FormBuilder,
    private resumesService: ResumeParserService,
    private _authenticationService: AuthenticationService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,

  ){
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    
  }
  // modal Basic
  modalOpen(modalBasic:any) {

    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
    }
  deleteResume(id: number){
    this.resumesService.deleteResume(id);
  }
  onSubmit(){
    this.page.pageNumber=0
    this.SearchResume();
  }
  SearchResume(){
    this.isLoading = true
    let file_name = "&file_name="+this.file_name
    let url_status = "&status=Approved"
    // let status = this.selectedStatus==null?"&status=":"&status="+this.selectedStatus
    let email = "&email="+this.email
    let phone_number = "&phone_number="+this.phone_number
    let owner__email = "&owner__email="+this.owner__email
    // let conf_score__gte = "&conf_score__gte="+this.conf_score__gte
    // let conf_score__lte = "&conf_score__lte="+this.conf_score__lte

    let url_dynamic = ''
    if(this.file_name!=undefined&&this.file_name!==null){
      url_dynamic = url_dynamic + file_name
    }
    url_dynamic = url_dynamic + url_status
    if(this.email!=undefined&&this.email!==null){
      url_dynamic = url_dynamic + email
    }
    if(this.phone_number!==undefined&&this.phone_number!==null){
      url_dynamic = url_dynamic+phone_number
    }
    if(this.owner__email!==undefined&&this.owner__email!==null){
      url_dynamic = url_dynamic+owner__email
    }
    // if(this.conf_score__gte!==undefined&&this.conf_score__gte!==null){
    //   url_dynamic = url_dynamic+conf_score__gte
    // }
    // if(this.conf_score__lte!==undefined&&this.conf_score__lte!==null){
    //   url_dynamic = url_dynamic+conf_score__lte
    // }
    this.url_dynamic = url_dynamic
    
    this.resumesService.getFilterListResume(url_dynamic,this.page.pageNumber,this.page.size).subscribe(
      (res)=>{
        this.resume_parser = res.results;
        this.page.totalElements = res.count
        this.isLoading = false
      },
      (err) =>{
        this.errorMessagesGetFilterListResume= err
        this.intervalSubscription.unsubscribe();
      }
    )
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.resumesService.getFilterListResume(this.url_dynamic,this.page.pageNumber,this.page.size).subscribe(
      (res)=>{
        this.resume_parser = res.results;
        this.page.totalElements = res.count
        this.isLoading = false

      },
      (err) =>{
        this.errorMessagesGetFilterListResume= err
      }
    )
  }
  //update JA
  onSubmitJA(){
    this.pageJA.pageNumber=0
    this.SearchResumeJA();
  }
  SearchResumeJA(){
    this.isLoadingJA = true
    let file_name = "&file_name="+this.file_name
    let url_status = "&status=SF4C_uploaded&status=SF4C_background_failed"
    // let status = this.selectedStatus==null?"&status=":"&status="+this.selectedStatus
    let email = "&email="+this.email
    let phone_number = "&phone_number="+this.phone_number
    let owner__email = "&owner__email="+this.owner__email
    // let conf_score__gte = "&conf_score__gte="+this.conf_score__gte
    // let conf_score__lte = "&conf_score__lte="+this.conf_score__lte

    let url_dynamic = ''
    if(this.file_name!=undefined&&this.file_name!==null){
      url_dynamic = url_dynamic + file_name
    }
    url_dynamic = url_dynamic + url_status
    if(this.email!=undefined&&this.email!==null){
      url_dynamic = url_dynamic + email
    }
    if(this.phone_number!==undefined&&this.phone_number!==null){
      url_dynamic = url_dynamic+phone_number
    }
    if(this.owner__email!==undefined&&this.owner__email!==null){
      url_dynamic = url_dynamic+owner__email
    }
    // if(this.conf_score__gte!==undefined&&this.conf_score__gte!==null){
    //   url_dynamic = url_dynamic+conf_score__gte
    // }
    // if(this.conf_score__lte!==undefined&&this.conf_score__lte!==null){
    //   url_dynamic = url_dynamic+conf_score__lte
    // }
    this.url_dynamicJA = url_dynamic
    
    this.resumesService.getFilterListResume(url_dynamic,this.pageJA.pageNumber,this.pageJA.size).subscribe(
      (res)=>{
        this.resume_parserJA = res.results;
        this.pageJA.totalElements = res.count
        this.isLoadingJA = false
      },
      (err) =>{
        this.errorMessagesGetFilterListResume= err
        this.isLoadingJA = false
      }
    )
  }
  setPageJA(pageInfo) {
    this.pageJA.pageNumber = pageInfo.offset;
    this.resumesService.getFilterListResume(this.url_dynamicJA,this.pageJA.pageNumber,this.pageJA.size).subscribe(
      (res)=>{
        this.resume_parserJA = res.results;
        this.pageJA.totalElements = res.count
        this.isLoadingJA = false

      },
      (err) =>{
        this.errorMessagesGetFilterListResume= err
      }
    )
  }
  modalOpenUploadSf4c(modalBasic:any) {
    this.have_Jr = false
    this.getJr();
    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
    }
    
    getJr(){
      this.isLoadingJr = true
      let url_dynamic = ""
      for (let i=0;i<this.arr_checkbox_current_title.length;i++){
        let url_current = "job_title="+this.arr_checkbox_current_title[0]
        url_dynamic = url_dynamic ? url_dynamic + "&" + url_current : url_current
      }
     this.resumesService.getJrWithQuery(url_dynamic).subscribe(
      (res)=>{
        this.resultJr= res;
        this.totalItemsJr = res.length
        this.have_Jr = true
        this.isLoadingJr = false
      },
      (err)=>{
        console.error("err: "+err)
        this.isLoadingJr = false;
      }
     ) 
    }
  
  onCheckboxChangeJr(e:any) {
    const checkResumeArrayJr: FormArray = this.formCheckBoxJr.get('checkResumeArrayJr') as FormArray;
    if (e.target.checked) {

      checkResumeArrayJr.push(new FormControl(e.target.value));
      
      this.arr_checkboxJr.push(e.target.value)
    } else {
      let i: number = 0;
      checkResumeArrayJr.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArrayJr.removeAt(i);
          this.arr_checkboxJr=this.arr_checkboxJr.filter(item=>item!==e.target.value)
          return;
        }
        i++;
      });
    }
  }
  FormCheckBoxJr(){
    this.loading_ = true
    let arr_checkbox_cp:any=[]
    for (let i=0;i<this.selectedRows.length;i++){
      arr_checkbox_cp.push(this.selectedRows[i].id)
    }
    this.resumesService.sf4c_upload(arr_checkbox_cp,this.arr_checkboxJr).subscribe(
      (res)=>{
        this.status_sf4c_upload = res.status;
        this.loading_ = false
        this._router.navigateByUrl("/manage-resume");
        
      },
      (err)=>{
        this.errorMessagesSf4cUpload = err[0];
        this.loading_ = false
      }
    )
  }
  FormCheckBoxJA(){
    this.loadingFormJA = true
    let arr_checkbox_cp=[]
    for (let i=0;i<this.selectedRowsJA.length;i++){
      arr_checkbox_cp.push(this.selectedRowsJA[i].id)
    }
    this.resumesService.updateJA(arr_checkbox_cp,this.arr_checkboxJr).subscribe(
      (res)=>{
        this.status_sf4c_upload = res.status;
        this.loadingFormJA = false
        this.modalService.dismissAll();
        this._router.navigateByUrl("/manage-resume");
        
        
      },
      (err)=>{
        this.errorMessagesSf4cUpload = err[0]
        this.loadingFormJA = false
        this.modalService.dismissAll();
        
      }
    )
  }

  onCheckboxChangeAll(e:any){
    this.arr_checkbox = []
    let url_dynamic = 'status=Approved'
    this.resumesService.getAllResume(url_dynamic).subscribe(x=>{
      this.resumeParserAll = x
      const checkResumeArray: FormArray = this.formCheckBox.get('checkResumeArray') as FormArray;
      if(e.target.checked&&this.resumeParserAll.length>0){
        for (let i=0;i<this.resumeParserAll.length;i++){
          checkResumeArray.push(new FormControl(this.resumeParserAll[i].id.toString()));
          this.arr_checkbox.push(this.resumeParserAll[i].id.toString())
          this.arr_checkbox_current_title.push(this.resumeParserAll[i].current_title)
        }
      } else {
        for (let i=0;i<this.resumeParserAll.length;i++){
          checkResumeArray.removeAt(i);
          this.arr_checkbox=[]
          this.arr_checkbox_current_title = []
        }
      }
    })
  }
  public allSelectedSf4cUploaded = false
  public resumeParserSf4cUploadedAll:ResumeParser[]=[]

  onCheckboxChangeSf4cUploadedAll(e:any){
    
    let url_dynamic = 'status=SF4C_uploaded&status=SF4C_background_failed'
    this.resumesService.getAllResume(url_dynamic).subscribe(x=>{
      this.resumeParserAll = x
      const checkResumeArray: FormArray = this.formCheckBox.get('checkResumeArray') as FormArray;
      if(e.target.checked&&this.resumeParserAll.length>0){
        for (let i=0;i<this.resumeParserAll.length;i++){
          checkResumeArray.push(new FormControl(this.resumeParserAll[i].id.toString()));
          this.arr_checkbox.push(this.resumeParserAll[i].id.toString())
          this.arr_checkbox_current_title.push(this.resumeParserAll[i].current_title) 
        }
      } else {
        for (let i=0;i<this.resumeParserAll.length;i++){
          checkResumeArray.removeAt(i);
          this.arr_checkbox=[]
          this.arr_checkbox_current_title = []
        }
      }
    })
  }
  onCheckboxChange(e:any) {
    const checkResumeArray: FormArray = this.formCheckBox.get('checkResumeArray') as FormArray;
    if (e.target.checked) {
      let id = e.target.value.split('__')[0]
      let current_title = e.target.value.split('__')[1]
      checkResumeArray.push(new FormControl(id));
      this.arr_checkbox.push(id)
      console.log("id",id)

      console.log("arr_checkbox",this.arr_checkbox)
      this.arr_checkbox_current_title.push(current_title)
      
    } else {
      let id = e.target.value.split('__')[0]
      let current_title = e.target.value.split('__')[1]
      let i: number = 0;
      checkResumeArray.controls.forEach((item: any) => {
        if (item.value.includes(id) || item.value.includes(current_title)) {

          checkResumeArray.removeAt(i);
          this.arr_checkbox=this.arr_checkbox.filter(item=>item!==id)
          this.arr_checkbox_current_title = this.arr_checkbox_current_title.filter(item=>item!==current_title)      
          return;
        }
        i++;
      });
    }
  }
  FormCheckBox(){
  }
  Search(){
    // alert(this.searchText)
    if(this.searchText!== ""){
      let searchValue = this.searchText.toLocaleLowerCase();
      this.resultJr = this.resultJr.filter((resultJr_:any) =>{
        return resultJr_.jobTitle.toLocaleLowerCase().match(searchValue )
        ;
      // you can keep on adding object properties here
            });   
          }  
          else{
            this.getJr();
          }
        }
  setIsSelectedRows(item: any) {
    return this.selectedRows.some((row) => row.id == item.id);
  }
  setIsSelectedRowsJA(item: any) {
    return this.selectedRowsJA.some((row) => row.id == item.id);
  }
  onSelect({ selected }) {
    if (selected.length == 0) {
      // unselected all rows
      const ids = this.resume_parser.map((row) => row.id);
      this.selectedRows = this.selectedRows.filter(
        (row) => !ids.includes(row.id)
      );
    } else {
      this.selectedRows = []
      const ids = this.selectedRows.map((row) => row.id);
      selected.forEach((row) => {
        if (!ids.includes(row.id)) this.selectedRows.push(row);
      });
    }
    // if (selected.length > 0) {
    //   console.log("A")
    // } else {
    //   this.selectedRows = []
    //   // this.SearchResume();
    // }
  }
  onSelectJA({ selected }) {
    if (selected.length == 0) {
      // unselected all rows
      const ids = this.resume_parserJA.map((row) => row.id);
      this.selectedRowsJA = this.selectedRowsJA.filter(
        (row) => !ids.includes(row.id)
      );
    } else {
      this.selectedRowsJA = []
      const ids = this.selectedRowsJA.map((row) => row.id);
      selected.forEach((row) => {
        if (!ids.includes(row.id)) this.selectedRowsJA.push(row);
      });
    }
    // if (selected.length > 0) {
    //   console.log("A")
    // } else {
    //   this.SearchResumeJA();
    // }
  }
  onActivate(event) {
    // console.log('Activate Event', event);
  }
  unselectAllRows() {
    if (this.tableRowDetails?.selected) this.tableRowDetails.selected = [];
    this.selectedRows = [];
  }
  getListUser(){
    this._authenticationService.listUsers().subscribe(
      (res)=>{
        this.users = res.results;
        for (let i=0;i<this.users.length;i++){
          let owner__email_item = this.users[i].first_name + ' '+ this.users[i].last_name + ': '+ this.users[i].email
          this.owner__email_items.push({"name":owner__email_item, "value": this.users[i].email})
          
        }
        this.owner__email_items = [...this.owner__email_items] 
      },
      (err)=>{
        this.errorMessagesListUser = err
      }
    )
  }
  

  ngOnInit(): void {
    // this.user = JSON.parse(localStorage.getItem("currentUser")|| '{}');
    // this.role = localStorage.getItem("role");
    // let url_dynamic = 'Approved';
    // this.resumesService.getAllResume(url_dynamic).subscribe(x=>{
    //   this.resumeParserAll = x;
    //   console.log("aa",this.resumeParserAll)
    // })
    this.user = this._authenticationService.currentUserValue
    this.getListUser();
    this.SearchResume();
    this.SearchResumeJA();
    
    //this.GetListUser()
  }

}
