import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role, UserPermissionTalentPool } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private BASE_URL = `${environment.apiUrl}/organization/user`;

  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;
  //public
  public token: Observable<string>;
  private tokenSubject: BehaviorSubject<string>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService, private router: Router,) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("currentUser")||"{}"));
    this.currentUser = this.currentUserSubject.asObservable();

    this.tokenSubject = new BehaviorSubject<string>(localStorage.getItem("token"));
    this.token = this.tokenSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public setCurrentUserValue(value: User){
    this.currentUserSubject.next(value)
  }
  get tokenValue(): string{
    return this.tokenSubject.value;
  }
  public setTokenValue(value: string){
    this.tokenSubject.next(value)
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.User;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  // public methods
  login(email: string, password: string) {
    const url = `${this.BASE_URL}/get-token/`;
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    return this._http.post<any>(url,formData, {
      headers: {
        Accept: "application/json; indent=4",
      },
    }).pipe(
      map((auth) => {
        
        //const result = this.setAuthFromLocalStorage_(auth);
        this.currentUserSubject.next(auth);
        localStorage.setItem("currentUser", JSON.stringify(auth));
        localStorage.setItem("first_name", auth.first_name);
        localStorage.setItem("last_name", auth.last_name);
        localStorage.setItem("email", auth.email);
        localStorage.setItem("token", auth.access);
        localStorage.setItem("role", auth.role);
        localStorage.setItem("company", auth.company);
        this.tokenSubject.next(auth.access);
        setTimeout(
          () =>
            this._toastrService.success(
              `${auth.email} 🎉`,
              "👋 Welcome!",
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            ),
          500
        );
        return auth;
      }),
      // switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      })
    );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');

    // notify
    localStorage.clear();
    this.tokenSubject.next('');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login'], {
      queryParams: {},
    });
  }
  listUsers(): Observable<any>{
    const url = `${this.BASE_URL}`;
    return this._http.get<any>(url)
  }
  changePassword(formData: any):Observable<any>{
    const url = `${this.BASE_URL}/change-password`;
    return this._http.put<any>(url, formData)
  }
  updateUser(id: any, formData: any): Observable<any>{
    const url = `${this.BASE_URL}/${id}`;
    return this._http.put<any>(url, formData)
  }
  detailUser(id: number): Observable<any>{
    const url = `${this.BASE_URL}/${id}`;
    return this._http.get<any>(url) 
  }
  resetPassword(id: number): Observable<any>{
    const url = `${this.BASE_URL}/${id}/reset-password`;
    return this._http.put<any>(url,{})
  }
  createUser(formData: any): Observable<any>{
    const url = `${this.BASE_URL}/register/`;
    return this._http.post<any>(url, formData)
  }
  adminUpdateUser(id: any, first_name: string, last_name: string, email: string, permissions: string[],is_site_admin: boolean): Observable<any>{
    const url = `${this.BASE_URL}/${id}`;
    return this._http.put<any>(url, {first_name, last_name, email,permissions,is_site_admin})
  }
  deleteUser(id: any, assign_to: any): Observable<any>{
    const url = `${this.BASE_URL}/${id}/delete?assign_to=${assign_to}`;
    return this._http.delete<any>(url)
  }
  //Talent pool
  update_user_permission_talent_pool(id: any, is_accessible: boolean): Observable<UserPermissionTalentPool>{
    const url = `${environment.apiUrl}/talent-pool/user-setting/${id}/`;
    return this._http.put<UserPermissionTalentPool>(url,{is_accessible})
  }
  //List company
  list_company(): Observable<any>{
    const url = `${environment.apiUrl}/organization/company`;
    return this._http.get<any>(url)
  }
  updateCompany(id: any, formData: any): Observable<any>{
    const url = `${environment.apiUrl}/organization/company/${id}`;
    return this._http.put<any>(url, formData)
  }
}
