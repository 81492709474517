export const locale = {
    lang: 'en',
    data: {
      RESUME_PARSER: {
        PARSE_RESUME: {
            
            UPLOAD_UP_TO: "Upload up to 50 Files CV (PDF, DOC, DOCX, EXCEL, JPEG)",
            RESUMES: "resumes",
            UPLOAD: "Upload",
            FILE_NAME: "File Name",
            STATUS: "Status",
            SF4C_STATUS: "Sf4c status",
            CREATE_AT: "Created At",
            FIRST_NAME: "First Name",
            LAST_NAME: "Last Name",
            ADDRESS: "Address",
            EMAIL: "Email",
            PHONE_NUMBER: "Phone number",
            GENDER: "Gender",
            DATE_OF_BIRTH: "Date Of Birth",
            CURRENT_TITLE: "Current Title",
            CURRENT_COMPANY: "Current Company",
            CONF_SCORE: "Confidence Score",
            APPROVE_RESUMES: "Approve resumes",
            SELECT_JOB: "Select job requirement title",
            UPLOAD_SF4C: "Upload SuccessFactors",
            JOB_REQ_ID: "Job requirement id",
            JOB_TITLE: "Job title",
            SUBMIT: "Submit",
            LABEL_UPLOAD: 'Drag and drop documents here or upload up 1 CV file (PDF, DOC, DOCX, JPEG, EXCEL)',
            FROM_DEVICE: 'From Device',
        },
        MANAGE_RESUME: {
            SEARCH: "Search",
            SHOW: "Show",
            ADD_RESUME: "Add resume",
            MODIFIED_AT: "Modified At",
            YEAR_EXPERIENCE: "Year Experience",
            JOB_APPLICATION_STATUS: "Job Application Status",
            DELETE_RESUME: "Delete resume!",
            DOWNLOAD_TYPE: "Download",
            DOWNLOADING: "Downloading",
            CANCLE: "Cancle",
            ACCEPT: "Accept",
            OWNER: "Owner",
            CHOICE_STATUS: "Choice status multiple",
            CHOICE_SF4C_STATUS: "Choice Sf4c status multiple",
            CHOICE_OWNER: "Choice owner email",
            MIN_CONF: "Min confidence (0,1)",
            MAX_CONF: "Max confidence (0,1)",
            ACTION: "Action",
            MODAL: {
              ACTION: "Action",
              DELETE_R: "Delete resume",
              CANT_UNDO: "You won't be able to undo this",
              CANCEL: "Cancel",
              CONFIRM: "Confirm",
            },
            TOAST: {
              EDIT_R_SUC: "Edit resume",
              CREATE_R_SUC: "Create resume",
              DELETE_R_SUC: "Delete resume",
              ERROR: "Error",
              FAILED: "Failed",
              SUCCESS: "Success"
            },
        },
        INTEGRATION: {
            INTEGRATION: "Integration SuccessFactors",
            NO_SUITABLE_JR: " There is no suitable JR",
            UPLOAD_FAILED: " Upload failed!",
            UPDATE_JA_SUCCESSFACTORS: " Update Job Application SuccessFactors",
            UPLOAD: " Upload",
            NO_RESUME_APPROVE:"There are no resume in an Approved status.",
            NO_RESUME_SF4C_UPLOAD:"There are no resume in an SF4C_uploaded status.",
        },
        DETAIL_RESUME:{
            TAB_CHR: "Parse resume C-BDP-HR",
            TAB_SF4C: "Parse resume of SuccessFactors",
            CANDIDATE_INFORMATION: "Candidate information.",
            SF4C_RESUME_ID: "Successfactor resume id:",
            META_DATA: "General information",
            DOB: "Dob",
            CATEGORY: "Category",
            SKILLS: "Skills",
            CONF_SCORE: "Confidence score",
            WORK_EXPERIENCE: "Work Experience",
            POSITION: "Position",
            COMPANY: "Company",
            INDUSTRY: "Industry",
            MAJOR: "Major",
            SCHOOL: "School",
            DIPLOMA: "Diplona",
            GPA: "GPA",
            EDUCATION: "Education & Training",
            EDUCATION_: "Education",
            JOB_REQ_MATCHING: "Job Requisition matching",
            SELECT_JR: "Select JR:",
            ANALYSIS: "Analysis",
            NO_MATCHING: "No matching results were found",
            SEARCHING: "Searching results",
            MATCHING_POINT: "Matching point",
            EXPERIENCE: "Experience",
            FOREIGN_LANGUAGE: "Foreign language",
            CANDIDATE_SKILL: "The candidate's skills are suitable for the job:",
            APPROVE: "Approve",
            UPSERT: "Upsert",
            EDIT: "Edit",
            CENSOR: "Censor",
            CANDIDATE_SF4C: "Candidate information of SuccessFactors.",
            YEAR_EXPERIENCE: "Year experience",
            PICKLIST_MAJOR: "Picklist major",
            DEGREE: "Degree",
            DECENSOR: "Decensor",
            EDIT_CANDIDATE: "Edit candidate information.",
            CHOOSE_GENDER: "Choose gender",
            CHOOSE_CATEGORY: "Choose category",
            CHOOSE_YEAR_EXPERIENCE: "Choose year experience",
            START_TIME: "Start time",
            END_TIME: "End time",
            CHOOSE_INDUSTRY: "Choose industry",
            DETAIL: "Detail",
            CHOOSE_MAJOR: "Choose major",
            CHOOSE_DIPLOMA: "Choose diploma"
        },
      },
      
    }
  };
  