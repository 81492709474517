import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { DetailResume, ListResumeMatching, ResultMatchingCV, ResultSearchResume, ResultSyncSf4cJr, SaveResumeByJd, SearchResume } from '../models/resume.model';
import { Observable } from 'rxjs';
import { CV_task } from '../models/job.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TalentPoolService {
  private BASE_URL = `${environment.apiUrl}`;
  constructor(
    private _http: HttpClient,
  ) { }
  //Talent Pool
//Search cv
search_resume(page_num: number, job_title: string, city: string[], fulltext_bool_expression: string, experience_bool_expression: string, education_bool_expression: string, current_title_bool_expression: string, current_company_bool_expression: string):Observable<ResultSearchResume>{
  const url = `${this.BASE_URL}/talent-pool/search/`;
  return this._http.post<ResultSearchResume>(url,{page_num, job_title, city, fulltext_bool_expression, experience_bool_expression, education_bool_expression, current_title_bool_expression, current_company_bool_expression})
  .pipe(
    map((res)=>{
      let processedResult: SearchResume[] = []
      res['result'].forEach((r)=>{
        let processed_r = {...r}
        let experiences = r.experience_fulltext.split("\n\n");
        let processed_experiences = experiences.map((e)=>(`<div><span class="candidate-position">${e.split('\n')[0]}</span> - <span class="candidate-company">${e.split('\n')[1]}</span></div><span>${e.split('\n').slice(2,4).join("<br>") + '</br> ...'}</span>`))
        processed_experiences = processed_experiences.slice(0,2)
        let educations =  r.education_fulltext ? r.education_fulltext.split("\n\n"): [];
        let processed_educations = educations.map((e)=>(`<div><span class="candidate-school">${e.split('\n')[0]}</span></div>${e.split('\n').slice(1,3).join("<br>") + '<br>...'}`))
        processed_educations = processed_educations.slice(0,1)
        processed_r ={...processed_r, "experiences": processed_experiences, "educations": processed_educations}
        processedResult.push(processed_r);
      })
      res = {...res,result: processedResult}
      return res;
    }
  )
  )
}

//Detail resume
detail_resume(resume_id: any):Observable<DetailResume>{
  const url = `${this.BASE_URL}/resume/${resume_id}`;
  return this._http.get<DetailResume>(url)
}
//Recommend resume
recommend_resume(url_dynamic: string, city: string[],fulltext_bool_expression: string, experience_bool_expression: string, education_bool_expression: string, current_title_bool_expression: string,current_company_bool_expression: string): Observable<ResultSearchResume>{
  const url = `${this.BASE_URL}/talent-pool/recommend-cvs/`+`${url_dynamic}`;
  return this._http.post<ResultSearchResume>(url, {city, fulltext_bool_expression, experience_bool_expression, education_bool_expression, current_title_bool_expression, current_company_bool_expression})
  .pipe(
    map((res)=>{
      let processedResult: SearchResume[] = []
      res['result'].forEach((r)=>{
        let processed_r = {...r}
        let experiences = r.experience_fulltext.split("\n\n");
        let processed_experiences = experiences.map((e)=>(`<div><span class="candidate-position">${e.split('\n')[0]}</span> - <span class="candidate-company">${e.split('\n')[1]}</span></div><span>${e.split('\n').slice(2,4).join("<br>") + '</br> ...'}</span>`))
        processed_experiences = processed_experiences.slice(0,2)
        let educations =  r.education_fulltext ? r.education_fulltext.split("\n\n"): [];
        let processed_educations = educations.map((e)=>(`<div><span class="candidate-school">${e.split('\n')[0]}</span></div>${e.split('\n').slice(1,3).join("<br>") + '<br>...'}`))
        processed_educations = processed_educations.slice(0,1)
        processed_r ={...processed_r, "experiences": processed_experiences, "educations": processed_educations}
        processedResult.push(processed_r);
      })
      res = {...res,result: processedResult}
      return res;
    }
  )
  )
}
//List Saved Resume By Jd
list_saved_resume_by_jd(id: number): Observable<SearchResume[]>{
  const url = `${this.BASE_URL}/talent-pool/saved-cvs/?job_description=${id}`;
  return this._http.get<SearchResume[]>(url)
  .pipe(
    map((res)=>{
      let processedResult: SearchResume[] = []
    res.forEach((r)=>{
      let processed_r = {...r}
      let experiences = r.experience_fulltext ? r.experience_fulltext.split("\n\n"): [];
      let processed_experiences = experiences.map((e)=>(`<div><span class="candidate-position">${e.split('\n')[0]}</span> - <span class="candidate-company">${e.split('\n')[1]}</span></div><span>${e.split('\n').slice(2,4).join("<br>") + '</br> ...'}</span>`))
      processed_experiences = processed_experiences.slice(0,2)
      let educations =  r.education_fulltext ? r.education_fulltext.split("\n\n"): [];
      let processed_educations = educations.map((e)=>(`<div><span class="candidate-school">${e.split('\n')[0]}</span></div>${e.split('\n').slice(1,3).join("<br>") + '<br>...'}`))
      processed_educations = processed_educations.slice(0,1)
      processed_r ={...processed_r, "experiences": processed_experiences, "educations": processed_educations}
      processedResult.push(processed_r);
    });
    res = [...processedResult]
      return res;
    }
  )
  )
}
save_resume_by_jd(job_description: number, resume:number): Observable<SaveResumeByJd>{
  const url = `${this.BASE_URL}/talent-pool/saved-cvs/`;
  return this._http.post<SaveResumeByJd>(url, {job_description, resume})
}
//Job Description Management
//List All Jd
list_all_jd(): Observable<CV_task[]>{
  const url = `${this.BASE_URL}/job-description/`;
  return this._http.get<CV_task[]>(url)
}
//Create New Jd
create_new_jd(job_title: string,location: string, job_level:string, industry:any[], job_type: string, job_description: string, requirements: string, years_of_experience_from: number, years_of_experience_to: number, yob_from: number, yob_to: number, gender: string, keyword_expression:string,hcm_system_id: any): Observable<CV_task>{
  const url = `${this.BASE_URL}/job-description/`;
  return this._http.post<CV_task>(url, {job_title, location, job_level, industry, job_type, job_description, requirements, years_of_experience_from, years_of_experience_to, yob_from, yob_to, gender,keyword_expression, hcm_system_id})
}
//Detail Jd
detail_jd(jd_id: any): Observable<CV_task>{
  const url = `${this.BASE_URL}/job-description/${jd_id}/`;
  return this._http.get<CV_task>(url)
}
//Edit Jd
edit_jd(jd_id: any, job_title: string,location: string, job_level:string, industry:any[], job_type: string, job_description: string, requirements: string, years_of_experience_from: number, years_of_experience_to: number, yob_from: number, yob_to: number, gender: string, keyword_expression:string):Observable<CV_task>{
  const url = `${this.BASE_URL}/job-description/${jd_id}/`;
  return this._http.put<CV_task>(url,{job_title, location, job_level, industry, job_type, job_description, requirements, years_of_experience_from, years_of_experience_to, yob_from, yob_to, gender,keyword_expression})
}
//List match resume
list_match_resume(page: number, page_size: number, url_dynamic: string): Observable<ListResumeMatching>{
  const url = `${this.BASE_URL}/resume-matching/sf4c?page=${page}&page_size=${page_size}`+`${url_dynamic}`;
  return this._http.get<ListResumeMatching>(url)
}
//Sync sf4c JR
sync_sf4c_jr(): Observable<ResultSyncSf4cJr>{
  const url = `${this.BASE_URL}/job-description/sf4c_jr`;
  return this._http.post<ResultSyncSf4cJr>(url,{})
}
//Matching CV-JD
matching_cv_jd(resume:any,job_description: any): Observable<ResultMatchingCV>{
  const url = `${this.BASE_URL}/search/score`;
  return this._http.post<ResultMatchingCV>(url, {resume, job_description})
}
}
