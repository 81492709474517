export const locale = {
    lang: 'vn',
    data: {
      TALENT_POOL: {
        MANAGE_JOB: {
            JOB: "Công việc",
            ADD_JOB: "Thêm đăng tuyển",
            SHOW: "Hiển thị",
            CATEGORY: "Loại",
            TYPE: "Kiểu",
            LEVEL: "Trình độ",
            APPLICATION1: "Ứng dụng 1",
            APPLICATION2: "Ứng dụng 2",
            NUM_SAVED_RESUMES: "Số hồ sơ đã lưu",
            ITEMS_PER_PAGE: "Mục trên trang",
            HCM_SYSTEM_ID: "id hệ thống SuccessFactors",
            SYNC_SUCCESSFACTORS: "Đồng bộ công việc trên SucccessFactors",
            PLACEHOLDER: 'Tìm kiếm tiêu đề đăng tuyển',
            RECOMMEND: "Khuyến nghị",
            SAVED: "Đã lưu",
            EDIT: "Sửa",
            ACTION: "Hành động"
        },
        CREATE_JOB: {
            NEW_JOB: "Tạo công việc mới",
            ADD_JOB: "Thêm một công việc mới vào danh sách công việc của công ty bạn.",
            CHANGE_JOB: "Thay đổi công việc",   
            REQUIRED: "Trường yêu cầu bắt buộc",
            LOCATION: "Địa điểm",
            JOB_LEVEL: "Cấp độ công việc",
            INDUSTRY: "Lĩnh vực",
            JOB_TYPE: "Loại hình công việc",
            GENDER: "Giới tính",

            YEARS_OF_EXPERIENCE: "Số năm kinh nghiệm",
            YOB: "Năm sinh",
            JOB_DESCRIPTION: "Mô tả công việc",
            JOB_REQUIREMENT: "Yêu cầu công việc",
            SUBMIT: "Gửi",
            SUBMIT_ADD_JOB: "Thêm đăng tuyển",

            PLACEHOLDER_INDUSTRY: "Lĩnh vực - Nhiều lựa chọn",
            BASIC_INFO: "Thông tin cơ bản",
            BASIC_REQUIRED: "Yêu cầu cơ bản",
            REQUIRES_EXPERTISE: "Yêu cầu chuyên môn ",
            PLACEHOLDER:{
                YOB_FROM: " từ",
                YOB_TO: " tới",
                YEAR_OF_EXPERIENCE_FROM: "từ",
                YEAR_OF_EXPERIENCE_TO: "tới"

            }
        },
        DETAIL_JOB: {
            EDIT_JOB: "Sửa đổi công việc",
            RECOMMEND_RESUME: "Khuyến nghị hồ sơ",
            SAVED_RESUME: "Hồ sơ đã lưu",
            SEARCH_RESUME: "Tìm kiếm hồ sơ",
            DESCRIPTION: "Mô tả",
            REQUIREMENTS: "Yêu cầu",
            KEYWORD_EXPRESSION: "Biểu thức từ khóa",
            EXPERIENCE: "Kinh nghiệm",
            WORK_LEVEL: "Trình độ công việc",
            EMPLOYMENT_TYPE: "Loại việc làm",
            BIRTHDAY: "Ngày sinh",
            ALL: "Tất cả",

        },
        SEARCH_RESUME: {
            JOB_TITLE: "Vị trí công việc",
            FULLTEXT_FILTER: "Thông tin chung",
            EXPERIENCE_FILTER: "Kinh nghiệm",
            EDUCATION_FILTER: "Học vấn",
            CURRENT_TITLE_FILTER: "Vị trí công việc hiện tại",
            CURRENT_COMPANY_FILTER: "Công ty hiện tại",
            ADDRESS: "Địa chỉ",
            SEARCH: "Search",
            LOADING: "Đang tải",
            FOUND: "Đã tìm",
            RESUMES: "hồ sơ",
            OWNER: "Chủ sở hữu",
            SKILL_SET: "Kỹ năng",
            SAVE_CV: "Lưu CV",
            SAVED_CV: "CV đã lưu",
            SELECT_JD: "Lựa chọn JD",
            NOT_JOB: "Bạn chưa tạo yêu cầu công việc nào, hãy tạo thêm tại đây:",
            CREATE_JOB: "Tạo công việc",
            PLACEHOLDER: "Điền từ khóa, ví dụ dạng: (từ khóa 1 & từ khóa 2)||từ khóa 3",
            PLACEHOLDER_ADDRESS: "Chọn địa chỉ - một hoặc nhiều lựa chọn"
        },
        RESUME_MATCHING: {
            CHOOSE_STATUS: "Chọn trạng thái",
            CHOOSE_BY_EMAIL: "Chọn e-mail chủ sở hữu",
            RESUME_MATCH: "lượt gợi ý ứng viên",
            CREATE_AT: "Thời gian tạo",
            OVERALL_SCORE: "Tổng điểm",
            STATUS: "Trạng thái",
            JOB_ID: "Id công việc",
            RESUME_ID: "Id hồ sơ",
            CREATED_BY: "Tạo bởi",
            RESUME_STATUS: "Trạng thái hồ sơ",
            JOB_APP_STATUS: "Trạng thái ứng dụng công việc",
            FULL_NAME: "Tên đầy đủ",
            CURRENT_TITLE: "Vị trí công việc hiện tại",
            CURRENT_COMPANY: "Công ty hiện tại",
            FILTER: "Lọc"

        },
        RECOMMEND_RESUME: {
            RECOMMENDED_RESUMES: "Khuyến nghị hồ sơ",
            FOR_THE_JOB_OF: "cho công việc của",
            SAVED: "đã lưu",      
            SAVED_RESUMES: "Hồ sơ đã lưu",
            RESUME_HAD_SAVED: "Số hồ sơ đã được lưu:",
            TOASTR: {
                SCORE: "Hệ thống bắt đầu tính điểm phù hợp",
                SUCCESS: "Thành công"
            }
        },
        SAVED_RESUME: {
            UPLOAD_SF4C: "Tải lên SuccessFactors"
        },
        RESUME_TALENT_POOL: {
            AT: "tại",
            DETAIL: "Chi tiết",
            SAVE: "Lưu",
            STATUS: "Trạng thái:",
            OVERALL_SCORE: "Điểm phù hợp: ",
            LOADING: "Đang tính điểm phù hợp của ứng viên"

        }
      }
      
    }
  };
  