import { NgModule } from "@angular/core";

import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { TranslateModule } from "@ngx-translate/core";
import { CoreCommonModule } from "@core/common.module";
import { RouterModule, Routes } from "@angular/router";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthenticationModule } from "./pages/authentication/authentication.module";
import { MiscellaneousModule } from "./pages/miscellaneous/miscellaneous.module";
import { AccountSettingsModule } from "./pages/account-settings/account-settings.module";
import { AuthGuard } from "app/auth/helpers/auth.guards";
import { JwtInterceptor } from "app/auth/helpers/jwt.interceptor";
import { AuthLoginV2Component } from "./pages/authentication/auth-login-v2/auth-login-v2.component";
import { AuthRegisterV2Component } from "./pages/authentication/auth-register-v2/auth-register-v2.component";
import { ErrorComponent } from "./pages/miscellaneous/error/error.component";
import { ResumeParserModule } from "./resume-parser/resume-parser.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { TalentPoolModule } from "./talent-pool/talent-pool.module";
import { ManageUserModule } from "./pages/manage-user/manage-user.module";
import { LandingPageModule } from "./landing-page/landing-page.module";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { PaymentModule } from "./payment/payment.module";
import { TryComponent } from "./landing-page/try/try.component";
import { ResumeComponent } from "./landing-page/try/resume/resume.component";

const routes: Routes = [
  {
    path: "",
    component: LandingPageComponent,
  },
  {
    path: "try",
    component: TryComponent,
    canActivate: [AuthGuard],
  },
  {
    path:"resume/:id",
    component: ResumeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    component: AuthLoginV2Component,
    data: { animation: "auth" },
  },
  {
    path: "register",
    component: AuthRegisterV2Component,
  },
  {
    path: "error",
    component: ErrorComponent,
    data: { animation: "misc" },
  },
  
];

@NgModule({
  imports: [
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    RouterModule.forChild(routes),
    AuthenticationModule,
    MiscellaneousModule,
    AccountSettingsModule,
    ResumeParserModule,
    DashboardModule,
    TalentPoolModule,
    ManageUserModule,
    LandingPageModule,
    PaymentModule,
  ],
  providers: [
    AuthGuard,
    { 
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true,
    // },
  ],
  exports: [],
  declarations: [],
})
export class MainModule {}
