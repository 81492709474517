export const locale = {
    lang: 'vn',
    data: {
        DASHBOARD:{
            CHOOSE_COMPANY: "Chọn công ty",
            ALL_COMPANY: "Tất cả",
            FILTER: "Lọc",
            RESET: "Bỏ lọc",
            RESUME_UPLOADED: "Hồ sơ đã tải lên",
            RESUME_UPLOADED_DAY: "Hồ sơ đã tải lên 7 ngày gần nhất",
            AVERAGE_CONF_SCORE: "Điểm tin cậy trung bình",
            RESUME_STATUS: "Trạng thái của hồ sơ",
            RESUME_OWNER: "Hồ sơ ứng viên của chủ sở hữu",
            RESUME_STATUS_DAY: "Trạng thái hồ sơ theo ngày trong 14 ngày qua",
            
            RESUME_STATUS_SF4C: "Trạng thái hồ sơ đã tải lên SuccessFactors",
            RESUME_STATUS_SF4C_DAY: "Trạng thái hồ sơ đã tải lên SuccessFactors theo ngày trong 14 ngày qua",
            
            RESUME_MATCHING: "Hồ sơ phù hợp đã tải lên",
            RESUME_MATCHING_DAY: "Hồ sơ phù hợp đã tải lên trong 7 ngày qua",
            RESUME_MATCHING_SF4C: "Hồ sơ phù hợp đã tải lên SuccessFactors",
            RESUME_MATCHING_SF4C_DAY: "Hồ sơ phù hợp đã tải lên SuccessFactors theo ngày trong 14 ngày qua",
            JOB_ID: "Id công việc",
            CREATE_AT: "Thời gian tạo",
            CREATE_BY: "Người tạo",
            COUNT_RECOMMEND: "Số lượng khuyến nghị",
            RESUME_ID: "Id CV",
            OVERALL_SCORE: "Điểm tổng",
            NAME_JOB: "Tên công việc",
            ITEM_PAGE: "Mục trên trang",
            CHOOSE_PERIOD: "Chọn giai đoạn"

          }
      
    }
  };
  