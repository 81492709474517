<div id="header-landing">
    <nav class="navbar navbar-expand-md navbar-dark container-fluid position-fixed nav mb-5 bg-white"
      style="top: 0;">
  
      <div class="container">
        <a class="navbar-brand fw-bold" href="#">
          <img src="../assets/images/landing-page/logo.svg" alt="logo" />
        </a>
  
        <button (click)="isCollapsed = ! isCollapsed" [attr.aria-expanded]="!isCollapsed" id="button-toggler" class="navbar-toggler"
          style="background-color: #00a5f4" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
  
        <div #collapse="ngbCollapse" [(ngbCollapse)]="!isCollapsed" class="collapse navbar-collapse d-flex justify-content-end"
          id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto mb-lg-0" *ngIf="displayMenu">
            <li class="nav-item hover-item">
              <a class="nav-link text-black" role="button" aria-expanded="false" (click)="onClick('introduce')" translate="FOOTER.INTRO"></a>
            </li>
            <li class="nav-item hover-item">
              <a class="nav-link text-black" role="button" aria-expanded="false" (click)="onClick('solution')" translate="HEADER.SOLUTION"></a>
            </li>
            <li class="nav-item hover-item">
              <a class="nav-link text-black" aria-current="page" (click)="onClick('pricing')" translate="HEADER.PRICE"></a>
            </li>
            <li class="nav-item hover-item">
              <a class="nav-link text-black" target="_blank" href="https://cmcati.vn/" translate="HEADER.ABOUT_US"></a>
            </li>
          </ul>
          <ul class=" navbar-nav align-items-center" >
            <li ngbDropdown class="nav-item dropdown dropdown-language">
              <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
                <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
                ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
              >
              <div ngbDropdownMenu aria-labelledby="dropdown-flag">
                <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
                  <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
                </a>
              </div>
            </li>
            <li class="nav-item ">
              <button class="btn btn-primary mr-1" rippleEffect style="border-radius: 40px" rippleEffect>
                <a [routerLink]="'/try'" class="p-0 text-white" style="text-decoration: none" translate="HEADER.TRY">
                </a>
              </button>
            </li>
            <li *ngIf="!isLogin" class="nav-item ">
              <a class="btn btn-outline-primary mr-1" rippleEffect [routerLink]="'/login'" style="border-radius: 40px;" rippleEffect>
                <span translate="HEADER.SIGNIN"> </span>
              </a>
            </li>
  
            
            
            
            
            
            <li ngbDropdown class="nav-item dropdown-user">
              <a
                class="nav-link dropdown-toggle dropdown-user-link"
                id="dropdown-user"
                ngbDropdownToggle
                id="navbarUserDropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                
                <ng-container *ngIf="isLogin">
                  <div class="row align-items-center mt-1">
                    <div style="margin-top: 16px;">
                      <span class="user-name font-weight-bolder"
                      >{{ currentUser.first_name }} {{ currentUser.last_name }}</span
                    >
                    <br>
                    
                    <span style="font-size: smaller;">{{ currentUser.role }}</span>
                    </div>
                    <div>
                      <span class="avatar"
                      ><img
                        class="round"
                        src="assets/images/portrait/small/avatar-s-1.jpg"
                        alt="avatar"
                        height="40"
                        width="40" />
                      </span>
                    </div>
                  </div>
                  
                </ng-container>
              </a>
              <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
                <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
  
    </nav>
  </div>