<div class="container-fluid block3 bg-image" id="solution">
    <div
      class="container d-flex justify-content-center mb-5"
      style="max-width: 532px; text-align: center"
    >
      <h2 style="font-size: 48px" [translate]="'SOLUTION.TITLE'"></h2>
    </div>
    <div class="container d-flex justify-content-around mb-5">
      <div class="row contain-feature">
        <div class="col-md col-sm">
          <img class="icon" src="../assets/images/landing-page/solution1.png" alt="Vector1" />
          <h3 class="my-3" [translate]="'SOLUTION.AVG_0.TITLE'"></h3>
          <p style="color: #3c4f62" [translate]="'SOLUTION.AVG_0.SUB_TITLE'"></p>
        </div>
        <div class="col-md col-sm">
          <img class="icon" src="../assets/images/landing-page/solution2.png" alt="Vector1" />
          <h3 class="my-3" [translate]="'SOLUTION.AVG_1.TITLE'"></h3>
          <p style="color: #3c4f62" [translate]="'SOLUTION.AVG_1.SUB_TITLE'"></p>
        </div>
        <div class="col-md col-sm">
          <img class="icon" src="../assets/images/landing-page/solution3.png" alt="Vector1" />
          <h3 class="my-3" [translate]="'SOLUTION.AVG_2.TITLE'"></h3>
          <p style="color: #3c4f62" [translate]="'SOLUTION.AVG_2.SUB_TITLE'"></p>
        </div>
        <div class="col-md col-sm">
          <img class="icon" src="../assets/images/landing-page/solution4.png" alt="Vector1" />
          <!-- <img class="icon" src="../assets/images/landing-page/multi-industry.png" alt="Vector1" /> -->
          
          <h3 class="my-3 " [translate]="'SOLUTION.AVG_3.TITLE'"></h3>
          <p style="color: #3c4f62" [translate]="'SOLUTION.AVG_3.SUB_TITLE'"></p>
        </div>
        
      </div>
    </div>
    <!-- <div class="container d-flex justify-content-center img-demo">
    <img src="../assets/images/solution.png" alt="Frame16" />
    </div> -->
  </div>
  