export const locale = {
    lang: 'vn',
    data: {
        ACCOUNT_SETTING:{
            GENERAL:{
                TITLE: "Tổng quan",
                FIRST_NAME: "Tên đầu",
                LAST_NAME: "Tên cuối",
                EMAIL: "E-mail",
                SAVE: "Lưu thay đổi",
                CANCEL: "Hủy",
                REQUIRED: "Trường này bắt buộc"
            },
            PASSWORD: {
                TITLE: "Thay đổi mật khẩu",
                OLD_PASS: "Mật khẩu cũ",
                NEW_PASS: "Mật khẩu mới",
                NOT_MATCH: "Mật khẩu mới không khớp",
                RETYPE_PASS: "Mật khẩu mới lặp lại",
                NOT_SAME: "Mật khẩu không khớp"
            },
            RESUME_SETTINGS: {
                TITLE: "Cài đặt trích xuất tự động",
                AUTO_APPROVE: "Hồ sơ tự động được chấp nhận",
                AUTO_SYNC: "Hồ sơ được tự động trích xuất bởi e-mail",
                CENSOR_CONTACT: "Che thông tin",
                APPROVE_SCORE: "Điểm tin cậy phê duyệt",
                WEBHOOK_PARSING: "Tích hợp trích xuất",
                WEBHOOK_DEV_PARSING: "Tích hợp phát triển trích xuất",
                WEBHOOK_MATCHING: "Tích hợp ứng viên phù hợp với đăng tuyển",
                WEBHOOK_DEV_MATCHING: "Tích hợp phát triển ứng viên phù hợp với đăng tuyển",
                WEBHOOK_RECOMMEND:"Tích hợp khuyến nghị ứng viên",
                WEBHOOK_RECOMMEND_DEV_API: "Tích hợp phát triển khuyến nghị ứng viên"
            },
            CONNECT_SF4C: {
                TITLE: "Kết nối tới SuccessFactors",
                UPLOAD_FILE: "Tải lên tệp PEM",
                API_KEY: "Khóa API",
                COMPANY_ID: "Id công ty",
                USER_NAME: "Tên người dùng",
                TOKEN_URL: "Mã thông báo url",
            },
            SOURCE_MAPPING: {
                TITLE: "Cấu hình trường dữ liệu",
                RESUME_CONFIG: "Cấu hình hồ sơ",
                GENDER: "Giới tính",
                ADDRESS: "Địa chỉ",
                PHONE_NUMBER: "Số điện thoại",
                FIRST_NAME: "Tên đầu",
                LAST_NAME: "Tên cuối",
                DATE_OF_BIRTH: "Ngày sinh",
                EMAIL: "E-mail",
                CURRENT_TITLE: "Công việc hiện tại",
                CURRENT_COMPANY: "Công ty hiện tại",
                EDUCATION: "Giáo dục",
                START_TIME: "Thời gian đầu",
                END_TIME: "Thời gian cuối",
                MAJOR: "Chuyên ngành",
                SCHOOL: "Trường",
                DIPLOMA: "Bằng cấp",
                GPA: "Điểm tích lũy",
                EXPERIENCE: "Kinh nghiệm",
                POSITION: "Vị  trí",
                COMPANY: "Công ty",
                DETAIL: "Chi tiết",
                JOB_APP_CONFIG: "Cấu hình ứng dụng công việc",
                MATCHING_SCORE: "Điểm phù hợp",
                AUTO_UPLOAD_SCORE: "Điểm tự động tải lên",
                
            },
            ACTIVITY_HISTORY:{
                TITLE: "Lịch sử hoạt động",
                USERS: "tài khoản",
                CREATE_USER: "Tạo tài khoản",
                NAME: "Tên",
                EMAIL: "E-mail",
                PASSWORD: "Mật khẩu",
                PASSWORD_REQUIRED: "Mật khẩu được yêu cầu",
                SUBMIT: "Gửi",
                PERMISSION_PARSE: "Quyền trích xuất hồ sơ",
                PERMISSION_SEARCH: "Quyền tìm kiếm hồ sơ",
                PERMISSION_TALENT: "Quyền tìm truy cập kho dữ liệu",
                IS_SITE_ADMIN: "Là quản trị viên",
                LAST_LOGIN: "Lần gần nhất đăng nhập",
                ACTION: "Hành động",
                ASSIGN_USER: "Chỉ định người dùng",
                CANCLE: "Hủy",
                ACCEPT: "Chấp nhận",
                ITEMS_PER_PAGE: "mục trên trang"
            }
          }
      
    }
  };
  