import { saveAs } from 'file-saver';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResumeParserService } from '../services/resume-parser.service';
import { ResumeParser } from '../models/resume.model';
import { User } from 'app/auth/models';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { Subscription, concat, interval } from 'rxjs';
import { Page } from '../models/paging.model';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'app/auth/service';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-manage-resume',
  templateUrl: './manage-resume.component.html',
  styleUrls: ['./manage-resume.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageResumeComponent implements OnInit {
  public isAuthenticated=false
  public resume_parser: ResumeParser[]=[]
  public myFiles:File[]=[];
  public errorMessagesResumeParsers!: any
  public errorMessagesGetFilterListResume!: any
  // public cvs_task:CV_task[]=[]
  public tag_filter_list_resume = false
  SearchForm!: FormGroup
  public file_name!: string
  public status=[ 
    {name: "Approved", value: "Approved"},
    {name: "Done", value: "Done"},
    {name: "Failure", value: "Failure"},
    {name: "Processing", value: "Processing"},
    {name: "Wait to process", value: "Wait_to_process"},
    {name: "Suspended", value: "Suspended"},
    {name: "Modified", value: "Modified"},
  ]
  public sf4c_status=[ 
    
    {name: "SF4C background failed", value: "SF4C_background_failed"},
    {name: "SF4C wait to upload", value: "SF4C_wait_to_upload"},
    {name: "SF4C failed", value: "SF4C_failed"},
    {name: "SF4C uploading", value: "SF4C_uploading"},
    {name: "SF4C uploaded", value: "SF4C_uploaded"},
    // {name: "Base upload failed", value: "Base_upload_failed"},
    // {name: "Base uploading", value: "Base_uploading"},
    // {name: "Base upload success", value: "Base_upload_success"},

  ]
  public selectedStatus:string[]=[]
  public selectedSf4cStatus:string[]=[]

  public email!: string
  public phone_number!: string
  public owner__email!: string
  public owner__email_items: any[]=[]
  public user: User = new User()
  public users: User[] = []
  public role: string
  public errorMessagesListUser: any
  basicSelectedOption = 5
  public page = new Page({});
  ColumnMode = ColumnMode;
  widthColumn: number = 0;
  @ViewChild(DatatableComponent) tableRowDetails: DatatableComponent;
  INTERVAL_TIME = 3000;
  intervalSubscription: Subscription;
  public isLoading = false;
  public url_dynamic = ""
  
  // page = 1;
  // pageSize = 5;
  // totalItems: number = 0
  // pageSizes = [5, 10, 15];

  public status_color: any = {
    "Approved": 'success',
    "SF4C_background_failed": 'warning',
    "Done": "primary",
    "Failure": "danger",
    "Processing": "info",
    "Wait_to_process":"dark",
    "Suspended":"secondary",
    "Modified":"warning",
    "SF4C_wait_to_upload":"dark",
    "SF4C_failed":"secondary",
    "SF4C_uploading":"info",
    "SF4C_uploaded":"success",
    "Base_upload_failed":"secondary",
    "Base_uploading":"info",
    "Base_upload_success":"success"
  }
  public job_status_color: any = {
    "Success": 'success',
    "Fail": "danger",
    "Check": "warning"
  }
  public loading_page_filter = false
  public tag_export = false
  public errorMessagesResultConnectSf4cs!: any
  public tag_resultConnectSf4c_exit =false
  public hidden_menu = false

  //upload
 
  public arr_checkboxJr:string[]=[]
  public arr_checkbox:string[]=[]
  public arr_checkbox_current_title:string[]=[]


  formCheckBox = this.fb.group({
    checkResumeArray: this.fb.array([], Validators.required)
  })
  formCheckBoxJr = this.fb.group({
    checkResumeArrayJr: this.fb.array([],Validators.required)
  })
  
  public range_conf: any = {
    'success': 0.6,
    'warning': 0.4,
  }
  conf_score__gte! : number
  conf_score__lte!:number
  options: Options = {
    // showTicksValues: true,
    step: 0.1,
    floor: 0,
    ceil: 1
  };
  onConfChangeEnd(changeContext: ChangeContext): void {

    this.conf_score__gte = changeContext.value;
    this.conf_score__lte = changeContext.highValue || this.conf_score__gte;
  }
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private _router: Router,
    private resumesService: ResumeParserService,
    public sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private _authenticationService: AuthenticationService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private _toastrService: ToastrService,


  ){
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    
  }
  // modal Basic
  modalOpen(modalBasic:any) {

    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
    }
  // deleteResume(id: number){
  //   this.resumesService.deleteResume(id);
  // }
  onSubmit(){
    this.page.pageNumber=0
    this.SearchResume();
  }
  SearchResume(){
    this.isLoading = true
    this.loading_page_filter = true
    let file_name = "&file_name="+this.file_name
    let url_status = ""
    if(this.selectedStatus.length>0){
      for(let i=0;i<this.selectedStatus.length;i++){
        let url_current_status = "&status="+this.selectedStatus[i]
        url_status = url_status + url_current_status
      }
    }else{
      url_status = ""
    }
    let url_sf4c_status =""
    if(this.selectedSf4cStatus.length>0){
      for(let i=0;i<this.selectedSf4cStatus.length;i++){
        let url_current_status = "&sf4c_status="+this.selectedSf4cStatus[i]
        url_sf4c_status = url_sf4c_status + url_current_status
      }
    }else{
      url_sf4c_status = ""
    }
    // let status = this.selectedStatus==null?"&status=":"&status="+this.selectedStatus
    let email = "&email="+this.email
    let phone_number = "&phone_number="+this.phone_number
    let owner__email = "&owner__email="+this.owner__email
    // let conf_score__gte = "&conf_score__gte="+this.conf_score__gte
    // let conf_score__lte = "&conf_score__lte="+this.conf_score__lte

    let url_dynamic = ''
    if(this.file_name!=undefined&&this.file_name!==null){
      url_dynamic = url_dynamic + file_name
    }
    if(this.selectedStatus.length>0){
      url_dynamic = url_dynamic + url_status
    }
    if(this.selectedSf4cStatus.length>0){
      url_dynamic = url_dynamic + url_sf4c_status
    }
    if(this.email!=undefined&&this.email!==null){
      url_dynamic = url_dynamic + email
    }
    if(this.phone_number!==undefined&&this.phone_number!==null){
      url_dynamic = url_dynamic+phone_number
    }
    if(this.owner__email!==undefined&&this.owner__email!==null){
      url_dynamic = url_dynamic+owner__email
    }
    // if(this.conf_score__gte!==undefined&&this.conf_score__gte!==null){
    //   url_dynamic = url_dynamic+conf_score__gte
    // }
    // if(this.conf_score__lte!==undefined&&this.conf_score__lte!==null){
    //   url_dynamic = url_dynamic+conf_score__lte
    // }
    this.url_dynamic = url_dynamic
    // const payload = {
    //   url_dynamic: url_dynamic,
    //   // gender: null,
    //   page: this.page,
    //   page_size: this.pageSize
    // }

    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
    // const api$ = this.resumesService.getFilterListResume(url_dynamic,this.page.pageNumber,this.page.size)
    const intervalAfterCallApi = interval(this.INTERVAL_TIME).pipe(
      switchMap(() =>
        this.resumesService.getFilterListResume(url_dynamic,this.page.pageNumber,this.page.size)
      )
    );
    this.intervalSubscription = intervalAfterCallApi.subscribe(
      (res)=>{
        this.resume_parser = res.results;
        this.page.totalElements = res.count
        this.isLoading = false
      },
      (err) =>{
        this.errorMessagesGetFilterListResume= err
      }
    )
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.resumesService.getFilterListResume(this.url_dynamic,this.page.pageNumber,this.page.size).subscribe(
      (res)=>{
        this.resume_parser = res.results;
        this.page.totalElements = res.count
        this.isLoading = false

      },
      (err) =>{
        this.errorMessagesGetFilterListResume= err
      }
    )
  }
  ngOnDestroy(): void {
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
  }
  getListUser(){
    this._authenticationService.listUsers().subscribe(
      (res)=>{
        this.users = res.results;
        for (let i=0;i<this.users.length;i++){
          let owner__email_item = this.users[i].first_name + ' '+ this.users[i].last_name + ': '+ this.users[i].email
          this.owner__email_items.push({"name":owner__email_item, "value": this.users[i].email})
          
        }
        this.owner__email_items = [...this.owner__email_items] 
      },
      (err)=>{
        this.errorMessagesListUser = err
      }
    )
  }
  export_csv(){
    this.tag_export = true
    let type_file="csv"
    this.resumesService.downloadResume(type_file).subscribe(x=>{
      const filename = `Result parse resumes.csv`;
      let blob = new Blob([x],{type: 'csv;charset=utf-8'});
      this.tag_export = false;
      saveAs(blob, filename);
    })
  }
  export_excel(){
    this.tag_export = true
    let type_file="excel"
    this.resumesService.downloadResume(type_file).subscribe(x=>{
      const filename = `Result parse resumes.xlsx`;
      let blob = new Blob([x],{type: 'application/vnd.ms-excel;charset=utf-8'});
      this.tag_export = false;
      saveAs(blob, filename);
    })
  }
  deleteResume(id: number) {
    Swal.fire({
      title: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.DELETE_R"),
      text: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CANT_UNDO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      cancelButtonText: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL"),
      confirmButtonText: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CONFIRM"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary ml-1",
      },
      preConfirm: async () => {
        this.resumesService.deleteResume(id).subscribe(
          (res) => {
            this._toastrService.success(
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.DELETE_R_SUC"),
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
            this.SearchResume();
          },
          (err) => {
            this._toastrService.error(
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.ERROR"),
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.FAILED"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
          }
        );
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
    });
  }
  ngOnInit(): void {
    this.user = this._authenticationService.currentUserValue
    this.getListUser();
    this.SearchResume();
  }

}
