<div class="content-wrapper container-xxxl p-0">
    <div class="content-body">
        <div>
            <nav>
                <div class="nav nav-tabs position-fixed d-flex justify-content-start" id="nav-tab" role="tablist" style="margin-top: -30px;">
                  <button class="nav-link active" id="nav-Resume-tab" data-toggle="tab" data-target="#nav-Resume" type="button" role="tab" aria-controls="nav-Resume" aria-selected="true" (click)="Tab()" [translate]="'RESUME_PARSER.DETAIL_RESUME.TAB_CHR'"></button>
                  <button *ngIf="tag_resultConnectSf4c_exit && tag_sf4c_candidate && (resume_parser.status=='SF4C_uploaded'||resume_parser.status=='SF4C_failed'||resume_parser.status=='SF4C_background_failed')" class="nav-link" id="nav-User-tab" data-toggle="tab" data-target="#nav-User" type="button" role="tab" aria-controls="nav-User" aria-selected="false" (click)="Tab_sf4c()" [translate]="'RESUME_PARSER.DETAIL_RESUME.TAB_SF4C'"></button>
                </div>
                
              </nav>
              <div class="col-md-5 position-fixed d-flex justify-content-start" *ngIf="pdf_content && !tab_sf4c">
                
                <!-- <object id="pdf" [data]="sanitizer.bypassSecurityTrustResourceUrl(url)" type="application/pdf"></object> -->
                
                    <!-- <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,'+pdf_content)" style="width: 100%;height: 50rem;border: none;margin-top: 10px;margin-bottom: 10px; "></iframe> -->
                    <iframe [cachedSrc]="url" style="width: 100%;height: 80vh;border: none;margin-top: 10px;margin-bottom: 10px; "></iframe>
            </div>
            
            <div class="d-flex justify-content-end">
                <div *ngIf="tag_detail && !tab_sf4c" class="col-md-6">
                    
                        <div class="" style="padding-top: 10px;">
                            <div class="d-flex justify-content-start align-items-center">
                                <h1 [translate]="'RESUME_PARSER.DETAIL_RESUME.CANDIDATE_INFORMATION'"></h1>
                                
                               <span class="badge bg-{{status_color[resume_parser.status]}}" data-toggle="tooltip" data-placement="bottom" title="{{resume_parser.sf4c_status}}" style="font-size: 15px;margin: 15px;">{{resume_parser.status}}</span>
                                
                            </div>
                            <p *ngIf="resume_parser.successfactor_resume_id!=null && resume_parser.status=='SF4C_uploaded'" [translate]="'RESUME_PARSER.DETAIL_RESUME.SF4C_RESUME_ID'"> {{resume_parser.successfactor_resume_id}}</p>
    
                            <p *ngIf="sf4cCandidate.candidateId!=null && resume_parser.status=='SF4C_failed'" [translate]="'RESUME_PARSER.DETAIL_RESUME.SF4C_RESUME_ID'"> {{sf4cCandidate.candidateId}}</p>
                            
                            
                            <div id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" [translate]="'RESUME_PARSER.DETAIL_RESUME.META_DATA'">
                                        
                                        </button>
                                    </h5>
                                    </div>
                                
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.FIRST_NAME'"></span>: {{resume_parser.first_name}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.LAST_NAME'"></span>: {{resume_parser.last_name}}</p></div>
    
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.DOB'"></span>: {{resume_parser.date_of_birth|date:'longDate'}}</p></div>
    
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.GENDER'"></span>: {{resume_parser.gender}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.ADDRESS'"></span>: {{resume_parser.address}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.EMAIL'"></span>: {{resume_parser.email}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'"></span>: {{resume_parser.phone_number}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY'"></span>: {{resume_parser.category}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXPERIENCE'"></span>: {{resume_parser.year_exp}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'"></span>: {{resume_parser.current_title}}</p></div>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.CURRENT_COMPANY'"></span>: {{resume_parser.current_company}}</p></div>
                                        <div *ngIf="resume_parser.skills.length>0">
                                            <p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.SKILLS'"></span><span>:</span></p>
                                            <div *ngFor="let skill of resume_parser.skills" class="list-inline" style="display: inline-block">
                                                <span class="badge bg-primary" style="margin-left: 2px;">{{skill}}</span>
                                            </div>
                                        </div>
    
                                        <br>
                                        <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.CONF_SCORE'"></span><span>: </span><span class="badge" [ngClass]="{
                                            'badge-success':resume_parser.conf_score>range_conf.success,
                                            'badge-warning':resume_parser.conf_score>range_conf.warning && resume_parser.conf_score < range_conf.success,
                                            'badge-danger':resume_parser.conf_score<range_conf.warning
                                        }">{{resume_parser.conf_score*100|number:'1.2-2'}}%</span></p></div>

                                    </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" [translate]="'RESUME_PARSER.DETAIL_RESUME.WORK_EXPERIENCE'">
                                        
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <div class="" *ngFor="let experience of resume_parser.experience">
                                            <div class="main" [ngClass]="{'border border-success':experience.conf_score>range_conf.success,'border border-warning':
                                                experience.conf_score>range_conf.warning && experience.conf_score < range_conf.success, 'border border-danger':
                                                experience.conf_score<range_conf.warning}" style="border-style: dashed !important;">
                                                <div class=""></div>
                                                <div class="ms-3">
                                                    <div><span class="text-dark" data-feather="calendar"></span><span style="width:fit-content"> {{experience.start_time|date:'longDate'}} - {{experience.end_time|date:'longDate'}}</span></div>
                                                    <div><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.POSITION'"></span><span>: {{experience.position}}</span></div>
                                                    <div><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.COMPANY'"></span>: {{experience.company}}</div>
                                                    <div><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.INDUSTRY'"></span>: {{experience.industry}}</div>
    
                                                    <div class="ml-1" *ngIf="experience.detail!=null"  [innerHTML]="experience.detail.trim().split('\n').join('<br>')"></div>
                                                    
                                                </div>
                                                <div class="sub" [ngClass]="{'bg-success':experience.conf_score>range_conf.success,'bg-warning':
                                                    experience.conf_score>range_conf.warning && experience.conf_score < range_conf.success, 'bg-danger':
                                                    experience.conf_score<range_conf.warning}">
                                                    <div class="">{{experience.conf_score*100|number:'1.2-2'}}%</div>
                                                    
                                                </div>
                                            </div>

                                            
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" [translate]="'RESUME_PARSER.DETAIL_RESUME.EDUCATION'">
                                        
                                        </button>
                                    </h5>
                                    </div>
                                    <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="" *ngFor="let education of resume_parser.education">
                                                <div class="main" [ngClass]="{'border border-success':education.conf_score>range_conf.success,'border border-warning':
                                                    education.conf_score>range_conf.warning && education.conf_score < range_conf.success, 'border border-danger':
                                                    education.conf_score<range_conf.warning}" style="border-style: dashed !important;">
                                                    <div class=""></div>
                                                    <div class="ms-3">
                                                        <div><span class="text-dark" data-feather="calendar"></span><span style="width:fit-content"> {{education.start_time|date:'longDate'}} - {{education.end_time|date:'longDate'}}</span></div>
                                                        <div><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.MAJOR'"></span>: {{education.major}}</div>
                                                        <div><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.SCHOOL'"></span>: {{education.school}}</div>
                                                        <div *ngIf="education.diploma!=null && education.diploma!=''"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.DIPLOMA'"></span>: {{education.diploma}}</div>
                                                        <div *ngIf="education.gpa!=null && education.gpa!=''"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.GPA'"></span>: {{education.gpa}}</div>
                                                        <div *ngIf="education.picklist_major!=null && education.picklist_major!=''"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.PICKLIST_MAJOR'"></span>: {{education.picklist_major}}</div>
    
                                                        
                                                    </div>
                                                    <div class="sub" [ngClass]="{'bg-success':education.conf_score>range_conf.success,'bg-warning':
                                                        education.conf_score>range_conf.warning && education.conf_score < range_conf.success, 'bg-danger':
                                                        education.conf_score<range_conf.warning}">
                                                        <div class="">{{education.conf_score*100|number:'1.2-2'}}%</div>
                                                        
                                                    </div>
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="card" *ngIf="tag_resultConnectSf4c_exit">
                                   
                                    <div class="card-header" id="headingFour">
                                        <h5 class="mb-0">
                                            
                                            <button (click)="Matching_jr()" class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" [translate]="'RESUME_PARSER.DETAIL_RESUME.JOB_REQ_MATCHING'">
                                            
                                            </button>
                                        </h5>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <div
                                            *ngIf="loading_match"
                                            id="loading"
                                            style="margin: 50px auto"
                                        >
                                            <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <!-- <app-loading *ngIf="loading_match"></app-loading> -->
                                    </div>
                                    
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample" >
                                        <div class="card-body">
                                            <div *ngIf="matching_jr.match_result!=null">
                                                <form>
                                                    
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        
                                                        
                                                        <div class="col-md-2" [translate]="'RESUME_PARSER.DETAIL_RESUME.SELECT_JR'">:</div>
                                                        
                                                        <div class="col-md-7">
                                                            
                                                            <ng-select id="JR" [items]="items_jr"
                                                            bindLabel="jobTitle"
                                                            bindValue="jobReqId"
                                                            placeholder=". Select Job Requisition-"
                                                            name="selectedJr"
                                                            [(ngModel)]="selectedJr"
                                                            
                                                            >
                                                            
                                                            </ng-select>
                                                        </div>
                                                        <div class="col-md-3"><button type="submit" class="btn btn-primary rounded-pill btn-sm" style="margin-left: 10px;font-size:12px;padding: 0.5rem 0.5rem;"  (click)="Post_matching_jr()"rippleEffect [translate]="'RESUME_PARSER.DETAIL_RESUME.ANALYSIS'"></button></div>
                                                        
                                                    </div>
                                                   
                                                </form>
                                                <hr>
                                                <p *ngIf="errorMessagesMatchingJr!=null; else NoError" [translate]="'RESUME_PARSER.DETAIL_RESUME.NO_MATCHING'"></p>
                                                <ng-template #NoError>
                                                    <p *ngIf="loading_match;else Result" style="margin-top: 30px;" [translate]="'RESUME_PARSER.DETAIL_RESUME.SEARCHING'"></p>
                                                    
                                                    <ng-template #Result>
                                                        <div *ngIf="matching_jr.match_result!=null">
                                                            <div class="d-flex align-items-center">
                                                                <div class="col-md-6">
                                                                    <div class="d-flex justify-content-start align-items-center" *ngIf="matching_jr.match_result.overall_score">
                                                                        <div class="col-md-6"><span [translate]="'RESUME_PARSER.DETAIL_RESUME.MATCHING_POINT'"></span><span>: </span> </div>
                                                                        <div class="col-md-6">
                                                                            <mwl-gauge
                                                                    
                                                                                [max]="100"
                                                                                [dialStartAngle]="-90"
                                                                                [dialEndAngle]="-90.001"
                                                                                [value]="matching_jr.match_result.overall_score*100"
                                                                                [animated]="true"
                                                                                [animationDuration]="1"
                                                                                [color]="gaugeColor"
                                                                                
                                                                            >
                                                                            </mwl-gauge>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="d-flex justify-content-start align-items-center" *ngIf="matching_jr.match_result.experience">
                                                                        <div class="col-md-6"><span [translate]="'RESUME_PARSER.DETAIL_RESUME.EXPERIENCE'"></span><span>: </span> </div>
                                                                        <div class="progress-wrapper col-md-6">
                                                                            <ngb-progressbar
                                                                                showValue="true"
                                                                                
                                                                                [type]="{
                                                                                    'success':matching_jr.match_result.experience.score>0.8,
                                                                                    'warning':matching_jr.match_result.experience.score<0.8 && matching_jr.match_result.experience.score>0.5,
                                                                                    'danger':matching_jr.match_result.experience.score<0.5
                                                                                }"
                                                                                [ngClass]="{
                                                                                    'progress-bar-success':matching_jr.match_result.experience.score>0.8,
                                                                                    'progress-bar-warning':matching_jr.match_result.experience.score<0.8 && matching_jr.match_result.experience.score>0.5,
                                                                                    'progress-bar-danger':matching_jr.match_result.experience.score<0.5
                                                                                }"
                                                                                [height]="progressbarHeight"
                                                                                [value]="matching_jr.match_result.experience.score*100"
                                                                            ></ngb-progressbar>
                                                                
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div class="d-flex justify-content-start align-items-center" *ngIf="matching_jr.match_result.education">
                                                                        <div class="col-md-6"><span [translate]="'RESUME_PARSER.DETAIL_RESUME.EDUCATION_'"></span><span>: </span> </div>
                                                                        <div class="progress-wrapper col-md-6">
                                                                            <ngb-progressbar
                                                                                showValue="true"
                                                                                
                                                                                [type]="{
                                                                                    'success':matching_jr.match_result.education.score>0.8,
                                                                                    'warning':matching_jr.match_result.education.score<0.8 && matching_jr.match_result.education.score>0.5,
                                                                                    'danger':matching_jr.match_result.education.score<0.5
                                                                                }"
                                                                                [ngClass]="{
                                                                                    'progress-bar-success':matching_jr.match_result.education.score>0.8,
                                                                                    'progress-bar-warning':matching_jr.match_result.education.score<0.8 && matching_jr.match_result.education.score>0.5,
                                                                                    'progress-bar-danger':matching_jr.match_result.education.score<0.5
                                                                                }"
                                                                                [height]="progressbarHeight"
                                                                                [value]="matching_jr.match_result.education.score*100"
                                                                            ></ngb-progressbar>
                                                                
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div class="d-flex justify-content-start align-items-center" *ngIf="matching_jr.match_result.language">
                                                                        <div class="col-md-6"><span [translate]="'RESUME_PARSER.DETAIL_RESUME.FOREIGN_LANGUAGE'"></span><span>: </span> </div>
                                                                        <div class="progress-wrapper col-md-6">
                                                                            <ngb-progressbar
                                                                                showValue="true"
                                                                                
                                                                                [type]="{
                                                                                    'success':matching_jr.match_result.language.score>0.8,
                                                                                    'warning':matching_jr.match_result.language.score<0.8 && matching_jr.match_result.language.score>0.5,
                                                                                    'danger':matching_jr.match_result.language.score<0.5
                                                                                }"
                                                                                [ngClass]="{
                                                                                    'progress-bar-success':matching_jr.match_result.language.score>0.8,
                                                                                    'progress-bar-warning':matching_jr.match_result.language.score<0.8 && matching_jr.match_result.language.score>0.5,
                                                                                    'progress-bar-danger':matching_jr.match_result.language.score<0.5
                                                                                }"
                                                                                [height]="progressbarHeight"
                                                                                [value]="matching_jr.match_result.language.score*100"
                                                                            ></ngb-progressbar>
                                                                
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                            <div *ngIf="matching_jr.match_result.skill.matched.length>0">
                                                                <p><span [translate]="'RESUME_PARSER.DETAIL_RESUME.CANDIATE_SKILL'"></span><span>: </span></p>
                                                                <div *ngFor="let skill of matching_jr.match_result.skill.matched" class="list-inline" style="display: inline-block">
                                                                    <span class="badge bg-warning" style="margin-left: 2px;">{{skill.value}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    
                                                    </ng-template>
                                                </ng-template>
                                            </div>    
                                              
                                                
                                                
                                        </div>
                    
                                    </div>
                                </div>
                            </div>
                        <div style="margin-top: 20px;" class="d-flex">
                            <button (click)="ApproveResume()" style="font-size: 14px;" class="btn btn-success rounded-pill"rippleEffect><span class="fa fa-check mr-1"></span> <span [translate]="'RESUME_PARSER.DETAIL_RESUME.APPROVE'"></span></button>
                            <div style="margin-left:10px" *ngIf="resume_parser.status=='Approved' && tag_resultConnectSf4c_exit">
                                <button (click)="modalOpenUploadSf4c(modalBasic)" style="font-size: 14px;" class="btn btn-info rounded-pill"rippleEffect><span class="fa fa-upload mr-1"></span> <span [translate]="'RESUME_PARSER.PARSE_RESUME.UPLOAD_SF4C'"></span></button>
                               
                            </div>
                            <div style="margin-left:10px" *ngIf="resume_parser.status=='Approved' && tag_resultConnectSf4c_exit">
                                <a *ngIf="resume_parser.status=='Approved' && !tag_resultConnectSf4c_exit" routerLink="/employer/get-credential" class="btn btn-info rounded-pill"rippleEffect style="font-size: 14px;"><span class="fa fa-upload mr-1"></span> <span [translate]="'RESUME_PARSER.PARSE_RESUME.UPLOAD_SF4C'"></span></a>
    
                               
                            </div>
                            <ng-template #modalBasic let-modal>
                                    
                                
                                
                                <div class="modal-header">
                                    <h4 class="modal-title" [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'"></h4>
                                    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                
                                </div>
                                <div class="me-4 mt-3">
                                    <div class="d-flex justify-content-end">
    
                                        <div class="input-group col-md-6">
                                            <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="job-search"
                                                placeholder="Search file name"
                                                aria-label="Search..."
                                                aria-describedby="job-search"
                                                [(ngModel)]="searchText"
                                                (input)="Search()"
                                            />
                                        </div>
    
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <div
                                        *ngIf="loading"
                                        id="loading"
                                        style="margin: 50px auto"
                                    >
                                        <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <!-- <app-loading *ngIf="loading"></app-loading> -->
                                </div>
                                <form (ngSubmit)="FormCheckBoxJr()" [formGroup]="formCheckBoxJr">
                                    <div class="modal-body" ngbAutofocus>
                                        
                                        
                                            <table class="table table-hover align-middle">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 1%;"></th>
                                                        <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_REQ_ID'"></th>
                                                        <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_TITLE'"></th>
                                                        
                                                        <!-- <th>Score</th> -->
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngIf="have_Jr && !loading;else noJr">
                                                        <tr *ngFor="let resultJr_ of resultJr
                                                        | slice: (pageJr - 1) * basicSelectedOption:(pageJr - 1) * basicSelectedOption + basicSelectedOption">
                                                            <td *ngIf="arr_checkboxJr.includes(resultJr_.jobReqId);else noChecked">
                                                                <input [value]="resultJr_.jobReqId" (change)="onCheckboxChangeJr($event)" type="checkbox" class="form-check" checked>
                                                                
                                                            </td>
                                                            <ng-template #noChecked>
                                                                <td>
                                                                    <input [value]="resultJr_.jobReqId" (change)="onCheckboxChangeJr($event)" type="checkbox" class="form-check">
                                                                    
                                                                </td>
                                                            </ng-template>
                                                        
                                                            <td>
                                                                <div class="" >{{resultJr_.jobReqId}}</div>
                                                            </td>
                                                            <td>
                                                                <div class="" >{{resultJr_.jobTitle}}</div>
                                                            </td>
                                                            <!-- <td>
                                                                <div class="" >{{resultJr_.score*100|number:'1.2-2'}}%</div>
                                                            </td> -->
                                                        
                                                        </tr>
                                                    </ng-container>
                                                    <ng-template #noJr>
                                                        <tr>
                                                            <p *ngIf="!loading">
                                                                NoSuitableJr
                                                            </p>
                                                        </tr>
                                                    </ng-template>
    
                                                </tbody>
                                            </table>
                                            <div class="row mt-4 mt-lg-5 justify-content-between align-items-center" *ngIf="resultJr.length>0">
                                                <div class="col-auto">
                                        
                                                    <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">
                                        
                                                    <ngb-pagination [collectionSize]="resultJr.length" [(page)]="pageJr" [pageSize]="basicSelectedOption"
                                                    [maxSize]="10" [rotate]="true" [boundaryLinks]="true">
                                        
                                        
                                                    </ngb-pagination>
                                                    </nav>
                                                </div>
                                                <!-- <div class="col-auto">
                                                <select class="form-select" style="width: auto" [(ngModel)]="basicSelectedOption">
                                                    <option [ngValue]="5">5 items per page</option>
                                                    <option [ngValue]="10">10 items per page</option>
                                                    <option [ngValue]="15">15 items per page</option>
                                                </select>
                                                </div> -->
                                            </div> 
                                            
    
                                            
    
                                    </div>
                                    <div class="modal-footer">
                                    <button type="submit" class="btn" (click)="modal.dismiss('Cross click')" rippleEffect [translate]="'RESUME_PARSER.PARSE_RESUME.SUBMIT'">
                                        
                                    </button>
                                    </div>
                                    
                            </form>
                            </ng-template>
                            <ng-container *ngIf="errorMessagesSf4cUpload">
                                <div class="alert alert-danger mt-2" role="alert">
                                  {{errorMessagesSf4cUpload}}
                                </div>
                            </ng-container>
                            
                            <div  style="margin-left:10px" *ngIf="tag_resultConnectSf4c_exit && tag_sf4c_candidate && resume_parser.status=='SF4C_failed' || resume_parser.status=='SF4C_background_failed' && !errorMessagesGetSf4cCandidate">
                                
                                
                                <button style="background-color: #000022;font-size: 14px;" class="btn rounded-pill"
                                        (click)="modalOpenJobReqId(modalBasicJobReqId)" rippleEffect>
                                          <span class="fa fa-compress mr-1"></span>
                                      <span [translate]="'RESUME_PARSER.PARSE_RESUME.UPSERT'"></span></button>
                                      <ng-template #modalBasicJobReqId let-modal>
                    
                    
                                        
                                        
                                        <div class="modal-header">
                                            <h4 class="modal-title" [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'"></h4>
                                            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                              </button>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <div
                                                *ngIf="loading"
                                                id="loading"
                                                style="margin: 50px auto"
                                            >
                                                <div class="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            <!-- <app-loading *ngIf="loading"></app-loading> -->
                                        </div>
                                        <form (ngSubmit)="Mapping()" [formGroup]="formCheckBoxJobReqId">
                                            <div class="modal-body" ngbAutofocus>
                                                
                                                <ng-container>
                                                    <table class="table table-hover align-middle">
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 1%;"></th>
                                                                <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_REQ_ID'"></th>
                                                                <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_TITLE'"></th>
                                                                
                                                            </tr>
                                                        </thead>
                                                        
                                                        <tbody>
                                                            <tr *ngFor="let list_jr_ of list_jr| slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
                                                                <td *ngIf="arr_checkboxJobReqId.includes(list_jr_.jobReqId.toString());else noChecked">
                                                                    <input [value]="list_jr_.jobReqId" (change)="onCheckboxChangeJobReqId($event)" type="checkbox" class="form-check" checked>
                                                                    
                                                                </td>
                                                                <ng-template #noChecked>
                                                                    <td>
                                                                        <input [value]="list_jr_.jobReqId" (change)="onCheckboxChangeJobReqId($event)" type="checkbox" class="form-check">
                                                                        
                                                                    </td>
                                                                </ng-template>
                                                            
                                                                <td>
                                                                    <div class="" >{{list_jr_.jobReqId}}</div>
                                                                </td>
                                                                <td>
                                                                    <div class="" >{{list_jr_.jobTitle}}</div>
                                                                </td>
                                                            </tr>
                        
                                                        </tbody>
                                                    </table>
                                                    <div class="row mt-4 mt-lg-5 justify-content-between align-items-center">
                                                        <div class="col-auto">
                                
                                                            <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">
                                
                                                                <ngb-pagination [collectionSize]="list_jr.length" [(page)]="page" [pageSize]="pageSize"
                                                                [maxSize]="10" [rotate]="true" [boundaryLinks]="true">
                                
                                
                                                                </ngb-pagination>
                                                            </nav>
                                                        </div>
                        
                                                    </div>
                                                     
                                                </ng-container>
                    
                                                </div>
                                            <ng-container>
                    
                                            <div class="modal-footer">
                                            <button type="submit" class="btn" rippleEffect (click)="modal.close('Accept click')" [translate]="'RESUME_PARSER.PARSE_RESUME.SUBMIT'">
                                                
                                            </button>
                    
                                            </div>
                                        </ng-container>
                                        
                    
                                    </form>
                                        
                                </ng-template>
                                     
                            </div>
                            
                            
                        </div>
                        <div class="d-flex mt-1">
                            <div>
                                
                                <a routerLink="/manage-resume/edit-resume/{{resume_parser.id}}" style="font-size: 14px;" class="btn btn-primary rounded-pill"rippleEffect><span class="fa fa-edit mr-1"></span> <span [translate]="'RESUME_PARSER.DETAIL_RESUME.EDIT'"></span></a>
                            </div>
                            <button  *ngIf="(role=='Site Admin' || role=='Admin')&&!display_decensor" (click)="CensorResume()" style="margin-left: 10px;" class="btn rounded-pill btn-warning"rippleEffect><span class="fa fa-lock mr-1"></span> <span [translate]="'RESUME_PARSER.DETAIL_RESUME.CENSOR'"></span></button>
                            <button  *ngIf="(role=='Site Admin' || role=='Admin')&&display_decensor" (click)="DeCensorResume()" style="background-color: #E4572E;margin-left: 10px;" class="btn rounded-pill text-light"rippleEffect><span class="fa fa-unlock mr-1"></span> <span [translate]="'RESUME_PARSER.DETAIL_RESUME.DECENSOR'"></span></button>
                        </div>
                    </div>
                    <div
                        *ngIf="loading_"
                        id="loading"
                        style="margin: 50px auto"
                    >
                        <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <!-- <app-loading *ngIf="loading_"></app-loading> -->
                </div>
                           
            </div>
            
            
            <div class="col-md-5 position-fixed d-flex justify-content-start" *ngIf="pdf_content_sf4c && tab_sf4c">
                
                <!-- <object id="pdf" [data]="sanitizer.bypassSecurityTrustResourceUrl(url)" type="application/pdf"></object> -->
    
                    <!-- <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,'+pdf_content)" style="width: 100%;height: 50rem;border: none;margin-top: 10px;margin-bottom: 10px; "></iframe> -->
                    <iframe [cachedSrc]="url_sf4c" style="width: 100%;height: 50rem;border: none;margin-top: 10px;margin-bottom: 10px; "></iframe>
    
            </div>
            
            <div style="margin-top: 30px;"*ngIf="errorMessagesGetSf4cCandidate">Error: <b style="color: red;">{{errorMessagesGetSf4cCandidate}}</b></div>
            <div class="d-flex justify-content-end">
                <div *ngIf="tag_sf4c_candidate && tab_sf4c" class="col-md-6">
                    
                    <div class="pxp-dashboard-content-details" style="padding-top: 10px;">
                        <div class="d-flex justify-content-start align-items-center">
                            <h1 [translate]="'RESUME_PARSER.DETAIL_RESUME.CANDIDATE_SF4C'"></h1>
                            
                        </div>
                        <div id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" [translate]="'RESUME_PARSER.DETAIL_RESUME.META_DATA'">
                                    
                                    </button>
                                </h5>
                                </div>
                            
                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.FIRST_NAME'"></span>: {{sf4cCandidate.firstName}}</p></div>
                                    <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.LAST_NAME'"></span>: {{sf4cCandidate.lastName}}</p></div>
    
                                    <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.DOB'"></span>: {{sf4cCandidate.dateOfBirth.split('00:00:00')[0].trim()}}</p></div>
    
    
                                    
                                    <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.GENDER'"></span>: {{sf4cCandidate.gender}}</p></div>
                                    <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.ADDRESS'"></span>: {{sf4cCandidate.address}}</p></div>
                                    <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.EMAIL'"></span>: {{sf4cCandidate.primaryEmail}}</p></div>
                                    <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'"></span>: {{sf4cCandidate.cellPhone}}</p></div>
                                    <!-- <div><p><span class="font-weight-bolder">Category</span>{{sf4cCandidate.category}}</p></div>
                                    <div><p><span class="font-weight-bolder">Year experience</span>{{sf4cCandidate.year_exp}}</p></div> -->
                                    <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'"></span>: {{sf4cCandidate.currentTitle}}</p></div>
                                    <div><p><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.CURRENT_COMPANY'"></span>: {{sf4cCandidate.currentCompany}}</p></div>
    
                                </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" [translate]="'RESUME_PARSER.DETAIL_RESUME.WORK_EXPERIENCE'">
                                    
                                    </button>
                                </h5>
                                </div>
                                <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <div class="" *ngFor="let experience of sf4cCandidate.outsideWorkExperience">
                                        <div class="main" style="border: 1px solid #198754;border-style: dashed;">
                                            <div class=""></div>
                                            <div class="ms-3">
                                                <div><span class="text-dark" data-feather="calendar"></span><span style="width:fit-content"> {{experience.startDate.split('00:00:00')[0].trim()}} - {{experience.endDate.split('00:00:00')[0].trim()}}</span></div>
                                                <div><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.POSITION'"></span><span>: {{experience.startTitle}}</span></div>
                                                <div><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.COMPANY'"></span><span>: {{experience.employer}}</span></div>
                                                <div class="ml-1" *ngIf="experience.status!=null"  [innerHTML]="experience.status.trim().split('\n').join('<br>')"></div>
                                                
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" [translate]="'RESUME_PARSER.DETAIL_RESUME.EDUCATION'">
                                    
                                    </button>
                                </h5>
                                </div>
                                <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <div class="" *ngFor="let education of sf4cCandidate.education">
                                            <div class="main" style="border: 1px solid #198754;border-style: dashed;">
                                                <div class=""></div>
                                                <div class="ms-3">
                                                    <div><span class="text-dark" data-feather="calendar"></span><span style="width:fit-content"> {{education.startDate.split('00:00:00')[0].trim()}} - {{education.endDate.split('00:00:00')[0].trim()}}</span></div>
                                                    <div *ngIf="education.major1!=null"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.MAJOR'"></span><span>: {{education.major1}}</span></div>
                                                    <div *ngIf="education.school!=null"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.SCHOOL'"></span><span>: {{education.school}}</span></div>
                                                    <div *ngIf="education.degree!=null"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.DEGREE'"></span><span>: {{education.degree}}</span></div>
                                                    <div *ngIf="education.gpa!=null"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.GPA'"></span><span>: {{education.gpa}}</span></div>
                                                    
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
