<div class="content-wrapper container-xxxl p-0">
    <div class="content-body card-body">
        <div id="user-profile">
            <!-- profile header -->
            <div class="row">
              <div class="col-12">
                <div class="card profile-header mb-2">
                  <!-- profile cover photo -->
                  <!-- <img class="card-img-top" height="400px" src="/assets/images/backgrounds/candidate-cover-1.jpg" alt="User Profile Image" /> -->
                  <!-- tabs pill -->
                  <div class="profile-header-nav">
                    <!-- navbar -->
                    <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
                      
      
                      <!-- collapse  -->
                      <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <div class="">
                            <div class="d-flex justify-content-start align-items-center mb-1">
                                <!-- avatar -->
                                <div class="avatar mr-1">
                                    <img *ngIf="detail_resume.avatar; else customAvatar"
                                    [src]="detail_resume.avatar"
                                    alt=""
                                    height="130px"
                                    width="130px"
                                    
                                    
                                    />
                                  <ng-template #customAvatar>
                                    <img src="assets/images/avatars/iconPersion.png" alt="avatar img" height="130px" width="130px" />
                                  </ng-template>
                                  
                                </div>
                                <!--/ avatar -->
                                <div class="profile-user-info">
                                    <h4 class="">{{detail_resume.last_name}} {{detail_resume.first_name}}</h4>
                                    <p class="">{{ detail_resume.title }}</p>
                                    <p class=""><b [translate]="'TALENT_POOL.SEARCH_RESUME.OWNER'"></b>: {{ user.email }}</p>
                                    <div class="">
                                        <b style="margin-top: 4px;width: 100px;" [translate]="'TALENT_POOL.SEARCH_RESUME.SKILL_SET'"></b><b>:</b>
                                        <div *ngFor="let skill of detail_resume.skills" class="list-inline" style="display: inline-block">
                                            <span class="badge bg-primary" style="margin-left: 2px;">{{skill}}</span>
                                        </div>
                        
                                    </div>
                                </div>
                              </div>
                        </div>
                      </div>
                      <!--/ collapse  -->
                    </nav>
                    <!--/ navbar -->
                  </div>
                  <!--/ profile cover photo -->
                </div>
              </div>
            </div>
            <!--/ profile header -->
      
            <!-- profile info section -->
            <div *ngIf="loading;else noLoading" id="loading">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                
              </div>
            <ng-template #noLoading>
                <section id="profile-info">
                    <div class="row">
                        <div class="col-lg-7 col-xl-8 col-xxl-9">
                            <div class="">
                                      <div class="" id="CV">
                                        
                                        <iframe [cachedSrc]="src_link" style="width: 100%;height: 80vh;border: none;margin-top: 10px;margin-bottom: 10px; "></iframe>
            
                                    </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-5 col-xl-4 col-xxl-3" >
                          <div class="mt-4 mt-lg-5" style="margin-bottom: 40px;">
                            <h3 [translate]="'TALENT_POOL.SEARCH_RESUME.SAVE_CV'"></h3>
                            <button type="button" *ngIf="!saved_cv && job_description==undefined" (click)="modalOpenSaveCv(modalBasicSaveCv)" class="btn btn-success"><span class="fa fa-floppy-o"></span> <span [translate]="'TALENT_POOL.SEARCH_RESUME.SAVE_CV'"></span></button> 
                            <button type="button" *ngIf="!saved_cv && job_description!=undefined" (click)="SaveResumeByJd()" class="btn btn-success"><span class="fa fa-floppy-o"></span> <span [translate]="'TALENT_POOL.SEARCH_RESUME.SAVE_CV'"></span></button> 
            
                            <button type="button" *ngIf="saved_cv" class="btn btn-success" [disabled]="true"><i class="fa fa-check"></i><span [translate]="'TALENT_POOL.SEARCH_RESUME.SAVED_CV'"></span></button>
            
                            
                    <ng-template #modalBasicSaveCv let-modal>
                        <div class="modal-header">
                            <h4 class="modal-title" [translate]="'TALENT_POOL.SEARCH_RESUME.SELECT_JD'"></h4>
                            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                        </div>
                        <form (ngSubmit)="FormCheckBoxSaveCv(detail_resume.id)" [formGroup]="formCheckBoxSaveCv">
                            <div class="modal-body" ngbAutofocus>
                                <ng-container *ngIf="list_all_jd.length===0">
                                    <p [translate]="'TALENT_POOL.SEARCH_RESUME.NOT_JOB'"><a target="_blank" routerLink="/manage-job/create-job" [translate]="'TALENT_POOL.SEARCH_RESUME.CREATE_JOB'"></a> </p>
                                </ng-container>
                                <ng-container *ngIf="list_all_jd.length!==0">
                                    
                                    <table class="table table-hover align-middle">
                                        <thead>
                                            <tr>
                                                <th class="pxp-is-checkbox" style="width: 1%;"></th>
                                                <th [translate]="'TALENT_POOL.MANAGE_JOB.JOB'">Job</th>
                                                <th [translate]="'TALENT_POOL.MANAGE_JOB.CATEGORY'">Category</th>
                                                <th [translate]="'TALENT_POOL.MANAGE_JOB.TYPE'">Type</th>
                                                <th [translate]="'TALENT_POOL.MANAGE_JOB.LEVEL'">Level</th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody>
                                            <tr *ngFor="let list_all_jd_ of list_all_jd| slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
                                                <td *ngIf="arr_checkboxSaveCv.includes(list_all_jd_.id.toString());else noChecked">
                                                    <input [value]="list_all_jd_.id" (change)="onCheckboxChangeSaveCv($event)" type="checkbox" class="form-check" checked>
                                                    
                                                </td>
                                                <ng-template #noChecked>
                                                    <td>
                                                        <input [value]="list_all_jd_.id" (change)="onCheckboxChangeSaveCv($event)" type="checkbox" class="form-check">
                                                        
                                                    </td>
                                                </ng-template>
                                            
                                                
                                                <td>
                                                    <div class="" >{{list_all_jd_.job_title}}</div>
                                                </td>
                                                <td>
                                                    <div class="">{{list_all_jd_.industry}}</div>
                                                </td>
                                                <td>
                                                    <div class="">{{list_all_jd_.job_type}}</div>
                                                </td>
                                                <td>
                                                    <div class="">{{list_all_jd_.job_level}}</div>
                                                </td>
                                            
                                            </tr>
            
                                        </tbody>
                                    </table>
                                    <div class="row mt-4 mt-lg-5 justify-content-between align-items-center">
                                        <div class="col-auto">
                
                                            <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">
                
                                                <ngb-pagination [collectionSize]="list_all_jd.length" [(page)]="page" [pageSize]="pageSize"
                                                [maxSize]="10" [rotate]="true" [boundaryLinks]="true">
                                                </ngb-pagination>
                                            </nav>
                                        </div>
            
                                    </div>
                                </ng-container>
            
                                </div>
                            
                            <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" rippleEffect (click)="modal.dismiss('Cross click')" [translate]="'TALENT_POOL.CREATE_JOB.SUBMIT'">
                                
                            </button>
            
                            </div>
                    </form>
                    </ng-template>
            
                          </div>
                            
                        </div>
                                
                        </div>
                </section>
            </ng-template>
            
            <!--/ profile info section -->
          </div>
    </div>
</div>