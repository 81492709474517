<!-- Checkout Payment Starts -->
<form id="checkout-payment" class="list-view product-checkout">
    <div class="payment-type">
        <div class="card">
            <div class="card-header flex-column align-items-start">
                <h4 class="card-title" translate="NOTE.TITLE"></h4>
                <br>
                <!-- <p class="card-text text-muted mt-25">Be sure to click on correct payment option</p> -->
                <p class="card-text text-muted mt-25" translate="NOTE.ONE"></p>
                <p class="card-text text-muted mt-25" translate="NOTE.TWO"></p>
            </div>
            <div class="card-body">
                <!-- <h6 class="card-holder-name my-75">John Doe</h6>
                <div class="custom-control custom-radio">
                    <input type="radio" id="customColorRadio1" name="paymentOptions" class="custom-control-input"
                        checked />
                    <label class="custom-control-label" for="customColorRadio1">
                        US Unlocked Debit Card 12XX XXXX XXXX 0000
                    </label>
                </div>
                <div class="customer-cvv mt-1">
                    <div class="form-inline">
                        <label class="mb-50" for="card-holder-cvv">Enter CVV:</label>
                        <input type="password" class="form-control ml-sm-75 ml-0 mb-50 input-cvv" name="input-cvv"
                            id="card-holder-cvv" />
                        <button type="button" class="btn btn-primary btn-cvv ml-0 ml-sm-1 mb-50" rippleEffect>
                            Continue
                        </button>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="card">
            <div class="card-header flex-column align-items-start">
                <h4 class="card-title" translate="OPTIONS.TITLE"></h4>
            </div>
            <div class="card-body">
                <hr class="my-2" />
                <div class="py-50">
                    <div class="d-flex align-items-center" style="height: 70px;">
                        <div class="custom-control custom-radio payment-option">
                        
                            <input type="radio" id="vnpayPaymentOption" name="paymentOptions"
                            class="custom-control-input" [checked]="true" (change)="changePaymentMethod('vnpay')" />
                            <label class="custom-control-label" for="vnpayPaymentOption" translate="OPTIONS.VNPAY">                            
                            </label> 
                    </div>
                    <div><img src="assets/images/payment/vnpay.svg" alt="VNPay" width="50" height="50"/></div>
                    </div>
                </div>
                <div class="py-50">
                    <div class="d-flex align-items-center" style="height: 70px;">
                        <div class="custom-control custom-radio payment-option">
                        
                            <input type="radio" id="momoPaymentOption" name="paymentOptions"
                            class="custom-control-input" (change)="changePaymentMethod('momo')"/>
                            <label class="custom-control-label" for="momoPaymentOption" translate="OPTIONS.MOMO"> 
                            </label> 
                    </div>
                    <div><img src="assets/images/payment/momo.svg" alt="MoMo" width="40" height="40" /></div>
                    </div>
                </div>
                <div class="py-50">
                    <div class="d-flex align-items-center">
                        <div class="custom-control custom-radio payment-option">
                        
                            <input type="radio" id="paypalPaymentOption" name="paymentOptions"
                            class="custom-control-input" (change)="changePaymentMethod('paypal')"/>
                            <label class="custom-control-label" for="paypalPaymentOption" translate="OPTIONS.PAYPAL"> 
                            </label>
                    </div>
                    <div><img src="assets/images/payment/paypal.svg" alt="Paypal" width="70" height="70" /></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="amount-payable checkout-options">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title" translate="DETAILS.TITLE"></h4>
            </div>
            <div class="card-body">
                <ul class="list-unstyled price-details">
                    <li class="price-detail">
                        <div class="details-title"><span translate="DETAILS.BUYER"></span></div>
                        <div class="detail-amt">{{userInfo.username}}</div>
                    </li>
                    <li class="price-detail">
                        <div class="details-title"><span translate="DETAILS.EMAIL"></span></div>
                        <div class="detail-amt">{{userInfo.email}}</div>
                    </li>
                    <!-- <li class="price-detail">
                        <div class="details-title"><span translate="DETAILS.PACKAGE"></span></div>
                        <div class="detail-amt">Free</div>
                    </li> -->
                    <li class="price-detail">
                        <div class="details-title"><span translate="DETAILS.NUM_PAGE"></span></div>
                        <div class="detail-amt">{{paymentQuota}} {{'DETAILS.PAGE' | translate}}</div>
                    </li>
                    <li class="price-detail">
                        <div class="details-title"><span translate="DETAILS.PRICE"></span></div>
                        <div class="detail-amt">
                            <strong>{{displayAmount}}</strong>
                        </div>
                    </li>
                </ul>
                <hr />
                <ul class="list-unstyled price-details">
                    <li class="price-detail" style="font-size: 18px; font-weight: 600;">
                        <div class="details-title" ><span translate="DETAILS.TOTAL_AMOUNT"></span></div>
                        <div class="detail-amt font-weight-bolder" style="color: #219cd7;">{{displayAmount}}</div>
                    </li>
                </ul>
                
                <div class="place-order-block">
                    
                    <button *ngIf="isLoading;else noLoading" class="btn btn-primary btn-icon mr-2 place-order" disabled>
                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        <span translate="DETAILS.PAY"></span>
                      </button>
                      <ng-template #noLoading>
                        <button
                            class="btn btn-primary btn-icon mr-2 place-order"
                            rippleEffect
                            (click)="submitPaymentForm()"
                        >
                        <span translate="DETAILS.PAY"> </span>
                      </button>
                      </ng-template>
                </div>
            </div>
        </div>
    </div>
</form>