<div class="content-wrapper container-xxxl p-0 card">
    <div class="content-body card-body">
      <div>
        <form (ngSubmit)="(SearchResume.form.valid)" #SearchResume="ngForm">

            <div class="d-flex justify-content-start align-items-center mb-2">
                
                
                        
                        
                        <div class="col-md-2 me-2">
                            <ng-select [items]="status"
                            bindLabel="name"
                            bindValue="value"
                            [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_STATUS'|translate"
                            name="selectedStatus"
                            [(ngModel)]="selectedStatus"
                            id="selectedStatus"
                            [multiple]="true"
                            
                            
                            >

                        </ng-select>

                        </div>
                        <div class="col-md-2 me-2">
                          <ng-select [items]="sf4c_status"
                          bindLabel="name"
                          bindValue="value"
                          [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_SF4C_STATUS'|translate"
                          name="selectedSf4cStatus"
                          [(ngModel)]="selectedSf4cStatus"
                          id="selectedSf4cStatus"
                          [multiple]="true"
                          
                          
                          >

                      </ng-select>

                      </div>
                        
                        <div class="col-md-2 me-2">

                            <div class="input-group">
                                
                                <input
                                    type="text"
                                    class="form-control"
                                    id="email"
                                    [placeholder]="'RESUME_PARSER.PARSE_RESUME.EMAIL'|translate"
                                    [(ngModel)]="email"
                                    name="email"
                                    
                                />
                            </div>

                        </div>
                        <div class=" col-md-2 me-2">

                            <div class="input-group">
                                
                                <input
                                    type="text"
                                    class="form-control"
                                    id="phone_number"
                                    [placeholder]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'|translate"
                                    [(ngModel)]="phone_number"
                                    name="phone_number"
                                    
                                />
                            </div>

                        </div>
                        <div class="col-md-4 me-2" *ngIf="user.role=='Site Admin' || user.role=='Admin'">

                            <ng-select [items]="owner__email_items"
                            bindLabel="name"
                            bindValue="value"
                            [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_OWNER'|translate"
                            name="owner__email"
                            [(ngModel)]="owner__email"
                            id="owner__email"
                            
                            >

                        </ng-select>

                        </div>
                
            </div>
            <div class="d-flex justify-content-start align-items-center mb-2">
                <div class="col-md-2 me-2">

                    <div class="input-group">
                        
                        <input
                            type="text"
                            class="form-control"
                            id="job-search"
                            [placeholder]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate"
                            name="file_name"
                            [(ngModel)]="file_name"
                        />
                    </div>

                </div>
                <!-- <div class="col-md-2 me-2">

                    <input
                        type="text"
                        class="form-control"
                        id="conf_score__gte"
                        [placeholder]="'RESUME_PARSER.MANAGE_RESUME.MIN_CONF'|translate"
                        [(ngModel)]="conf_score__gte"
                        name="conf_score__gte"
                        
                    />

                </div>
                <div class="col-md-2 me-2">

                    <input
                        type="text"
                        class="form-control"
                        id="conf_score__lte"
                        [placeholder]="'RESUME_PARSER.MANAGE_RESUME.MAX_CONF'|translate"
                        [(ngModel)]="conf_score__lte"
                        name="conf_score__lte"
                        
                    />

                </div> -->
                
                
                
                <div class="col-md-2 d-flex align-items-center">
                    <button style="height: 38px;padding:6px" type="submit" (click)="onSubmit()" class="btn fw-bold btn-primary" rippleEffect><i class="fa fa-search mr-1" aria-hidden="true"></i> <span [translate]="'RESUME_PARSER.MANAGE_RESUME.SEARCH'"></span>  </button>

                </div>
            </div>
            
        </form>
      </div>
      
      
    </div>
  </div>
  <div class="content-wrapper container-xxxl p-0 card">
    <div class="content-body card-body" id="ngx-datatable-row-details">
        <div class="d-flex justify-content-center"
        *ngIf="isLoading; else no_loading"
        id="loading"
        style="margin: 50px auto"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <ng-template #no_loading>
        <div class="row" *ngIf="resume_parser.length>0">
            <div class="col-md-6 col-12">
              <div class="d-flex justify-content-between align-items-center m-1">
                <label
                  class="d-flex align-items-center"
                  style="max-width: 100%; flex-wrap: nowrap"
                  ><span [translate]="'RESUME_PARSER.MANAGE_RESUME.SHOW'"></span>
                  <select
                    class="form-control mx-25 pr-3 show-doc"
                    [(ngModel)]="page.size"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="40">40</option>
                  </select>
                  <span [translate]="'RESUME_PARSER.PARSE_RESUME.RESUMES'"></span>
                </label>
              </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
              <div class="">
                <a routerLink="/parse-resume" class="btn btn-outline-primary mr-2" [translate]="'RESUME_PARSER.MANAGE_RESUME.ADD_RESUME'">
                    
                </a>
            </div>
              <div class="btn-group">
                <div ngbDropdown>
                  <button
                    ngbDropdownToggle
                    class="btn btn-success"
                    type="button"
                    id="dropdownMenuButton"
                    rippleEffect
                    [translate]="'RESUME_PARSER.MANAGE_RESUME.DOWNLOAD_TYPE'"
                    >
                    
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                    <a ngbDropdownItem (click)="export_csv()">Csv</a>
                    <a ngbDropdownItem (click)="export_excel()">Excel</a>
                  </div>
                </div>
              </div>       
            <div class="alert alert-success alert-dismissible fade show me-2 mt-4" role="alert" *ngIf="tag_export">
                <strong [translate]="'RESUME_PARSER.MANAGE_RESUME.DOWNLOADING'"></strong>
            </div>
            </div>
        </div>
        <ngx-datatable
          
          *ngIf="resume_parser.length>0"
          id="table-user"
          #tableRowDetails
          class="bootstrap core-bootstrap"
          [rows]="resume_parser"
          [externalPaging]="true"
            [count]="page.totalElements"
            [offset]="page.pageNumber"
            [limit]="page.size"
            (page)="setPage($event)"
          [rowHeight]="50"
          class="bootstrap core-bootstrap"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [scrollbarH]="true"
        >
          <!-- <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
        [resizeable]="false">
        <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
          let-selectFn="selectFn">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
              (change)="selectFn(!allRowsSelected)" id="headerFolderChkbxRef" />
            <label class="custom-control-label" for="headerFolderChkbxRef"></label>
          </div>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-isSelected="isSelected"
          let-onCheckboxChangeFn="onCheckboxChangeFn">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" [checked]="isSelected"
              (change)="onCheckboxChangeFn($event)" id="rowFolderChkbxRef{{ rowIndex }}" />
            <label class="custom-control-label" for="rowFolderChkbxRef{{ rowIndex }}"></label>
          </div>
        </ng-template>
      </ngx-datatable-column> -->
          <ngx-datatable-column
            [name]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate"
            [width]="1.5*widthColumn"
          >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
                <a routerLink="/manage-resume/detail-resume/{{row.id}}" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.file_name}}" class="d-inline-block text-truncate max-width-custom w-100">{{ row.file_name }}</a>
            </div>
          </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [name]="'RESUME_PARSER.PARSE_RESUME.STATUS'|translate"
            prop="status"
            width="140"
          >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100" >
              <span class="badge bg-{{status_color[row.status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.message}}" >{{row.status}}</span>
            </div>
          </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [name]="'RESUME_PARSER.PARSE_RESUME.SF4C_STATUS'|translate"
            prop="sf4c_status"
            width="150"
          >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100" >
              <span class="badge bg-{{status_color[row.sf4c_status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.sf4c_message}}" >{{row.sf4c_status}}</span>
            </div>
          </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [name]="'RESUME_PARSER.MANAGE_RESUME.JOB_APPLICATION_STATUS'|translate"
            prop="status"
            width="200"
          >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100" >
                <span *ngIf="row.status=='SF4C_uploaded';else noStatus" class="badge bg-{{job_status_color[row.job_application_status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.job_application_detail_status}}" >{{row.job_application_status}}</span>
                <ng-template #noStatus></ng-template>
            </div>
          </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [name]="'RESUME_PARSER.PARSE_RESUME.CONF_SCORE'|translate"
            width="180"
          >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
                <span *ngIf="row.conf_score>range_conf.success" class="badge bg-success">{{row.conf_score*100|number:'1.2-2'}}%</span>
                <span *ngIf="row.conf_score>range_conf.warning && row.conf_score < range_conf.success" class="badge bg-warning">{{row.conf_score*100|number:'1.2-2'}}%</span>
                <span *ngIf="row.conf_score<range_conf.warning" class="badge bg-danger">{{row.conf_score*100|number:'1.2-2'}}%</span>
            </div>
          </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [name]="'RESUME_PARSER.MANAGE_RESUME.OWNER'|translate"
            width="200"
            *ngIf="user.role=='Site Admin' || user.role=='Admin'"
          >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100" >
                <span>{{ row.owner }}</span>
            </div>
          </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CREATE_AT'|translate" [width]="widthColumn">
            <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CREATE_AT'|translate" [width]="widthColumn">
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100">
                  <span>{{ row.created_at|date:'medium' }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable-column>
          <!-- ACTIONSSSSSSSSSSSSSSSSS -->
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.FIRST_NAME'|translate" prop="first_name" width="140">
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.LAST_NAME'|translate" prop="last_name" width="140">
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.EMAIL'|translate" prop="email" width="200">
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'|translate" prop="phone_number" width="140">
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.GENDER'|translate" prop="gender" width="100">
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.DATE_OF_BIRTH'|translate" width="200">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="w-100">
                <span>{{ row.date_of_birth|date:'longDate' }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'|translate" [width]="widthColumn">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="w-100">
                <span class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.current_title}}">{{ row.current_title }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_COMPANY'|translate" [width]="widthColumn">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="w-100">
                <span class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.current_company}}">{{ row.current_company }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
                  [name]="'RESUME_PARSER.MANAGE_RESUME.ACTION' | translate"
                  [sortable]="false"
                  [sortable]="false"
                  width="200"
                  [draggable]="false"
                >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                      <div class="avatar bg-light-primary mr-1">
                        <div class="avatar-content">
                          <a
                            routerLink="/manage-resume/edit-resume/{{row.id}}"
                            class="d-flex align-items-center justify-content-center"
                            title="Edit"
                            ><i data-feather="edit"></i>
                          </a>
                        </div>
                      </div>
    
                      <div class="avatar bg-light-danger mr-1">
                        <div class="avatar-content">
                          <a
                            href="javascript:void(0)"
                            (click)="deleteResume(row.id)"
                            class="d-flex align-items-center justify-content-center"
                            style="color: red"
                            title="Delete"
                            ><i data-feather="trash-2"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    
                  </ng-template>
                </ngx-datatable-column>
        </ngx-datatable>
      </ng-template>
    </div>
  </div>