export const locale = {
    lang: 'en',
    data: {
        ACCOUNT_SETTING:{
            GENERAL:{
                TITLE: "General",
                FIRST_NAME: "First name",
                LAST_NAME: "Last name",
                EMAIL: "Email",
                SAVE: "Save changes",
                CANCEL: "Cancel",
                REQUIRED: "This field is required"
            },
            PASSWORD: {
                TITLE: "Change password",
                OLD_PASS: "Old password",
                NEW_PASS: "New password",
                NOT_MATCH: "New password not matches",
                RETYPE_PASS: "Retype new password",
                NOT_SAME: "Passwords do not match"
            },
            RESUME_SETTINGS: {
                TITLE: "Resume settings",
                AUTO_APPROVE: "Auto approve done resume",
                AUTO_SYNC: "Auto sync email",
                CENSOR_CONTACT: "Censor contact",
                APPROVE_SCORE: "Approve score",
                WEBHOOK_PARSING: "Webhook parsing api",
                WEBHOOK_DEV_PARSING: "Webhook dev parsing api",
                WEBHOOK_MATCHING: "Webhook matching api",
                WEBHOOK_DEV_MATCHING: "Webhook dev matching api",
                WEBHOOK_RECOMMEND:"Webhook recommend",
                WEBHOOK_RECOMMEND_DEV_API: "Webhook recommend dev api"
            },
            CONNECT_SF4C: {
                TITLE: "Connect to SuccessFactors",
                UPLOAD_FILE: "Upload file PEM",
                API_KEY: "API key",
                COMPANY_ID: "Company id",
                USER_NAME: "User name",
                TOKEN_URL: "Token url",
            },
            SOURCE_MAPPING: {
                TITLE: "Source mapping",
                RESUME_CONFIG: "Resume config",
                GENDER: "Gender",
                ADDRESS: "Address",
                PHONE_NUMBER: "Phone number",
                FIRST_NAME: "First name",
                LAST_NAME: "Last name",
                DATE_OF_BIRTH: "Date of birth",
                EMAIL: "Email",
                CURRENT_TITLE: "Current title",
                CURRENT_COMPANY: "Current company",
                EDUCATION: "Education",
                START_TIME: "Start time",
                END_TIME: "End time",
                MAJOR: "Major",
                SCHOOL: "School",
                DIPLOMA: "Diploma",
                GPA: "Gpa",
                EXPERIENCE: "Experience",
                POSITION: "Position",
                COMPANY: "Company",
                DETAIL: "Detail",
                JOB_APP_CONFIG: "Job application config",
                MATCHING_SCORE: "Matching score",
                AUTO_UPLOAD_SCORE: "Auto upload score",
                
            },
            ACTIVITY_HISTORY:{
                TITLE: "Activity history",
                USERS: "users",
                CREATE_USER: "Create user",
                NAME: "Name",
                EMAIL: "Email",
                PASSWORD: "Password",
                PASSWORD_REQUIRED: "Password is required",
                SUBMIT: "Submit",
                PERMISSION_PARSE: "Permission parse resume",
                PERMISSION_SEARCH: "Permission search engine",
                PERMISSION_TALENT: "Permissions talent pool",
                IS_SITE_ADMIN: "Is site admin",
                LAST_LOGIN: "Last login",
                ACTION: "Action",
                ASSIGN_USER: "Select assign user",
                CANCLE: "Cancle",
                ACCEPT: "Accept",
                ITEMS_PER_PAGE: "Items per page"
            }
          }
      
    }
  };
  