<div class="content-wrapper container-xxxl p-0">
    <div class="content-body">
        <div>
              <div class="col-md-5 position-fixed d-flex justify-content-start" *ngIf="url">
                
                <!-- <object id="pdf" [data]="sanitizer.bypassSecurityTrustResourceUrl(url)" type="application/pdf"></object> -->
                
                    <!-- <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,'+pdf_content)" style="width: 100%;height: 50rem;border: none;margin-top: 10px;margin-bottom: 10px; "></iframe> -->
                    <iframe [cachedSrc]="url" style="width: 100%;height: 80vh;border: none;margin-top: 10px;margin-bottom: 10px; "></iframe>
            </div>
            
            <div class="d-flex justify-content-end">
                <div style="margin-top: 30px;"*ngIf="errorMessagesEditResume">Error: <b style="color: red;">{{errorMessagesEditResume}}</b></div>

                <div class="col-md-6">
                    
                        <div class="" style="padding-top: 10px;">
                            <div class="d-flex justify-content-start align-items-center">
                                <h1 [translate]="'RESUME_PARSER.DETAIL_RESUME.EDIT_CANDIDATE'"></h1>                                                         
                            </div>
                            <form (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()" [formGroup]="EditResumeForm">
                    
                                <div id="accordionExample">
                                    <div class="card">
                                        <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" [translate]="'RESUME_PARSER.DETAIL_RESUME.META_DATA'">
                                            
                                            </button>
                                        </h5>
                                        </div>
                                    
                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <div class="form-group d-flex align-items-center">
                                                    <label class="col-sm-3" for="first_name" ><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.FIRST_NAME'"></span><span>: </span></label>
                                                    <div class="col-sm-9">
                                                        <input
                                                            
                                                            formControlName="first_name"
                                                            type="text"
                                                            class="form-control"
                                                            id="first_name"
                                                            name="first_name"
                                                            
                                                        />
                                                    </div>
                                                </div>
                                                <div class="form-group d-flex align-items-center">
                                                    <label class="col-sm-3" for="last_name"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.LAST_NAME'"></span><span>: </span></label>
                                                    <div class="col-sm-9">
                                                        <input
                                                        
                                                        
                                                        formControlName="last_name"
                                                        type="text"
                                                        class="form-control"
                                                        id="last_name"
                                                        name="last_name"
                                                        
                                                    />
                                                    </div>
                                                </div>
                                                <div class="form-group d-flex align-items-center">
                                                    <label class="col-sm-3" for="date_of_birth"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.DATE_OF_BIRTH'"></span><span>: </span></label>
                                                    <div class="col-sm-9">
                                                        <input
                                                        formControlName="date_of_birth"
                                                        bsDatepicker
                                                        type="text"
                                                        class="form-control"
                                                        id="date_of_birth"
                                                        name="date_of_birth"
                                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
                                                        
                                                    />
                                                    
                                                    </div>

                                                </div>
                                                <div class="form-group d-flex align-items-center">
                                                    <label class="col-sm-3" for="gender"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.GENDER'"></span><span>: </span></label>
                                                    <div class="col-sm-9">
                                                        <select
                                                        
                                                        id="gender"
                                                        class="form-control"
                                                        (change)="changeGender($event)"
                                                        formControlName="gender"
                                                        
                                                      >
                                                        <option value="" [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_GENDER'"></option>
                                                        <option *ngFor="let genderItem of genderItems" [value]="genderItem">
                                                          {{ genderItem }}
                                                        </option>
                                                      </select>    
                                                    
                                                    </div>
                                                </div>
                                                <div class="form-group d-flex align-items-center">
                                                    <label class="col-sm-3" for="address"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.ADDRESS'"></span><span>: </span></label>
                                                    <div class="col-sm-9">
                                                        <input
                                                        
                                                        formControlName="address"
            
                                                        type="text"
                                                        class="form-control"
                                                        id="address"
                                                        name="address"
                                                        
                                                    />
                                                    </div>
                                                    
                                                </div>
                                                <div class="form-group d-flex align-items-center">
                                                    <label class="col-sm-3" for="email"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.EMAIL'"></span><span>: </span></label>
                                                    <div class="col-sm-9">
                                                        <input
                                                        
                                                        formControlName="email"
            
                                                        type="email"
                                                        class="form-control"
                                                        id="email_edit"
                                                        name="email"
                                                        
                                                    />
                                                    </div>
                                                </div>
                                                <div class="form-group d-flex align-items-center">
                                                    <label class="col-sm-3" for="phone_number"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'"></span><span>: </span></label>
                                                    <div class="col-sm-9">
                                                        <input
                                                        
                                                        formControlName="phone_number"
            
                                                        type="text"
                                                        class="form-control"
                                                        id="phone_number_edit"
                                                        name="phone_number"
                                                        
                                                    />
                                                    </div>
                                                </div>
                                                <div class="form-group d-flex align-items-center">
                                                    <label class="col-sm-3" for="category"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.CATEGORY'"></span><span>: </span></label>
                                                    <div class="col-sm-9">
                                                        <select
                                                        id="category"
                                                        class="form-control"
                                                        (change)="changeCategory($event)"
                                                        formControlName="category"
                                                        
                                                      >
                                                        <option value="" [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_CATEGORY'"></option>
                                                        <option *ngFor="let categoryItem of categoryItems" [value]="categoryItem">
                                                          {{ categoryItem }}
                                                        </option>
                                                      </select>    
                                                    
                                                    </div>
                                                </div>
                                                <div class="form-group d-flex align-items-center">
                                                    <label class="col-sm-3" for="year_exp"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR_EXPERIENCE'"></span><span>: </span></label>
                                                    <div class="col-sm-9">
                                                        <select
                                                        id="year_exp"
                                                        class="form-control"
                                                        (change)="changeExperienceYear($event)"
                                                        formControlName="year_exp"
                                                        id="year_exp"
                                                      >
                                                        <option value="" [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_YEAR_EXPERIENCE'"></option>
                                                        <option *ngFor="let expItem of expItems" [value]="expItem">
                                                          {{ expItem }}
                                                        </option>
                                                      </select>    
                                                    
                                                    </div>
                                                </div>
                                                
            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingTwo">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" [translate]="'RESUME_PARSER.DETAIL_RESUME.WORK_EXPERIENCE'">
                                            
                                            </button>
                                        </h5>
                                        </div>
                                        <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                            <div class="card-body" formArrayName="experience">
                                                <div class="d-flex justify-content-end">
                                                    <button type="button" (click)="AddExperience()" class="btn rounded-pill btn-outline-primary" rippleEffect><i class="fa fa-plus" aria-hidden="true"></i></button>
                                                </div>
                                                <div *ngFor="let experience_ of experience.controls; let i = index">
                                                    <div class="d-flex justify-content-between align-items-center mb-1">
                                                        <div class=""><span class="" style="width:fit-content" [translate]="'RESUME_PARSER.DETAIL_RESUME.EXPERIENCE'"></span><span> {{i+1}} st</span></div>
                                                        <div class="mt-1 mt-lg-1">
                                                            <button type="button" (click)="DeleteExperience(i)" class="btn rounded-pill btn-outline-danger" rippleEffect><i class="fa fa-minus" aria-hidden="true"></i></button>
                                                        </div>
                                                    </div>
                                                    <div [formGroupName]="i">
                                                    
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.START_TIME'"></span><span>: </span></label>
                                                            <div class="col-sm-9">
                                                                <input
                                                                id="start_time"
                                                                formControlName="start_time"
                                                                
                                                                bsDatepicker
                                                                type="text"
                                                                class="form-control"
                                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"  
                                                            />
                                                            </div>
                                                            
                                                        </div>
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.END_TIME'"></span><span>: </span></label>
                                                            <div class="col-sm-9">
                                                                <input
                                                                id="end_time"
                                                                formControlName="end_time"
                                                                bsDatepicker
                                                                type="text"
                                                                class="form-control"
                                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
                                                                
                                                                
                                                                
                                                            />
                                                            </div>
                                                        </div>
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.POSITION'"></span><span>: </span></label>
                                                            <div class="col-sm-9">
                                                                <textarea
                                                                
                                                                id="position"
                                                                formControlName="position"
                    
                                                                type="text"
                                                                class="form-control"
                                                                style="width: fit-contend;height: fit-content"
                                                                
                                                                
                                                            ></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.INDUSTRY'"></span><span>: </span></label>
                                                            <div class="col-sm-9">
                                                                <select
                                                                    id="industry"
                                                                    class="form-control"
                                                                    (change)="changeIndustry($event)"
                                                                    formControlName="industry"
                                                                    
                                                                >
                                                                    <option value="" [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_INDUSTRY'"></option>
                                                                    <option *ngFor="let industryItem of industryItems" [value]="industryItem">
                                                                    {{ industryItem }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.COMPANY'"></span><span>: </span><span style="color:red">*</span></label>
                                                            <div class="col-sm-9">
                                                                <textarea
                                                                id="company"
                                                                formControlName="company"
                                                                style="width: fit-contend;height: fit-content"
                                                                
                    
                                                                type="text"
                                                                class="form-control"
                                                                required
                                                                
                                                                
                                                            ></textarea>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder">Industry: </span></label>
                                                            <div class="col-sm-9">
                                                                <select
                                                                    id="industry"
                                                                    class="form-control"
                                                                    (change)="changeIndustry($event)"
                                                                    formControlName="industry"
                                                                    
                                                                >
                                                                    <option value="">Choose Industry</option>
                                                                    <option *ngFor="let industryItem of industryItems" [value]="industryItem">
                                                                    {{ industryItem }}
                                                                    </option>
                                                                </select>
                                                                
                                                            </div>
                                                        </div> -->
                                                        
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder"[translate]="'RESUME_PARSER.DETAIL_RESUME.DETAIL'"></span><span>: </span></label>
                                                            <div class="col-sm-9">
                                                                <textarea
                                                                id="detail"
                                                                formControlName="detail"
                                                                style="width: fit-contend;height: fit-content"
                                                                type="text"
                                                                class="form-control"
                                                            
                                                            ></textarea>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" [translate]="'RESUME_PARSER.DETAIL_RESUME.EDUCATION'">
                                            
                                            </button>
                                        </h5>
                                        </div>
                                        <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
                                            <div class="card-body" formArrayName="education">
                                                <div class="d-flex justify-content-end">
                                                    <button type="button" (click)="AddEducation()" class="btn rounded-pill btn-outline-primary" rippleEffect><i class="fa fa-plus" aria-hidden="true"></i></button>
                                                </div>
                                                
                                                <div *ngFor="let education_ of education.controls; let i = index">
                                                    <div class="d-flex justify-content-between align-items-center mb-1">
                                                        <div class=""><span style="width:fit-content" [translate]="'RESUME_PARSER.DETAIL_RESUME.EDUCATION_'"></span><span> {{i+1}} st </span></div>
                                                        <div class="mt-1 mt-lg-1">
                                                            <button type="button" (click)="DeleteEducation(i)" class="btn rounded-pill btn-outline-danger" rippleEffect><i class="fa fa-minus" aria-hidden="true"></i></button>
                                                            
                                    
                                                        </div>
                                                    </div>
            
                                                    <div [formGroupName]="i">
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder"[translate]="'RESUME_PARSER.DETAIL_RESUME.START_TIME'"></span><span>: </span></label>
                                                            <div class="col-sm-9">
                                                                <input
                                                                
                                                                id="start_time"
                                                                formControlName="start_time"
                                                                bsDatepicker
                                                                type="text"
                                                                class="form-control"
                                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
                                                                
                                                                
                                                                
                                                            />
                                                            </div>
                                                        </div>
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.END_TIME'"></span><span>: </span></label>
                                                            <div class="col-sm-9">
                                                                <input
                                                                
                                                                id="end_time"
                                                                formControlName="end_time"
                                                                bsDatepicker
                                                                type="text"
                                                                class="form-control"
                                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
                                                                
                                                                
                                                                
                                                            />
                                                            </div>
                                                        </div>
                                                        <div class="form-group d-flex align-items-center" *ngIf="company!='4';else th_company">
                                                            <label class="col-sm-3"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.MAJOR'"></span><span>: </span></label>
                                                            <div class="col-sm-9">
                                                                <textarea
                                                                style="width: fit-contend;height: fit-content"
                                                                id="major"
                                                                formControlName="major"
                    
                                                                type="text"
                                                                class="form-control"
                                                                
                                                                
                                                                
                                                            ></textarea>
                                                            </div>
                                                        </div>
                                                        <ng-template #th_company>
                                                            <div class="form-group d-flex align-items-center">
                                                                <label class="col-sm-3"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.MAJOR'"></span><span>: </span></label>
                                                                <div class="col-sm-9">
                                                                    <textarea
                                                                    style="width: fit-contend;height: fit-content"
                                                                    id="major"
                                                                    formControlName="major"
                        
                                                                    type="text"
                                                                    class="form-control"
                                                                    
                                                                    
                                                                    
                                                                ></textarea>
                                                                </div>
                                                            </div>
                                                            <div class="form-group d-flex align-items-center">
                                                                <label class="col-sm-3"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.PICKLIST_MAJOR'"></span><span>: </span></label>
                                                                <div class="col-sm-9">
                                                                    <select
                                                                        id="picklist_major"
                                                                        class="form-control"
                                                                        (change)="changeMajor($event)"
                                                                        formControlName="picklist_major"
                                                                        
                                                                    >
                                                                    <option value="" [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_MAJOR'"></option>
                                                                    <option *ngFor="let majorItem of majorItems" [value]="majorItem">
                                                                    {{ majorItem }}
                                                                    </option>
                                                                </select>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.SCHOOL'"></span><span>: </span><span style="color:red">*</span></label>
                                                            <div class="col-sm-9">
                                                                <textarea
                                                                style="width: fit-contend;height: fit-content"
                                                                id="school"
                                                                formControlName="school"
                    
                                                                type="text"
                                                                class="form-control"
                                                                required
                                                                
                                                                
                                                            ></textarea>
                                                            
                                                            </div>
                                                            
                                                        </div>
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.DIPLOMA'"></span><span>: </span></label>
                                                            <div class="col-sm-9">
                                                                <select
                                                                    id="diploma"
                                                                    class="form-control"
                                                                    (change)="changeDiploma($event)"
                                                                    formControlName="diploma"
                                                                    
                                                                >
                                                                    <option value="" [translate]="'RESUME_PARSER.DETAIL_RESUME.CHOOSE_DIPLOMA'"></option>
                                                                    <option *ngFor="let diplomaItem of diplomaItems" [value]="diplomaItem">
                                                                    {{ diplomaItem }}
                                                                    </option>
                                                                </select>
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder" [translate]="'RESUME_PARSER.DETAIL_RESUME.GPA'"></span><span>: </span></label>
                                                            <div class="col-sm-9">
                                                                <input
                                                                
                                                                id="gpa"
                                                                formControlName="gpa"
                                                                type="text"
                                                                class="form-control"
                                                                
                                                            />
                                                            </div>
                                                        </div>
                                                        <!-- <div class="form-group d-flex align-items-center">
                                                            <label class="col-sm-3"><span class="font-weight-bolder">Picklist major: </span></label>
                                                            <div class="col-sm-9">
                                                                <input
                                                                
                                                                id="picklist_major"
                                                                formControlName="picklist_major"
                                                                type="text"
                                                                class="form-control"
                                                                
                                                            />
                                                                
                                                                
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2 mt-lg-2">
                                    <button type="submit" [disabled]="EditResumeForm.invalid" class="btn btn-primary rounded-pill" rippleEffect [translate]="'RESUME_PARSER.PARSE_RESUME.SUBMIT'"></button>
            
                                </div>
                                <div class="mt-2">
                                    <p *ngIf="errorMessagesEditResume!=null">Error: {{errorMessagesEditResume}}</p>
                                </div>
                                <!-- <div *ngIf="tag_transfer">
                                    <ngb-alert [type]="'success'" [dismissible]="false" class="mt-2">
                                        <div class="alert-body">
                                            <p>Update successfully! <a role="button" class="pxp-modal-link" routerLink="/employer/resume-detail/{{resume_parser.id}}">Preview</a></p>
                                        </div>
                                    </ngb-alert>
                                </div> -->
                            </form>
                    </div>
                    
                </div>
                           
            </div>
        </div>
    </div>
</div>
