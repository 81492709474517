<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/manage-resume']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Bookmark -->
    <!-- <app-navbar-bookmark></app-navbar-bookmark> -->
    <!--/ Bookmark -->
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Language selection -->
    <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li>
    <!--/ Language selection -->

    <!-- Toggle skin -->
    <!-- <li class="nav-item d-none d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li> -->
    <!--/ Toggle skin -->

    <!-- Search -->
    <!-- <app-navbar-search></app-navbar-search> -->
    <!--/ Search -->

    <!-- Notification -->
    <!-- <app-navbar-notification></app-navbar-notification> -->
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-container *ngIf="currentUser.first_name">
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder"
              >{{ currentUser.first_name }} {{ currentUser.last_name }}</span
            ><span class="user-status">{{ currentUser.role }}</span>
          </div>
          <div class="avatar"
            ><img
              class="round"
              src="assets/images/portrait/small/avatar-s-1.jpg"
              alt="avatar"
              height="40"
              width="40" /></div>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <!-- <a ngbDropdownItem [routerLink]="['/pages/profile']"
          ><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a
        ><a ngbDropdownItem [routerLink]="['/apps/email']"
          ><span [data-feather]="'mail'" [class]="'mr-50'"></span> Inbox</a
        ><a ngbDropdownItem [routerLink]="['/apps/todo']"
          ><span [data-feather]="'check-square'" [class]="'mr-50'"></span> Task</a
        ><a ngbDropdownItem [routerLink]="['/apps/chat']"
          ><span [data-feather]="'message-square'" [class]="'mr-50'"></span> Chats</a
        >
        <div class="dropdown-divider"></div> -->
        <a ngbDropdownItem [routerLink]="'/account-settings'"
          ><span [data-feather]="'settings'" [class]="'mr-50'"></span> <span translate="DROP_DOWN.SETTING"></span></a
        >
        <a
          ngbDropdownItem
          currentUser.is_site_admin
          [routerLink]="['/billing']"
          ><span [data-feather]="'credit-card'" [class]="'mr-50'"></span> <span translate="DROP_DOWN.BILLING"></span></a
        >
        <a
          ngbDropdownItem
          *ngIf="currentUser.is_site_admin"
          [routerLink]="['/pricing']"
          ><span [data-feather]="'dollar-sign'" [class]="'mr-50'"></span> <span translate="DROP_DOWN.PAYMENT"></span></a
        >
        <!-- <a *ngIf="currentUser.is_site_admin || currentUser.is_superuser" ngbDropdownItem [routerLink]="'/manage-user'"
          ><span [data-feather]="'user'" [class]="'mr-50'"></span> Manage user</a
        > -->
        <!-- <a ngbDropdownItem [routerLink]="['/pages/pricing']"
          ><span [data-feather]="'credit-card'" [class]="'mr-50'"></span> Pricing</a
        ><a ngbDropdownItem [routerLink]="['/pages/faq']"
          ><span [data-feather]="'help-circle'" [class]="'mr-50'"></span> FAQ</a
        > -->
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> <span translate="DROP_DOWN.LOG_OUT"></span></a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
