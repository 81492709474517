import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { User } from 'app/auth/models/user';
import { CV_task } from '../models/job.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { TalentPoolService } from '../services/talent-pool.service';
import { Page } from 'app/main/resume-parser/models/paging.model';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
@Component({
  selector: 'app-manage-job',
  templateUrl: './manage-job.component.html',
  styleUrls: ['./manage-job.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageJobComponent implements OnInit {
  public isAuthenticated=false
  public errorMessagesListAllJd!:any
  public errorMessagesSyncSf4cJr!: any
  public list_all_jd:CV_task[]=[]
  public owner__email!: string
  public owner__email_items: any[]=[]
  public users: User[] = []
  public errorMessagesListUser: any
  public tag_list_user = false
  public tag_filter_user = false
  public basicSelectedOption = 10
  // page = 1;
  // pageSize = 5;
  public page = new Page({});
  ColumnMode = ColumnMode;
  widthColumn: number = 0;
  @ViewChild(DatatableComponent) tableJd: DatatableComponent;

  public searchText: string = '';
  public searchOwner: string = ''
  public status: string = ''
  public loading = false
  constructor(
    private modalService: NgbModal,
    private _router: Router,
    private toastr: ToastrService,
    private _authenticationService: AuthenticationService,
    private talentPoolService: TalentPoolService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,

  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
   }
  Search(){
    // alert(this.searchText)
    if(this.searchText!== ""){
      let searchValue = this.searchText.toLocaleLowerCase();

      this.list_all_jd = this.list_all_jd.filter((cv_task:any) =>{
        return cv_task.job_title.toLocaleLowerCase().match(searchValue ) 
        ;
      // you can keep on adding object properties here

            });

          }
          else{
            this.ListAllJd();
          }
        }
        SearchOwner(){
          // alert(this.searchOwner)
          if(this.searchOwner!== ""){
            let searchValue = this.searchOwner.toLocaleLowerCase();
      
            this.list_all_jd = this.list_all_jd.filter((cv_task:any) =>{
              return cv_task.owner_email.toLocaleLowerCase().match(searchValue ) 
              ;
            // you can keep on adding object properties here
      
                  });
      
                }
                else{
                  this.ListAllJd();
                }
              }
  
              getListUser(){
                this._authenticationService.listUsers().subscribe(
                  (res)=>{
                    this.users = res.results;
                    for (let i=0;i<this.users.length;i++){
                      let owner__email_item = this.users[i].first_name + ' '+ this.users[i].last_name + ': '+ this.users[i].email
                      this.owner__email_items.push({"name":owner__email_item, "value": this.users[i].email})
                      
                    }
                    this.owner__email_items = [...this.owner__email_items] 
                  },
                  (err)=>{
                    this.errorMessagesListUser = err
                  }
                )
              }
              ListAllJd(){
                this.talentPoolService.list_all_jd().subscribe(
                  (res)=>{
                    this.list_all_jd = res;
                  },
                  (err)=>{
                    this.errorMessagesListAllJd =err
                  }
                )
              }
              sync_sf4c_jr(){
                this.loading = true
                this.talentPoolService.sync_sf4c_jr().subscribe(
                  (res)=>{
                    this.status = res.status;
                    if(res.status!=undefined){
                      this.loading = false
                      this.toastr.success('Sync SuccessFactors JR Success');
                      this.ListAllJd()
                      
                    }
                  },
                  (err)=>{
                    this.errorMessagesSyncSf4cJr = err
                  }
                )
              }
              ngOnInit(): void {
                // this.GetListUser()
                this.ListAllJd()
              }

}
