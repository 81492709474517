import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { locale as enLocale } from "../../../main/landing-page/i18n/en";
import { locale as vnLocal } from "../../../main/landing-page/i18n/vn";
import { CoreTranslationService } from '@core/services/translation.service';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  public haveLogo = false
  public isLogin = false;
  public isTryPage = false;

  public languageOptions: any;
  public selectedLanguage: any;
  public userName: any;
  public userQuota: any;
  public loading= false
  public isCollapsed = false;
  public currentUser: User;
  public displayMenu = false

  constructor(
    private _coreConfigService: CoreConfigService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this.languageOptions = {
      en: {
        title: 'en',
        flag: 'us'
      },
      vn: {
        title: 'vn',
        flag: 'vn'
      },
      
    };
   }
   public onClick(elementId: string): void { 
    this.viewportScroller.scrollToAnchor(elementId);
  }
   setLanguage(lang) {
    // Set the selected language for the navbar on change
    this.selectedLanguage = lang;

    // Use the selected language id for translations
    // this._translateService.use(lang);

    this._coreConfigService.setConfig({
      app: {
        appLanguage: this.selectedLanguage,
      },
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    });
  }
  logout() {
    this._authenticationService.logout();
    this._router.navigate(['/login']);
  }
  ngOnInit(): void {
    this.currentUser = this._authenticationService.currentUserValue
    if(this.currentUser.first_name!=null&&this.currentUser.first_name!=undefined){
      this.isLogin = true;
      this.isTryPage = true;
    }
    if(!window.location.href.includes('resume')){
      this.displayMenu = true
    } 
    

  }
  public navigateToSection(section: string) {
    window.location.hash = '';
    window.location.hash = section;
}

}
