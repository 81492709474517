<div class="content-wrapper container-xxxl p-0">
    <div>


        <div class="content-body">
            <form (ngSubmit)="onSubmit()" [formGroup]="FilterStatForm">

                <div class="row">
                    <div class="col-md-4" *ngIf="is_superuser">
                        <select id="company" class="form-select" (change)="changeCompany($event)"
                            formControlName="company">
                            <option value="" [translate]="'DASHBOARD.ALL_COMPANY'"></option>
                            <option *ngFor="let company of companys" [value]="company.id">
                                {{ company.name }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-4 d-flex align-items-center">
                        <input id="period" formControlName="period" [placeholder]="'DASHBOARD.CHOOSE_PERIOD'|translate" bsDaterangepicker
                            type="text" class="form-control"
                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" />

                    </div>
                    <div class="col-md-4">


                        <button type="submit" class="btn btn-primary" rippleEffect [translate]="'DASHBOARD.FILTER'"></button>
                        <button type="button" (click)="onReset()" class="btn btn-secondary m-2"
                            style="background-color: #6c757d;" rippleEffect [translate]="'DASHBOARD.RESET'"></button>
                    </div>
                </div>

            </form>
        </div>
        <div *ngIf="loading;else noLoading" id="loading" style="margin: 50px auto">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <ng-template #noLoading>
            <div class="card card-statistics">
                <div class="card-body statistics-body">
                    <div class="row">
                        <div class="col-sm-6 col-xl">
                            <div class="media">
                                <div class="avatar bg-light-success mr-2">
                                    <div class="avatar-content">
                                        <i data-feather="file" class="avatar-icon"></i>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{stat.num_resumes}}</h4>
                                    <p class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.RESUME_UPLOADED'"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl">
                            <div class="media">
                                <div class="avatar bg-light-info mr-2">
                                    <div class="avatar-content">
                                        <i data-feather="file" class="avatar-icon"></i>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{stat.latest_resumes}}</h4>
                                    <p class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.RESUME_UPLOADED_DAY'"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl mb-2 mb-xl-0">
                            <div class="media">
                                <div class="avatar bg-light-primary mr-2">
                                    <div class="avatar-content">
                                        <i data-feather="percent" class="avatar-icon"></i>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{stat.avg_score*100|number:'1.2-2'}}</h4>
                                    <p class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.AVERAGE_CONF_SCORE'"></p>
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>
    
    
            <div class="row align-items-center">
    
                <div class="col-xl-6 col-12">
                    <div class="card card-statistics">
                        <div class="card-header flex-column align-items-start">
                            <h4 class="card-title mb-75" [translate]="'DASHBOARD.RESUME_STATUS'"></h4>
                            <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
                        </div>
                        <div class="card-body">
                            <div id="earnings-donut-chart" #statusChartRef>
                                <!-- Apexchart component-->
                                <apx-chart [chart]="{
                                type: 'donut',             
                                height: 400,
                                toolbar: {
                                  show: false
                                }
                              }" [dataLabels]="statusChartoptions.dataLabels" [series]="statusChartoptions.series"
                                    [legend]="statusChartoptions.legend" [labels]="statusChartoptions.labels"
                                    [stroke]="statusChartoptions.stroke" [colors]="statusChartoptions.colors"
                                    [grid]="statusChartoptions.grid"
                                    [plotOptions]="statusChartoptions.plotOptions"></apx-chart>
                                <!-- / Apexchart component-->
                            </div>
    
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12">
                    <div class="card card-statistics">
                        <div class="card-header flex-column align-items-start">
                            <h4 class="card-title mb-75" [translate]="'DASHBOARD.RESUME_OWNER'"></h4>
                            <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
                        </div>
                        <div class="card-body">
                            <div id="earnings-donut-chart" #ownerChartRef>
                                <!-- Apexchart component-->
                                <apx-chart [chart]="{
                                type: 'donut',             
                                height: 400,
                                toolbar: {
                                  show: false
                                }
                              }" [dataLabels]="ownerChartoptions.dataLabels" [series]="ownerChartoptions.series"
                                    [legend]="ownerChartoptions.legend" [labels]="ownerChartoptions.labels"
                                    [stroke]="ownerChartoptions.stroke" [colors]="ownerChartoptions.colors"
                                    [grid]="ownerChartoptions.grid"
                                    [plotOptions]="ownerChartoptions.plotOptions"></apx-chart>
                                <!-- / Apexchart component-->
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
            <div class="row">
                <div class="col-xl-6 col-12">
                    <div class="card card-statistics">
                        <div class="card-header flex-column align-items-start">
                            <h4 class="card-title mb-75" [translate]="'DASHBOARD.RESUME_STATUS_DAY'"></h4>
                            <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
                        </div>
                        <div class="card-body">
                            <div id="line-chart" #resumeStatusChartRef>
                                <apx-chart [series]="resumeStatusChartoptions.series" [chart]="{
                                height: 400,
                                
                                type: 'line',
                                
                                toolbar: {
                                  show: false
                                }
                              }" [xaxis]="resumeStatusChartoptions.xaxis" [stroke]="resumeStatusChartoptions.stroke"
                                    [tooltip]="resumeStatusChartoptions.tooltip" [colors]="resumeStatusChartoptions.colors"
                                    [dataLabels]="resumeStatusChartoptions.dataLabels"
                                    [grid]="resumeStatusChartoptions.grid"></apx-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>