<div class="content-wrapper container-xxxl p-0">
    <div class="content-body card-body">
        <div id="user-profile">
            <!-- profile header -->
            <div class="row">
              <div class="col-12">
                <div class="card profile-header mb-2">
                  <!-- tabs pill -->
                  <div class="profile-header-nav">
                    <!-- navbar -->
                    <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
                      
      
                      <!-- collapse  -->
                      <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <div class="profile-tabs d-flex justify-content-end flex-wrap mt-1 mt-md-0">
                          <!-- edit button -->
                          <a class="btn btn-warning mr-1" routerLink="/manage-job/edit-jd/{{detail_jd.id}}" [translate]="'TALENT_POOL.DETAIL_JOB.EDIT_JOB'">
                            
                          </a>
                          <a class="btn btn-success mr-1" routerLink="/manage-job/edit-jd/{{detail_jd.id}}" [translate]="'TALENT_POOL.DETAIL_JOB.RECOMMEND_RESUME'">
                            
                          </a>
                          <a class="btn btn-info mr-1" routerLink="/manage-job/edit-jd/{{detail_jd.id}}" [translate]="'TALENT_POOL.DETAIL_JOB.SAVED_RESUME'">
                            
                          </a>
                          <a class="btn btn-primary" routerLink="/search-resume" [translate]="'TALENT_POOL.DETAIL_JOB.SEARCH_RESUME'">
                            
                          </a>
                        </div>
                      </div>
                      <!--/ collapse  -->
                    </nav>
                    <!--/ navbar -->
                  </div>
                </div>
              </div>
            </div>
            <!--/ profile header -->
      
            <!-- profile info section -->
            <section id="profile-info">
              <div class="row">
                <!-- left profile info section -->
                <div class="col-lg-9 col-12 order-2 order-lg-1">
                  <!-- about -->
                  <div class="card">
                    <div class="card-body">
                    <h5 class="mb-75" [translate]="'TALENT_POOL.SEARCH_RESUME.JOB_TITLE'">{{detail_jd.job_title}} </h5>

                      <h5 class="mb-75" [translate]="'TALENT_POOL.SEARCH_RESUME.OWNER'"><h5>:</h5></h5>
                      <p class="card-text">
                        {{ detail_jd.owner }}
                      </p>
                      <div class="mt-2">
                        <h5 class="mb-75" [translate]="'TALENT_POOL.RESUME_MATCHING.CREATE_AT'"><h5>:</h5></h5>
                        <p class="card-text">{{ detail_jd.created_at|date: 'medium' }}</p>
                      </div>
                      <div class="mt-2">
                        <h5 class="mb-75" [translate]="'TALENT_POOL.DETAIL_JOB.DESCRIPTION'"><h5>:</h5></h5>
                        <p *ngIf="detail_jd.job_description;else noJobDescription" class="card-text" [innerHTML]="detail_jd.job_description">
                        </p>   
                        <ng-template #noJobDescription>
                            
                        </ng-template>
                        
                      </div>
                      <div class="mt-2">
                        <h5 class="mb-75" [translate]="'TALENT_POOL.DETAIL_JOB.REQUIREMENTS'"><h5>:</h5></h5>
                        <p *ngIf="detail_jd.job_description;else noJobDescription" class="card-text" [innerHTML]="detail_jd.job_description">
                        </p>   
                        <ng-template #noJobDescription>
                            
                        </ng-template>
                      </div>  
                    </div>
                  </div>
                  <!--/ about -->
                </div>
                <!--/ left profile info section -->
      
                <!-- right profile info section -->
                <div class="col-lg-3 col-12 order-3">
                  <!-- latest profile pictures -->
                  <div class="card">
                    <div class="card-body">
                      <h5 class="mb-0" [translate]="'TALENT_POOL.DETAIL_JOB.EDIT_JOB'"></h5>
                      <div class="mt-2">
                        <h5 class="mb-75" [translate]="'TALENT_POOL.CREATE_JOB.YEARS_OF_EXPERIENCE'"></h5>
                        <p class="card-text">From {{detail_jd.years_of_experience_from}} year to {{detail_jd.years_of_experience_to}} year</p>
                      </div>
                      <div class="mt-2">
                        <h5 class="mb-75" [translate]="'TALENT_POOL.DETAIL_JOB.WORK_LEVEL'"></h5>
                        <p class="card-text">{{detail_jd.job_level}}</p>
                      </div>
                      <div class="mt-2">
                        <h5 class="mb-75" [translate]="'TALENT_POOL.DETAIL_JOB.EMPLOYMENT_TYPE'"></h5>
                        <p class="card-text">{{detail_jd.job_type}}</p>
                      </div>
                      <div class="mt-2">
                        <h5 class="mb-75" [translate]="'TALENT_POOL.CREATE_JOB.INDUSTRY'"></h5>
                        <p class="card-text">{{detail_jd.industry}}</p>
                      </div>
                      <div class="mt-2">
                        <h5 class="mb-75" [translate]="'TALENT_POOL.DETAIL_JOB.BIRTHDAY'"></h5>
                        <p class="card-text">{{detail_jd.yob_from}}-{{detail_jd.yob_to}}</p>
                      </div>
                      <div class="mt-2">
                        <h5 class="mb-75" [translate]="'TALENT_POOL.CREATE_JOB.LOCATION'"></h5>
                        <p class="card-text">{{detail_jd.location}}</p>
                      </div>
                      <div class="mt-2">
                        <h5 class="mb-75" [translate]="'TALENT_POOL.CREATE_JOB.GENDER'"></h5>
                        <p class="card-text" *ngIf="detail_jd.gender=='';else noAll" [translate]="'TALENT_POOL.CREATE_JOB.ALL'"></p>
                        <ng-template #noAll><p class="card-text">{{detail_jd.gender}}</p></ng-template>
                        
                      </div>
                    </div>
                  </div>
                  <!--/ latest profile pictures -->
      
                  <!-- suggestion -->
                  <!-- <div class="card">
                    <div class="card-body">
                      <h5 class="mb-2">Information</h5>
                      <div class="mt-2">
                        <h5 class="mb-75">CMC</h5>
                        <p class="card-text">From {{detail_jd.years_of_experience_from}} year to {{detail_jd.years_of_experience_to}} year</p>
                      </div>
                      <div class="mt-2">
                        <h5 class="mb-75">Years Of Experience</h5>
                        <p class="card-text">From {{detail_jd.years_of_experience_from}} year to {{detail_jd.years_of_experience_to}} year</p>
                      </div>
                      <div class="mt-2">
                        <h5 class="mb-75">Years Of Experience</h5>
                        <p class="card-text">From {{detail_jd.years_of_experience_from}} year to {{detail_jd.years_of_experience_to}} year</p>
                      </div>
                      <div class="mt-2">
                        <h5 class="mb-75">Years Of Experience</h5>
                        <p class="card-text">From {{detail_jd.years_of_experience_from}} year to {{detail_jd.years_of_experience_to}} year</p>
                      </div>
                      </div>
                    </div>
                  </div> -->
                  <!--/ suggestion -->
                </div>
                <!--/ right profile info section -->
              </div>
            </section>
            <!--/ profile info section -->
          </div>
    </div>
</div>