import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentComponent } from './payment.component';
import { PaymentDetailComponent } from './payment-detail/payment-detail.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BillingComponent } from './billing/billing.component';

const routes: Routes = [
  {
    path: "pricing",
    component: PaymentComponent,
    canActivate: [AuthGuard],
    data: { animation: "payment" },
  },
  {
    path: "pricing/order",
    component: PaymentDetailComponent,

    canActivate: [AuthGuard],
  },
  {
    path: "payment-status/:transactionId",
    component: PaymentStatusComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "billing",
    component: BillingComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    PaymentComponent,
    PaymentDetailComponent,
    PaymentStatusComponent,
    BillingComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    NgbModule,
    CoreCommonModule,
    ContentHeaderModule,
    TranslateModule,
  ],
  providers: [
    AuthGuard
  ],
})
export class PaymentModule { }
