export const locale = {
    lang: 'vn',
    data: {
      RESUME_PARSER: {
        PARSE_RESUME: {
            
            UPLOAD_UP_TO: "Tải lên tối đa 50 tệp CV (PDF, DOC, DOCX, EXCEL, JPEG)",
            RESUMES: "hồ sơ",
            UPLOAD: "Tải lên",
            FILE_NAME: "Tên tệp",
            STATUS: "Trạng thái",
            SF4C_STATUS: "Trạng thái Sf4c",
            CREATE_AT: "Thời gian tạo",
            FIRST_NAME: "Tên đầu",
            LAST_NAME: "Tên cuối",
            ADDRESS: "Địa chỉ",
            EMAIL: "E-mail",
            PHONE_NUMBER: "Số điện thoại",
            GENDER: "Giới tính",
            DATE_OF_BIRTH: "Ngày sinh",
            CURRENT_TITLE: "Vị trí hiện tại",
            CURRENT_COMPANY: "Công ty hiện tại",
            CONF_SCORE: "Điểm tin cậy",
            APPROVE_RESUMES: "Phê duyệt hồ sơ",
            SELECT_JOB: "Chọn tiêu đề yêu cầu công việc",
            UPLOAD_SF4C: "Tải lên SuccessFactors",
            JOB_REQ_ID: "ID công việc",
            JOB_TITLE: "Tiêu đề công việc",
            SUBMIT: "Gửi",
            LABEL_UPLOAD: 'Kéo thả tài liệu vào đây hoặc tải lên tối đa 50 tệp CV (PDF, DOC, DOCX, JPEG, EXCEL)',
            FROM_DEVICE: 'Từ thiết bị',
        },
        MANAGE_RESUME: {
            SEARCH: "Tìm kiếm",
            SHOW: "Hiển thị",
            ADD_RESUME: "Thêm hồ sơ",
            MODIFIED_AT: "Thời gian sửa",
            YEAR_EXPERIENCE: "Năm kinh nghiệm",
            JOB_APPLICATION_STATUS: "Trạng thái hồ sơ",
            DELETE_RESUME: "Xóa hồ sơ!",
            DOWNLOAD_TYPE: "Tải xuống",
            DOWNLOADING: "Đang tải",
            CANCLE: "Hủy",
            ACCEPT: "Chấp nhận",
            OWNER: "Chủ sở hữu",
            CHOICE_STATUS: "Chọn nhiều trạng thái",
            CHOICE_SF4C_STATUS: "Chọn nhiều trạng thái Sf4c",

            CHOICE_OWNER: "Chọn chủ sở hữu",
            MIN_CONF: "Điểm tin cậy nhỏ nhất (0,1)",
            MAX_CONF: "Điểm tin cậy lớn nhất (0,1)",
            ACTION: "Hành động",
            MODAL: {
              ACTION: "Hành động",
              DELETE_R: "Xóa hồ sơ",
              CANT_UNDO: "Bạn không thể hoàn tác",
              CANCEL: "Hủy",
              CONFIRM: "Xác nhận",
            },
            TOAST: {
              EDIT_R_SUC: "Sửa hồ sơ",
              CREATE_R_SUC: "Tạo hồ sơ",
              DELETE_R_SUC: "Xóa hồ sơ",
              ERROR: "Lỗi khi thực hiện",
              FAILED: "Thất bại",
              SUCCESS: "Thành công"
            },


        },
        INTEGRATION: {
            INTEGRATION: "Tích hợp SuccessFactors",
            NO_SUITABLE_JR: "Không có vị trí công việc phù hợp",
            UPLOAD_FAILED: "Tải lên thất bại!",
            UPDATE_JA_SUCCESSFACTORS: "Cập nhật công việc SuccessFactors",
            UPLOAD: " Tải lên",
            NO_RESUME_APPROVE:"Không có hồ sơ nào ở trạng thái được chấp nhận.",
            NO_RESUME_SF4C_UPLOAD:"Không có hồ sơ nào ở trạng thái đã tải lên SF4C.",
        },
        DETAIL_RESUME:{
            TAB_CHR: "Trích xuất hồ sơ trên C-BDP-HR",
            TAB_SF4C: "Trích xuất hồ sơ trên SuccessFactors",
            CANDIDATE_INFORMATION: "Thông tin ứng viên.",
            SF4C_RESUME_ID: "Id hồ sơ trên Successfactor:",
            META_DATA: "Thông tin chung",
            DOB: "Ngày sinh",
            CATEGORY: "Loại",

            SKILLS: "Kỹ năng",
            CONF_SCORE: "Điểm tin cậy",
            WORK_EXPERIENCE: "Kinh nghiệm làm việc",
            POSITION: "Vị trí",
            COMPANY: "Công ty",
            INDUSTRY: "Lĩnh vực",
            EDUCATION: "Học vấn và đào tạo",
            EDUCATION_: "Học vấn",
            MAJOR: "Chuyên ngành",
            SCHOOL: "Trường",
            DIPLOMA: "Bằng cấp",
            GPA: "Điểm tích lũy",
            JOB_REQ_MATCHING: "Phù hợp yêu cầu công việc",
            SELECT_JR: "Lựa chọn công việc:",
            ANALYSIS: "Phân tích",
            NO_MATCHING: "Không tìm thấy kết quả phù hợp",
            SEARCHING: "Kết quả tìm kiếm",
            MATCHING_POINT: "Điểm phù hợp",
            EXPERIENCE: "Kinh nghiệm",
            FOREIGN_LANGUAGE: "Ngoại ngữ",
            CANDIDATE_SKILL: "Các kỹ năng của ứng viên phù hợp với công việc:",
            APPROVE: "Chấp nhận",
            UPSERT: "Khớp lại",
            EDIT: "Sửa đổi",
            CENSOR: "Che thông tin",
            CANDIDATE_SF4C: "Thông tin ứng viên trên SuccessFactors.",
            YEAR_EXPERIENCE: "Năm kinh nghiệm",
            PICKLIST_MAJOR: "Danh mục trường dữ liệu",
            DEGREE: "Bằng cấp",
            DECENSOR: "Bỏ che thông tin",
            EDIT_CANDIDATE: "Sửa đổi thông tin ứng viên.",
            CHOOSE_GENDER: "Chọn giới tính",
            CHOOSE_CATEGORY: "Chọn loại",
            CHOOSE_YEAR_EXPERIENCE: "Chọn năm kinh nghiệm",
            START_TIME: "Bắt đầu",
            END_TIME: "Kết thúc",
            CHOOSE_INDUSTRY: "Chọn lĩnh vực",
            DETAIL: "Chi tiết",
            CHOOSE_MAJOR: "Chọn chuyên ngành",
            CHOOSE_DIPLOMA: "Chọn bằng cấp"
        },
      },
      
    }
  };
  