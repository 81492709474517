<div class="content-wrapper container-xxxl p-0">
    <div class="content-body">
      <!-- Kitchen Sink Datatables -->
      <section id="datatable-billing">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <label class="d-flex align-items-center"
                ><span [translate]="'BILLING.SHOW'"></span>
                <select
                  class="form-control mx-25"
                  [(ngModel)]="basicSelectedOption"
                >
                  <option value="5">5</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span [translate]="'BILLING.ENTRIES'"></span>
              </label>
            </div>
          </div>
        </div>
        <!-- Data TABLE START  -->
  
        <div class="card">
          <div
            *ngIf="isLoading; else no_loading"
            id="loading"
            style="margin: 50px auto"
          >
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <ng-template #no_loading>
            <ngx-datatable
              id="table-billing"
              #table
              class="bootstrap core-bootstrap"
              [rows]="billingRows"
              [rowHeight]="50"
              class="bootstrap core-bootstrap"
              [columnMode]="ColumnMode.force"
              [headerHeight]="50"
              [footerHeight]="50"
              [scrollbarH]="false"
              [limit]="basicSelectedOption"
              
            >
              <ngx-datatable-column
                name="ID"
                prop="id"
                [width]="383"
              ></ngx-datatable-column>
              <ngx-datatable-column
                [name]="'BILLING.CREATED_AT' | translate"
                [width]="255"
              >
              <ng-template ngx-datatable-cell-template let-row="row">
                <span
                >
                  {{ row.created_at|date:'short' }}
              </span>
              </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [name]="'BILLING.TYPE' | translate"
                prop="type"
                [width]="255"
              >
              </ngx-datatable-column>
              <ngx-datatable-column
                [name]="'BILLING.AMOUNT' | translate"
                [width]="255"
              >
              <ng-template ngx-datatable-cell-template let-row="row">
                <span
                >
                  {{ row.amount|currency:"VND":"symbol" }}
              </span>
              </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [name]="'BILLING.QUOTA_BUY' | translate"
                prop="quota_buy"
                [width]="255"
              >
              </ngx-datatable-column>
              <ngx-datatable-column
                [name]="'BILLING.STATUS' | translate"
                [width]="255"
              >
              <ng-template ngx-datatable-cell-template let-row="row">
                <span>{{row.status}}</span>
              </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </ng-template>
        </div>
        <!-- DATA TABLE END  -->
      </section>
      <!--/ Kitchen Sink Datatables -->
    </div>
  </div>
  