import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PaymentService } from './services/payment.service';
import { Router } from '@angular/router';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as enLocale } from "./i18n/en";
import { locale as vnLocale } from "./i18n/vn";
import { PaymentInfo } from './models/payment.model';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentComponent implements OnInit {
  public no_full_service = false

  public qandA: any;

  Monthly = false;

  constructor(
    private _paymentService: PaymentService,
    private _router: Router,
    private _coreTranslateService: CoreTranslationService
  ) {}

  ngOnInit(): void {
    this.qandA = [
      {
        question: "PAYMENT.FAQ.Q1",
        ans: "PAYMENT.FAQ.A1",
      },
      {
        question: "PAYMENT.FAQ.Q2",
        ans: "PAYMENT.FAQ.A2",
      },
    ];
    this._coreTranslateService.translate(enLocale);
    this._coreTranslateService.translate(vnLocale);
  }

  buyQuota(packageName: string) {
    // localStorage.setItem("paymentAmount", amountCharge);
    // localStorage.setItem("paymentQuota", quotaAmount);
    var paymentInfo = new PaymentInfo(packageName);
    this._paymentService.setPaymentInfoValue(paymentInfo);
    this._router.navigate(["/pricing/order"]);
  }

}
