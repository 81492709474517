import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlatpickrOptions } from 'ng2-flatpickr';

import { AccountSettingsService } from 'app/main/pages/account-settings/account-settings.service';
import { User } from 'app/auth/models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { ResumeParserService } from 'app/main/resume-parser/services/resume-parser.service';
import { ResultConnectSf4c, ResultSourceMapping } from 'app/main/resume-parser/models/resume.model';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as enLocale } from "./i18n/en";
import { locale as vnLocal } from "./i18n/vn";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Stepper from 'bs-stepper';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  // public
  public contentHeader: object;
  public data: any;
  public birthDateOptions: FlatpickrOptions = {
    altInput: true
  };
  //password
  public passwordTextTypeOld = false;
  public passwordTextTypeNew = false;
  public passwordTextTypeRetype = false;
  public avatarImage: string;
  public ChangeProfileForm: FormGroup
  public submitted = false;
  // private
  private _unsubscribeAll: Subject<any>;
  public errorMessagesGetDetailUser!:any
  public errorMessagesResultResumeSettings!:any
  public errorMessagesPutResultResumeSettings!: any
  public errorMessagesSyncEmail!:any
  public errorMessagesUpdateUser!: any
  public user: User = new User()
  public first_name! : string|undefined
  public last_name! : string|undefined
  public email!: string|undefined
  public password!: string
  public company_taxcode!: string
  public auto_approve_done_resume: boolean=false
  public auto_sync_email: boolean=false
  public id!: number
  public approve_score: number
  public censor_contact: boolean
  public webhook_parsing_api: string
  public webhook_dev_parsing_api: string
  public webhook_matching_api: string
  public webhook_dev_matching_api: string
  public webhook_recommend_api: string
  public webhook_recommend_dev_api: string

  public tag_sync_email=false
  public status!: string
  public edit_user = false
  public password_arr :string[]= []
  public EditProfileForm: FormGroup
  //Connect Sf4c
  ConnectSf4cForm!: FormGroup;
  @ViewChild('InputVar')
  myInputVariable!: ElementRef;
  public loading=false
  public errorMessagesResultConnectSf4c!: any
  public errorMessagesResultConnectSf4cs!: any

  public resultConnectSf4c!: ResultConnectSf4c
  public tag_resultConnectSf4c =false
  public tag_resultConnectSf4c_exit =false
  //Source mapping
  public resultSourceMapping: ResultSourceMapping = new ResultSourceMapping()
  public idMapping!: number
  public id_company!: number
  public emailMapping!: string
  public gender!: string
  public address!: string
  public date_of_birth!: string
  public firstNameMapping!: string
  public lastNameMapping!: string
  public phone_number!: string
  public current_title!: string
  public current_company!: string
  public id_education!: string
  public start_time_education!: string
  public end_time_education!: string
  public major!: string
  public school!: string
  public diploma!: string
  public gpa!: string
  public id_experience!: string
  public start_time_experience!: string
  public end_time_experience!: string
  public position!: string
  public company!: string
  public detail!: string
  public matching_score!: string
  public autoupload_score!: number
  public errorMessagesGetSourceMapping!: any
  public edit_source_mapping = false

  //manage user
  public errorMessagesListUser!:any
  public users: User[] = []
  public currentUser: User;
  public searchText: string = '';
  page = 1;
  pageSize = 5;
  basicSelectedOption = 5
  pageAssignUser = 1;
  pageSizeAssignUser = 5;
  basicSelectedOptionAssignUser = 5
  createUserForm: FormGroup
  public _checkbox:string

  deleteUserForm = this.fb.group({
    checkUser: ["",Validators.required]
  })

  UpdateUserTalentPoolForm = this.fb.group({
    is_accessible: false
  })
  public editBoolean = false

  passwordTextType: boolean=false;
  private horizontalWizardStepper: Stepper;

  /**
   * Constructor
   *
   * @param {AccountSettingsService} _accountSettingsService
   */
  constructor(
    private _accountSettingsService: AccountSettingsService,
    private _router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private _authenticationService: AuthenticationService,
    private resumesService: ResumeParserService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private modalService: NgbModal,
    ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this._unsubscribeAll = new Subject();
    this.EditProfileForm = this.fb.group({
      first_name: [''],
      last_name: [''],
      email: ['']
    })
    this.ChangeProfileForm = this.fb.group({
      old_password:['', [Validators.required]],
      new_password: ['', [Validators.required]],
      confirmPassword: ['']
    }, { validator: [this.checkPasswords, this.checkPassword] });
    this.ConnectSf4cForm = new FormGroup({
      file: new FormControl(null, Validators.required),
      api_key: new FormControl('', Validators.required),
      company_id: new FormControl('', Validators.required),
      username: new FormControl('', Validators.required),
      token_url: new FormControl('', Validators.required),

    });
    this.createUserForm = this.fb.group({
      first_name: ["",Validators.required],
      last_name: ["", Validators.required],
      email: ["", Validators.email],
      password: ["", Validators.required],
      checkSearchEngine: false,
      checkParseResume: false,
      is_site_admin: false,
      id: [""]
    })
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle Password Text Type Old
   */
  togglePasswordTextTypeOld() {
    this.passwordTextTypeOld = !this.passwordTextTypeOld;
  }

  /**
   * Toggle Password Text Type New
   */
  togglePasswordTextTypeNew() {
    this.passwordTextTypeNew = !this.passwordTextTypeNew;
  }

  /**
   * Toggle Password Text Type Retype
   */
  togglePasswordTextTypeRetype() {
    this.passwordTextTypeRetype = !this.passwordTextTypeRetype;
  }

  /**
   * Upload Image
   *
   * @param event
   */
  uploadImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (event: any) => {
        this.avatarImage = event.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls['new_password'].value;
    let confirmPass = group.controls['confirmPassword'].value;
    let old_password = group.controls['old_password'].value;
    
    return pass !== old_password ? true : { notMatch: true}
    
  }
  checkPassword(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls['new_password'].value;
    let confirmPass = group.controls['confirmPassword'].value;
    let old_password = group.controls['old_password'].value;
    
    return pass == confirmPass ? true : { notSame: true}
    
  }
  changePassword(){
    this.submitted = true;
    if(this.ChangeProfileForm.invalid){
      return;
    }
    let formData: any = {}
    formData["current_password"] = this.ChangeProfileForm.value.old_password
    formData["new_password"] = this.ChangeProfileForm.value.new_password
    this._authenticationService.changePassword(formData).subscribe(
      (res)=>{
        this.status = res;
        this.toastr.success('Change Password Success',);
      },
      (err)=>{
        this.toastr.error(err,);
      }
    )
  }
  get f(){
    return this.ChangeProfileForm.controls;
  }



  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  resumeSettings(){
    this.resumesService.resumeSettings().subscribe(
      (res)=>{
        this.auto_approve_done_resume = res.auto_approve_done_resume;
        this.auto_sync_email = res.auto_sync_email;
        this.id = res.id
        this.censor_contact = res.censor_contact;
        this.approve_score = res.approve_score;
        this.webhook_parsing_api = res.webhook_parsing_api;
        this.webhook_dev_parsing_api = res.webhook_dev_parsing_api;
        this.webhook_matching_api = res.webhook_matching_api;
        this.webhook_dev_matching_api = res.webhook_dev_matching_api;
        this.webhook_recommend_api = res.webhook_recommend_api;
        this.webhook_recommend_dev_api = res.webhook_recommend_dev_api;
      }
    )
  }
  editUser(){
    this.user = this._authenticationService.currentUserValue
    this.EditProfileForm.patchValue({
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      email: this.user.email
    })
  }
  saveProfile(){
    const id = Number(this.user.id);
    let formData:any = {}
    formData["first_name"] = this.EditProfileForm.value.first_name
    formData["last_name"] = this.EditProfileForm.value.last_name
    formData["email"] = this.EditProfileForm.value.email

    this._authenticationService.updateUser(id,formData).subscribe(
      (res)=>{
        this._authenticationService.setCurrentUserValue(res)
        this.first_name = res.first_name
        this.last_name = res.last_name
        this.email = res.email
        this.toastr.success("Edit user success")
        this.editUser()
      },
      (err)=>{
        this.errorMessagesUpdateUser = err
        this.toastr.error(err)
      }
    )
  }
  putResumeSettings(){
    let formData: any = {}
    formData["id"]=this.id;
    formData["auto_approve_done_resume"]=this.auto_approve_done_resume;
    formData["auto_sync_email"]=this.auto_sync_email;
    formData["censor_contact"]=this.censor_contact;
    formData["approve_score"]=this.approve_score;
    formData["webhook_parsing_api"]=this.webhook_parsing_api;
    formData["webhook_dev_parsing_api"]=this.webhook_dev_parsing_api;
    formData["webhook_matching_api"]=this.webhook_matching_api;
    formData["webhook_dev_matching_api"]=this.webhook_dev_matching_api;
    formData["webhook_recommend_api"]=this.webhook_recommend_api;
    formData["webhook_recommend_dev_api"]=this.webhook_recommend_dev_api;
    this.resumesService.putResumeSettings(formData).subscribe(
      (res)=>{
        this.toastr.success('Auto Setting Config Success',);

      },
      (err)=>{
        this.errorMessagesPutResultResumeSettings = err
      }
    )
  }
  syncCv(){
    this.resumesService.syncEmail().subscribe(
      (res)=>{
        this.status = res.status;
        this.toastr.success('Sync Email Success',);
      },
      (err)=>{
        this.errorMessagesSyncEmail = err
      }
    )
  }
  //connect sf4c
  selectedFiles?: FileList;

  onFileSelect(event: any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      if(file){
        // const file = event.target.files.item(0);
        this.ConnectSf4cForm.patchValue({ file: file });
        }
      }
    }
  GetCredential(){
    this.tag_resultConnectSf4c_exit = false

    this.loading = true
    let file= this.ConnectSf4cForm.value.file
    let api_key= this.ConnectSf4cForm.value.api_key
    let company_id= this.ConnectSf4cForm.value.company_id
    let username= this.ConnectSf4cForm.value.username
    let token_url= this.ConnectSf4cForm.value.token_url
    this.resumesService.getCredential(file,api_key,company_id,username,token_url).subscribe(
      (res)=>{
        this.resultConnectSf4c = res;
        this.tag_resultConnectSf4c = true;
        this.tag_resultConnectSf4c_exit = false
        this.toastr.success("Create credential success")
      },
      (err)=>{
        this.toastr.error(err)
        this.errorMessagesResultConnectSf4c = err
      }
    )
  }
  getListCredential(){
    this.resumesService.getListCredential().subscribe(
      (res)=>{
        
        this.resultConnectSf4c = res[0];
        this.tag_resultConnectSf4c_exit=true
        this.editConnectSf4c();
      },
      (err)=>{
        this.errorMessagesResultConnectSf4cs = err
      }
    )
  }
  editConnectSf4c(){
    
    this.tag_resultConnectSf4c_exit = false
    this.ConnectSf4cForm.patchValue({
      file: null,
      api_key: null,
      company_id: this.resultConnectSf4c?.company_id,
      username: this.resultConnectSf4c?.username,
      token_url: this.resultConnectSf4c?.token_url,
    })
    
  }
  //Source mapping
  SourceMapping(){
    let education = {
      id: this.id_education,
      start_time: this.start_time_education,
      end_time: this.end_time_education,
      major: this.major,
      school: this.school,
      diploma: this.diploma,
      gpa: this.gpa
    }
    let experience = {
      id: this.id_experience,
      start_time: this.start_time_experience,
      end_time: this.end_time_experience,
      position: this.position,
      company: this.company,
      detail: this.detail
    }
    let config = {
      email: this.emailMapping,
      gender: this.gender,
      address: this.address,
      date_of_birth:this.date_of_birth,
      first_name: this.firstNameMapping,
      last_name: this.lastNameMapping,
      phone_number: this.phone_number,
      current_title: this.current_title,
      current_company: this.current_company,
      education: {"education": education},
      experience: {"outsideWorkExperience": experience}
    }
    let jobapplication_config = {
      matching_score: this.matching_score
    }
    let autoupload_score = {
      autoupload_score: Number(this.autoupload_score)
    }
    this.resumesService.edit_source_mapping(this.idMapping, config, jobapplication_config, this.id_company,this.autoupload_score).subscribe(
      (res)=>{
        this.toastr.success("Mapping success");
        this.GetSourceMapping()
      },
      (err)=>{
        this.toastr.error(err)
        this.errorMessagesGetSourceMapping = err
      }
    )
  }
  GetSourceMapping(){
    this.resumesService.get_source_mapping().subscribe(
      (res)=>{
        this.resultSourceMapping = res
        this.idMapping = this.resultSourceMapping.id
        this.id_company = this.resultSourceMapping.company
        this.emailMapping = this.resultSourceMapping.config.email
        this.gender = this.resultSourceMapping.config.gender
        this.address = this.resultSourceMapping.config.address
        this.date_of_birth = this.resultSourceMapping.config.date_of_birth
        this.firstNameMapping = this.resultSourceMapping.config.first_name
        this.lastNameMapping = this.resultSourceMapping.config.last_name
        this.phone_number = this.resultSourceMapping.config.phone_number
        this.current_title = this.resultSourceMapping.config.current_title
        this.current_company = this.resultSourceMapping.config.current_company
        this.id_education = this.resultSourceMapping.config.education.education.id
        this.start_time_education = this.resultSourceMapping.config.education.education.start_time
        this.end_time_education = this.resultSourceMapping.config.education.education.end_time
        this.major = this.resultSourceMapping.config.education.education.major
        this.school = this.resultSourceMapping.config.education.education.school
        this.diploma = this.resultSourceMapping.config.education.education.diploma
        this.gpa = this.resultSourceMapping.config.education.education.gpa
        this.id_experience = this.resultSourceMapping.config.experience.outsideWorkExperience.id
        this.start_time_experience = this.resultSourceMapping.config.experience.outsideWorkExperience.start_time
        this.end_time_experience = this.resultSourceMapping.config.experience.outsideWorkExperience.end_time
        this.position = this.resultSourceMapping.config.experience.outsideWorkExperience.position
        this.company = this.resultSourceMapping.config.experience.outsideWorkExperience.company
        this.detail = this.resultSourceMapping.config.experience.outsideWorkExperience.detail
        this.matching_score = this.resultSourceMapping.jobapplication_config.matching_score
        this.autoupload_score = this.resultSourceMapping.autoupload_score
      },
      (err)=>{
        this.errorMessagesGetSourceMapping = err
      }
    )
  }
  //manage user
  Search(){
    // alert(this.searchText)
    if(this.searchText!== ""){
      let searchValue = this.searchText.toLocaleLowerCase();
      
      this.users = this.users.filter((user:any) =>{
        return user.email.toLocaleLowerCase().match(searchValue )
        ;
      // you can keep on adding object properties here

            });

          }
          else{
            this.listUser();
          }
        }
        listUser(){
          this._authenticationService.listUsers().subscribe(
            (res)=>{
              this.users = res.results;
              this.users = Object.assign([], this.users);
            },
            (err)=>{
              this.errorMessagesListUser = err
            }
          )
        }
        get f_user() {
          return this.createUserForm.controls;
        }
        modalOpenCreateUser(modalBasic:any, info: any = null) {
          if(info){
            this.editBoolean = true
            this.createUserForm = this.fb.group({
              first_name: ["",Validators.required],
              last_name: ["", Validators.required],
              email: ["", Validators.email],
              password: [""],
              checkSearchEngine: false,
              checkParseResume: false,
              is_site_admin: false,
              id: [""]
            })
            this.createUserForm.get("first_name").setValue(info.first_name);
            this.createUserForm.get("last_name").setValue(info.last_name);
            this.createUserForm.get("email").setValue(info.email);
            // this.createUserForm.get("password").setValue(info.password);
            this.createUserForm.get("is_site_admin").setValue(info.is_site_admin);
            this.createUserForm.get("checkSearchEngine").setValue(info.checkSearchEngine)
            this.createUserForm.get("checkParseResume").setValue(info.checkParseResume)
            this.createUserForm.get("id").setValue(info.id)
      
          }
          this.modalService.open(modalBasic, {
            windowClass: 'modal',
            size: 'lg',
            scrollable: true
          });
        }
        CreateUser(){
          let formData: any = {}
          let permissions: string[]=[]
          if(this.createUserForm.value.checkSearchEngine){
            permissions.push("search_engine")
          }
          if(this.createUserForm.value.checkParseResume){
            permissions.push("parse_cv")
          }
          let first_name= this.createUserForm.value.first_name
          let last_name= this.createUserForm.value.last_name
          let email= this.createUserForm.value.email
          let password= this.createUserForm.value.password
          let is_site_admin= this.createUserForm.value.is_site_admin
          let id = this.createUserForm.get("id").value
          formData["first_name"] = this.createUserForm.value.first_name
          formData["last_name"] = this.createUserForm.value.last_name
          formData["email"] = this.createUserForm.value.email
          formData["permissions"] = permissions
          formData["is_site_admin"] = this.createUserForm.value.is_site_admin

          if(id){
            this._authenticationService.adminUpdateUser(id,first_name, last_name,email,permissions,is_site_admin).subscribe(
              (res)=>{
                this.toastr.success('Edit user success');
                this.createUserForm.reset();
                this.modalService.dismissAll();
                this.listUser();
                
              },
              (err)=>{
                this.toastr.error(err)
                this.createUserForm.reset();
                this.modalService.dismissAll()
              }
            )
          }else{
            this._authenticationService.createUser(formData).subscribe(
              (res)=>{
                this.toastr.success('Create user success');
                this.createUserForm.reset();
                this.modalService.dismissAll();
                this.listUser();
              },
              (err)=>{
                this.toastr.error(err)
                this.createUserForm.reset();
                this.modalService.dismissAll()
              }
            )
          }
        }
        
        modalOpenDeleteUser(modalBasic:any) {
          this.page = 1
          this.modalService.open(modalBasic, {
            windowClass: 'modal',
            size: 'lg',
            scrollable: true
          });
        }
        onCheckboxChange(id: string, isChecked: boolean) {

          if (isChecked) {
            this._checkbox = id
          } else {
            this._checkbox = ""
          }
        }
        // onCheckboxChange(e:any) {
        //   let checkUser: any = this.deleteUserForm.get('checkUser');
        //   if (e.target.checked) {
        //     console.log("aaa,",e.target.value)
        //     checkUser=new FormControl(e.target.value);
        //     this._checkbox = e.target.value
            
        //   } else {
        //     checkUser = new FormControl()
        //     this._checkbox = ""
        //   }
        // }
        DeleteUser(id: any){
          this._authenticationService.deleteUser(id, this._checkbox).subscribe(
            (res)=>{
              this.toastr.success('Delete user success');
              this.modalService.dismissAll();
              this.listUser();
            },
            (err)=>{
              this.toastr.error(err);
              this.modalService.dismissAll();
            }
          )
        }
        //Talent pool
        modalOpenUpdatePermissionTalentPool(modalBasic:any) {
          this.modalService.open(modalBasic, {
            windowClass: 'modal',
            size: 'lg',
            scrollable: true
          });
        }
        UpdateUserPermissionTalentPool(id:any){
          let is_accessible = this.UpdateUserTalentPoolForm.value.is_accessible;
          this._authenticationService.update_user_permission_talent_pool(id, is_accessible).subscribe(
            (res)=>{
              this.toastr.success("Update user permission talent pool success");
              this.modalService.dismissAll();
              this.UpdateUserTalentPoolForm.reset();
              this.listUser();
            },
            (err)=>{
              this.toastr.error(err);
              this.UpdateUserTalentPoolForm.reset();
              this.modalService.dismissAll();
            }
          )
        }
        togglePasswordTextType() {
          this.passwordTextType = !this.passwordTextType;
        }
  /**
   * On init
   */
  ngOnInit() {
    this.listUser();
    // this.user = JSON.parse(localStorage.getItem("currentUser")|| '{}');
    this.user = this._authenticationService.currentUserValue
    this.currentUser = this._authenticationService.currentUserValue;
    
    this.editUser()
    this.resumeSettings();
    this.getListCredential()
    if(this.user.role == 'Site Admin'){
      this.GetSourceMapping()
    }
    
    // content header
    this.contentHeader = {
      headerTitle: 'Account Settings',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          
          {
            name: 'Account Settings',
            isLink: false
          }
        ]
      }
    };
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
