import { Locale } from '@core/services/translation.service';

export const locale: Locale = {
  lang: 'en',
  data: {
    HEADER: {
      DASHBOARD: {
        COLLAPSIBLE: 'Overview',
        BADGE: '2',
        ANALYTICS: 'Analytics',
        ACTIVITY_HISTORY: 'Activity history'
      },
      RESUME_PARSER: {
        SECTION: 'Resume parser tools',
        PARSE_RESUME: 'Add resume',
        MANAGE_RESUME: 'Manage resume',
        INTEGRATION: 'Integration SAP SF',
        UPDATE_JA: 'Update JA SAP SF',

      },
      TALENT_POOL: {
        SECTION: 'Talent pool tools',
        CREATE_JOB: 'Create job',
        MANAGE_JOB: 'Manage job',
        SEARCH_CV: 'Search candidate',
        MONITOR_UPLOAD: 'Score candidates'
      },
      PRODUCT: 'Product',
      SOLUTION: 'Feature',
      PRICE: 'Pricing',
      ABOUT_US: "About us",
      SIGNIN: 'Sign In',
      TRY: 'Try for free',
      SIGNUP: 'Sign Up',
      PAGE_REST: "pages left",
      LOG_OUT: "Logout"
    },
    FOOTER: {
      CALL_US: "Call us",
      ADDRESS: "Address: 4th Floor, CMC Building, 11 Duy Tan Street, Dich Vong Hau Ward, Cau Giay District, Hanoi",
      ABOUT: "About CHR",
      INTRO: "Home",
      CONTACT: "Contact",
      PRICING: "Pricing",
      PHONE: "Phone number",
      TERMS: "Terms of Service",
      EXTRACT: "Extract resume",
      SEARCH: "Search resume",
      PARTNER: "Partner",
      LET_TAKL: "Start optimizing your resume management process today!",
      NAME: "Name",
      EMAIL: "Email *",
      PHONE_NUMBER: "Phone Number *",
      POSITION: "Position",
      COMPANY: "Company Name",
      REGISTER: "Contact",
      REGISTER_SUC: "Register success!",
      REGISTER_FAIL: "Register failure!"
    },
    ABOUT_US: {
      TITLE: "We help companies and candidates find the right fit",
      ABOUT: "We help companies and candidates find the right fit",
      ABOUT_DESCRIPTION: "We help companies and candidates find the right fit",
      C_HR: `C-HR is a proud product of CMC Applied Technology Institute (ATI)
      Founded in 2014, CMC ATI fosters CMC Corporation's research and 
      development for ground-breaking digital solutions that fits the modern 
      global technology vision `,
      CMC_ATI: `CMC ATI's vision is to be a pioneer in applied research for multiple DX applications,
      products, and services that proudly serve the economy, society and its citizens.
      Current CMC ATI's area of research includes BigData, AI, Blockchain, IoT, Security
      and its clients include multiple local & international corporations that put technology
      & DX as a fore-front emphasis in the 4.0 market landscape`,
      CORE_VALUE: `CMC ATI puts the customers at the core of its innovations.
      With endless aspiration and creativity, CMC-ers emphasize and translate 
      "as soon as possible" into their actions in order to inspire and fulfill their 
      commitments to serving their valued customers
      - Creativity 
      - C-Speed
      - Commitment
      - Customer Centricity`,
      VALUE: "Value",
      DES_VALUE: "The value we bring.",
      COMMUNITY: "Community",
      DES_COMMUNITY: "We realize that no great work is done alone. Every one of us has a unique value and, when put in the right position, will maximize our potential. We believe that is the foundation to achieve greatness.",
      UNDERSTANDING: "Understanding",
      DES_UNDERSTANDING:"No connection is at its fullest without understanding. We put no one as we strive to listen and deeply understand each of our connection to build a strong bond in the community of C-HR.",
      GROWTH: "Growth",
      DES_GROWTH: "Our company can only grow when each of its connections grows. Therefore, we deeply understand each of our connection to give the best possible platform for growth, subsequently, to achieve greatness as a whole.",
      FAQ: {
        TITLE: "Frequently Asked Questions",
        QUES1: "How does the free trial work",
        ANS1: "Select a plan and get a free 4-day free trial in any subscription plan. Post jobs for free during your trial period. When your free trial ends, we charge the payment method you provided during signup. You can pause, cancel, or change your subscription at any time by signing into your account.",
        QUES2: "How accurate is the extraction?",
        ANS2: "Fullname: 97.22%, Address: 91.27%, Date of birth: 99.21%, Phone number: 99.21%, Current title: 78.97%, Experience: 73.31%, Education: 77.35%",
        QUES3: "How many CVs can we upload?",
        ANS3: "Can extract up to 50 resumes simultaneously",
        QUES4: "What is the speed of extracting CV information?",
        ANS4: "On average, 2 seconds per resume and 0.8 seconds per page.",
        QUES5: "How long will it take to post my job?",
        ANS5: "Our job posting form only takes about 1 minute to complete. You need to include the job title, job description, and job requirements."
      }

    },
    INTRODUCE: {
      TITLE: 'C-HR Smart Recruitment Solution',
      SUB_TITLE: `CMC Corporation's AI-integrated platform that supports resume digitalization, 
      candidate advanced search and smart suggestions, ultimately optimizing resume
      management process`,
    },
    SOLUTION: {
      TITLE: 'Outstanding features',
      AVG_0: {
        TITLE: 'Innovative AI-integration',
        SUB_TITLE:
          `C-HR operates based on CMC's trademark AI technologies in NLP, CV, ML to support 
          resume management process`,
      },
      AVG_1: {
        TITLE: 'Prompt & Precision',
        SUB_TITLE:
          `C-HR integrated technologies' speed and precision have been proven compared 
          to local & international competitors, along with several recognitions and awards`,
      },
      AVG_2: {
        TITLE: 'Smart Agility ',
        SUB_TITLE: 'C-HR features allow multiple files upload & platforms, databases integration',
      },
      AVG_3: {
        TITLE: 'User-centric interface',
        SUB_TITLE: 'An intuitive interface allows seamless experience throughout platform usage ',
      },
    },
    CHOSE: {
      TITLE: 'Why Choose',
      REASON_1: {
        TITLE: `C-HR optimizes candidates' resume digitization`,
        SUB_TITLE:
          `Instead of having to manually classify candidate information, C-HR automates candidate information into 10+ useful fields
          C-HR's flexibility supports a variety of file formats as well as supports uploading multiple files 
          helping digitizing candidate information faster and more accurately than ever before`,
      },
      REASON_2: {
        TITLE: `C-HR optimizes candidate matching for jobs' fill-in`,
        SUB_TITLE:
          `Relevance score is calculated using CMC's AI trademark technology
          Suitable candidate resumes will be smart-suggested and saved within C-HR database
          `,
      },
      REASON_3: {
        TITLE: 'C-HR optimizes recruitment support for multiple business models',
        SUB_TITLE:
          `No need to worry about product flexibility, C-HR features can be adjusted & customized accordingly to each customer's unique business model
          C-HR is a current vendor of standard corporate and recruitment consulting customers`,
      },
    },
    PRODUCT: {
      TITLE: 'Try the product now',
      PROCCESSED: ' processed documents',
    },
    CUSTOMER: {
      TITLE: 'Our customers'
    },
    PAYMENT: {
      TITLE: "Choose the best package to fit your needs.",
      DESCRIPTION:
        "",
      PARSE: "Parsing",
      FULL_SERVICE: "Full service",
      PLAN_1: {
        BASIC: "Basic",
        SUB_BASIC: "Regular business customers",
        COUNT_BASIC: "document",
        PRICING_BASIC_PARSING: "9.999.000đ/month",
        PRICING_BASIC: "15.999.000đ/month",
        FEATURE_BASIC1: "Extracting 14 Fields of CV Information",
        FEATURE_BASIC2: "Format conversion: PDF, Doc, Docx, png, jpeg",
        FEATURE_BASIC3: "3 Users",
        FEATURE_BASIC4: "20 CVs Extracted Simultaneously",
        FEATURE_BASIC5: "Configure Callback After CV Extraction",
        FEATURE_BASIC6: "Manage job description",
        FEATURE_BASIC7: "Suggesting Candidates Matching the Job Requirements",
        FEATURE_BASIC8: "Scoring CV based on criteria: education, skills, work experience",
        
      },
      PLAN_2: {
        STANDARD: "Standard",
        SUB_STANDARD: "Perfect for businesses that need to capture specific data points from documents and train on their data",
        COUNT_STANDARD: "document",
        PRICING_STANDARD_PARSING: "19.999.000đ/month",
        PRICING_STANDARD: "24.999.000đ/month",
        FEATURE_STANDARD1: "Extracting 14 Fields of CV Information",
        FEATURE_STANDARD2: "Format conversion: PDF, Doc, Docx, png, jpeg",
        FEATURE_STANDARD3: "10 Users",
        FEATURE_STANDARD4: "50 CVs Extracted Simultaneously",
        FEATURE_STANDARD5: "Machine learning capability",
        FEATURE_STANDARD6: "Configure Callback After CV Extraction",
        FEATURE_STANDARD7: "Technical Support",
        FEATURE_STANDARD8: "Manage job description",
        FEATURE_STANDARD9: "Suggesting Candidates Matching the Job Requirements",
        FEATURE_STANDARD10: "Scoring CV based on criteria: education, skills, work experience, advanced matching",
        
        MOST_POPULAR: "Most Popular",
        },
      PLAN_3: {
        ENTERPRISE: "Enterprise",
        SUB_ENTERPRISE: "Ideal for enterprises that need to process multiple document types, train on their data, and implement custom workflows",
        COUNT_ENTERPRISE: "Contact",
        PRICING_ENTERPRISE: "Custom Pricing",
        FEATURE_ENTERPRISE1: "Extracting 14 Fields of CV Information",
        FEATURE_ENTERPRISE2: "Format conversion: PDF, Doc, Docx, png, jpeg",
        FEATURE_ENTERPRISE3: "Unlimited users",
        FEATURE_ENTERPRISE4: "50 CVs Extracted Simultaneously",
        FEATURE_ENTERPRISE5: "Machine learning capability",
        FEATURE_ENTERPRISE6: "Technical Support",
        FEATURE_ENTERPRISE7: "Integration support for SAP, CRM",
        FEATURE_ENTERPRISE8: "Synchronize Customer Database Support",
        
        

      },
      PRICING: "Custom Pricing",
      BUY_NOW: "Buy now",
    },
    UPLOAD: {
      TITLE: 'Extract or Convert Format',
      FEATURE_TYPE: {
        EXTRACT: "Information extraction",
        CONVERT: "Document digitization"
      },
      DOCUMENT_TYPE:{
        BANG_TOT_NGHIEP: "Diploma",
        CMND_4_LOAI: "ID Card",
        CMND_4_LOAI_MAT_SAU: "ID Card Backside",
        DANG_KIEM: "Registration",
        DANG_KY_KET_HON: "Certificate of marriage",
        GCNDN: "Certificate of Business registration",
        GPLX: "Driving license",
        VCB: "Application for opening VCB account",
        UY_NHIEM_CHI: "Payment Order",
        CONSENT_LETTER: "Consent Letter of Customer Personal Data",
        HOA_DON_BAN: "VAT Invoice",
        HOA_DON_VIEN_PHI: "Hospital bills",
        HO_CHIEU: "Passport",
        GIAY_KHAI_SINH: "Birth Certificate",
        DANG_KY_XE: "Vehicle registration",
        DON_THUOC: "Prescription",
      },
      LABEL_UPLOAD: 'Drag and drop documents here or upload from:',
      FROM_DEVICE: 'From Device',
      TABLE: {
        NAME: 'File Name',
        TIME: 'Upload Time',
        STATUS: {
          TITLE: 'Status',
          PROCESS: 'Processed',
          ERROR: 'Error',
          CONFIRMED: 'Confirmed',
          DOWNLOADED: 'Downloaded',
          QUEUEING: "Queueing",
          BEING_PROCESSED: "Being processed"
        },
        ACTIONS: 'Actions',
        TOOLTIPS: {
          EXCEL: 'Download Excel',
          WORD: 'Download Word',
          TEXT: 'Download text file',
        },
        BUTTON: {
          CANCEL: 'Cancel',
          DOWNLOAD: 'Download Data',
        },
        MODAL: {
          TITLE: 'Select Format',
          TAB: {
            BASIC: 'Basic Format',
            ACCOUNTING: 'Accounting Software',
          },
          MISA: 'MISA Accounting Software',
          FAST: 'FAST Accounting Software',
        },
      },
      TOASTR:{
        UPLOAD_SUC: 'Upload file',
        SUCCESS: 'Success',
        DOWNLOAD: 'Download',
        ERROR: 'Error',
        ERROR_FILE_DROP: 'Drop only 1 file to the system!',
        DELETE: "Delete"
      },
      EXPERIENCE_ALL_FEATURE: "Experience all feature"
    },
    DOCUMENT: {
      TITLE: "BASIC INFORMATION",
      TABLE: "Table",
      SIGNATURE: "Signature",
      MATCHED: "Matched",
      EXPORT: "Export information"
    },
    DESCRIPTION: {
      DES_1: {
        TITLE: 'Support Multiple Input Types',
        SUB_TITLE:
          'Support various input formats, allowing you to optimize your document processing workflow and save time and resources.',
      },
      DES_2: {
        TITLE: 'Streamline Document Processing from Emails',
        SUB_TITLE:
          'Integrate with email and support direct file import, enabling you to quickly and easily process documents from multiple sources.',
      },
      DES_3: {
        TITLE: 'Self-Improvement Capability',
        SUB_TITLE:
          'By using machine learning and advanced data analysis algorithms, our AI can continue learning and improving results over time.',
      },
      DES_4: {
        TITLE: 'Cloud Computing',
        SUB_TITLE:
          'All documents will be processed and stored in the cloud, saving you storage costs and allowing convenient access to documents anytime, anywhere without the need to install any software.',
      },
      DES_5: {
        TITLE: 'Enhanced Accuracy',
        SUB_TITLE:
          'Enhance accuracy and minimize incidents and errors related to manual data entry, saving time and increasing work efficiency through optimized recognition of both handwriting and printed text.',
      },
      DES_6: {
        TITLE: 'Easy Integration',
        SUB_TITLE:
          'With batch processing, custom configurations, and integration with other software, CMC OCR solution is a comprehensive, user-friendly, and intuitive document management solution.',
      },
    },
    AUTH: {
      AUTH_LOGIN:{
        TITLE: "Welcome to SmartDoc! 👋",
        SUB_TITLE: "Please sign-in to your account and start the adventure",
        EMAIL: "Email or User name",
        REQUIRED: "This field is required",
        EMAIL_REQUIRED: "Email must be a valid email address",
        PHONE_REQUIRED: "The phone number must have 10 digits",
        PASSWORD: "Password",
        SIGNIN: "Sign in",
        NEW_PLATFORM: "New on our platform?",
        CREATE: "Create an account",
        OR: "or"
      },
      AUTH_REG: {
        TITLE: "Adventure starts here 🚀",
        SUB_TITLE: "Make your app management easy and fun!",
        USER_NAME: "User name",
        SIGNUP: "Sign up",
        HAVE_ACCOUNT: "Already have an account?",
        SIGNIN: "Sign in instead",
        
      },
      AUTH_NOTI: {
        CHECK_EMAIL: "Check your email to activate account!",
        BACK_TO: "Back to login"
      }
    }
  },
};
