import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbCollapseModule, NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { RouterModule } from '@angular/router';
import { CoreDirectivesModule } from '@core/directives/directives';


@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    TranslateModule,
    NgbCollapseModule,
    NgbModule,
    RouterModule,
    NgbDropdownModule,
    CoreDirectivesModule,
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
