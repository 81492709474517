export const locale = {
    lang: 'en',
    data: {
        DASHBOARD:{
            CHOOSE_COMPANY: "Choose Company",
            ALL_COMPANY: "All companys",
            FILTER: "Filter",
            RESET: "Reset",
            RESUME_UPLOADED: "Resume Uploaded",
            RESUME_UPLOADED_DAY: "Resume Uploaded in last 7 days",
            AVERAGE_CONF_SCORE: "Average Parsing Confidence Score",
            RESUME_STATUS: "Resume by status",
            RESUME_OWNER: "Resume by owner",
            RESUME_STATUS_DAY: "Resume status by Day in last 14 days",
            
            RESUME_STATUS_SF4C: "Resume status uploaded to SuccessFactors",
            RESUME_STATUS_SF4C_DAY: "Resume status uploaded to SuccessFactors by Day in last 14 days",
            
            RESUME_MATCHING: "Resume Matching Uploaded",
            RESUME_MATCHING_DAY: "Resume Matching Uploaded in last 7 days",
            RESUME_MATCHING_SF4C: "Resume matching status uploaded to SuccessFactors",
            RESUME_MATCHING_SF4C_DAY: "Resume matching status uploaded to SuccessFactors by Day in last 14 days",
            JOB_ID: "Job Id",
            CREATE_AT: "Create at",
            CREATE_BY: "Created by",
            COUNT_RECOMMEND: "Count recommend",
            RESUME_ID: "Resume Id",
            OVERALL_SCORE: "Overall score",
            NAME_JOB: "Name job",
            ITEM_PAGE: "Items per page",
            CHOOSE_PERIOD: "Choose period"
          }
      
    }
  };
  