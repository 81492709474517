import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Stat, StatResumeMatching } from 'app/main/resume-parser/models/resume.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private BASE_URL = `${environment.apiUrl}`;

  constructor(
    private _http: HttpClient,
  ) { }
  //Get stat
  getStat(url_dynamic: string): Observable<Stat>{
    const url = `${this.BASE_URL}/resume/stat?`+`${url_dynamic}`;
    return this._http.get<Stat>(url);
  }
  //Get stat resume matching
  getStatResumeMatching(url_dynamic: string): Observable<Stat>{
    const url = `${this.BASE_URL}/resume-matching/sf4c/stat?`+`${url_dynamic}`;
    return this._http.get<Stat>(url)
  }
  //Stat resume matching recruitery
  getStatMatchingRecruitery(page: number, page_size: number): Observable<StatResumeMatching>{
    const url = `${this.BASE_URL}/resume-matching/stat?page=${page}&page_size=${page_size}`;
    return this._http.get<StatResumeMatching>(url)
  }
}
