import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CV_task } from '../../models/job.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TalentPoolService } from '../../services/talent-pool.service';
import { locale as enLocale } from "../../i18n/en";
import { locale as vnLocal } from "../../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
@Component({
  selector: 'app-create-job',
  templateUrl: './create-job.component.html',
  styleUrls: ['./create-job.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateJobComponent implements OnInit {
  isSubmitted = false;
  public detail_jd: CV_task = new CV_task();
  public job_title: string = "";
  public location = [
    { name: "Hà Nội", value: "ha noi" },
    { name: "Hồ Chí Minh", value: "ho chi minh" },
    { name: "Đà Nẵng", value: "da nang" },
    { name: "Tất cả", value: null },

  ];
  public selectedLocation!: string;
  public job_level = [
    { name: "Thực tập sinh", value: "Student/Internship" },
    { name: "Mới tốt nghiệp", value: "Entry Level" },
    { name: "Nhân viên", value: "Staff" },
    { name: "Chuyên viên", value: "Specialist" },
    { name: "Trưởng phòng/Giám sát", value: "Team Leader/Supervisor" },
    // { id: 6, name: "Trưởng phòng", value: "Manager" },
    { name: "Giám đốc và cấp cao hơn", value: "Director" },
    { name: "Tất cả", value: null },
  ];
  public selectedRank!: string;
  public industry = [
    { id: 15, name: "THÔNG TIN VÀ TRUYỀN THÔNG", value: "THÔNG TIN VÀ TRUYỀN THÔNG"},
    {id: 4, name: "CHUYÊN MÔN, KHOA HỌC VÀ CÔNG NGHỆ", value: "HOẠT ĐỘNG CHUYÊN MÔN, KHOA HỌC VÀ CÔNG NGHỆ"},
    { id: 14, name: "TÀI CHÍNH, NGÂN HÀNG VÀ BẢO HIỂM", value: "HOẠT ĐỘNG TÀI CHÍNH, NGÂN HÀNG VÀ BẢO HIỂM"},
    { id: 7, name: "GIÁO DỤC VÀ ĐÀO TẠO", value: "GIÁO DỤC VÀ ĐÀO TẠO"},

    { id: 1, name: "BÁN BUÔN VÀ BÁN LẺ; SỬA CHỮA CÁC XE ĐỘNG CƠ", value: "BÁN BUÔN VÀ BÁN LẺ; SỬA CHỮA Ô TÔ, MÔ TÔ, XE MÁY VÀ XE CÓ ĐỘNG CƠ KHÁC" },
    { id: 2, name: "CUNG CẤP NƯỚC VÀ XỬ LÝ CHẤT THẢI", value: "CUNG CẤP NƯỚC; HOẠT ĐỘNG QUẢN LÝ VÀ XỬ LÝ RÁC THẢI, NƯỚC THẢI"},
    { id: 3, name: "CÔNG NGHIỆP CHẾ BIẾN, CHẾ TẠO", value: "CÔNG NGHIỆP CHẾ BIẾN, CHẾ TẠO"},
    
    { id: 5, name: "DỊCH VỤ LƯU TRÚ VÀ ĂN UỐNG", value: "DỊCH VỤ LƯU TRÚ VÀ ĂN UỐNG"},
    
    
    { id: 6, name: "DỊCH VỤ KHÁC", value: "HOẠT ĐỘNG DỊCH VỤ KHÁC"},
    
    { id: 8, name: "HÀNH CHÍNH VÀ DỊCH VỤ HỖ TRỢ", value: "HOẠT ĐỘNG HÀNH CHÍNH VÀ DỊCH VỤ HỖ TRỢ"},
    { id: 9, name: "KINH DOANH BẤT ĐỘNG SẢN", value: "HOẠT ĐỘNG KINH DOANH BẤT ĐỘNG SẢN"},
    { id: 10, name: "KHAI KHOÁNG", value: "KHAI KHOÁNG"},
    
    
    { id: 11, name: "NGHỆ THUẬT, VUI CHƠI VÀ GIẢI TRÍ", value: "NGHỆ THUẬT, VUI CHƠI VÀ GIẢI TRÍ"},
    { id: 12, name: "NÔNG NGHIỆP, LÂM NGHIỆP VÀ THUỶ SẢN", value: "NÔNG NGHIỆP, LÂM NGHIỆP VÀ THUỶ SẢN"},
    { id: 13, name: "VẬN TẢI", value: "VẬN TẢI KHO BÃI"},
    
    
    
    { id: 16, name: "XÂY DỰNG", value: "XÂY DỰNG"},
    { id: 17, name: "Y TẾ ", value: "Y TẾ VÀ HOẠT ĐỘNG TRỢ GIÚP XÃ HỘI"},
    {id: 18, name: "TẤT CẢ", value: 'TẤT CẢ'}
  ];
  public selectedBusiness: string[] =[];
  public job_type = [
    { name: "Toàn thời gian", value: "full_time" },
    { name: "Bán Thời Gian", value: "part_time" },
    { name: "Hợp Đồng", value: "contract" },
    { name: "Làm Từ Xa", value: "remote" },
    { name: "Thực Tập", value: "intern" },
  ];
  public selectedTypeWork!: string;
  public english_skill = [
    {name: "Sơ cấp", value: "so_cap"},
    {name: "Cao cấp", value: "cao_cap"},

  ]
  // public selectedEnglishSkill: string
  //YearsOfExprience
  public yearOfExperienceFrom: number;
  public yearOfExperienceTo: number
  
  //yob from to
  public yob_from: number;
  public yob_to: number;

  public keyword_expression: string = ""

  public gender = [
    {name: "Nam", vaule: "male"},
    {name: "Nữ", value: "female"},
    {name: "Tất cả", value: ""}
  ]
  public selectedGender!: string;
  public job_description: string = "";
  public requirements: string =
    "";


  public page = 1;
  public searchText = "";
  public basicSelectedOption: number = 5;
  public disabled:boolean = true
  public errorMessagesEditJd!:any
  public errorMessagesCreateNewJd!: any
  public isAddMode!:boolean
  public id!: string
  public isAuthenticated=true
  constructor(
    private route: ActivatedRoute,
    private router_: Router,
    private talentpoolService: TalentPoolService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
   }
  getDetailJob(){
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    if(!this.isAddMode){
      this.talentpoolService.detail_jd(this.id).subscribe(
        (res)=>{
          this.job_title = res.job_title
          this.selectedLocation = res.location
          this.selectedRank = res.job_level
          this.selectedBusiness = res.industry
          this.selectedBusiness = Object.assign([], this.selectedBusiness);
          this.selectedTypeWork = res.job_type
          this.job_description = res.job_description
          this.requirements = res.requirements
  
          this.yearOfExperienceFrom = res.years_of_experience_from
          this.yearOfExperienceTo = res.years_of_experience_to
          this.yob_from = res.yob_from
          this.yob_to = res.yob_to
          
          this.selectedGender = res.gender
          this.keyword_expression = res.keyword_expression
        },
        (err)=>{
          console.error("error: "+err)
        }
      )
    }
  }
  onSubmit(){
    if (this.isAddMode) {
      this.SearchCv();
      } else {
          this.ChangeCv();
      }
    }
    SearchCv(): void {
      let job_title= this.job_title
      let location= this.selectedLocation
      let job_level= this.selectedRank
      let industry= this.selectedBusiness
      let job_type= this.selectedTypeWork
      let job_description= this.job_description
      let requirements= this.requirements
      let years_of_experience_from = this.yearOfExperienceFrom
      let years_of_experience_to = this.yearOfExperienceTo
      let yob_from= this.yob_from
      let yob_to= this.yob_to
      let gender= this.selectedGender
      let keyword_expression= this.keyword_expression
      let hcm_system_id= null
      this.talentpoolService.create_new_jd(job_title, location, job_level, industry,job_type,
        job_description, requirements, years_of_experience_from, years_of_experience_to, yob_from, yob_to,gender, keyword_expression, hcm_system_id).subscribe(
          (res)=>{
            if(res.id!=undefined){
              this.detail_jd = res;
              let url = "/manage-job"
              //let url = "/search-resume/recommend-resume/" + this.detail_jd.id.toString();
              this.router_.navigateByUrl(url, { replaceUrl: true });
            }
          }
        )
    }
    ChangeCv(): void {
      const jd_id= Number(this.route.snapshot.paramMap.get('id'))
      let job_title= this.job_title
      let location= this.selectedLocation
      let job_level= this.selectedRank
      let industry= this.selectedBusiness
      let job_type= this.selectedTypeWork
      let job_description= this.job_description
      let requirements= this.requirements
      let years_of_experience_from = this.yearOfExperienceFrom
      let years_of_experience_to = this.yearOfExperienceTo
      let yob_from= this.yob_from
      let yob_to= this.yob_to
      let gender= this.selectedGender
      let keyword_expression= this.keyword_expression
      this.talentpoolService.edit_jd(jd_id, job_title, location, job_level, industry,job_type,
        job_description, requirements, years_of_experience_from, years_of_experience_to, yob_from, yob_to,gender, keyword_expression).subscribe(
          (res)=>{
            this.detail_jd = res;
            window.scroll({ 
              top: 0, 
              left: 0, 
              behavior: 'smooth' 
              });
            let url = "/manage-job/detail-job/" + this.detail_jd.id.toString();
            this.router_.navigateByUrl(url, { replaceUrl: true });
          },
          (err)=>{
            this.errorMessagesEditJd = err
          }
        )
    }
  ngOnInit(): void {
    this.getDetailJob();
  }

}
