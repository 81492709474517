<!-- Under maintenance-->
<!-- <div [ngClass]="coreConfig.layout.skin === 'dark' ?'bg-url-dark' : 'bg-url-dark'" class="misc-wrapper"> -->
    <div class="misc-wrapper auth-v1 auth-wrapper">
        <div class="auth-inner">
          <div class="card">
            <div class="card-body bg-primary rounded" style="padding-top: 40px; padding-bottom: 40px">
              <button class="btn btn-icon">
                <a routerLink="/">
                  <span [data-feather]="'home'" [size]="25" class="text-white"></span>
                </a>
              </button>
      
              <div class="w-100 bg-white rounded position-relative">
                <div class="pt-1">
                  <div class="bg-white position-absolute"
                    style="top: 0; left: 50%;transform: translate(-50%, -50%); padding: 10px; border-radius: 50%;">
                    <img [src]="linkImage" width="60" alt="" style="margin: 0 auto">
                  </div>
                  <div class="position-relative pb-2">
                    <h2 class="mt-3 w-100 text-center mb-0"
                      [style]="{ color:  transactionInfo?.status == PaymentStatus.SUCCESS ? 'green' : transactionInfo?.status == PaymentStatus.PENDING ? '#FFBF00' : 'red'}"
                      [translate]="transactionInfo?.status == PaymentStatus.SUCCESS ? 'PAYMENT_STATUS.SUCCESS' : transactionInfo?.status == PaymentStatus.PENDING ? 'PAYMENT_STATUS.PENDING' : 'PAYMENT_STATUS.ERROR'">
                    </h2>
                    <div class="rounded-circle bg-primary position-absolute"
                      style="bottom: 0; left: 0; width: 20px; height: 20px; transform: translate(-50%, 50%)"></div>
                    <div class="rounded-circle bg-primary position-absolute"
                      style="bottom: 0; left: 100%; width: 20px; height: 20px; transform: translate(-50%, 50%)"></div>
                  </div>
      
                  <div class="invoice p-2
                  " style="border-top: 1px #EDEDED dashed;">
                    <div>
                      <h2 style="text-align: center;"><span translate="PAYMENT_STATUS.TITLE"></span> <span
                          class="text-primary">{{coreConfig.app.appName}}</span></h2>
                    </div>
                    <div class="py-2" style="border-bottom: 1px #EDEDED dashed;border-top: 1px #EDEDED dashed;">
                      <div class="row mt-1">
                        <div class="col-4 text-left font-weight-bold"><span translate="PAYMENT_STATUS.QUOTA"></span></div>
                        <div class="col-8 text-right font-weight-normal">{{transactionInfo?.quota_buy}}</div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-6 text-left font-weight-bold d-flex align-items-center"><span
                            translate="PAYMENT_STATUS.PAYMENT_ID"></span>
                          <div class="avatar bg-light-info ml-1 mr-custom">
                            <div class="avatar-content">
                              <a href="javascript:void(0)" (click)="copyToThisClipboard(transactionInfo?.id)"
                                class="d-flex align-items-center justify-content-center" title="Copy Id to the Clipboard"
                                style="color: #00cfe8"><i data-feather="copy"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 text-right font-weight-normal text-truncate">{{transactionInfo?.id}}</div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-4 text-left"><span translate="PAYMENT_STATUS.DATE"></span></div>
                        <div class="col-8 text-right font-weight-normal">{{transactionInfo?.date}}</div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-4 text-left"><span translate="PAYMENT_STATUS.TIME"></span></div>
                        <div class="col-8 text-right font-weight-normal">{{transactionInfo?.time}}</div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-4 text-left font-weight-bold"><span translate="PAYMENT_STATUS.VIA"></span></div>
                        <div class="col-8 text-right font-weight-normal">{{transactionInfo?.type}}</div>
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div class="col-4 text-left" style="font-weight: 800"><span translate="PAYMENT_STATUS.TOTAL"></span>
                      </div>
                      <div class="col-8 text-right" style="font-weight: 800">{{transactionInfo?.amount}}đ</div>
                    </div>
      
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card w-100">
            <button class="w-100 btn btn-primary"><a routerLink="/pricing" style="color: white"
                translate="PAYMENT_STATUS.CREATE_NEW"></a></button>
          </div>
        </div>
      </div>
      <!-- / Under maintenance-->