export class EducationResume{
    END_TIME: string=''
    SCHOOL: string = ''
    START_TIME: string = ''
    MAJOR: string = ''
}
export class Metadata{
    ADDRESS: string= ''
    DOB: string = ''
    EMAIL: string = ''
    FULLNAME: string = ''
    GENDER: string = ''
    PHONE_NUMBER: string = ''
}
export class Work_experience{
    COMPANY: string = ''
    POSITION: string = ''
    START_TIME: string = ''
    END_TIME: string = ''
}
export class resultResume{
    education: EducationResume[]=[]
    metadata: Metadata=new Metadata()
    work_experience: Work_experience[]=[]
}

export class ResultResume{
    result: resultResume = new resultResume()
    images: string[] = []
    status: string = ''
}
export class _experience{
    id!: number
    company!: string
    start_time!: any
    end_time!: any
    position!: string
    conf_score!: number
    detail!: string
    industry!: string
}
export class _education{
    id!: number
    end_time!: any
    school: string = ''
    start_time!: any
    major: string = ''
    conf_score!: number
    diploma!: string
    gpa!: string
    picklist_major!: string
}

export class ResumeParser{
    id!: number
    pdf_content!: string
    file_name!: string
    status!: string
    message: any
    owner!: string
    created_at!: string
    modified_at!: string
    first_name!: string
    last_name!: string
    email!: string
    phone_number!: string
    gender!: string
    date_of_birth!: any
    address!: string
    experience: _experience[]=[]
    education: _education[]=[]
    task!: number
    successfactor_update_status!: string
    sf4c_status!: string
    sf4c_message: string
    base_status!: string
    current_title!: string
    current_company!: string
    successfactor_resume_id!: string
    job_application_status!: string
    job_application_detail_status!: string
    conf_score!:number
    category!: any
    year_exp!: any
    skills: string[]=[]
}

export class ListResumeParser{
    count!: number
    results: ResumeParser[]=[]
}

export class ResultUpload{
    id!: number
    Limited_error!: string[]
    created_at!: string
    modified_at!: string
    status!: string
    create_by!: number
    num_resumes!: number
    num_done!: number
    num_fail!: number
    num_suspend!:number
    num_processing!: number
}
export class Status{
    status!: string
    message !:string
}
export class Group_status{
    status!: string
    total!: number
}
export class Group_owner{
    owner__email!: string
    total!: number
}
export class Group_sf4c_status{
    status!:string
    total!: number
}
export class Status_by_day{
    index!: string
    Failed!: number
    Success!: number
}
export class Successfactor_status_by_day{
    index!: string
    Failed!: number
    Success!: number
}
export class Group_job_requisition{
    hcm_system_id!: string
    total!: number
}
//Statistic
export class Stat{
    num_resumes!: number
    latest_resumes!: number
    avg_score!: number
    group_status: Group_status[]=[]
    group_owner: Group_owner[]=[]
    group_sf4c_status: Group_sf4c_status[]=[]
    status_by_day: Status_by_day[]=[]
    successfactor_status_by_day: Successfactor_status_by_day[]=[]
    group_job_requisition: Group_job_requisition[] = []
}
export class StatResumeMatching{
    count!: number
    results!: ResultsResumeMatching[]
}
export class ResultsResumeMatching{
    id!: number
    job_id!: number
    created_at!: string
    created_by!: string
    result!: ResultDetailResumeMatching[]
    jd_detail!: JrDetail
}
export class JrDetail{
    title!: string
    description!: string
    requirements!: string
}
export class ResultDetailResumeMatching{
    resume_id!: number
    overall_score!: number
}
export class ResultConnectSf4c{
    id!: number
    company_id!: string
    token_url!: string
    client_id!: string
    username!: string
    expire_in_days!: number
    created_at!: string
    modified_at!: string
}
export class ResultResumeSettings{
    id!: number
    auto_approve_done_resume!: boolean
    auto_sync_email!: boolean
    approve_score: number
    censor_contact: boolean
    webhook_parsing_api: any
    webhook_dev_parsing_api: any
    webhook_matching_api: any
    webhook_dev_matching_api: any
    webhook_recommend_api: any
    webhook_recommend_dev_api: any
}
export class Jr{
    jobReqId!: string
    jobTitle!: string
    externalTitle!: string
    jobDescription!: string
    extJobDescHeader!: string
    competencies!: Competencies
    city: string[]=[]
    fulltext_bool_expression!: string
    experience_bool_expression!: string
    education_bool_expression!: string
    current_title_bool_expression!: string
    current_company_bool_expression!: string
    loaded: boolean = false;
    saved_cvs: any[] = []
    score!: number
    otherrequire!:string
}
export class Competencies{
    results!: []
}
export class Sf4cUpload{
    status!: string
    message!: string
    job_application_status!: string
}

export class Sf4cCandidate{
    candidateId!: string
    primaryEmail!: string
    cellPhone!: string
    currentTitle!: string
    currentCompany!: string
    gender!: string
    country!: string
    firstName!: string
    lastName!: string
    address!: string
    dateOfBirth!: string
    education: ResultEducation[]=[]
    outsideWorkExperience: ResultOutsideWorkExperience[]=[]
    resume!: Resume
}
export class ResultEducation{
    backgroundElementId!: string
    startDate!: string
    endDate!: string
    major1!: string
    school!: string
    degree!: string
    gpa!: string 
}
export class ResultOutsideWorkExperience{
    backgroundElementId!: string
    startDate!: string
    endDate!: string
    startTitle!: string
    employer!: string
    status!: string
}
//source mapping
export class ResultSourceMapping{
    id!: number
    config!: Config
    company!: number
    jobapplication_config!: Jobapplication_configMap
    autoupload_score!: number
}
export class Config{
    email!: string
    gender!: string
    address!: string
    education!: EducationMap
    experience!: ExperienceMap
    last_name!: string
    first_name!: string
    phone_number!: string
    current_title!: string
    current_company!: string
    date_of_birth!: string
}
export class EducationMap{
    education!: educationMap
}
export class educationMap{
    id!: string
    start_time!: string
    end_time!: string
    major!: string
    school!: string
    diploma!: string
    gpa!: string
}
export class experienceMap{
    id!: string
    start_time!: string
    end_time!: string
    position!: string
    company!: string
    detail!: string
}
export class ExperienceMap{
    outsideWorkExperience!: experienceMap
}
export class Jobapplication_configMap{
    matching_score!: string
}
export class Resume{
    fileContent!: string 
}
//update sf4c resume
export class Successfactor_update_status{
    successfactor_update_status!: string
    job_application_status!: string
}
//Matching jr
export class All_jrs{
    jobReqId!: string
    jobTitle!: string
}
export class Experience_match{
    score!: number
}
export class Education_match{
    score!: number
}
export class Language_match{
    score!: number
}
export class Matched{
    type!: string
    value!: string
    text!: string
    status!: string
}
export class Skill{
    matched: Matched[] = []
}
export class Match_result{
    experience: Experience_match = new Experience_match()
    education: Education_match = new Education_match()
    language: Language_match = new Language_match()
    skill: Skill = new Skill()
    overall_score!: number
    detail!: any
}
export class Matching_JR{
    jobReqId!: string
    jobTitle!: string
    all_jrs: All_jrs[] = []
    match_result!: Match_result
}
//Mapping picklist
export class CHR_Picklist{
    id!: number
    type_picklist!: string
    label!: string
}
export class Mapping_Picklist{
    id!: number
    sf4c_label_id!: string
    sf4c_eng_label!: string
    sf4c_vn_label!: string
    company!: number
    chr_label!: number
}

