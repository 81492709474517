<!-- <div style="height: 150px;background: white;">

</div> -->
<div class="container-fluid block5" id="customer">
    <!-- carousel-->
    <div class="title-carousel container container-customer">
      <div id="demo1" class="carousel slide" data-bs-ride="carousel">
        <div class="text-center">
          <h2 class="mt-5" style="font-size: 48px">
            <span [translate]="'CUSTOMER.TITLE'"></span>
          </h2>
          <!-- Left and right controls/icons -->
          
        </div>
        <!-- content-->
  
        <div>
          
          <div class="card-contain slick-wrapper mt-5">
            <ngx-slick-carousel
              class="carousel"
              #slickModal="slick-carousel"
              [config]="slideConfig"
            >
              <div ngxSlickItem *ngFor="let customer of customers" class="slide">
                <div
                  class="m-2"
                >
                  <div
                    class="bg-image hover-overlay ripple rounded-0"
                    data-mdb-ripple-color="light"
                  >
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <div>
                        <img
                          [src]="customer.image"
                          alt=""
                          width="100%"
                          height="100%"
                          class=""
                          style="
                            object-fit: cover;
                            display: block;
                            max-height: 90px;
                          "
                        />
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
  